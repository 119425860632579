// @import "./base.scss";
// Large devices (desktops, 767px and up)
@media (min-width: 320px) and (max-width: 414px) {
  .error-page-section1 {
    .vh100-100 {
      min-height: calc(100vh - 175px) !important;

      .error-img-wrap {
        position: relative;
        top: 30px;
      }
    }
  }
  


}

@media (min-width: 320px) and (max-width: 767.98px) {
  .logo-list{
    overflow: auto;
    padding-bottom: 20px;
  }
.home-sec7{
ul{
  flex-wrap: nowrap;
  overflow: auto;
  li{
    a{
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
           
    }
  }
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
	color: #000;
	background-color: #fff;
	border-bottom: 3px solid #f98e2f;
}
}


  
  .mobile-contact-btn{
    background: #fff !important;
    box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.1);
    padding: 15px;
    position: fixed;
    z-index: 9;
    bottom: 0px;
    width: 100%;
    color: #002B3B;
    span{
      font-size: 16px;
    }
  }

  #section10 {
    margin-bottom: 90px;
  }
  .container-fluid.h-100 {
    padding-bottom: 120px;
  }
  .text-white.lg-mt-60.aos-init.aos-animate {
    margin-bottom: 25px;
  }
  .logo-mark-img-adj2 {
    position: absolute;
    top: -98px;
    left: 25px;
    width: 210px;
  }
  .insisive-website-sect8-right.aos-init.aos-animate {
    margin-top: 50px;
  }

  .p3{
    font-size: 14px !important;
  }
  .mobile-adj{
   height: 650px;
  }
  .flexifyme-banner-img{
    margin-top: 250px !important;
  }
  .flexifyme-overview-img{
    margin-top: 90px !important;
  }
  .choose-slider-item-adj{
    margin-top: 70px;
  }
  .personas-sec-slider .ui-card-slider{
    transform-origin: 130px 50px;
  }
  .flow-diagrams-left-wrap{
   margin-top: 0;
  }
  .flexifyme-diagram-adj{
    margin-left: 25px !important;
    margin-top: 160px !important;
  }
  .wireframe-img2{
    display: none !important;
  }
.mobile-mt-adj{
  margin-top: 90px;
}
.amazin-result-img1 {
	position: relative;
	top: -20px;
	right: 16px;
}
.amazin-result-img2 {
	position: absolute;
	right: 0px;
	bottom: 110px;
}
.mobile-adj1{
  height: 700px;
 }
  .xs-mt70 {
    margin-top: 70px !important;
  }
  .swal2-title {
    font-size: 18px !important;
    line-height: normal !important;
    color: #242424 !important;
    font-weight: 600 !important;
    font-family: "Poppins", sans-serif !important;
  }
  .swal2-container .swal2-icon {
    transform: scale(0.8);
    transform-origin: center 0;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    margin-bottom: 0;
    margin-top: 15px;
  }
  h1,
  h2,
  h3 {
    br {
      display: none !important;
    }
  }
  .xs-mt100 {
    margin-top: 100px !important;
  }
  .xs-pt100 {
    padding-top: 100px !important;
  }
  .xs-pt60 {
    padding-top: 60px !important;
  }
  .xs-ml50 {
    margin-left: 50px !important;
  }
  .xs-mt30 {
    margin-top: 30px !important;
  }
  .xs-mt40 {
    margin-top: 40px !important;
  }
  .xs-mt50 {
    margin-top: 50px !important;
  }
  .xs-mt-30 {
    margin-top: -30px !important;
  }
  .xs-mt-15-adj {
    margin-top: -15px !important;
  }
  .xs-pb0 {
    padding-bottom: 0 !important;
  }
  .xs-pb30 {
    padding-bottom: 30px !important;
  }
  .xs-mb0 {
    margin-bottom: 0 !important;
  }
  .xs-mb10 {
    margin-bottom: 10px !important;
  }
  .xs-mb20 {
    margin-bottom: 20px !important;
  }
  .xs-mr0 {
    margin-right: 0 !important;
  }
  .xs-mt10 {
    margin-top: 10px !important;
  }
  .xs-mt25 {
    margin-top: 25px !important;
  }
  .xs-mt15 {
    margin-top: 15px !important;
  }
  .xs-mt30 {
    margin-top: 30px !important;
  }
  .xs-mt35 {
    margin-top: 35px !important;
  }
  .xs-mt60 {
    margin-top: 60px !important;
  }
  .xs-mb45 {
    margin-bottom: 45px !important;
  }
  .xs-mr20 {
    margin-right: 20px !important;
  }
  .xs-mt55 {
    margin-top: 55px !important;
  }
  .xs-pt0 {
    padding-top: 0 !important;
  }
  .xs-pt10 {
    padding-top: 10px !important;
  }
  .xs-no-margin {
    margin: 0 !important;
  }
  .xs-text-center {
    text-align: center !important;
  }
  .xs-no-padding {
    padding: 0 !important;
  }
  .bg-blue-xs {
    background: #53c7ff !important;
  }
  .order-xs-last {
    order: 5;
  }
  .hide-mobile {
    display: none !important;
  }
  .show-mobile {
    display: block !important;
  }
  .cursor {
    display: none;
  }
  h1,
  .h1 {
    font-size: 30px;

    line-height: 39px !important;
  }
  h2,
  .h2 {
    font-size: 28px;
    line-height: 36.4px !important;
    font-weight: 600 !important;
  }
  h3,
  .h3 {
    font-size: 26px;
    line-height: 34px !important;
    font-weight: 600 !important;
  }
  h4,
  .h4 {
    font-size: 18px !important;

    line-height: 23.4px !important;
  }

  h5,
  .h5 {
    font-size: 18px;

    line-height: 23.4px !important;
  }
  h6,
  .h6 {
    font-size: 14px;

    line-height: 18.2px !important;
  }
  .p1 {
    font-size: 16px;
    line-height: 25px;
    margin: 0;
  }
  .p2 {
    font-size: 14px;
    line-height: 19px;
    margin: 0;
  }
  .p3 {
    font-size: 12px;
    line-height: 19px;
    margin: 0;
  }
  .p4 {
    font-size: 12px;
    line-height: 19.4px;
    margin: 0;
  }
  .p5 {
    font-size: 10px;
    line-height: 16.2px;
    margin: 0;
  }
  .bttn {
    font-size: 14px;
  }
  .logo-img-xs-white {
    path {
      fill: $white !important;
    }
  }
  .btn-secondary-custom,
  .btn-primary-custom {
    padding: 9px 22px;
    min-width: 120px;
  }
  .custom-checkbox {
    .label-checkbox {
      padding-left: 30px;

      &::before {
        width: 16px !important;
        height: 16px !important;
        top: 3px;
        position: relative;
      }
    }
  }

  .btn-line {
    span {
      &::after {
        display: none !important;
      }
    }
  }

  .pr-form-control {
    position: relative;
    margin-bottom: 38px;

    .form-label {
      top: -6px;
    }

    .input-control-wrap {
      &.has-content,
      &:focus {
        ~ .form-label {
          top: -17px;
          transition: 0.3s;
        }

        ~ .focus-border {
          width: 100%;
        }
      }
    }
  }

  .container,
  .container-fluid {
    padding-right: 30px;
    padding-left: 30px;
  }
  .comman-header-menu-right {
    position: static;
    margin: 0;
  }
  .arrow-scroll-abs {
    display: none !important;
  }
  .header-row-mobile {
    max-height: calc(100vh - 100px);
    overflow: auto;
  }
  .comman-top-menu {
    clip-path: circle(30px at calc(100% - 45px) 45px);
    -webkit-clip-path: circle(30px at calc(100% - 45px) 45px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;

    .h2 {
      font-size: 38px !important;
      line-height: 50px !important;
    }

    .btn-line {
      span::after {
        display: none;
      }
    }

    .container-fluid.mw-1470 {
      position: fixed;
      top: 0;
      transition: all 0.2s linear;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      -ms-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      width: 100%;
      z-index: 890;
      padding: 30px 30px 15px;
    }

    .container.mw-1000 {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      margin-top: 60px;
      overflow: auto;
      height: initial !important;
    }
  }
  .expertise-sect2 .expertise-sect-right .expertise-ul-list li::before {
    width: 5px;
    height: 5px;
    left: -16px;
    top: 8px;
  }
  /* .comman-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 890;
    padding: 15px 0;
    transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    z-index: 890;
    padding: 15px 0;
    &.active-logo-white {
      .logo-img path {
        fill: $white;
      }
      .comman-header-menu-ul {
        .btn-link-custom {
          color: $white;
        }
        .mega-menu-img1 path {
          fill: $white;
        }
      }
    }
    &.nav-up {
      top: -150px !important;
    }
    &.nav-down {
      top: 0;
      background: rgba(255, 255, 255, 0.9);
      -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
      .logo-img path {
        fill: $black;
      }
      .comman-header-menu-ul {
        .btn-link-custom {
          color: $black;
        }
        .mega-menu-img1 path {
          fill: $black;
        }
      }
    }
  }
  */
  .comman-card-top-ul {
    margin: 0;

    li {
      &:first-child {
        &::after {
          top: 11px;
        }
      }
    }
  }

  .comman-section-100vh {
    .comman-card-img-wrap {
      transform: scale(0.55) translateZ(0);
      -webkit-transform: scale(0.55) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.55) translateZ(0);
      -ms-transform: scale(0.55) translateZ(0);
      -o-transform: scale(0.55) translateZ(0);
      transform-origin: 0 center;

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }

    .about-sec1-3 {
      bottom: -248px;
      left: 100px;
      z-index: 9;
    }

    .comman-card-info {
      br {
        display: none;
      }

      &.pl30 {
        padding-left: 15px !important;
      }

      h2.sec-heading {
        margin-bottom: 7px;
      }

      p.sec-para {
        margin-bottom: 10px;

        br {
          display: none;
        }
      }

      .comman-card-bottom-ul {
        margin-bottom: 8px;

        li {
          padding-left: 9px !important;
          margin-right: 15px;

          &::before {
            left: 0px;
            top: 5px;
            width: 4px;
            height: 4px;
          }
        }
      }

      .bttn {
        margin-top: 15px;
        min-width: 125px;
      }
    }
  }
  body {
    .comman-section {
      padding: 60px 0;
      overflow: hidden;

      .comman-card-info {
        .comman-card-top-ul {
          margin-bottom: 0;
        }

        .comman-card-bottom-ul {
          li {
            position: relative;
            padding-left: 20px;
            margin-right: 8px;

            &:last-child {
              margin-right: 0px !important;
            }

            .star-icon {
              font-size: 4px;
              vertical-align: top;
            }
          }
        }
      }

      .vh100-100 {
        min-height: inherit;
        position: relative;
      }
    }
  }

  .comman-footer {
    padding-bottom: 70px !important;

    .container,
    .container-fluid {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }

    .heart-text {
      margin-top: 10px;
      font-size: 12px;

      i.fa-heart-icon {
        font-size: 20px;
      }

      p {
        font-size: 14px;

        i {
          font-size: 14px;
        }
      }
    }

    h2,
    .h2 {
      margin-bottom: 20px;
      margin-top: 0;
      font-size: 72px;
    }

    .btn-secondary-custom {
      padding: 9px 37px;
    }

    .row:first-child {
      height: inherit;
    }

    .comman-footer-row1 {
      height: inherit;
    }

    .comman-footer-row2 {
      position: relative;
      bottom: auto;
      left: auto;
      right: auto;
      top: auto;
      margin-top: 60px;

      > div {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center !important;

        .bottom-menu-bottom-right.list-inline {
          margin: 25px auto 0;
          padding: 0 10px;
          max-width: 320px;
        }
      }
    }

    .bottom-menu-bottom-right {
      li.list-inline-item {
        margin-right: 0 !important;

        &:first-child {
          float: left;
        }

        &:last-child {
          float: right;
          margin-top: -5px;
        }
      }

      margin-top: 0;
      position: relative;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .bottom-menu-bottom-left {
      a {
        display: inline-block;
      }

      ul {
        margin: 0 !important;
      }
    }
  }

  .comman-top-menu-dark.comman-top-menu,
  .comman-top-menu {
    .top-menu-bottom {
      margin: 10px 0 0;
    }

    .top-menu-left {
      margin-bottom: 0;

      li {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }

    .top-menu-right {
      float: none;
      margin-top: 0;

      &::after {
        display: block;
        clear: both;
        content: "";
      }

      li {
        float: left;

        &:not(:last-child) {
          margin-bottom: 0;
          margin-right: 30px;
        }
      }
    }
  }

  .comman-header-wrap {
    .comman-top-menu {
      .top-menu-bottom {
        li {
          margin-right: 30px !important;

          a {
            font-size: 20px !important;

            span {
              display: none;
            }
          }
        }
      }
    }
  }
  .comman-footer {
    .comman-footer-row2 {
      ul.bottom-menu-bottom-left {
        li {
          margin-right: 15px !important;
          margin-left: 15px !important;

          a {
            font-size: 20px !important;

            span {
              display: none;
            }
          }
        }
      }
    }
  }

  .portfolio-info-wrap,
  .portfolio-info-wrap2 {
    h2,
    h3,
    p {
      br {
        display: none;
      }
    }
  }
  .error-page-section1 {
    padding-bottom: 10px;

    .portfolio-info-wrap2 {
      max-width: 270px;
      margin: auto;

      .bttn {
        margin-top: 20px;
      }
    }

    .error-img-wrap {
      position: relative;
      max-width: 280px;
      margin: 40px auto 30px;

      .error-page {
        max-width: 100%;
        width: auto;
        display: block;
      }

      .error-icon {
        max-width: 100%;
        width: 100px;
        display: block;
        top: 0;
        right: 40px;
        margin-left: 0;
      }
    }
  }
  .award-card {
    min-height: 250px;
    padding: 15px;
  }
  .home-sec-tab {
    .flex-column {
      .nav-link span::after {
        display: none;
      }

      .nav-link:not(:last-child) {
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid rgba($white, 0.1);
      }
    }

    .tab-content {
      margin-left: 0;
      margin-top: 30px;
    }
  }
  .carousel-comman-wrap {
    [data-animation] {
      animation: none !important;
      -webkit-animation: none !important;

      br {
        display: none;
      }
    }
  }
  .comman-card-img-wrap-md {
    transform-origin: 120px center !important;
  }
  .portfolio-info-wrap2 {
    h3 {
      margin-bottom: 5px;
    }

    p {
    }
  }
  .home-sec1 {
    text-align: center;

    .comman-card-img-wrap {
      transform: scale(0.5) translateZ(0);
      -webkit-transform: scale(0.5) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.5) translateZ(0);
      -ms-transform: scale(0.5) translateZ(0);
      -o-transform: scale(0.5) translateZ(0);
      height: 268px;
      transform-origin: 0 0;
      margin: 55px auto 0;
      width: 230px;
      min-height: inherit;
    }

    .h1 {
      br {
        display: block !important;
      }
    }

    .btn-primary-custom {
      margin-top: 26px !important;
    }
  }

  .home-sec3 {
    background: $black;

    .ani-wrap50-2,
    .ani-wrap50 {
      display: none !important;
    }

    .comman-card-top-ul {
      li {
        margin-right: 0 !important;
      }
    }

    .comman-card-info {
      margin-top: 0;
      margin-left: 0px;

      .comman-card-top-ul {
        margin-bottom: 10px;
      }

      .flex-column {
        margin-top: 17px;
      }
    }
  }
  .home-sec4-2 {
    left: -80px !important;
  }
  .home-sec4 {
    background: $white;
    text-align: center;

    .comman-card-info {
      .comman-card-top-ul {
        li {
          padding: 0;
          margin-right: 0 !important;
        }
      }
    }

    .ani-wrap50-3 {
      display: none !important;
    }

    .comman-card-info {
      margin-top: 0 !important;

      h2,
      .text-white {
        color: $black !important;
      }

      h2 {
        margin-top: 10px;
        padding-bottom: 20px;
      }
    }

    .client-logo-wrap {
      margin-left: 0;
      margin-top: 40px;

      ul {
        li {
          margin: 40px 0;
        }
      }
    }

    .client-logo-wrap-outer-col {
      left: 0;
      height: 368px;
    }
  }

  .home-sec5 {
    background: $black !important;

    .ani-wrap50-2 {
      display: none !important;
    }

    .owl-nav {
      margin-top: 30px;
      text-align: center;

      div {
        background: transparent;
        text-indent: -9999px;
        padding: 0;
        margin: 5px 20px !important;
        width: 45px;
        height: 45px;

        &:hover,
        &:active,
        &:focus {
          background-color: transparent;

          color: transparent;

          text-decoration: none;
        }
      }

      .owl-prev {
        background: transparent url("../images/svg/testimonial-left-arrow.svg") no-repeat 0 0 !important;
        padding-right: 15px;
      }

      .owl-next {
        position: relative;
        background: transparent url("../images/svg/testimonial-right-arrow.svg") no-repeat 0 0 !important;
      }
    }

    .testimonial-xs-carosual {
      margin-top: 24px;

      .fas {
        display: none !important;
      }

      h4 {
        margin-bottom: 15px;
      }

      p.p1 {
        margin-bottom: 15px;

        i {
          font-size: 8px;
          vertical-align: 6px;
        }
      }
    }
  }
  .home-sec6 {
    .container {
      padding: 0 !important;
    }

    .comman-card-info {
      padding-left: 30px;
      padding-right: 30px;
    }

    .awards-carosual {
      margin-top: -20px;
    }

    .owl-nav {
      display: none !important;
      position: absolute;
      bottom: 0;
      z-index: 9;
      margin: 0;
      background: transparent;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);

      div {
        background: transparent;
        text-indent: -9999px;
        padding: 0;
        margin: 0;
        width: 10px;
        height: 10px;

        &:hover,
        &:active,
        &:focus {
          background-color: transparent;

          color: transparent;

          text-decoration: none;
        }
      }

      .owl-prev {
        background: transparent url("../images/svg/slick-prev1.svg") no-repeat 0 0 !important;
        padding-right: 15px;
      }

      .owl-next {
        position: relative;
        background: transparent url("../images/svg/slick-next1.svg") no-repeat 0 0 !important;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -16px;
          background: url("../images/svg/slick-play1.svg") no-repeat 0 0 !important;
          width: 10px;
          height: 10px;
        }
      }
    }

    .awards-carosual {
      .owl-stage-outer {
        padding: 45px 10px;

        .owl-stage {
          .award-card {
            min-height: 335px;
            padding: 35px 25px;
            width: 234px;
            max-width: none;

            h6 {
              margin-bottom: -2px;
            }

            .card-img-wrap {
              margin-bottom: 15px;
              width: 100px;
            }

            p.p3.text-orange {
            }

            p.p3.text-light-dark-c {
              min-height: 25px;
              position: absolute;
              bottom: 36px;
              left: 24px;
              width: calc(100% - 50px);
            }
          }

          .owl-item.center {
            width: 248px !important;
          }
        }
      }
    }

    .award-logo-wrap {
      width: 100%;
      height: inherit;
      min-height: inherit;
      padding-top: 50px;
      overflow: auto;
      padding-left: 0;
      padding-bottom: 40px;

      .award-card {
        padding: 15px;
        width: 215px;
      }

      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: end;
        align-items: stretch;
        align-content: stretch;
        margin: 0;

        li {
          width: 215px;
        }

        li:nth-child(2n + 1) {
          margin-top: 15px;
          margin-right: 15px;
        }
      }
    }
  }
  .home-sec7 {
    padding-bottom: 0 !important;

    .process-img-wrap {
      height: inherit;

      .process-xs-img {
        margin-top: 30px;
      }
    }
  }
  .comman-card-img-wrap .home-sec1-1 {
    right: -270px;
  }
  .comman-card-img-wrap .home-sec5-1 {
    top: 300px;
    left: -113px;
    z-index: 4;
  }
  .comman-card-img-wrap .home-sec5-2 {
    top: -20px;
    left: -84px;
    z-index: 3;
  }
  .comman-card-right {
    padding-left: 25px;
  }
  .comman-card-left {
    padding-right: 25px;
  }
  .home-sec8 {
    .comman-card-img-wrap2 {
      transform: none;
      -webkit-transform: none;
      -webkit-backface-visibility: hidden;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform-origin: center center;
      left: auto;
      top: auto;
      margin-left: 0;
      margin-top: 30px;

      img {
        width: 100%;
      }

      #culture-carousel {
        max-width: 100%;
        position: relative;
        z-index: 4;
        margin-bottom: 15px !important;

        &::after {
          display: none !important;
        }
      }

      #culture-carousel2 {
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        right: auto;
        max-width: 100%;
        z-index: 3;
      }

      .home-sec8-3,
      .home-sec8-4 {
        display: none !important;
      }

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .about-sec1 {
    h4 {
      margin-bottom: 0;
    }

    .about-head-card-top-ul {
      margin-top: 23px;
      margin-bottom: 0;
      white-space: nowrap;

      li:not(:last-child) {
        margin-right: 25px;
      }

      h5.text-purple {
        min-width: 50px;
        font-size: 22px;
      }
    }

    .comman-card-img-wrap {
      height: 210px;
      margin-top: 60px !important;
      transform-origin: 0 0;
    }

    .btn-line {
      span {
        position: relative;

        &::before {
          display: none !important;
          width: 16px;
          top: 10px;
          right: -20px;
          height: 1.5px;
          margin-left: 0;
          content: "";
          position: absolute;
          background-color: $orange;
          transition: transform 0.3s;
        }
      }
    }

    .comman-card-img-wrap {
      margin: auto;
      width: 300px;
      min-height: inherit;
      height: 280px;
    }

    .about-sec1-3 {
      bottom: -178px;
    }
  }
  .about-sec2 {
    padding: 0 !important;

    .card-img-inner-wrap {
      position: relative;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      z-index: 9;
      height: 340px;
      margin: 30px auto 0;
      display: block;
      text-align: center;
    }
  }
  .about-sec3 {
    .comman-card-left {
      padding-right: 0;
    }

    .ani-wrap50-2 {
      position: absolute;
      top: 0;
      left: 0 !important;
      right: 0;
      bottom: 0;
      height: 50%;
      width: 100% !important;
    }

    .ani-wrap50 {
      background-color: #1a3453;
      position: absolute;
      top: 46%;
      left: 0 !important;
      right: 0;
      bottom: 0;
      height: 54%;
      width: 100% !important;
    }

    .comman-card-img-wrapper {
      height: 26px;
    }

    .vision-img1 {
      width: 60px;
    }

    .vision-img2 {
      width: 40px;
    }

    .mission-img1 {
      width: 60px;
    }

    .mission-img2 {
      width: 40px;
    }

    .comman-card-info.comman-card-right {
      margin-top: 0;
      padding-left: 0;
      padding-top: 50px;
    }

    .comman-card-info {
      max-width: inherit;
      min-height: 160px;
      margin: auto;
    }

    .comman-card-info {
      .comman-card-img-wrapper {
        height: 20px;
        margin-bottom: 15px;
      }
    }
  }
  .about-sec4 {
    padding-bottom: 0 !important;

    .comman-card-info {
      .comman-card-top-ul {
        li {
          color: $black !important;
        }
      }

      h2 {
        color: $black !important;
      }
    }

    background: $white;
    position: relative;

    .comman-card-info {
      h2 {
        br {
          display: none;
        }
      }
    }

    .ani-wrap50-3 {
      display: none;
    }

    .our-values-wrap {
      max-width: 500px;
      margin: 16px 0 0;
      height: 400px;
      overflow: auto;

      ul {
        margin-bottom: 0;

        .our-values-card {
          margin-bottom: 38px;
        }
      }

      h4 {
        margin-bottom: 8px;
      }
    }
  }
  .about-sec5-1 .about-expressions {
    position: absolute;
    top: -27px;
    right: -22px;
    width: 156px;
  }
  .about-sec5-2 .about-expressions {
    position: absolute;
    top: -27px;
    right: -22px;
    width: 156px;
  }
  .about-sec5 {
    padding-bottom: 20px !important;
    border-bottom: 3px solid #2f2f2f;

    .carousel-indicators-ul {
      position: relative;
      top: auto;
      left: auto;
      transform: none !important;
      height: auto !important;
      display: block;
      width: 100%;
      z-index: 49;
      -webkit-transform: none !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      -o-transform: none !important;
      margin: -10px 0 40px;
      height: 30px;
      text-align: center;

      li {
        border: 2px solid rgba(167, 167, 167, 0.6);
        display: inline-block !important;
        margin: 6px !important;
      }
    }

    .comman-card-info {
      margin-top: 7px;

      h2 {
        padding-top: 60px;

        span {
          display: inline-block;
          max-width: 420px;
        }

        .about-expressions1 {
          position: absolute;
          top: 30px;
          left: 0;
          width: 24px;
        }

        .about-expressions {
          position: absolute;
          top: -6px;
          right: 0px;
          width: 160px;
          z-index: 9;
        }

        .comman-card-footer {
          position: relative;
          margin-top: 45px;
          padding-top: 15px;
        }
      }
    }

    .ani-wrap50 {
      width: 100%;
      height: 100%;
    }

    .mt60-ratina-lg {
      margin-top: 0px !important;
    }

    .our-expressions-wrap {
      margin-top: 17px;
      transform: none !important;
      -webkit-transform: none !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      -o-transform: none !important;
      transform-origin: inherit;
      margin-left: auto;
      margin-bottom: 20px;

      .about-sec5-1 {
        display: none !important;
      }

      .comman-video {
        width: 100%;
        height: auto;
      }

      /*.comman-video:;
    {
            transform: scale(0.70) translateZ(0);
            -webkit-transform: scale(0.70) translateZ(0); -webkit-backface-visibility: hidden;
            -moz-transform: scale(0.70) translateZ(0);
            -ms-transform: scale(0.70) translateZ(0);
            -o-transform: scale(0.70) translateZ(0);
            transform-origin: center 200px;
            }
            .about-sec5-1 {
                position: absolute;
                top: 150px;
                right: 85px;
                z-index: 1;
                height: 330px;
            }*/
    }
  }
  .about-sec6 {
    // padding-top: 0 !important;
    .comman-card-img-wrap3 {
      position: relative;
      max-width: inherit;
      margin: 0;
    }

    .carousel-fade {
      margin-bottom: 15px;
    }

    #about-sec6-carousel2::after,
    #about-sec6-carousel4::after,
    #about-sec6-carousel::after {
      display: none;
    }

    #about-sec6-carousel,
    #about-sec6-carousel2,
    #about-sec6-carousel3,
    #about-sec6-carousel4 {
      position: relative !important;
      top: auto !important;
      left: auto !important;
      right: auto !important;
      bottom: auto !important;
      z-index: 2;
      width: inherit !important;
      height: inherit !important;

      img {
        max-width: 100%;
        width: 100%;
      }
    }

    .about-sec8-3 {
      display: none;
    }

    .comman-card-img-wrap3-outer {
      transform: none;
      -webkit-transform: none;
      -webkit-backface-visibility: hidden;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform-origin: center 0;
      height: inherit;
    }
  }

  .about-sec7 {
    //padding-top: 0 !important;
    margin: 80px 0px;

    .comman-card-info {
     // margin: 0 auto 0;

      .p2 {
        display: none;
      }
    }

    .about-team-row {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      flex-direction: column;
      margin-top: 30px;
    }

    .comman-team-card {
      img.st.priyanka-p-2 {
        left: 20px;
        top: 53px;
      }

      img.st.abdul-r-2 {
        left: 13px;
        top: 84px;
      }

      .abdul-r-img-adj {
        margin-left: 10px !important;
      }

      .sunil-b-adj {
        margin-left: 15px !important;
      }

      .dhananjay-a-adj {
        margin-left: -20px !important;
      }

      img.st.sunil-b-1 {
        left: 45px;
        top: 56px;
      }

      img.st.dhananjay-a-1 {
        left: 45px;
        top: 56px;
      }
    }

    .comman-team-card {
      width: 340px;
      height: 265px;
      position: relative;
      transform: scale(0.45) translateZ(0);
      transform-origin: 0 top;
      -webkit-transform: scale(0.45) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.45) translateZ(0);
      -ms-transform: scale(0.45) translateZ(0);
      -o-transform: scale(0.45) translateZ(0);
      margin: 0 auto 20px -10px !important;

      .comman-team-card-foot {
        h6.restlessRegular {
          font-size: 80px;
          margin-bottom: 18px;
          padding-top: 40px;
          height: 80px;
        }

        .p3 {
          font-size: 30px !important;
          line-height: normal;
          margin: 0;
          white-space: nowrap;
        }
      }

      .comman-team-card-head {
        img {
          filter: none;
          -webkit-filter: none;
          -moz-filter: blur(0px);
          -ms-filter: blur(0px);
          -webkit-backface-visibility: hidden;
        }
      }

      .team-card-left1,
      .team-card-left2,
      .team-card-right1,
      .team-card-right2,
      .team-card-center1,
      .team-card-center2 {
        transform: scale(0.6) !important;
        -webkit-transform: scale(0.6) !important;
        -moz-transform: scale(0.6) !important;
        -ms-transform: scale(0.6) !important;
        -o-transform: scale(0.6) !important;
        transform-origin: center center !important;
        width: inherit !important;
      }

      .team-card-right1 {
        position: absolute;
        left: 10px;
        top: -10px;
      }

      .team-card-right2 {
        position: absolute;
        left: 37px;
        top: -20px;
      }

      .team-card-left2 {
        position: absolute;
        left: -30px;
        top: -30px;
      }

      .team-card-left1 {
        position: absolute;
        left: 7px;
        top: -10px;
      }

      .team-card-center2 {
        position: absolute;
        left: 50px;
        top: -50px;
      }

      .team-card-center1 {
        position: absolute;
        left: -20px;
        top: -84px;
        width: 300px !important;
      }
    }

    .join-team-wrap {
      text-align: center;
      margin-top: 20px;
    }
  }
  .industries-section1 {
    .process-img-wrapper {
      position: relative;
      height: 70px;
      max-width: 100px;
      margin-bottom: 10px;
      transform: scale(0.7);
      transform-origin: 0 0;
      -webkit-transform: scale(0.7);
      -moz-transform: scale(0.7);
      -ms-transform: scale(0.7);
      -o-transform: scale(0.7);
    }
  }
  .process-section1 {
    .comman-card-info-left {
      margin-top: 0;
      margin-bottom: -20px;
      position: static;
      top: 0;
      transform: none;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
    }
  }
  .team-carosual {
    margin-top: 30px;
    // .owl-nav {
    //   display: none !important;
    // }
    .comman-team-card-foot h6.text-blank {
      padding-bottom: 10px;
    }

    .text-light-dark-c {
      margin-top: -5px;
    }

    .owl-nav {
      position: absolute;
      bottom: 10px;
      left: 50%;
      z-index: 9;
      margin: 0;
      background: transparent !important;
      transform: scale(1.3) translateX(-50%);
      -webkit-transform: scale(1.3) translateX(-50%);
      -moz-transform: scale(1.3) translateX(-50%);
      -ms-transform: scale(1.3) translateX(-50%);
      -o-transform: scale(1.3) translateX(-50%);
      margin-left: 30px;

      div {
        background: transparent;
        text-indent: -9999px;
        padding: 0;
        margin: 0;
        width: 10px;
        height: 10px;

        &:hover,
        &:active,
        &:focus {
          background-color: transparent;

          color: transparent;

          text-decoration: none;
        }
      }

      .owl-prev {
        background: transparent url("../images/svg/slick-prev1.svg") no-repeat 0 0 !important;
        padding-right: 15px;
      }

      .owl-next {
        position: relative;
        background: transparent url("../images/svg/slick-next1.svg") no-repeat 0 0 !important;

        &::before {
          content: "";
          position: absolute;
          top: 2px;
          left: -16px;
          background: url("../images/svg/slick-play1.svg") no-repeat 0 0 !important;
          width: 10px;
          height: 10px;
        }
      }
    }

    .comman-team-card img.st {
      width: auto !important;
    }

    .comman-team-card-head {
      width: 340px;
      height: 265px;
      position: relative;
      transform: scale(0.55) translateZ(0);
      transform-origin: center top;
      -webkit-transform: scale(0.55) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.55) translateZ(0);
      -ms-transform: scale(0.55) translateZ(0);
      -o-transform: scale(0.55) translateZ(0);
      margin: auto auto;

      .team-card-img1 {
        object-fit: contain;
      }
    }

    .comman-team-card-foot {
      margin-top: 0 !important;
      padding-bottom: 50px;
    }
  }
  .work-comman-card-info {
    margin-top: 70px;
  }
  .work-sec6 {
    .comman-card-info {
      h2.sec-heading {
        br {
          display: none;
        }
      }
    }
  }

  .process-section1,
  .industries-section1 {
    .process-img-wrapper {
      margin-bottom: 10px !important;
    }

    .comman-card-info-left {
      z-index: 0;
      max-width: 340px !important;
      margin-left: 0;
    }

    .process-section1-row {
      margin-top: 0;
    }

    .comman-card-info {
      &:not(:last-child) {
        margin-bottom: 80px;
      }
    }

    .process-img7 {
      right: 310px;
      height: 950px;
      display: none !important;
    }
  }
  .work-sec2 {
    .comman-card-info {
      max-width: 460px;
    }
  }
  .career-sec1 {
    .career-sec-1 {
      bottom: -246px;
    }

    .comman-card-img-wrap {
      transform: scale(0.5) translateZ(0);
      -webkit-transform: scale(0.5) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.5) translateZ(0);
      -ms-transform: scale(0.5) translateZ(0);
      -o-transform: scale(0.5) translateZ(0);
      height: 300px;
      transform-origin: 0 0;
      margin: 77px auto 0;
      width: 230px;
      min-height: inherit;
    }
  }
  .career-sec2 {
    .career-sec2-right {
      height: inherit;
      overflow: inherit;
      margin-top: 10px;

      .comman-card-info {
        padding-bottom: 30px;
        padding-top: 25px;
        margin-left: 0;

        h4 {
        }

        p.p2.sec-para {
          margin-top: 6px;
          margin-bottom: 13px;
        }
      }
    }
  }
  .career-sec3 {
    // border-top: 1px solid rgba($color: $black, $alpha: 0.15);
    padding-bottom: 0 !important;

    .addr-img-wrap {
      transform: scale(0.8);
      transform-origin: center 0;
      -webkit-transform: scale(0.8);
      -moz-transform: scale(0.8);
      -ms-transform: scale(0.8);
      -o-transform: scale(0.8);
      height: 47px;
    }

    .addr-wrap {
      margin-bottom: 80px;

      .addr-indo-wrap {
        margin-top: 0;

        .addr-list-inline {
          margin-bottom: 25px;

          .list-inline-item {
            padding-right: 15px;
            margin-right: 10px;

            &:nth(:last-child) {
              padding-right: 20px;
              margin-right: 10px;
            }
          }
        }
      }

      .btn-link-custom {
        .location-icon {
          width: 13px;
          margin-right: 8px;
        }
      }
    }
  }

  .contact-section1 {
    overflow: hidden;
    // &.top-section-wrapper {
    //   height: 265px;
    // }
    .career-form-wrap {
      .contact-form-inner {
      }
    }

    .ani-wrap100-top {
      width: 100% !important;
    }

    .sec-heading {
      margin-top: 46px;
      margin-bottom: 26px;
    }

    .addr-wrap-col-adj.hide-tab-view {
      display: none;
    }

    .addr-wrap {
      margin-left: auto;
      margin-top: 30px;

      &.mb90 {
        margin-bottom: 20px !important;
      }

      .addr-indo-wrap .addr-list-inline {
        margin-bottom: 23px;
        white-space: nowrap;

        .list-inline-item:not(:last-child) {
          padding-right: 20px;
          border-right: 1px solid rgba(113, 113, 113, 0.5);
          margin-right: 15px;
          line-height: 15px;
        }
      }

      .addr-img-wrap {
        position: relative;
        max-width: 86px;
        margin: 0;
        height: 53px;
        margin: auto;
        transform: scale(0.8) translateZ(0);
        transform-origin: 0 0;
        -webkit-transform: scale(0.8) translateZ(0);
        -webkit-backface-visibility: hidden;
        -moz-transform: scale(0.8) translateZ(0);
        -ms-transform: scale(0.8) translateZ(0);
        -o-transform: scale(0.8) translateZ(0);

        img {
          filter: none;
          -webkit-filter: none;
          -moz-filter: blur(0px);
          -ms-filter: blur(0px);
          -webkit-backface-visibility: hidden;
        }
      }
    }
  }
  .contact-map-md-section {
    .addr-img-wrap {
      transform: scale(0.8);
      transform-origin: center 0;
      -webkit-transform: scale(0.8);
      -moz-transform: scale(0.8);
      -ms-transform: scale(0.8);
      -o-transform: scale(0.8);
      height: 47px;
    }

    .addr-wrap .btn-link-custom .location-icon {
      width: 16px;
      margin-right: 11px;
    }
  }
  .g-capcha-col-wrap {
    margin-top: -10px;
  }
  .term-col-wrap {
    margin-top: 4px;

    label.error {
      left: 15px;
      bottom: -30px;
    }
  }
  .contact-section2 {
    overflow: hidden;

    .radioOverride label.form-label {
      margin: 0;
      padding-left: 5px;
    }

    .contact-form-inner {
      h5 {
        margin-bottom: 8px;
      }

      table {
        display: block;

        tbody,
        tr,
        td {
          display: block;
          width: 100%;
        }

        td {
          padding-bottom: 15px;
        }
      }
    }

    .my-budget-wrap {
      padding-left: 0;
      min-width: 400px;
      margin-left: 0;

      h4 {
        margin-left: 0;
      }
    }

    .carousel-indicators-ul {
      display: none !important;
      opacity: 0 !important;
    }

    .range-slider-main {
      margin-top: 25px;
      margin-left: 40px;

      .slider-tick-label {
        margin-left: 26px !important;
      }
    }

    .range-slider-main .slider.slider-vertical {
      height: 382px;
      width: 20px;
    }

    .career-form-wrap {
      margin-top: 0;
    }
  }
  .contact-map {
    .google_map-btn {
      top: 20px;
      left: 30px;
    }

    .google_map {
      p {
        margin-bottom: 0 !important;
      }
    }
  }
  .career-form-wrap {
    margin-top: 120px;
  }
  .contact-section2 .interest-chk-error {
    bottom: -20px;
  }
  .contact-section2 .service-chk-error {
    bottom: -20px !important;
  }
  .custom-checkbox .label-checkbox .checkmark {
    top: 0;
  }
  .custom-checkbox {
    margin-top: 20px;
  }
  .custom-checkbox .label-checkbox {
    padding-left: 30px;
    font-size: 12px;
    line-height: 19.4px;
  }
  .career-form-wrap {
    .form-control-col {
      padding-right: 15px !important;
      padding-left: 15px !important;

      .pr-form-control {
        position: relative;
        margin-bottom: 60px;
      }
    }

    .contact-breadcrum {
      margin-bottom: 10px;
      overflow: auto;

      li {
        &.first-inline-item a {
          vertical-align: 0;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 3;
        }

        padding-right: 20px;
        margin-right: 6px;
      }

      li:not(:last-child)::after {
        width: 7px;
        height: 9px;
        background-size: contain;
      }
    }

    .contact-form-inner.mb30 {
      margin-bottom: 20px !important;
    }
  }
  .expertise-sec1 {
    padding-bottom: 0 !important;

    .vh100-100 {
      overflow: hidden;
    }

    .ani-wrap50-3 {
      height: 310px;
      margin-top: 0;
      width: 100% !important;
    }

    .comman-card-info {
      margin-top: 20px;

      .sec-heading {
        br {
          display: none;
        }
      }
    }

    img.expertise1 {
      transform: none !important;
      -webkit-transform: none !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      -o-transform: none !important;
      width: auto;
      height: 340px;
      margin: auto;
      display: block;
      object-fit: cover;
    }

    .comman-card-img-wrap2 {
      height: inherit;
      min-height: inherit;
      left: auto;
      top: 0;
      margin-left: 0;
    }

    img.expertise1 {
      transform: scale(0.75) translateZ(0);
      -webkit-transform: scale(0.75) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.75) translateZ(0);
      -ms-transform: scale(0.75) translateZ(0);
      -o-transform: scale(0.75) translateZ(0);
      transform-origin: center center;
      min-height: inherit;
      top: 0;
    }
  }
  .expertise-sec2 {
    padding-top: 15px !important;

    .expertise-list-wrap.ml75 {
      margin-left: 0;
      margin-top: 30px;
    }
  }
  .expertise-sec4 {
    padding-top: 15px !important;

    .vh100-100 {
      padding-top: 0;

      .comman-card-img-wrap {
        margin-left: -20px;
      }
    }
  }
  .expertise-sec5 {
    .vh100-100 {
      .comman-card-img-wrap {
        margin-left: -50px;
        margin-top: -40px;
      }
    }

    .join-team-wrap {
      text-align: center;
      margin-top: 20px;
    }
  }
  .expertise-sec2-1 {
    h2 {
      margin-top: 0;
    }
  }

  .career-form-wrap {
    margin-top: 0;

    .mb50 {
      margin-bottom: 20px !important;
    }

    .form-control-col-btn {
      margin-top: 40px;
    }

    .pr-form-control {
      position: relative;
    }
  }

  .career-details-sec1 {
    padding-bottom: 0 !important;

    .career-details-sec1-row {
      margin-top: 42px;

      .exp-h6 {
        margin-top: 15px;
        margin-right: 0;
        position: absolute;
        top: -94px;
      }

      .btn-primary-custom.bttn {
        min-width: inherit;
        margin-top: 36px;
        text-align: center;
      }

      .btn-back-link {
        margin-bottom: 18px;
      }
    }

    .career-details-sec2-row {
      margin-top: 62px;

      .career-details-card {
        margin-bottom: 40px;

        h4 {
          margin-bottom: 4px;
        }

        ul {
          margin-top: 10px;
        }
      }
    }

    .exp-h6 {
      text-align: left;
    }
  }
  .career-details-sec2 {
    // border-top: 1px solid rgba($black, 0.15);
    .form-control-col {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }

    .career-form-wrap {
      margin-bottom: 70px;

      .btn-primary-custom {
        min-width: 120px;
      }
    }
  }

  .crubba-sec1 {
    min-height: 300px;

    .portfolio-img-wrap {
      transform: none;
      -webkit-transform: none;
      -webkit-backface-visibility: hidden;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform-origin: right center;
      position: relative;
      min-height: initial;
      width: 230px;
      margin: 0 auto 60px;

      .crubba-sec9-inner4 {
        top: -30px;
        left: -80px;
        width: 330px;
      }

      .crubba-sec9-inner3 {
        bottom: -70px;
        left: -80px;
        width: 100px;
      }

      .crubba-sec9-inner5 {
        top: 10px;
        left: -30px;
        width: 240px;
      }
    }

    .crubba-sec9-inner1 {
      bottom: -70px;
      right: 0;
      width: 70px;
    }
  }
  .crubba-sec2 {
    background-size: 360px auto;

    .crubba-sec2-img1 {
      max-width: 480px;
    }

    .crubba-sec2-img2 {
      position: absolute;
      top: 50px;
      left: -30px;
      width: 110px;
    }

    .crubba-sec2-img3 {
      position: absolute;
      top: 40px;
      right: 0;
      width: 100px;
    }
  }
  .crubba-sec3-upd {
    background-image: none !important;
  }
  .crubba-sec3 {
    .crubba-sec3-right {
      transform: scale(0.75) translateZ(0);
      -webkit-transform: scale(0.75) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.75) translateZ(0);
      -ms-transform: scale(0.75) translateZ(0);
      -o-transform: scale(0.75) translateZ(0);
      transform-origin: center center;
    }
  }

  .crubba-sec4 {
    padding-bottom: 0;

    .crubba-sec4-center {
      margin-top: 30px;
    }

    .crubba-sec4-bottom1 {
      width: 250px;
      display: none !important;
    }

    .crubba-sec4-bottom {
      margin-top: 40px;
    }

    .viewer, .ng-draggable {
      height: 340px;
      overflow: auto;

      .crubba-sec4-bottom-img {
        height: 1100px;
      }
    }

    .ng-draggable {
      overflow: none
    }
  }
  .crubba-sec5 {
    padding-bottom: 0 !important;
    background-position: right bottom 50px;
    background-size: 150px;

    .crubba-sec5-center {
      margin-top: 30px;
    }

    .crubba-sec5-bottom3 {
      position: absolute;
      top: 60px;
      left: -10px;
    }

    .crubba-sec5-col-height {
      height: 660px;
      overflow: hidden;
    }
  }
  .crubba-sec6 {
    .crubba-sec6-1 {
      display: none !important;
    }

    .crubba-sec6-bottom {
      background-size: contain;
      padding: 20px;
      height: initial;
      margin-top: 0;

      .owl-caroual-comman {
        position: relative;
        display: block;
        width: 100%;
        margin: 0 auto 0;
        top: 30px;
      }
    }

    .owl-theme .owl-nav.disabled + .owl-dots {
      margin-top: 30px;
      position: relative;
      top: -15px;
    }

    .carousel-indicators {
      bottom: -55px;
    }
  }

  .crubba-sec7 {
    & > .container {
      overflow: hidden;
    }

    .crubba-sec7-img1 {
      bottom: auto;
      top: 100px;
      left: 0px;
      display: none !important;
    }

    .crubba-sec6-bottom-before {
      position: absolute;
      bottom: 170px;
      right: -60px;
      z-index: 6;
      width: 150px;
    }

    .crubba-sec7-bottom3 {
      width: 100%;
    }

    .crubba-sec7-bottom {
      transform: none;
      -webkit-transform: none;
      -webkit-backface-visibility: hidden;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform-origin: center top;
      min-height: inherit;
      height: 320px;

      .crubba-sec7-bottom2 {
        position: absolute;
        top: 90px;
        right: 10px;
        z-index: 10;
        width: 180px;
      }

      .crubba-sec7-bottom1 {
        position: absolute;
        top: 45px;
        left: -50px;
        z-index: 9;
        width: 300px;
      }

      .crubba-sec7-bottom3-wrap {
        .crubba-sec7-bottom4-hover {
          top: 20px;
          left: 50%;
          bottom: 0;
          width: 320px;
          height: 203px;
          margin-left: 0;
        }
      }

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }

  .crubba-sec8 {
    .comman-card-bottom-ul {
      margin: 0 !important;

      li {
        padding-left: 10px;

        &::before {
          top: 9px;
          width: 4px;
          height: 4px;
        }
      }
    }

    .crubba-sec3-right {
      display: none !important;
    }

    .crubba-sec8-center {
      margin-left: 20px;
      margin-top: 40px;
    }

    .crubba-sec8-bottom1 {
      max-width: 100%;
      display: block;
    }

    .crubba-sec4-bottom1 {
      position: absolute;
      top: 230px;
      left: -90px;
      z-index: 6;
      width: 230px;
    }
  }
  .crubba-sec9 {
    background-size: 280px auto;
    background-position-y: 100px;

    .crubba-sec3-left {
      margin-top: 0;
    }

    .vh100-100 {
      min-height: 240px !important;
    }

    .crubba-sec9-img-wrap {
      .crubba-sec9-img {
        width: 340px;
        height: auto;
      }
    }

    .crubba-sec9-img2 {
      left: -50px;
      z-index: 0;
      width: 200px;
    }

    .crubba-sec9-bottom2 {
      max-width: 1010px;
    }
  }

  .crubba-sec11 {
    background-size: 280px auto;
    background-position-y: 250px;
    padding-bottom: 0;

    .container-fluid {
      overflow: hidden;
    }

    .crubba-sec11-bottom {
      position: relative;
      left: -20px;
      min-height: inherit;
      height: 800px;
    }

    .crubba-sec11-bottom1 {
      max-width: 1100px;
    }
  }
  .crubba-sec12 {
    &::after {
      height: 680px;
    }

    .mobile-row {
      height: 1400px;
    }

    .crubba-sec12-1 {
      position: relative;
      left: -80px;
      width: 320px;
    }

    .crubba-sec12-2 {
      margin: -70px 0 0 35px;
      max-width: 100%;
    }

    .crubba-sec12-3 {
      display: block;
      margin: 60px auto -10px;
      max-width: 100%;
    }

    .crubba-sec12-4 {
      max-width: 100%;
    }

    .crubba-sec12-6 {
      display: block;
      margin: 182px auto 0 40px;
      width: 400px;
    }

    .crubba-sec12-7 {
      max-width: 100%;
    }

    .crubba-sec12-5-wrap {
      position: relative;
      transform: scale(0.9) translateZ(0) !important;
      -webkit-transform: scale(0.9) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.9) translateZ(0) !important;
      -ms-transform: scale(0.9) translateZ(0) !important;
      -o-transform: scale(0.9) translateZ(0) !important;
      transform-origin: center top;
      height: 600px;
      margin-left: -10px;

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .comman-impact-section {
    .h44 {
      margin-bottom: 40px;
    }

    .comman-impact-top {
      hr {
        margin: 45px auto -30px;
      }
    }

    .comman-impact-bottom {
      .h5-20 {
        margin-top: 30px;
      }
    }

    .crubba-impact1-circle {
      top: -50px;
      transform: scale(0.85) translateZ(0);
      -webkit-transform: scale(0.85) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.85) translateZ(0);
      -ms-transform: scale(0.85) translateZ(0);
      -o-transform: scale(0.85) translateZ(0);

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .easy-plan-sec3 {
    .container {
      max-width: inherit !important;
    }

    .easy-plan-strategy-right {
      margin-right: 78px;
    }
  }
  .easy-plan-sec4 {
    .easy-plan-strategy-left {
      -webkit-backface-visibility: hidden;
      transform: scale(0.65) translateZ(0) !important;
      min-height: 500px;
      transform-origin: center 40px;
      -webkit-transform: scale(0.65) translateZ(0) !important;
      -moz-transform: scale(0.65) translateZ(0) !important;
      -ms-transform: scale(0.65) translateZ(0) !important;
      -o-transform: scale(0.65) translateZ(0) !important;

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }

    .easy-plan-strategy-left-inner2 {
      top: 30px;
      margin-bottom: 0;
    }

    .easy-plan-strategy-right {
      margin-top: 0;
    }

    .easy-plan-xs-left {
      margin-top: 60px;

      .owl-theme .owl-dots .owl-dot.active span,
      .owl-theme .owl-dots .owl-dot:hover span {
        background: #3a3c49;
      }

      .owl-caroual-comman {
        position: relative;
        top: auto;
        left: auto;
        width: 250px;
        margin: auto;
        margin-left: auto;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        z-index: 49;
        height: auto;
        background-size: 250px auto;

        &::after {
          top: -20px;
          left: -40px;
          background-size: 260px auto;
          background-position: center 0;
        }
      }

      .easy-plan-xs-4-inner {
        height: 100%;
        max-width: 260px;
        display: block;
        margin: auto;
        max-width: 190px;
        margin-top: 40px;
      }

      .easy-plan-xs-4-img {
        width: 100%;
        height: auto;
        display: block;
        min-height: 170px;

        img {
          display: block;
          height: auto;
          width: 168px;
          max-width: 100%;
        }
      }

      p {
        text-align: center;
        color: #3a3c49;
      }
    }
  }

  .easy-plan-sec5 {
    .ease-plan-chat-wrap {
      min-height: 500px;
      transform-origin: center 0px;
      transform: scale(0.7) translateY(-50%);
      -webkit-transform: scale(0.7) translateY(-50%);
      -moz-transform: scale(0.7) translateY(-50%);
      -ms-transform: scale(0.7) translateY(-50%);
      -o-transform: scale(0.7) translateY(-50%);
      position: absolute;
      top: 50%;
    }
  }

  .easy-plan-sec6 {
    .easy-plan-sec6-inner {
      min-height: 500px;
      transform-origin: center 250px;
      transform: scale(0.7) translateY(-50%);
      -webkit-transform: scale(0.7) translateY(-50%);
      -moz-transform: scale(0.7) translateY(-50%);
      -ms-transform: scale(0.7) translateY(-50%);
      -o-transform: scale(0.7) translateY(-50%);
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: 50px;
    }
  }

  .easy-plan-sec8 {
    .easy-plan-sec8-inner {
      min-height: 500px;
      transform-origin: center 250px;
      transform: scale(0.7) translateY(-50%);
      -webkit-transform: scale(0.7) translateY(-50%);
      -moz-transform: scale(0.7) translateY(-50%);
      -ms-transform: scale(0.7) translateY(-50%);
      -o-transform: scale(0.7) translateY(-50%);
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: 100px;
    }
  }
  .easy-plan-sec9 {
    .easy-plan-sec9-inner {
      min-height: 600px;
      transform: scale(0.7) translateZ(0);
      -webkit-transform: scale(0.7) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.7) translateZ(0);
      -ms-transform: scale(0.7) translateZ(0);
      -o-transform: scale(0.7) translateZ(0);
      transform-origin: center 200px;

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .easy-plan-sec10 {
    .easy-plan-sec10-inner {
      min-height: 600px;
      transform: scale(0.7) translateZ(0);
      -webkit-transform: scale(0.7) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.7) translateZ(0);
      -ms-transform: scale(0.7) translateZ(0);
      -o-transform: scale(0.7) translateZ(0);
      transform-origin: center 200px;

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }

  .easy-plan-sec7 {
    .easy-plan-sec7-inner {
      .easy-plan-sec7-inner1,
      .easy-plan-sec7-inner2,
      .easy-plan-sec7-inner3,
      .easy-plan-sec7-inner4 {
        width: 80%;
      }
    }
  }

  .rsmart-sec3 {
    .rsmart-right-wrap {
      min-height: 600px;
      -webkit-backface-visibility: hidden;
      transform: scale(0.65) translateZ(0) !important;
      -webkit-transform: scale(0.65) translateZ(0) !important;
      -moz-transform: scale(0.65) translateZ(0) !important;
      -ms-transform: scale(0.65) translateZ(0) !important;
      -o-transform: scale(0.65) translateZ(0) !important;
      transform-origin: center 260px;

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }

  .rsmart-sec6 {
    .container-fluid {
      .rsmart-left-wrap {
        min-height: 510px;
        padding-top: 50px;
      }
    }
  }
  .rsmart-sec8 {
    overflow: hidden;

    .rsmart-app-wrap {
      height: inherit !important;
    }

    .rsmart-right-wrap {
      transform: scale(0.8) translateZ(0);
      -webkit-transform: scale(0.8) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.8) translateZ(0);
      -ms-transform: scale(0.8) translateZ(0);
      -o-transform: scale(0.8) translateZ(0);
      transform-origin: center 0;
      top: 0;

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .rsmart-sec9 {
    overflow: hidden;

    .rsmart-left-wrap,
    .rsmart-right-wrap {
      height: 850px;
      display: block;
    }

    .rsmart-app-view1-img,
    .rsmart-app-view2-img {
      width: 360px;
    }
  }

  .rsmart-sec10 {
    .rsmart-left-wrap {
      transform: scale(0.8) translateZ(0);
      -webkit-transform: scale(0.8) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.8) translateZ(0);
      -ms-transform: scale(0.8) translateZ(0);
      -o-transform: scale(0.8) translateZ(0);
      transform-origin: center center;
    }

    .rsmart-right-wrap {
      li:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .mytrux-logo-sec3 {
    .mytrux-logo-right-wrap {
      transform: scale(0.8) translateZ(0);
      -webkit-transform: scale(0.8) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.8) translateZ(0);
      -ms-transform: scale(0.8) translateZ(0);
      -o-transform: scale(0.8) translateZ(0);
      transform-origin: center center;
      min-height: inherit;
      height: 530px;
    }
  }
  .mytrux-logo-sec6 {
    .mytrux-logo-sec6-inner {
      h5 {
        margin-top: 40px;
      }
    }

    .mytrux-logo-sec6-1,
    .mytrux-logo-sec6-2 {
      width: 400px;
      margin: auto;
    }
  }
  .mytrux-logo-sec9 {
    .p2 {
      margin-top: 100px;
    }
  }

  .mytrux-logo-sec12 {
    margin-top: -180px;
    overflow: hidden;
    z-index: 9;
  }
  .mytrux-logo-sec14 {
    min-height: inherit;

    .mytrux-logo-sec14-2 {
      position: absolute;
      bottom: -80px;
      left: -130px;
      z-index: 2;
      width: 200px;
    }
  }
  .mytrux-logo-sec15 {
    padding-top: 0 !important;
  }

  .mytrux-logo-sec13 {
    padding: 0 !important;
  }
  .mytrux-logo-sec13-1-wrap,
  .mytrux-logo-sec12-1-outer {
    height: initial;
    min-height: inherit;

    .img-fluid {
      max-width: 100%;
      height: 100%;
    }
  }
  .mytrux-logo-sec14 {
    .mytrux-logo-sec14-inner-wrap {
      height: inherit;
    }
  }
  .mytrux-website-sec1 {
    padding-bottom: 0 !important;

    .mytrux-website-center-wrap {
      margin-top: 70px;
    }
  }

  .mytrux-website-sec7 {
    .mytrux-website-sec7-1-outer {
      height: 2900px;
    }
  }
  .mytrux-website-sec9 {
    .container {
      height: 2500px;
      display: block;
    }
  }
  .mytrux-website-sec10 {
    .mytrux-website-top-wrap {
      transform: scale(0.7) translateZ(0);
      transform-origin: center 0;
      -webkit-transform: scale(0.7) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.7) translateZ(0);
      -ms-transform: scale(0.7) translateZ(0);
      -o-transform: scale(0.7) translateZ(0);
      height: 600px;
      min-height: inherit;

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .mytrux-website-sec9 {
    .mytrux-website-sec9-1 {
      max-width: initial;
      margin-left: -250px;
      margin-top: 70px;
      position: relative;
      z-index: 5;
      width: 850px;
    }

    .mytrux-website-sec9-3 {
      max-width: initial;
      margin-top: -220px;
      margin-left: -230px;
      width: 800px;
    }

    .mytrux-website-sec9-2 {
      margin-left: 6px;
    }
  }

  .accordion-tab-xs {
    .accordion-card {
      border-top: 1px solid rgba(255, 255, 255, 0.1);

      &:first-child {
        border-top: 0;
      }
    }

    .accordion-card-header {
      .h4 {
        color: $white;
        border: 0;
        padding: 15px 15px 15px 0;
        margin: 0 !important;
        background: transparent;
        display: block;
        width: 100%;
        text-align: left;
        outline: none;
        opacity: 1;
        position: relative;

        i {
          position: absolute;
          top: 50%;
          right: 0;
          z-index: 3;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
        }

        &:hover,
        &:active,
        &:focus {
          outline: none;
        }

        &.collapsed {
          opacity: 0.3;

          i {
            transform: translateY(-50%) rotate(180deg);
            -webkit-transform: translateY(-50%) rotate(180deg);
            -moz-transform: translateY(-50%) rotate(180deg);
            -ms-transform: translateY(-50%) rotate(180deg);
            -o-transform: translateY(-50%) rotate(180deg);
          }
        }
      }
    }

    .card-body {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
    }

    .bttn {
      margin-bottom: 15px;
      margin-top: 10px;
    }
  }
  .term-section1 {
    .term-card-wrap1 {
      margin-bottom: 30px !important;
    }

    .term-card-wrap-outer {
      margin-top: 0 !important;
    }

    .term-card-wrap-inner {
      &:not(:last-child) {
        margin-bottom: 40px;
      }

      .h4 {
        margin-bottom: 10px;
      }

      .ol-list {
        li {
          &::before {
            width: 15px;
            height: 0px;
            left: -25px;
            top: 10px;
            border: 1px solid $orange;
          }
        }

        .ol-list {
          margin-top: 20px;
          padding-left: 15px;

          li {
            &::before {
              width: 5px;
              height: 5px;
              left: -13px;
              top: 8px;
            }
          }

          .ol-list {
            margin-top: 20px;
          }
        }

        li:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
  .comman-bg-section {
    min-height: 400px;
  }
  .comman-solution-challenge {
    padding-bottom: 0 !important;

    .ani-wrap50-2,
    .ani-wrap50 {
      display: none !important;
    }

    .portfolio-info-wrap2.ml85 {
      margin-left: 0 !important;
      position: relative;
      margin-top: 60px;
      padding-top: 60px;
      padding-bottom: 60px;

      h3,
      p {
        position: relative;
        z-index: 5;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        right: -100%;
        bottom: 0;
        background: $black;
        z-index: 3;
      }
    }
  }
  .authntick-sec1 {
    .authntick-logo {
      width: 120px;
    }

    .authntick-fingerprint2 {
      position: absolute;
      top: -150px;
      left: 240px;
      max-width: 260px;
    }

    .authntick-fingerprint1 {
      position: absolute;
      bottom: -180px;
      left: -44px;
      max-width: 260px;
    }

    .authntick-left-wrap {
      width: auto;
      margin: auto;

      br {
        display: none;
      }
    }

    .authntick-right-wrap {
      img {
        max-width: 100%;
        float: right;
        margin-top: 0;
        height: 250px;
        margin-right: -50px;
      }
    }
  }
  .authntick-sec2 {
    .authntick-sec2-1 {
      max-width: 260px;
      bottom: -200px;
      left: -120px;
    }

    .authntick-left-wrap {
      padding-right: 10px;
    }
  }
  .authntick-sec3 {
    .authntick-sec3-bg {
      background-size: contain;
    }
  }
  .authntick-sec4 {
    .authntick-sec4-3 {
      display: none !important;
    }

    .authntick-sec4-2-outer {
      .authntick-sec4-2 {
        max-width: 100%;
        left: 0;
        position: static;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
      }
    }
  }
  .authntick-sec6 {
    .authntick-sec6-2-outer {
      right: auto;
      position: relative;
      margin-top: 30px;
    }

    .authntick-sec6-2 {
      max-width: 100%;
      position: relative;
      z-index: 3;
    }

    .authntick-sec6-1 {
      max-width: 350px;
      display: none !important;
    }
  }
  .authntick-sec7 {
    .authntick-sec7-2-outer {
      margin-left: -15px;
    }

    .authntick-left-wrap {
      float: none;
      margin-left: 0;
    }

    [data-aos="new-animation5"].aos-animate {
      display: none !important;
    }

    .ui-card-slider {
      width: 340px;
      height: 230px;
      transform: none;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      margin: 30px auto 0px;
      display: block;
      float: none;
    }
  }
  .authntick-sec8 {
    .authntick-sec8-2-outer {
      .authntick-wizard-carosual {
        width: 100%;
        margin-top: 30px;
        margin-left: 0;
      }
    }
  }
  .authntick-sec9 {
    .authntick-sec9-2-outer {
      padding: 0 15px;
    }

    &::after {
      height: 70px;
    }

    .bottom-col-set {
      bottom: -15px;
    }

    .authntick-center-wrap {
      max-width: 705px;
      padding: 0 15px;
      margin: 0;
    }
  }

  .impact-section-upd {
    background: #242424;
    padding-top: 50px !important;

    .comman-impact-bottom {
      .comman-impact-img {
        width: 85px;
        height: 85px;
        margin-bottom: 10px;
      }
    }

    .ani-wrap50,
    .ani-wrap50-2 {
      display: none !important;
    }

    ul,
    div,
    h3,
    p,
    h6,
    img {
      position: relative;
      z-index: 9;
    }

    .comman-impact-list-inline {
      li:first-child {
        margin-right: 50px;
      }
    }

    .comman-impact-bottom.ml85 {
      margin-left: 0 !important;
      position: relative;
      padding-top: 50px;

      .comman-impact-list-inline {
        margin-top: 30px;
      }

      .fas {
        font-size: 8px;
        vertical-align: 6px;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        right: -100%;
        bottom: -50px;
        background: #1e1e1e;
        z-index: 3;
      }
    }

    .portfolio-info-wrap2 {
      margin-left: 0;
      position: relative;
      padding-bottom: 30px;
      text-align: center;

      .comman-card-top-ul {
        margin: 0 0 6px !important;

        li {
          margin: 0 !important;
        }
      }

      &::after {
        content: "";
        position: absolute;
        top: -50px;
        left: -100%;
        right: -100%;
        bottom: 0;
        background: $black;
        z-index: 3;
      }
    }
  }
  .failure-section {
    .failure-ul {
      margin-top: 25px;

      li {
        padding-left: 18px;

        &:before {
          top: 8px;
          left: 0;
          width: 10px;
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    .vh100-100 {
      min-height: inherit !important;
    }

    .failure-sec-right {
      top: auto;
      display: none !important;
    }
  }

  .moneyguru-sec2 {
    .moneyguru-sec2-right {
      float: none !important;
    }

    .moneyguru-sec2-3 {
      width: 300px;
      margin: auto;
      display: block;
    }
  }
  .moneyguru-sec3 {
    .moneyguru-sec3-bg {
      margin-top: 60px;
      margin-left: 170px;
      width: 300px;
    }
  }
  .moneyguru-sec4 {
    .viewer, .ng-draggable {
      height: 340px;
      width: 100%;
      height: inherit;
      overflow: auto;
    }

    .ng-draggable {
      overflow: none
    }

    .moneyguru-sec4-bottom {
      padding-bottom: 60px;

      .moneyguru-sec4-img {
        height: 340px;
      }
    }
  }

  .moneyguru-header-section {
    .moneyguru-header-right-wrap {
      min-height: inherit;
    }

    .moneyguru-banner-img {
      width: 500px;
    }
  }

  .moneyguru-strategy-section {
    .moneyguru-strategy-left-col-img {
      position: absolute;
      top: -40px;
      left: -50px;
      right: 0;
      bottom: 0;
      height: 100vh;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }

    .moneyguru-strategy-right {
      margin-left: 0;
    }
  }

  .moneyguru-story-section {
    overflow: hidden;

    .moneyguru-story-left-outer {
      height: 480px;
      margin-left: -50px;
    }

    .moneyguru-story-left {
      margin-top: 0;
      margin-left: 0;
      transform: scale(0.5);
      transform-origin: 0px 0px;
      -webkit-transform: scale(0.5);
      -moz-transform: scale(0.5);
      -ms-transform: scale(0.5);
      -o-transform: scale(0.5);
      position: relative;
      top: 0;
      left: 0;
    }

    .moneyguru-story-right {
      max-width: 500px;
      margin-top: 0;
    }
  }
  .moneyguru-insights-section {
    overflow: hidden;

    .moneyguru-insights-right-col {
      position: static;
      top: 0;
      left: 0;
      margin-top: 30px;

      .moneyguru-insights-right {
        height: 100%;

        video {
          float: none;
          width: 100%;
          transform: none;
          position: static;
          top: 0;
          left: 0;
          -webkit-transform: none;
          -moz-transform: none;
          -ms-transform: none;
          -o-transform: none;
          margin-left: 30px;
        }
      }
    }
  }

  .moneyguru-investments-section {
    .moneyguru-investments-left {
      height: 100%;
      margin-left: 0;
    }

    .moneyguru-investments-left-col {
      position: static;
      top: 0;

      video {
        margin-left: 0;
        width: 100%;
        transform: none;
        position: static;
        top: 0;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
      }
    }
  }
  .moneyguru-gateway-section {
    .moneyguru-gateway-right {
      height: 100%;
      position: static;
      top: 0;

      video {
        width: 100%;
        transform: none;
        position: static;
        top: 0;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
      }
    }
  }
  .moneyguru-investing-section {
    overflow: hidden;

    .moneyguru-investing-left-col {
      position: static;

      .moneyguru-investing-left {
        height: 100%;
      }

      video {
        margin-left: 0;
        width: 100%;
        transform: none;
        position: static;
        top: 0;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
      }
    }

    .moneyguru-investing-right {
      float: none;
      margin-right: 0;
      margin-left: 0;
      text-align: center;
    }
  }
  .moneyguru-comprehensive-section {
    .moneyguru-comprehensive-bottom {
      height: 100%;

      video {
        position: static;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        width: 100%;
      }
    }
  }
  .moneyguru-illustrations-icon {
    video {
      width: 100%;
      margin: 0 auto 20px;
      height: 100px;
      display: block;
    }
  }
  .viewer-section {
    .portfolio-info-wrap2 {
      margin: 0 auto 30px !important;

      h3 {
        white-space: normal;
      }
    }

    .viewer, .ng-draggable {
      height: 340px;
      overflow: auto;
    }

    .ng-draggable {
      overflow: none
    }
  }

  .form-control-col-btn2 {
    .reset-btn {
      margin-left: 20px;
    }
  }
  .vigan-sec1 {
    .vigan-logo {
      display: inline-block;
      margin-bottom: -20px;
      margin-top: 30px;
      width: 100px;
    }
  }
  .vigan-sec2 {
    .vigan-sec2-left {
      max-width: inherit;
    }

    .vigan-right-wrap {
      text-align: center;
      right: 0;
      top: 0;
      margin-top: 30px;

      .img-fluid {
        max-width: 400px;
        margin-left: 0;
        width: 100%;
      }
    }
  }
  .vigan-sec3 {
    .vigan-left-wrap {
      margin-left: 0;
    }

    .vigan-left-wrap.portfolio-info-wrap2 {
      h3,
      p {
        color: $black !important;
      }
    }

    .vigan-right-wrap.portfolio-info-wrap2.ml85 {
      margin-left: 0 !important;
      max-width: 100%;

      h3,
      p {
        color: $white !important;
      }
    }

    .vigan-sec3-1-outer1 {
      .vigan-sec2-1 {
        width: 200px;
      }
    }
  }
  .vigan-sec4 {
    .vigan-left-wrap {
      max-width: inherit;
    }

    .vigan-right-wrap {
      margin-top: 30px;

      .img-fluid {
        max-width: 470px;
        width: 100%;
        margin: auto;
        display: block !important;
      }
    }
  }

  .vigan-sec6 {
    padding-top: 60px !important;
    background-image: none !important;

    .vigan-left-wrap {
      max-width: inherit;
    }

    .vigan-right-wrap {
      width: auto;
      margin-top: 18px;
      margin-left: auto;
      margin-right: auto;

      .img-fluid {
        max-width: 150px;
        width: 100%;
        margin: auto !important;
        display: block !important;
        padding-top: 30px;
      }
    }
  }

  .vigan-sec7 {
    .vigan-left-wrap {
      max-width: inherit;
    }

    .vigan-right-wrap {
      position: relative;
      top: 0;
      left: 0;
      max-width: 400px;
      width: 100%;
      margin: auto;

      .img-fluid {
        max-width: 250px;
        padding-top: 30px;
        margin: auto;
        display: block !important;
      }
    }
  }

  .vigan-sec7-1 {
    .vigan-left-wrap {
      max-width: inherit;
    }

    .vigan-right-wrap {
      margin-top: 30px;
      text-align: center;
    }
  }
  .comman-comparison {
    height: 380px;
  }

  .mytrux-logo-sec1 {
    .mytrux-logo-img {
      margin-bottom: 30px;
      margin-top: 60px;
    }
  }
  .mytrux-logo-sec2 {
    .mytrux-website-sec9-4 {
      margin-top: 30px;
    }
  }
  .mytrux-logo-sec4 {
    .mytrux-logo-sec4-right {
      img {
        height: 400px;
        margin: 30px auto 0;
        display: block !important;
      }
    }

    .ani-wrap50-2 {
      display: none !important;
    }

    .mytrux-logo-sec4-right-img {
      margin-top: 30px;
    }
  }
  .mytrux-logo-sec5 {
    .mytrux-logo-mark {
      margin-top: 30px;
    }
  }

  .mytrux-logo-sec9 {
    .portfolio-info-wrap2 h3 {
      margin-bottom: 40px;
    }

    .p2 {
      margin-top: 0;
      margin-bottom: 30px;
    }

    .mytrux-logo-sec9-2,
    .mytrux-logo-sec9-1 {
      width: 180px;
    }
  }
  .mytrux-logo-sec10 {
    height: 380px;
    position: relative;
    overflow: hidden;

    h3 {
      margin-bottom: 80px;
    }

    .app-icon-ul {
      height: auto;
      white-space: nowrap;
      margin: -40px 0 0;
      overflow-x: auto;
      padding-top: 30px;
      transform: scale(0.6);
      transform-origin: 0 0;
      width: 546px;
      -webkit-transform: scale(0.6);
      -moz-transform: scale(0.6);
      -ms-transform: scale(0.6);
      -o-transform: scale(0.6);

      li {
        margin: 0;
      }
    }
  }

  .mytrux-logo-sec13-1-wrap .img-fluid,
  .mytrux-logo-sec12-1-outer .img-fluid {
    max-width: 100%;
    height: auto;
  }
  .mytrux-logo-sec4 {
    .mytrux-logo-sec4-right {
      img {
        height: auto;
        width: 100%;
      }
    }
  }

  .mytrux-logo-sec6 {
    .mytrux-logo-sec6-inner {
      text-align: center;
      height: initial;

      h5 {
        margin-top: 0;
      }
    }
  }
  .bagshaha-sec1 {
    .bagshaha-logo-img {
      margin-bottom: 30px;
      width: 60px;
      margin-top: 50px;
    }
  }
  .bagshaha-sec2 {
    .bagshaha-sec1-1 {
      margin-top: 30px;
    }
  }
  .bagshaha-sec4 {
    .ani-wrap50-2,
    .ani-wrap50-b {
      display: none !important;
    }

    .bagshaha-sec4-2 {
      max-width: 250px;
      margin: auto;
      display: block !important;
    }

    .bagshaha-sec4-1 {
      width: 70px;
    }

    .bagshaha-sec4-right {
      margin-left: 0;
      text-align: center;

      .img-fluid {
        position: relative;
        z-index: 9;
      }

      .bagshaha-sec4-right-t {
        position: relative;
        top: 0;
        padding: 30px 0;
        margin-top: 30px;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          right: -100%;
          bottom: 0;
          background: #0c1d34;
          z-index: 3;
        }
      }

      .bagshaha-sec4-right-b {
        padding: 40px 0 !important;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          right: -100%;
          bottom: 0;
          background: #0f2541;
          z-index: 3;
        }
      }
    }
  }
  .bagshaha-sec5 {
    padding-bottom: 0 !important;

    .bagshaha-sec5-right {
      position: relative;
      top: 0;
      right: 0;
      width: 100%;
      height: 350px;
      margin-top: 30px;

      .bagshaha-sec5-right-tbl {
        td {
          padding: 15px;
        }

        .td2 p {
          top: 15px;
          left: auto;
          right: 15px;
          bottom: auto;
        }
      }
    }
  }
  .bagshaha-sec6 {
    border-top: 1px solid $white;

    .bagshaha-sec6-2 {
      width: 230px;
      margin: 0 auto;
      display: block !important;
    }

    .bagshaha-sec6-3-outer {
      position: absolute;
      top: 0;
      right: -120px;

      .bagshaha-sec6-2 {
        height: 240px;
      }

      .bagshaha-sec6-inner {
        margin-top: 20px;
      }
    }
  }
  .bagshaha-sec9 {
    .bagshaha-sec9-2-outer {
      margin-top: 20px;

      .bagshaha-sec9-2 {
        max-width: 300px;
        margin: auto;
        display: block !important;
      }
    }
  }
  .bagshaha-sec10 {
    background-size: contain;
    background-position: right bottom;
    min-height: 270px;

    .portfolio-info-wrap {
      h3 {
        margin-top: 0;
      }
    }
  }
  .bagshaha-sec13 {
    padding-top: 0 !important;
  }
  .bagshaha-sec11 {
    .bagshaha-sec11-1-outer {
      .bagshaha-sec11-1 {
        width: 230px;
        margin: 0 auto 40px;
        display: block !important;
      }
    }

    .bagshaha-sec11-2-outer {
      text-align: center;

      .bagshaha-sec11-2 {
        height: 360px;
        position: relative;
        top: 0;
        right: 0;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
      }
    }
  }
  .mytrux-webapp-sec1 {
    .mytrux-webapp-sec1-1 {
      width: 300px;
      margin-left: -80px;
    }
  }
  .mytrux-logo-sec2 {
    .mytrux-website-sec9-4 {
      margin-top: 35px;
      max-width: 660px;
      left: 50%;
      transform: translateX(-50%);
      position: relative;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
    }
  }
  .mytrux-webapp-sec3 {
    .mytrux-banner-line {
      bottom: -50px;
    }
  }
  .mytrux-webapp-sec4 {
    .mytrux-webapp-sec4-bottom {
      .viewer, .ng-draggable {
        height: 340px;

        img {
          height: 320px;
        }
      }
    }
  }

  .mytrux-webapp-sec6 {
    .row.vh100-100 {
      min-height: inherit;
    }

    .mytrux-webapp-sec6-right {
      .mytrux-web-sec6-1 {
        right: 0;
        transform: translateZ(0) !important;
        -webkit-transform: translateZ(0) !important;
        -moz-transform: translateZ(0) !important;
        -ms-transform: translateZ(0) !important;
        -o-transform: translateZ(0) !important;
        transform-origin: inherit;
        position: static;
        top: 0;
        margin-top: 100px;

        img {
          width: inherit;
          max-width: 480px;
          margin-top: -30px;
        }
      }

      .mytrux-web-sec6-2 {
        right: 0;
        transform: translateZ(0) !important;
        -webkit-transform: translateZ(0) !important;
        -moz-transform: translateZ(0) !important;
        -ms-transform: translateZ(0) !important;
        -o-transform: translateZ(0) !important;
        transform-origin: inherit;
        top: auto;
        bottom: 100px;
        position: absolute;

        img {
          width: 700px;
        }
      }
    }
  }
  .mytrux-webapp-sec7 {
    margin-top: -40px;
    position: relative;

    overflow: hidden;

    z-index: 3;
  }
  .mytrux-webapp-sec8-3-xs {
    margin-top: 30px;
  }
  .mytrux-website-top-wrap-xs {
    .img-fluid {
      max-width: 300px;
    }
  }
  .mytrux-webapp-sec8 {
    .bottom-col-set {
      transform: scale(0.7) !important;
      transform-origin: center bottom;
      -webkit-transform: scale(0.7) !important;
      -moz-transform: scale(0.7) !important;
      -ms-transform: scale(0.7) !important;
      -o-transform: scale(0.7) !important;
    }
  }

  .mytrux-website-sec10 {
    .mytrux-website-top-wrap {
      height: 400px;

      .mytrux-website-sec10-1-1 {
        position: absolute;
        top: -10px;
        right: 0;
        z-index: 6;
      }

      .mytrux-website-sec10-2 {
        top: 100px;
        left: 50px;
        margin-top: 4px;
        width: 370px;
      }

      .mytrux-website-sec10-3 {
        left: 0;
      }
    }
  }
  .mytrux-webapp-sec7 {
    .portfolio-info-wrap2 {
      margin: 0px auto 0;
    }
  }

  .insisive-website-sec2 {
    .portfolio-info-wrap2 {
      margin: 0 auto 0;
      max-width: 500px;
    }

    .insisive-website-sec2-1 {
      height: auto;
      object-fit: cover;
      margin: 40px auto 0;
      max-width: 400px;
      width: 100%;
      display: block;
    }
  }
  .insisive-website-sec4 {
    .insisive-website-sec4-bottom-img {
      max-width: 1350px;
    }
  }
  .insisive-website-sec3 {
    .insisive-website-sec3-3-outer {
      img {
        display: inline-block;
        height: 400px;
        max-width: inherit;
        width: auto;
      }
    }
  }
  .insisive-website-sec4 {
    .viewer, .ng-draggable {
      height: 340px;
      margin-top: 30px;
    }
  }
  .insisive-website-sec6 {
    .insisive-website-sec6-1-outer {
      .insisive-website-sec6-1 {
        height: auto;
        margin: 30px auto 10px !important;
        max-width: 100%;
      }
    }
  }
  .insisive-website-sec7 {
    .insisive-website-sec7-right {
      float: none;
    }

    .ani-wrap100-top {
      display: none !important;
    }

    .insisive-website-sec7-1-outer {
      .insisive-website-sec7-1 {
        max-width: 100%;
        height: auto;
        margin: auto !important;
      }
    }
  }
  .insisive-website-sec8 {
    padding-bottom: 0;

    .insisive-website-sec8-right {
      position: relative;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      z-index: 9;

      .img-fluid {
        height: auto;
        max-width: 100%;
        width: auto;
        margin: 30px auto 0;
      }
    }

    .portfolio-info-wrap2 {
      margin-top: 0;
    }
  }
  .process-section1,
  .industries-section1 {
    .process-img-wrapper2 {
      margin-bottom: 20px !important;

      img {
        width: 60px;
      }
    }
  }

  .alloch-sec2 {
    padding-bottom: 0 !important;

    .alloch-sec2-1 {
      display: inline-block;
      position: relative;
      top: 0;
      left: 0;
      z-index: 3;
      padding: 40px 0;
      text-align: center;
      width: 100%;

      img {
        max-width: 320px;
        margin: auto;
        display: block;
      }
    }

    .vh100-100 {
      min-height: 480px;
    }

    .alloch-left-wrap {
      margin-top: 0;
      margin-left: 18px;
      max-width: 400px;
    }

    .alloch-right-wrap {
      position: relative;
      top: 0;
      margin-top: 40px;
      padding-top: 0px;

      img {
        position: relative;
        z-index: 9;
      }

      ::after {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        right: -100%;
        bottom: 0;
        background: #5c202e;
        z-index: 3;
      }
    }

    .alloch-sec2-2 {
      display: none;
    }

    .alloch-sec2-3 {
      position: relative;
      top: auto;
      left: auto;
      max-width: inherit;
    }

    .alloch-right-wrap {
      .alloch-sec2-3-inner {
        display: none !important;
      }

      &::after {
        display: none;
      }
    }
  }
  .alloch-u-sec2 {
    .alloch-u-left-wrap.portfolio-info-wrap2 {
      h3,
      p {
        color: $black !important;
      }
    }

    .portfolio-info-wrap2.ml85 {
      h3,
      p {
        color: $white !important;
      }
    }
  }
  .alloch-sec5 {
    .alloch-sec3-4-outer {
      .alloch-sec3-4 {
        width: 220px;
      }
    }
  }
  .alloch-sec7 {
    .alloch-sec7-4-outer {
      position: absolute;
      bottom: 30px;
      left: 180px;
    }

    .alloch-sec7-left {
      margin-top: 0;
      margin-left: 0;
      max-width: 450px;
    }

    .vh100-100 {
      min-height: inherit !important;
    }

    .alloch-sec7-right {
      transform: scale(0.5);
      transform-origin: center 0;
      -webkit-transform: scale(0.5);
      -moz-transform: scale(0.5);
      -ms-transform: scale(0.5);
      -o-transform: scale(0.5);
      height: 420px;
    }

    .alloch-sec7-4-outer {
      .alloch-sec7-4 {
        width: 150px;
        display: none;
      }
    }
  }

  .alloch-sec17 {
    .alloch-sec3-4-outer {
      position: absolute;
      bottom: 20px;
      left: 90px;

      .alloch-sec3-4 {
        width: 150px;
      }
    }

    .alloch-sec7-right-outer-r {
      height: 420px;
    }

    .alloch-sec7-right.portfolio-info-wrap {
      transform: scale(0.8);
      transform-origin: center 0;
      -webkit-transform: scale(0.8);
      -moz-transform: scale(0.8);
      -ms-transform: scale(0.8);
      -o-transform: scale(0.8);

      table {
        position: relative;
        left: 0;
        top: 0;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;

        .alloch-sec3-4-outer {
          position: absolute;
          bottom: 90px;

          .alloch-sec3-4 {
            width: 220px;
          }
        }
      }
    }
  }
  .alloch-sec10 {
    .portfolio-info-wrap2 {
      z-index: 9;
      position: relative;
    }

    .portfolio-info-wrap.alloch-sec10-left {
      transform: scale(0.55);
      transform-origin: 0 0;
      -webkit-transform: scale(0.55);
      -moz-transform: scale(0.55);
      -ms-transform: scale(0.55);
      -o-transform: scale(0.55);
      height: 430px;
    }
  }
  .alloch-sec18 {
    .owl-carousel {
      .owl-item {
        img {
          // max-width: 200px;
        }
      }
    }

    .alloch-sec7-left.portfolio-info-wrap2 {
      margin: 0 auto 30px;
    }
  }
  .next-page-section {
    padding: 30px 0 !important;

    .join-team-wrap {
      margin-top: 0;

      h6 {
        margin-bottom: 12px;
      }

      .next-page-img {
        display: none !important;
      }
    }
  }
  .comman-card-top-ul {
    li {
      &:not(:last-child) {
        margin-right: 35px !important;
      }

      &:first-child::after {
        right: -25px;
        top: 11px;
        width: 15px;
        height: 1px;
      }
    }
  }
  .our-work-page .work-comman-card-info .custom-nav-wrap-outer.nav-up,
  .our-work-page .work-comman-card-info .custom-nav-wrap-outer.nav-down {
    padding: 13px 10px;
    z-index: 99999;
  }
  .our-work-page .work-comman-card-info .custom-nav-wrap-outer::after {
    width: 40px;
  }
  .our-work-page {
    .work-comman-card-info {
      .custom-nav-wrap {
        min-height: 60px;
        padding-top: 10px;
      }

      li {
        position: relative;

        .bttn {
          padding: 25px 10px;
          position: static;
        }

        a.active {
          &::after {
            content: "";
            position: absolute;
            bottom: -8px;
            left: 50%;
            transform: translateX(-50%);
            width: calc(100% - 20px);
            height: 2px;
            background: $orange;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
          }
        }
      }
    }
  }
  .smartlinks-sec1 {
    .smartlinks-logo {
      margin-bottom: 20px;
    }

    .smartlinks-logo-after {
      display: none !important;
    }
  }

  .smartlinks-sect2 {
    .smartlinks-logo-after2 {
      display: none !important;
    }
  }
  .smartlinks-sect4 {
    .ani-wrap50-2 {
      display: none !important;
    }

    .smartlinks-sect4-1 {
      background: #37bbfe;
      max-width: 500px;
      margin: 30px auto 0;
      display: block;
      width: 100%;
    }

    .smartlinks-sec4-right {
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      width: 100%;
      z-index: 9;
    }
  }
  .smartlinks-sec4 {
    .smartlinks-sec4-1.img-fluid {
      margin-top: 40px;
    }
  }

  .smartlinks-sec9 {
    background: #27273c url("../images/portfolio/portfolio-branding-smartlink/smartlinks-sec9-1.jpg") no-repeat center bottom;
    background-size: contain;
    min-height: 280px;
    padding-bottom: 0 !important;
  }
  .easy-plan-sec1 {
    .easy-plan-right-wrap {
      height: inherit;
    }

    .easy-plan-banner-img {
      max-width: 100%;
      margin-bottom: 30px;
    }

    h1 {
      br {
        display: none;
      }
    }
  }

  .easy-plans-sec3 {
    .portfolio-info-wrap2.easy-plans-left-wrap {
      h3.text-white {
        color: $black !important;
      }

      p.text-white {
        color: $light-dark-c !important;
      }
    }
  }

  .easy-plan-sec6 {
    .easy-plan-goal-right {
      margin-top: 0;
    }
  }
  .portfolio-easy-plan-body {
    .portfolio-info-wrap {
      h3 {
        margin-bottom: 5px;
      }
    }
  }
  .easy-plan-sec7 {
    .easy-plan-xs-right {
      white-space: nowrap;
      overflow: auto;

      ul {
        white-space: nowrap;
        margin: 0;
        padding: 0;

        li {
          display: inline-block;
        }
      }
    }

    .easy-plan-goal-right {
      margin-top: 0;
    }
  }
  .easy-plan-sec8 {
    & > .container {
      padding-bottom: 0;
    }

    .easy-plan-track-right {
      position: relative;
      margin-top: 0;
    }
  }
  .easy-plan-sec9 {
    .easy-plan-track-left {
      margin-top: 0;
    }
  }
  .easy-plan-sec10 {
    .easy-plan-track-left {
      margin-top: 0;
    }
  }
  .phycheme-sec1 {
    h1 {
      br {
        display: none;
      }
    }

    .portfolio-info-banner-c {
      .logo-img {
        margin-top: 40px;
        margin-bottom: 20px;
        max-width: 130px;
        width: auto;
      }
    }
  }
  .phycheme-sec2 {
    background-color: #0a0333;
    padding-top: 0 !important;

    .ani-wrap50 {
      display: none !important;
    }

    .card-img-inner-wrap {
      margin-bottom: 40px;
    }
  }
  .phycheme-sec3 {
    .crubba-sec3-left.portfolio-info-wrap2 {
      h3.text-white {
        color: $black !important;
      }

      p.text-white {
        color: $light-dark-c !important;
      }
    }

    .crubba-sec3-right.portfolio-info-wrap2.ml85 {
      h3 {
        color: $white !important;
      }

      p {
        color: $white !important;
      }
    }
  }
  .phycheme-sec4 {
    .phycheme-sec4-right {
      padding-top: 40px;
      background-color: #0a0333;
      margin-top: 30px;
      border-radius: 5px;
      margin-bottom: 30px;
      position: relative;

      .phycheme4-left {
        position: relative;
        z-index: 5;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -50%;
        right: -50%;
        bottom: -30px;
        z-index: 3;
        background: #0a0333;
      }
    }

    .owl-caroual-comman-fade {
      .phycheme4-4-img {
        min-height: 200px;

        img {
          max-width: 210px;
        }
      }
    }
  }
  .phycheme-sec5 {
    padding-bottom: 0px !important;

    .owl-carousel .owl-item img,
    .phycheme-logo-concept {
      height: auto;
      width: 100%;
      object-fit: cover;
      display: inline-block;
    }
  }

  .phycheme-sec6 {
    .phycheme-sec6-1 {
      margin-top: 15px;
    }
  }
  .phycheme-sec8 {
    background-color: #0a0333;
    padding-top: 0 !important;

    .ani-wrap50 {
      display: none !important;
    }

    .card-img-inner-wrap {
      margin-bottom: 40px;
    }
  }
  .phycheme-sec9 {
    min-height: 400px;

    .phycheme-sec9-1-outer {
      transform: scale(0.4) !important;
      transform-origin: center center !important;
      -webkit-transform: scale(0.4) !important;
      -moz-transform: scale(0.4) !important;
      -ms-transform: scale(0.4) !important;
      -o-transform: scale(0.4) !important;

      ul {
        margin-top: 300px;
      }
    }
  }
  .phycheme-sec10 {
    background-size: auto 100%;
  }
  .phycheme-sec11 {
    .phycheme-center-wrap {
      display: inline-block;
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 4;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .phycheme-sec12 {
    .phycheme-left-wrap4 {
      .phycheme-sec12-4 {
        height: inherit;
        position: relative;
        margin: auto;
        display: block;
      }
    }
  }
  .phycheme-sec13 {
    .phycheme-left-wrap {
      position: relative;
      bottom: 0;
      left: 0;
    }
  }
  .phycheme-sec14 {
    .phycheme-left-wrap {
      position: relative;
      bottom: -70px;
      left: 0;
    }
  }

  .moneyguru-sec-website1 {
    text-align: center;

    .portfolio-info-banner-l.portfolio-info-wrap2 {
      max-width: 600px;
      margin: 30px auto 0;
    }

    .portfolio-info-banner-r {
      .moneyguru-banner-img {
        margin-left: 0;
        max-width: 280px;
        width: 100%;
        height: auto;
      }

      .moneyguru-banner-after {
        margin-left: 0;
        top: 23px;
        width: 160px;
      }
    }

    .mt30 {
      margin-top: 10px !important;
    }
  }

  .moneyguru-sec-website4 {
    .moneyguru-architecture-bottom {
      .viewer, .ng-draggable {
        height: 400px;
        padding: 0;

        img {
          width: 1300px;
        }
      }
    }
  }
  .moneyguru-sec-website5 {
    .moneyguru-landing-outer {
      .moneyguru-landing {
        max-width: 100%;
        margin-left: 0 !important;
      }
    }
  }
  .moneyguru-sec-website6 {
    padding-bottom: 20px !important;

    .moneyguru-insights-left.portfolio-info-wrap2 {
      text-align: center;
      margin-bottom: 30px;
    }

    .moneyguru-insights-right {
      .moneyguru-platform {
        display: inline-block;
        max-width: 100%;
        margin-top: 0;
        margin-left: auto;
      }
    }
  }

  .moneyguru-sec-website2 {
    .moneyguru-overview {
      top: -40px;
    }
  }

  .moneyguru-sec-website7,
  .moneyguru-sec-website8 {
    .moneyguru-insights-right-col {
      position: relative;
      top: 0;
      right: 0;
      bottom: 0;
      padding-right: 0;

      img {
        float: right;
        display: block;
        max-width: 300px;
        margin-right: -20px;
      }
    }
  }
  .portfolio-branding-insisive-body {
    .insisive-logo-after-img {
      position: absolute;
      bottom: 30px;
      right: 30px;
      width: 150px;
      z-index: 1;
    }

    .bagshaha-sec4 {
      .bagshaha-sec4-right {
        .bagshaha-sec4-right-b {
          img {
            padding-top: 0;
            margin-top: 0;
          }
        }
      }
    }
  }
  .notion-edge-sec1 {
    .notion-edge-logo-bg {
      display: none !important;
    }
  }

  .portfolio-branding-vimson-body {
    .notion-edge-sec1 {
      .notion-edge-logo-bg {
        display: none !important;
      }

      .smartlinks-logo.lazy.img-fluid.mb30 {
        margin-bottom: 20px !important;
        width: 100px;
        margin-top: 30px;
      }
    }

    .vimson-sec6 {
      .vh100-100 {
        min-height: 250px;

        .vimson-compare.img-fluid.lazy {
          width: 170px;
        }
      }
    }
  }
  .portfolio-webapp-insisive-body {
    .insisive-website-sec1 {
      h1 {
        br {
          display: none;
        }
      }

      .insisive-logo-after {
        position: absolute;
        bottom: 150px;
        right: -90px;
        z-index: 1;
        width: 200px;
      }

      .insisive-website-right-outer {
        max-width: 250px;
        margin: 0 auto 30px;

        .insisive-webapp-banner-outer {
          position: relative;
          z-index: 3;
          top: 0;
          left: 0;
          margin: auto;
          display: block;
        }
      }
    }

    .insisive-webapp-4 {
      margin-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .insisive-webapp-4 {
      .portfolio-info-wrap2 {
        margin-top: 0;
      }

      .insisive-straight-up-outer {
        position: relative;
        top: 0;
        right: 0;
        bottom: auto;
        left: 0;
        z-index: 9;
      }
    }

    .insisive-website-sec26 {
      .insisive-website-sec2-1 {
        height: 140px !important;
        object-fit: cover;
      }
    }
  }

  .portfolio-website-bynry-body {
    .bynry-website-sec1 {
      .bynry-banner-wrap {
        margin-top: 0;

        .img-fluid {
          height: 330px;
        }
      }
    }

    .bynry-website-sec2 {
      .moneyguru-sec2-3 {
        max-width: 480px !important;
        margin: auto;
        display: block;
        width: 100%;
      }

      .moneyguru-sec2-left {
        margin-left: 0;
        margin-top: 20px;

        .img-fluid {
          max-width: 490px;
        }
      }
    }

    .bynry-website-sec4 {
      &::after {
        display: none;
      }

      .authntick-center-wrap {
        h3 {
          white-space: normal;
        }
      }

      .authntick-sec9-2-outer {
        img {
          display: inline-block;
          max-width: 400px;
          width: 100%;
        }
      }
    }

    .bynry-website-sec5 {
      background-color: #051941;

      .ani-wrap50 {
        display: none !important;
      }

      .insisive-website-sec8-right {
        text-align: center;

        .img-fluid {
          display: inline-block;
          height: 100%;
          width: 100%;
          object-fit: cover;
          max-width: 400px;
        }
      }
    }

    .bynry-website-sec6 {
      [data-aos="new-animation5"].aos-animate {
        width: 24%;
      }

      .img-fluid.bynry-landing-page.lazy {
        max-width: 550px;
        margin-top: 0;
        margin-left: -50px;
      }
    }

    .bynry-website-sec7 {
      .bagshaha-sec9-2-outer {
        .bynry-devices {
          max-width: 460px;
          margin-left: 0;
        }
      }
    }
  }

  .portfolio-website-insisive-body {
    .insisive-website-sec1 {
      margin-top: 0 !important;
    }
  }
  .moneyguru-logo-img {
    margin-top: 10px !important;
    width: 150px;
  }

  .expertise-sect2 {
    .expertise-container {
      padding-top: 0;
    }

    .expertise-sect-left {
      .expertise-sect-left-head {
        .expertise-icon {
          position: absolute;
          top: -17px;
          left: -45px;
          z-index: 0;
          width: 90px;
        }
      }

      .expertise-sect-left-body {
        .bttn {
          margin: 10px 0;
        }

        .expertise-icon-big {
          width: 220px;
          top: -20px;
          left: 140px;
        }
      }
    }

    .expertise-sect-right {
      p {
        margin-bottom: 20px !important;
      }

      h6 {
        margin-bottom: 10px;
      }

      .expertise-ul-list {
        margin-bottom: 25px;

        li {
          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }
      }

      h4 {
        margin-bottom: 15px;
      }

      p {
        margin-bottom: 30px;
      }
    }
  }

  .industries-list {
    li {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
  .industries-section1 {
    .industries-list {
      margin-top: 0;
    }

    .comman-card-info:not(:last-child) {
      margin-bottom: 60px;
    }
  }

  .expertise-sect1 {
    .comman-card-img-wrap {
      transform-origin: center 100px;
      transform: scale(0.55) translateZ(0) translateX(-50%);
      left: 50%;
      width: 450px;
      margin-left: -140px;
      height: 400px;
      -webkit-transform: scale(0.55) translateZ(0) translateX(-50%);
      -moz-transform: scale(0.55) translateZ(0) translateX(-50%);
      -ms-transform: scale(0.55) translateZ(0) translateX(-50%);
      -o-transform: scale(0.55) translateZ(0) translateX(-50%);
    }

    .expertise-tbl {
      margin-top: 20px;
    }

    .expertise-tbl,
    .expertise-tbl tr,
    .expertise-tbl td {
      width: 100%;
      display: block;
    }
  }
  .expertise-sect2 {
    .expertise-sect-row {
      margin-bottom: 60px;
    }

    .expertise-sect-left .expertise-sect-left-body .bttn {
      margin-top: 15px;
      margin-bottom: 30px;
    }

    .expertise-sect-left .expertise-sect-left-head .expertise-icon {
      display: none !important;
    }

    .expertise-sect-left .expertise-sect-left-body .expertise-icon-big {
      display: none !important;
    }
  }
  .industries-list li::before {
    border: 1px solid $orange;
    top: 9px;
  }
  .sec-heading-wrap-xs {
    h2 {
      br {
        display: none !important;
      }
    }
  }
  .rsmart-sect7-1 .rsmart-landing-outer .rsmart-landing {
    display: inline-block;
    height: inherit !important;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    max-width: 100%;
  }
  .phycheme-sect3-right-adj {
    position: absolute;
    top: 40px;
    left: 30px;
    z-index: 9;
  }
  .crubba-sec1 .portfolio-img-wrap .crubba-sec9-inner2 {
    position: relative;
    top: 24px;
    left: -28px;
    z-index: 5;
    width: 230px;
  }
  .career-form-wrap .contact-breadcrum-outer .contact-breadcrum2 li {
    position: relative;
    padding-right: 10px;
    margin-right: 5px;
    vertical-align: middle;
    margin-bottom: 0 !important;
    height: 20px;
  }
  .career-form-wrap
  .contact-breadcrum-outer
  .contact-breadcrum2
  li:not(:last-child)::after {
    top: 2px;
    right: 0;
    width: 8px;
    height: 14px;
  }
  .phycheme-sec8 .crubba-sec3-ul li::before {
    top: 6px;
  }

  // for top section for all pages
  .top-section-wrapper {
    h1 {
      margin-top: 54px !important;
    }
  }
  .our-work-page .work-comman-card-info .custom-nav-wrap-after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 80px;
    height: 100%;
    background: -moz-linear-gradient(
        left,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 1) 100%
    );
    background: -webkit-linear-gradient(
        left,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 1) 100%
    );
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
  }
  .home-sec4 .client-logo-wrap-outer {
    height: inherit !important;
  }
  .pr-form-control .input-control-wrap {
    // font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
  .pr-form-control textarea.input-control-wrap {
    height: 120px;
  }
  .my-budget-wrap-col-xs .slider.slider-horizontal {
    max-width: calc(100% - 40px) !important;
    display: block;
    margin: 20px auto 0;
  }
  .career-form-wrap .contact-breadcrum-outer .btn-back-link {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
  .about-sec1 {
    .comman-card-img-wrap-upd {
      margin-bottom: 30px;

      .about-sect-1-wrap {
        img {
          max-width: 300px;
          width: 100%;
        }
      }
    }
  }
  .comman-card-img-wrap-upd2 {
    margin-top: 20px;
    margin-bottom: 10px;

    img {
      max-width: 300px;
      margin: auto;
    }
  }
  .home-sec4 .client-logo-wrap-outer:hover {
    animation-play-state: inherit !important;
  }
  .portfolio-thumb-all {
    &:nth-child(odd) {
      padding-top: 0 !important;
    }

    .work-card {
      margin-bottom: 70px !important;

      .work-card-img {
        position: relative;
        margin-left: -60px;
        transform: scale(0.6);
        transform-origin: center top;
        -webkit-transform: scale(0.6);
        -moz-transform: scale(0.6);
        -ms-transform: scale(0.6);
        -o-transform: scale(0.6);
        height: 350px;
      }

      .work-card-body {
        width: 270px;
        margin-top: 30px;

        h5 {
          margin-top: 8px;
        }
      }
    }
  }
  .our-work-page {
    #comman-footer {
      height: inherit !important;
    }
  }
  .comman-card-info.work-comman-card-info {
    min-height: 130px;
    display: block;
  }
  .our-expressions-upd-xs {
    max-width: 260px;
    margin: auto;
  }
  .comman-team-card .comman-team-card-foot h6.text-blank {
    font-size: 34px;
    margin-bottom: 15px;
    padding-top: 10px;
    color: #000 !important;
  }
  .restlessRegularText {
    font-size: 42px;
    position: relative;
    margin: 0 !important;
  }
  .services-user-exp-card {
    margin-top: -70px;
  }
  .about-sec-img-right {
    transform: none;
    transform-origin: none;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    position: relative;
    height: inherit;
    padding: 15px 0;

    img {
      height: 300px;
    }
  }
  .about-sec-name {
    // font-size: 38px; old code
    font-size: 20px;
  }
  .question-icon {
    margin-left: -5px;
    width: 18px;
    display: inline-block;
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
  }
  .our-work-row .portfolio-thumb-all .work-card-body ul li:nth-child(2) {
    width: calc(100% - 100px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: -7px;
    margin-left: 0;
  }
  .our-work-row .portfolio-thumb-all .work-card-body ul {
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: auto;
    text-overflow: ellipsis;
    width: 100%;
  }
  .portfolio-website-insisive2-body {
    .insisive-website-sect1-1 {
      width: 100%;
      margin: 30px auto 40px !important;
      display: block !important;
      text-align: center;

      h1 {
        margin-top: 30px;
      }
    }

    .insisive-website-sect1 {
      text-align: center !important;
    }

    .white-line {
      display: none !important;
    }

    .insisive-website-sect2-img {
      display: none !important;
    }

    [data-aos="new-animation5"].aos-animate {
      width: 26.5%;
      display: none !important;
    }

    .insisive-website-sect5-right {
      float: right;
      margin-top: 70px;
    }

    .insisive-website-sect6 .portfolio-info-wrap2 {
      min-height: inherit !important;
    }

    .img-fluid.lazy.insisive-website-sect6-1 {
      max-width: 80% !important;
      margin: 40px auto 40px !important;
    }

    .img-fluid.insisive-website-sect5-img.lazy {
      margin-left: 0;
    }

    .insisive-website-sect7-wrap {
      height: initial;
    }

    .insisive-website-sect2-center {
      max-width: 470px;
      margin: 0 auto 60px;
      text-align: center;
      float: right;
    }

    .insisive-website-sect4-img {
      margin-top: 50px;
    }

    .insisive-website-sect7-wrap .insisive-website-sect7-hover {
      margin-left: 10px;
      position: absolute;
      z-index: 5;
      top: 12px;
      left: 50%;
      bottom: 0;
      width: 224px;
      height: 142px;
    }

    .insisive-website-sect7-wrap img.insisive-website-sect7-lazy {
      margin-left: auto !important;
      width: 320px;
      margin-right: auto !important;
    }

    .insisive-website-sect7-wrap {
      transform: none !important;
      -webkit-transform: none !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      -o-transform: none !important;
      position: relative;
      top: 10px;

      img.insisive-website-sect7-lazy {
        margin-left: -120px;
      }
    }

    .insisive-website-sect8-wrap {
      transform: none !important;
      -webkit-transform: none !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      -o-transform: none !important;
      position: relative;
      top: 20px;

      .insisive-website-sect8-lazy {
        top: -23px;
        width: 340px;
        margin-left: 0;
      }
    }

    .insisive-website-sect8-carousel .owl-item img {
      margin: auto;
      width: 284px;
    }

    .insisive-website-sect8 {
      padding-bottom: 0;
      margin-top: 0;
      position: relative;
      margin-bottom: 0;
    }

    .insisive-website-sect6-1 {
      margin-bottom: 50px;
    }

    .insisive-website-sect8 h3 {
      margin-top: 10px;
    }

    .insisive-website-sect8-right {
      max-width: 300px;
      margin: auto;
    }
  }

  .portfolio-webapp-mytrux-body {
    .mytrux-webapp-sect1 {
      text-align: center;

      .comman-card-info {
        margin-top: 50px;
        margin-bottom: 50px;
      }

      [data-aos="new-animation7"].aos-animate {
        width: 100%;
      }

      .mytrux-webapp-sect1-1 {
        display: none !important;
      }

      .mytrux-webapp-sect1-2 {
        display: none !important;
      }
    }

    .mytrux-webapp-sect2 {
      .mytrux-website-sect2-1 {
        position: absolute;
        bottom: -45px;
        right: -50px;
        z-index: 3;
        width: 260px;
        display: none !important;
      }
    }

    .text-md-ceter {
      text-align: center;
    }

    .mytrux-webapp-sect3 {
      background: #0f2541;

      .ani-wrap50 {
        background: #0f2541;
        width: 100% !important;
        height: 44% !important;
      }

      .mytrux-webapp-sect3-left-list {
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        margin: 30px auto 60px;
        text-align: center;

        li {
          img {
            max-width: 110px;
          }
        }
      }

      .mytrux-webapp-sect3-right-list {
        li {
          position: relative;
          padding-left: 40px;
          margin-bottom: 30px;
        }
      }
    }

    .comman-solution-challenge .portfolio-info-wrap2 {
      min-height: inherit;
      margin: auto !important;

      &.ml85::after {
        display: none !important;
      }

      h3 {
        text-align: center;
        color: #ffffff;
        margin-bottom: 30px;
      }

      .p2 {
        color: #ffffff;
      }
    }

    .mytrux-webapp-sect4 {
      .portfolio-info-wrap2 {
        margin: 0 auto !important;
      }

      .mytrux-webapp-sect4-1,
      .mytrux-webapp-sect4-2,
      .mytrux-webapp-sect4-3 {
        max-width: 100%;
        position: relative;
        top: auto;
        left: 0 !important;
        margin: 30px auto 0;
        display: block !important;
      }
    }

    .mytrux-webapp-sect5 {
      .portfolio-info-wrap2 {
        margin: 40px auto 0;
        text-align: center;
      }

      .mytrux-webapp-sect5-1 {
        max-width: 100%;
        margin: auto !important;
        display: block !important;
      }

      .mytrux-webapp-sect5-2 {
        display: none !important;
      }
    }

    .mytrux-webapp-sect6 {
      .mytrux-webapp-sect6-1-wrap {
        .mytrux-webapp-sect6-1 {
          max-width: 100%;
          margin: 0 auto !important;
          display: block !important;
        }
      }

      .mytrux-webapp-sect6-2-wrap {
        .mytrux-webapp-sect6-2 {
          max-width: 100%;
          margin: 0 auto !important;
          display: block !important;
        }
      }
    }

    .mytrux-webapp-sect7 {
      .mytrux-webapp-sect7-1-wrap {
        padding-top: 0;
      }
    }
  }
  .portfolio-webapp-mytrux-body .mytrux-webapp-sect6 .mytrux-webapp-sect6-2-wrap p,
  .portfolio-webapp-mytrux-body .mytrux-webapp-sect6 .mytrux-webapp-sect6-1-wrap p {
    position: relative !important;
    transform: none !important;
    left: auto !important;
    top: auto !important;
    margin: auto !important;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -o-transform: none !important;
  }
  .portfolio-webapp-mytrux-body .comman-header .mega-menu .mega-menu-img1 path,
  .contact-page-body .comman-header .mega-menu .mega-menu-img1 path {
    fill: #fff;
  }

  .portfolio-webapp-mytrux-body .comman-header.nav-down .mega-menu .mega-menu-img1 path,
  .contact-page-body .comman-header.nav-down .comman-header-menu-ul .logo-img path,
  .contact-page-body .comman-header.nav-down .comman-header-menu-ul .mega-menu-img1 path {
    fill: #242424;
  }
  .authntick-sec9 .authntick-result-carosual .owl-stage-outer.owl-height {
    height: 200px !important;
  }
  .portfolio-website-insisive2-body .insisive-website-sect8-wrap {
    margin-top: 50px !important;
    position: relative;
  }
  .work-card-img .circle {
    margin-top: -10px;
    transform: scale(0.7) translate(-50%, -50%);
    -webkit-transform: scale(0.7) translate(-50%, -50%);
    -moz-transform: scale(0.7) translate(-50%, -50%);
    -ms-transform: scale(0.7) translate(-50%, -50%);
    -o-transform: scale(0.7) translate(-50%, -50%);
  }
  .concerro-sec3.comman-solution-challenge .portfolio-info-wrap2.ml85::after {
    background: #fff;
  }
  .concerro-sec4 .ani-wrap50-2,
  .concerro-sec4 .ani-wrap50-b {
    display: none;
  }
  .comman-section.comman-section-100vh.scroll-div.concerro-sec4 {
    background: #f4f4f4;

    .concerro-right-wrap {
      display: none;
    }
  }
  .concerro-sec4-1.lazy.img-fluid {
    margin-top: 30px;
    max-width: 300px;
  }
  // .comman-header-menu-right {
  //   position: fixed !important;
  //   top: 30px !important;
  //   right: 20px !important;
  //   display: block !important;
  // }
  .coming-soon-hover .coming-soon-h5 {
    font-size: 30px;
    width: 100%;
    display: block;
  }
  .process-section1 .comman-card-info, .industries-section1 .comman-card-info {
    transform: none !important;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -o-transform: none !important;
  }
  .col-sm-12.col-md-12.col-lg-6.position-relative.process-left-sec.industries-left-sec-height.xs-mt50 {
    .mCustomScrollbar {
      touch-action: auto !important;
    }

    .mCustomScrollbar,
    .mCustomScrollBox,
    .mCSB_container {
      overflow: inherit !important;
      transform: none !important;
      -webkit-transform: none !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      -o-transform: none !important;
    }

    .mCSB_scrollTools_vertical {
      display: none !important;
    }
  }
  .wellness-sec3 {
    background: #561E5E;

    .portfolio-info-wrap2.ml85::after {
      background: #fff;
      z-index: 3;
    }
  }
  .wellness-sect4 {
    .wellness-sec4-right {
      position: static;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      z-index: 9;
    }

    .ani-wrap50-2 {
      display: none !important;
    }

    .smartlinks-sec4-left.portfolio-info-wrap2 {
      margin-bottom: 30px !important;
    }
  }
  .wellness-sect5 {
    .ani-wrap50 {
      display: none !important;
    }

    .vigan-right-wrap.portfolio-info-wrap2.ml85 {
      margin-left: 0 !important;
      margin-bottom: 70px !important;
    }

    .wellness-sec5-left {
      position: static;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }

  .source-trace-sec1 {
    .p2 {
      margin-top: 15px;
    }

    .source-trace-right-wrap {
      img {
        position: static;
        margin-top: 30px;
        width: 100% !important;
        margin-bottom: 20px;
      }
    }
  }
  .source-trace-sec2 {
    .farmers-img {
      width: 300px;
    }

    .ani-wrap50-3 {
      width: 100%;
    }
  }
  .source-trace-sec3 {
    .small-tracker-img {
      bottom: -100px;
    }

    .leave1-img {
      top: -60px;
      display: none !important;
    }

    .overview-sec-center {
      position: relative;
      margin-bottom: 0;
    }
  }
  .source-trace-sec4 {
    background: #009456;

    .portfolio-info-wrap2.ml85::after {
      background: #F7F7F8;
    }

    .leave3-img {
      display: none !important;
    }

    .corn-img {
      display: none !important;
      position: absolute;
      bottom: -70px;
      right: -130px;
      z-index: 2;
      width: 300px;
    }
  }
  .source-trace-sec5 {
    .info-architecture1 {
      max-width: 100%;
      display: block;
      margin: 20px auto 0;
    }
  }
  .source-trace-sec6 {
    .source-trace-sec6-row {
      white-space: nowrap;
      overflow: auto;
      flex-wrap: nowrap;

      > div {
        min-width: 280px;
        margin-bottom: 30px;
      }
    }

    .source-trace-center-wrap {
      margin-bottom: 20px;
    }

    .wireframe-img {
      margin-top: 30px;
    }
  }
  .source-trace-sec7 {
    .leave1-img {
      top: 60px;
      display: none !important;
    }

    .leave2-img {
      top: 60px;
      display: none !important;
    }

    .farmer-flow {
      margin-top: 20px;
      max-width: 100%;
      margin-bottom: 30px;
    }
  }
  .source-trace-sec8 {
    .leave2-img {
      top: 180px;
      display: none !important;
    }

    .multi-dashboard {
      max-width: 100%;
      margin-top: 30px;
      margin-left: 0;
      position: static;
      transform: none !important;
    }

    .leave-bg {
      bottom: -177px;
      display: none !important;
    }

    .plant-bg {
      display: none !important;
    }
  }
  .source-trace-sec10 {
    .dt-img {
      max-width: 100%;
    }

    .db1 {
      display: none !important;
    }

    .db2 {
      display: none !important;
      bottom: -10px;
      left: -70px;
      max-width: 280px;
    }
  }
  .source-trace-sec11.esop-sec6 {
    .esop-slider-circle {
      margin-top: -70px;
    }

    .esop-left-wrap {
      .esop-slider-outer-wrap .esop-slider {
        padding-top: 35px !important;
      }

      .comman-scrollbar-wrap {
        .esop-slider-outer-wrap {
          .mt140 {
            margin-top: 90px;
          }

          margin-left: 10px;
          animation: none;
          -webkit-animation: none;
          margin-top: 0;
        }
      }
    }
  }
  .source-trace-sec12 {
    .gui-components {
      max-height: calc(100vh - 180px);
      margin: auto !important;
      display: block;
    }
  }


  .wellness-logo-caroual {
    .wellness-caroual-item {
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .owl-item {
      img {
        max-width: 100%;
        padding: 0 70px;
      }
    }
  }

  .tab-scroll-ul-home{
    .nav-item{
      .nav-link{
        margin-right: 20px;
       
      }
    }
  }
}

@media (min-width: 576px) {
  .show-mobile-xxs {
    display: none !important;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  a.mega-menu.mega-menu-close {
    top: 8px;
    left: 10px;
    position: relative;
  }
  .home-sec2 {
    .carousel-indicators-ul {
      left: -40px;
    }
  }
  .home-sec-tab {
    .flex-column {
      .nav-link:not(:last-child) {
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
    }
  }
  .authntick-sec1 {
    .authntick-right-wrap {
      img {
        margin-right: -100px;
      }
    }
  }
  .authntick-sec9 {
    .authntick-result-carosual {
      width: 500px;
      margin: auto;
    }
  }
  .moneyguru-insights-section {
    .moneyguru-insights-right-col {
      .moneyguru-insights-right {
        video {
          height: 400px;
          width: auto !important;
        }
      }
    }
  }
  .vigan-sec12 {
    background-size: contain !important;
    min-height: 340px !important;
    padding: 0 !important;
  }
  .mytrux-logo-sec7 {
    #comparison {
      #divisor,
      figure {
        background-position-x: 200px;
        background-size: 200px;
      }
    }
  }

  .mytrux-logo-sec11 {
    .mytrux-logo-right-wrap {
      .mytrux-logo-sec11-1 {
        top: 110px;
        width: 280px;
        margin-top: -80px;
      }

      .mytrux-logo-sec11-2-outer {
        margin-top: -300px;
      }

      .mytrux-logo-sec11-2 {
        position: relative;
        bottom: auto;
        max-width: initial;
        left: auto;
        width: auto;
        max-width: 100%;
      }
    }
  }
  .mytrux-webapp-sec8 {
    .bottom-col-set {
      transform: scale(0.6) !important;
      transform-origin: center bottom;
      -webkit-transform: scale(0.6) !important;
      -moz-transform: scale(0.6) !important;
      -ms-transform: scale(0.6) !important;
      -o-transform: scale(0.6) !important;
      height: 260px;
    }
  }
  .mytrux-logo-sec10 {
    .app-icon-ul {
      width: 915px;
    }
  }
  .insisive-website-sec1 {
    .container.h-100 {
      margin-top: 40px;
    }

    .insisive-website-sec1-1 {
      max-width: 500px;
      margin-left: -30px;
    }
  }
  .mytrux-webapp-sec6 {
    .mytrux-web-sec6-3-xs {
      .img-fluid {
        max-width: 400px;
        margin: 30px auto 0;
        width: 100%;
        display: block;
      }
    }
  }
  .alloch-sec1 {
    height: 360px;
    overflow: hidden;
    position: relative;

    .alloch-right-wrap {
      text-align: center;
      margin-top: 40px;
    }

    .alloch-right-wrap {
      .alloch-sec1-1 {
        width: 110px;
        margin-left: 0;
      }

      .alloch-sec1-2 {
        position: absolute;
        top: -30px;
        left: -18px;
        width: 120px;
      }
    }

    .portfolio-info-banner-l.portfolio-info-wrap2 {
      margin-top: 0;
      margin-left: -20px;
    }
  }
  .rsmart-sect1 {
    .container.h-100 {
      margin-top: 20px;

      .col-sm-4.col-md-5 {
        padding: 0;
      }
    }
  }
  .rsmart-sect2 {
    padding-bottom: 0 !important;
  }
  .rsmart-sect4 {
    .rsmart-center-wrap {
      p {
        max-width: 703px;
        margin: 15px auto;
      }
    }

    .viewer, .ng-draggable {
      height: 340px;

      img {
        height: 180px;
      }
    }
  }
  .rsmart-sect6 {
    .rsmart-sect6-2 {
      display: inline-block;
      max-width: 100%;
      height: auto;
      margin-top: 30px;
    }
  }

  .rsmart-sect7 {
    .portfolio-info-wrap2 {
      max-width: 430px;
      margin-top: 0;
    }

    .rsmart-strategy-img {
      display: block !important;
      height: 100%;
      width: 280px;
      margin: auto !important;
    }
  }
  .comman-card-top-ul {
    margin-bottom: 7px;

    li {
      margin-right: 30px !important;

      &:last-child {
        margin-right: 0px !important;
      }

      &:first-child {
        &::after {
          top: 9px;
          width: 20px;
          right: -28px;
        }
      }
    }
  }
  .work-sec2 .vh100-100 > div {
    margin-top: 30px;
  }
  .work-comman-card-info {
    .custom-nav-wrap {
      white-space: nowrap;
      overflow-x: auto;
      margin-bottom: 0;

      .list-inline-item {
        margin-right: 30px;
      }
    }
  }
  .our-work-page {
    .comman-section {
      padding: 40px 0 !important;
    }

    .comman-card-img-wrap-xs {
      .lazy.img-fluid {
        max-width: 400px;
      }
    }

    .comman-footer {
      margin-top: 20px;
      padding: 60px 0 !important;
    }

    .pt0 {
      padding-top: 0 !important;
    }
  }
  .moneyguru-sec-website7,
  .moneyguru-sec-website8 {
    .moneyguru-insights-left.portfolio-info-wrap2 {
      max-width: 300px;
    }

    .moneyguru-insights-left {
      white-space: inherit;
    }

    .moneyguru-insights-right-col {
      .moneyguru-insights-right {
        img {
          float: right;
          display: block;
          max-width: 290px;
          margin-right: -86px;
        }
      }
    }
  }
  .moneyguru-sec-website9 {
    .moneyguru-landing-outer {
      position: relative;
      max-width: 500px;
      margin: 0 auto 0;

      .mytrux-details-up {
        position: absolute;
        top: 80px;
        left: 0px;
        z-index: 0;
        width: 80px;
      }
    }
  }
  .portfolio-branding-notion-edge-body {
    .notion-edge-sec1 {
      .smartlinks-logo.lazy.img-fluid.mb30 {
        display: inline-block;
        width: 90px;
        margin-bottom: 15px !important;
      }
    }

    .notion-edge-sec8 {
      #comparison {
        figure,
        #divisor {
          background-position: 200px center;
          background-size: 240px auto;
        }
      }
    }
  }
  .portfolio-webapp-insisive-body {
    .insisive-website-sec8 {
      .portfolio-info-wrap2 {
        margin-top: 0;
        position: relative;
        top: 70px;
      }

      .insisive-website-sec8-right {
        margin-top: -150px;
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        transform: none;
        z-index: 3;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        max-width: 300px;
        float: right;
      }
    }
  }
  .smartlinks-sec7 {
    #comparison {
      #divisor,
      figure {
        background-position-x: 200px;
        background-size: 260px auto;
      }
    }
  }
  .moneyguru-sec1 {
    padding-bottom: 0 !important;

    br {
      display: none !important;
    }

    .portfolio-info-wrap2 .moneyguru-banner-img {
      left: 70px;
      width: 190px;
      margin-top: -20px;
    }

    [data-aos="new-animation4"].aos-animate {
      width: 75%;
    }
  }
  .authntick-sect5 {
    .authntick-sect5-laptop-wrap {
      img {
        height: auto;
        width: auto;
      }
    }

    .authntick-sect5-overlay-wrap {
      margin-left: -162px;
      margin-top: -10px;

      img {
        width: 150px;
      }
    }
  }
  .expertise-sect-9 {
    .table-expertise {
      border-collapse: collapse;
      width: 100%;
      margin: auto;
      border-style: hidden;
    }

    .comman-card-info {
      padding: 0 10px;

      .process-image.lazy.mb25 {
        width: 80px;
      }
    }
  }
  .team-carosual .comman-team-card-head {
    left: -40px;
  }

  /* Added on 30-10-2020 */
  .esop-sec1 {
    .esop-triangle1 {
      top: 10px;
      right: 300px;
    }

    .esop-triangle2 {
      bottom: -37px;
      left: -70px;
    }

    .esop-header-img {
      max-width: 520px;
      margin-top: 30px;
      margin-left: 110px;
    }

    h1.hide-mobile {
      display: block !important;
    }
  }
  .esop-sec5 {
    .ani-wrap50.aos-init.aos-animate {
      width: 100%;
    }

    .esop-left-wrap {
      h3 {
        margin-bottom: 0 !important;
      }

      .esop-hits-img {
        max-width: 410px;
      }
    }

    .esop-hits-map-img {
      max-width: 360px;
      margin-top: 40px;
    }
  }
  .esop-sec6 {
    .esop-left-wrap {
      overflow: hidden;

      .esop-slider-circle {
        width: 460px;
        height: 460px;
      }

      .comman-scrollbar-wrap {
        .esop-slider-outer-wrap {
          margin-left: 30px;

          .esop-slider {
            width: 200px;
            height: 240px;
          }

          .esop-slider-ul {
            width: 200px;
            margin-right: 20px;

            li {
              margin-bottom: 20px;
            }
          }
        }
      }
    }

    .esop-right-wrap {
      margin-left: auto;
      margin-top: 0px;
      margin-right: auto;
      margin-bottom: 40px;
    }
  }
  .esop-sec8 {
    .esop-right-wrap {
      .esop-attention-wrap {
        top: -85px;
        right: auto;
        left: -97px;
      }

      .esop-right-row.clearfix {
        transform-origin: center;
        transform: translateX(-50%) scale(1);
        -webkit-transform: translateX(-50%) scale(1);
        -moz-transform: translateX(-50%) scale(1);
        -ms-transform: translateX(-50%) scale(1);
        -o-transform: translateX(-50%) scale(1);
        width: 700px;
        top: 0;
        left: 50%;
        margin: auto;
      }
    }
  }
  .esop-sec9 {
    .esop-right-wrap {
      margin-left: 0;
    }

    .esop-left-wrap .img-fluid {
      margin-left: -30px;
      top: 30px;
      max-width: 560px;
    }

    .circle-sec {
      width: 540px;
      height: 520px;
    }
  }
  .esop-sec10 {
    padding-top: 30px !important;

    .esop-left-wrap {
      margin-top: 40px;
      margin-left: 0;
    }

    .esop-right-wrap .img-fluid {
      transform-origin: center;
      transform: translateX(-50%) scale(0.8);
      -webkit-transform: translateX(-50%) scale(0.8);
      -moz-transform: translateX(-50%) scale(0.8);
      -ms-transform: translateX(-50%) scale(0.8);
      -o-transform: translateX(-50%) scale(0.8);
      width: 700px;
      top: -30px;
      left: 50%;
      margin: auto;
    }

    .circle-sec {
      width: 540px;
      height: 520px;
    }
  }
  .esop-sec11 {
    padding-top: 0 !important;

    .esop-right-wrap {
      margin-left: 0;
      margin-top: 0;
    }

    .esop-left-wrap .img-fluid {
      transform-origin: center;
      transform: translateX(-50%) scale(0.8);
      -webkit-transform: translateX(-50%) scale(0.8);
      -moz-transform: translateX(-50%) scale(0.8);
      -ms-transform: translateX(-50%) scale(0.8);
      -o-transform: translateX(-50%) scale(0.8);
      width: 720px;
      top: -30px;
      left: 50%;
      margin: auto;
    }

    .circle-sec {
      top: 44%;
      width: 540px;
      height: 520px;
    }
  }
  .esop-sec12 {
    .esop-center-wrap {
      top: 0;
    }

    .ui-card-slider {
      transform-origin: 0 100px;
      transform: translateX(-50%) scale(2);
      -webkit-transform: translateX(-50%) scale(2);
      -moz-transform: translateX(-50%) scale(2);
      -ms-transform: translateX(-50%) scale(2);
      -o-transform: translateX(-50%) scale(2);
      left: 16%;
      right: auto;
    }
  }

}

@media (max-width: 575.98px) {

  .xs-order-1{
    order: 1 !important;
  }

  a.mega-menu.mega-menu-close {
    top: 8px;
    left: 10px;
    position: relative;
  }
  .hide-mobile-xxs {
    display: none !important;
  }
  .show-mobile-xxs {
    display: block !important;
  }
  .xxs-bb0 {
    border-bottom: 0 !important;
  }
  .xxs-text-center {
    text-align: center !important;
  }
  .xs-text-left {
    text-align: left !important;
  }
  .xxs-mt30 {
    margin-top: 30px !important;
  }
  .xxs-mt0 {
    margin-top: 0px !important;
  }
  .order-xxs-last {
    order: 12;
  }
  .hide-mobile-xs {
    display: none !important;
  }
  .show-mobile-xs {
    display: block !important;
  }

  .comman-top-menu {
    .top-menu-left {
      margin-top: 20px;
    }

    .top-menu-right {
      padding-top: 0;

      li {
        float: none;
        margin-bottom: 15px;

        &:not(:last-child) {
          margin-right: 15px;
          margin-bottom: 15px;
        }
      }
    }
  }
  .order-xs-last-adj {
    -ms-flex-order: 1;
    order: 1;
  }
  .home-sec2 {
    padding-bottom: 50px !important;

    .carousel-indicators-ul {
      position: relative;
      top: auto;
      left: auto;
      transform: none !important;
      height: auto !important;
      display: block;
      width: 100%;
      margin: 0;
      z-index: 49;
      -webkit-transform: none !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      -o-transform: none !important;
      margin-top: 20px;
      height: 40px !important;
      text-align: center;

      li {
        display: inline-block !important;
        margin: 6px !important;

        &.active {
          position: relative;
          top: 3px;
        }
      }
    }
  }
  .career-sec2 {
    padding-top: 0 !important;
  }
  .contact-section2 {
    .my-budget-wrap {
      h4 {
        margin-left: -20px;
      }
    }
  }

  .moneyguru-sec1 {
    [data-aos="new-animation4"].aos-animate {
      width: 100%;
    }

    .moneyguru-sec12 {
      .moneyguru-sec12-right {
        margin-top: 20px;
      }

      .row.mt70 {
        margin-top: 10px !important;
      }
    }

    .comman-card-top-ul {
      margin-bottom: 5px;
    }

    .portfolio-info-wrap2 {
      .moneyguru-banner-img {
        left: 0;
        width: 240px;
        margin: 0 auto 30px;
        display: block;
        position: static;
        top: 0;
        left: 0;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
      }

      .img-fluid.mt30 {
        margin-top: 20px;
        width: 150px;
      }
    }
  }

  .crubba-sec1 {
    .crubba-sec1-right {
      margin-left: 0;
      margin-top: 30px;
    }

    .portfolio-img-wrap {
      top: auto;
      left: auto;
      text-align: center;
      width: 260px;
      margin: 30px auto 0;

      .crubba-sec9-inner4 {
        top: -60px;
        left: -48px;
        width: 370px;
      }

      .crubba-sec9-inner5 {
        top: -10px;
        left: 0;
        width: 270px;
      }

      .crubba-sec9-inner2 {
        position: relative;
        top: 0;
        left: 0;
        z-index: 5;
        width: 260px;
        display: block !important;
      }
    }

    .crubba-sec9-inner1,
    .crubba-sec9-inner3 {
      display: none !important;
    }
  }

  .crubba-sec2 {
    .crubba-sec2-img1 {
      max-width: 300px;
    }

    .crubba-sec2-img3,
    .crubba-sec2-img2 {
      display: none !important;
    }
  }
  .crubba-sec4 {
    .viewer, .ng-draggable {
      height: 340px;
      overflow: auto;
    }

    .ng-draggable {
      overflow: none
    }
  }
  .crubba-sec7 {
    background: $black;

    .crubba-sec7-bottom1,
    .crubba-sec7-bottom2,
    .crubba-sec6-bottom-before {
      display: none !important;
    }

    .crubba-sec7-img1 {
      display: none !important;
    }
  }
  .crubba-sec7-bottom {
    height: auto !important;

    .crubba-sec7-bottom3-wrap {
      margin-left: -10px;

      .crubba-sec7-bottom4-hover {
        top: 10px !important;
        left: 50% !important;
        bottom: 0 !important;
        width: 240px !important;
        height: 153px !important;
        margin-left: 10px !important;
      }
    }
  }
  .crubba-sec7-bottom3 {
    width: 340px !important;
    display: block !important;
  }
  .crubba-sec8 {
    padding-bottom: 0 !important;
  }
  .crubba-sec9 {
    .vh100-100 {
      min-height: inherit !important;
    }

    .crubba-sec9-img2 {
      display: none !important;
    }

    .crubba-sec9-img {
      max-width: 320px;
      height: auto;
      margin: auto;
      display: block !important;
    }

    .crubba-sec9-img-wrap {
      position: relative;
      top: 0;
      right: 0;
      z-index: 1;
    }
  }
  .vigan-sec12 {
    background-size: contain !important;
    min-height: 230px !important;
    padding: 0 !important;
  }

  .mytrux-logo-sec11 {
    .mytrux-logo-right-wrap {
      .mytrux-logo-sec11-1 {
        top: -50px;
        width: 280px;
        margin-top: 70px;
      }

      .mytrux-logo-sec11-2-outer {
        margin-top: -300px;
      }

      .mytrux-logo-sec11-2 {
        position: relative;
        bottom: auto;
        max-width: initial;
        left: auto;
        width: auto;
        max-width: 100%;
      }
    }
  }
  .mytrux-logo-sec7 {
    #comparison {
      #divisor,
      figure {
        background-position-x: 80px;
        background-size: 200px;
      }
    }
  }
  .mytrux-webapp-sec1 {
    .mytrux-webapp-sec1-1 {
      max-width: 300px;
      margin: auto !important;
      width: auto;
      display: block !important;
    }
  }
  .insisive-website-sec1 {
    margin-top: 0;

    .insisive-website-sec1-1 {
      max-width: 100%;
      margin: 0 auto 25px !important;
    }
  }

  .alloch-sec1 {
    height: inherit;
    overflow: hidden;
    position: relative;

    .alloch-sec1-1 {
      margin-left: 0;
      margin-bottom: 30px;
    }

    .alloch-right-wrap {
      text-align: center;
      margin-top: 50px;
    }

    .alloch-right-wrap {
      .alloch-sec1-1 {
        width: 160px;
      }

      .alloch-sec1-2 {
        position: absolute;
        top: -40px;
        left: -38px;
        width: 150px;
      }
    }

    .portfolio-info-banner-l.portfolio-info-wrap2 {
      margin-top: 0;
      margin-left: 0;
    }
  }
  .alloch-sec2 {
    .alloch-sec2-1 img {
      max-width: 250px;
      margin: auto;
      display: block;
    }
  }

  .alloch-sec7 {
    .alloch-sec7-4-outer {
      position: absolute;
      bottom: 30px;
      left: auto;
    }

    .alloch-sec7-3-outer {
      left: -66px;
    }

    .alloch-sec7-5-outer {
      position: absolute;
      top: 0;
      z-index: 3;
      right: -510px;
      max-width: inherit;

      img {
        width: 630px;
      }
    }

    .alloch-sec7-right {
      transform: scale(0.7);
      transform-origin: center 0;
      -webkit-transform: scale(0.7);
      -moz-transform: scale(0.7);
      -ms-transform: scale(0.7);
      -o-transform: scale(0.7);
      height: 420px;
      margin-top: 20px;
    }
  }

  .alloch-sec17 {
    .alloch-sec7-right-outer-r {
      height: inherit;
    }

    .alloch-sec7-right.portfolio-info-wrap {
      transform-origin: center 0;
      transform: scale(1);
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
    }

    .alloch-sec3-4-outer {
      position: absolute;
      bottom: 20px;
      left: auto;
    }
  }
  .our-work-page {
    .comman-section {
      padding: 40px 0 !important;
    }

    .comman-footer {
      margin-top: 20px;
      padding: 60px 0 !important;
    }

    .pt0 {
      padding-top: 0 !important;
    }

    .work-comman-card-info {
      .custom-nav-wrap {
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        margin-bottom: 0;
        padding-left: 0;

        .list-inline-item {
          margin-right: 25px;
        }
      }
    }

    .comman-card-info.comman-card-info-lg {
      margin-top: 15px;
    }

    .work-sec2 .vh100-100 > div {
      margin-top: 0;
    }
  }
  .rsmart-sect1 {
    .container.h-100 {
      margin-top: 20px;

      .col-sm-4.col-md-5 {
        padding: 0;
      }
    }
  }
  .rsmart-sect2 {
    padding-bottom: 0 !important;
  }
  .rsmart-sect4 {
    .rsmart-center-wrap {
      p {
        max-width: 703px;
        margin: 15px auto;
      }
    }

    .viewer, .ng-draggable {
      height: 340px;

      img {
        height: 180px;
      }
    }

    .comman-card-bottom-ul {
      text-align: left;
      width: 210px;
      margin: auto;
    }
  }
  .rsmart-sect6 {
    .rsmart-sect6-2 {
      display: inline-block;
      max-width: 100%;
      height: auto;
      margin-top: 30px;
    }
  }
  .rsmart-sect8 {
    .rsmart-app-view2-img,
    .rsmart-app-view1-img {
      display: block;
      width: 260px;
      float: none;
      margin: auto !important;
    }
  }
  .rsmart-sect7 {
    .portfolio-info-wrap2 {
      max-width: 430px;
      margin-top: 0;
    }

    .rsmart-strategy-img {
      display: block !important;
      height: 100%;
      width: 280px;
      margin: auto !important;
    }
  }
  .comman-card-top-ul {
    margin-bottom: 7px;

    li {
      margin-right: 30px !important;

      &:last-child {
        margin-right: 0px !important;
      }

      &:first-child {
        &::after {
          top: 8px;
          width: 18px;
          right: -26px;
        }
      }
    }
  }
  .moneyguru-sec-website7,
  .moneyguru-sec-website8 {
    .moneyguru-insights-left.portfolio-info-wrap2 {
      max-width: 300px;
    }

    .moneyguru-insights-left {
      white-space: inherit;
    }

    .moneyguru-insights-right-col {
      .moneyguru-insights-right {
        img {
          float: right;
          display: block;
          max-width: 100%;
          margin-right: -26px;
        }
      }
    }
  }
  .moneyguru-sec-website9 {
    .moneyguru-landing-outer {
      .mytrux-details-up {
        display: none !important;
      }
    }
  }
  .portfolio-branding-notion-edge-body {
    .notion-edge-sec1 {
      .smartlinks-logo.lazy.img-fluid.mb30 {
        display: inline-block;
        width: 90px;
        margin-bottom: 20px !important;
      }
    }

    .notion-edge-sec8 {
      #comparison {
        figure,
        #divisor {
          background-position: 90px center;
          background-size: 240px auto;
        }
      }
    }
  }
  .portfolio-webapp-insisive-body {
    .insisive-website-sec1 {
      .insisive-logo-after {
        position: absolute;
        bottom: 200px;
        right: -50px;
        z-index: 1;
        width: 150px;
      }

      .insisive-website-right-outer {
        max-width: 230px;
        margin: 0 auto 30px;
      }

      h1 {
        margin-bottom: 15px;
      }
    }

    .insisive-website-sec8 {
      .insisive-website-sec8-right {
        margin-top: 0;
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        transform: none;
        z-index: 3;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        max-width: 300px;
        float: right;
      }
    }

    .insisive-website-sec26 {
      .insisive-website-sec2-1 {
        height: 130px !important;
        object-fit: cover;
      }
    }
  }
  .portfolio-website-bynry-body {
    .bynry-website-sec1 {
      background: #011845;

      h1 {
        br {
          display: none;
        }
      }

      .bynry-banner-wrap {
        position: relative;
        top: 0;
        transform: none;
        right: 0;
        margin-top: 0;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;

        .img-fluid {
          height: auto;
          margin-left: -40px;
          margin-top: 20px;
        }
      }
    }

    .bynry-website-sec2 {
      .moneyguru-sec2-3 {
        max-width: 480px !important;
        margin: auto;
        display: block;
        width: 100%;
      }

      .moneyguru-sec2-left {
        margin-left: 0;
        margin-top: 20px;

        .img-fluid {
          max-width: 490px;
        }
      }
    }

    .bynry-website-sec4 {
      .authntick-center-wrap {
        h3 {
          white-space: normal;
        }
      }

      .authntick-sec9-2-outer {
        img {
          display: inline-block;
          max-width: 400px;
          width: 100%;
        }
      }
    }

    .bynry-website-sec5 {
      background-color: #051941;

      .ani-wrap50 {
        display: none !important;
      }

      .insisive-website-sec8-right {
        text-align: center;

        .img-fluid {
          display: inline-block;
          height: 100%;
          width: 100%;
          object-fit: cover;
          max-width: 400px;
        }
      }
    }

    .bynry-website-sec6 {
      [data-aos="new-animation5"].aos-animate {
        width: 24%;
      }

      .img-fluid.bynry-landing-page.lazy {
        max-width: 550px;
        margin-top: 0;
        margin-left: 0;
        width: 100%;
      }
    }

    .bynry-website-sec7 {
      .bagshaha-sec9-2-outer {
        .bynry-devices {
          max-width: 460px;
          margin-left: 0;
          width: 100%;
        }
      }
    }
  }
  .smartlinks-sec7 {
    #comparison {
      #divisor,
      figure {
        background-position-x: 60px;
        background-size: 260px auto;
      }
    }
  }
  .moneyguru-sec1 {
    padding-bottom: 0 !important;

    .portfolio-info-wrap2 .moneyguru-banner-img {
      left: 70px;
      width: 240px;
      margin-top: 0px;
    }

    [data-aos="new-animation4"].aos-animate {
      width: 100%;
    }
  }
  .authntick-sect5 {
    .authntick-sect5-laptop-wrap {
      img {
        height: auto;
        width: auto;
      }
    }

    .authntick-sect5-overlay-wrap {
      margin-left: -110px;
      margin-top: -10px;

      img {
        width: 100px;
      }
    }
  }
  .moneyguru-comprehensive-section .moneyguru-comprehensive-bottom video {
    min-width: 450px;
    margin-left: -27%;
  }
  .moneyguru-gateway-section .moneyguru-gateway-right video {
    min-width: 450px;
    margin-left: 0;
  }
  .moneyguru-investments-section .moneyguru-investments-left-col video {
    min-width: 450px;
    margin-left: -27%;
  }

  .comman-card-bottom-ul li::before {
    left: 0px;
    top: 7px;
  }
  .expertise-sect-9 {
    .expertise-sect9-col-center {
      border: 0 !important;
    }

    .table-expertise {
      border-collapse: collapse;
      width: 100%;
      margin: auto;
      border-style: hidden;
      display: block;

      tr,
      td {
        display: block;
        border-left: 0 !important;
        border-right: 0 !important;
        border-top: 0 !important;
      }

      tr {
        &:last-child {
          border-bottom: 0 !important;

          td {
            &:last-child {
              border-bottom: 0 !important;
            }
          }
        }
      }
    }

    .comman-card-info {
      padding: 0 10px;

      .process-image.lazy.mb25 {
        width: 80px;
      }
    }
  }
  .page-foot-link span {
    display: inline-block;
    white-space: normal !important;
  }
  .home-sec2 #home-carosual2 {
    min-height: 740px;
  }


  /* Added on 30-10-2020 */
  .esop-sec1 {
    .esop-left-wrap {
      margin-top: 70px;
    }

    .esop-triangle1 {
      top: 10px;
      right: 300px;
    }

    .esop-triangle2 {
      bottom: -37px;
      left: -70px;
    }

    .esop-header-img {
      max-width: 320px;
      margin-top: 30px;
      margin-left: 18%;
    }

    .comman-card-top-ul {
      margin-bottom: 0;
      margin-top: 12px;
      margin-left: 0;
    }

    h1.hide-mobile {
      display: block !important;
      margin-top: 24px !important;
    }

    .esop-sec1 h1 {
      margin-bottom: 0px !important;
      font-size: 22px;
    }
  }

  .esop-sec2 {
    padding-bottom: 0 !important;

    .overview-sec-center.portfolio-info-wrap2 {
      text-align: left;
      max-width: unset;
      margin: auto;
    }
  }

  .esop-sec5 {
    .ani-wrap50.aos-init.aos-animate {
      width: 100%;
    }

    .esop-left-wrap {
      h3 {
        margin-bottom: 0 !important;
      }

      .esop-hits-img {
        max-width: 100%;
      }
    }

    .esop-hits-map-img {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  .esop-sec6 {
    .esop-left-wrap {
      overflow: hidden;

      .esop-slider-circle {
        width: 300px;
        height: 300px;
      }

      .comman-scrollbar-wrap {
        width: 100%;
        max-width: 286px;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);

        .esop-slider-outer-wrap {
          margin-left: 0;

          .esop-slider {
            display: block;
            width: 120px;
            height: 144px;
            padding-top: 30px;
            margin: auto;

            .img-fluid {
              max-width: 60%;
            }
          }

          .esop-slider-ul {
            width: 50%;
            margin-left: auto;
            margin-right: auto;

            li {
              margin-bottom: 20px;
            }
          }
        }
      }
    }

    .esop-right-wrap {
      margin-left: auto;
      margin-top: 0px;
      margin-right: auto;
      margin-bottom: 40px;
    }
  }
  .esop-sec7 {
    &::after {
      height: 50%;
    }

    .bottom-col-set {
      bottom: -66px;
    }
  }
  .esop-sec8 {
    .esop-right-wrap {
      .esop-attention-wrap {
        top: -85px;
        right: auto;
        left: -97px;
      }

      .esop-right-row.clearfix {
        width: 100%;
        top: 30px;
        left: auto;
        margin: auto;
      }
    }
  }
  .esop-sec9 {
    .esop-right-wrap {
      margin-left: 0;
    }

    .esop-left-wrap .img-fluid {
      top: 30px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .circle-sec {
      width: 320px;
      height: 320px;
      top: calc(50% + 20px);
    }
  }
  .esop-sec10 {
    padding-top: 30px !important;

    .esop-left-wrap {
      margin-top: 40px;
      margin-left: 0;
    }

    .esop-right-wrap .img-fluid {
      transform-origin: center;
      transform: translateX(-50%) scale(1);
      -webkit-transform: translateX(-50%) scale(1);
      -moz-transform: translateX(-50%) scale(1);
      -ms-transform: translateX(-50%) scale(1);
      -o-transform: translateX(-50%) scale(1);
      width: 100%;
      top: 0px;
      left: 50%;
      margin: auto;
    }

    .circle-sec {
      width: 320px;
      height: 320px;
      top: calc(50% + 20px);
    }
  }
  .esop-sec11 {
    padding-top: 0 !important;

    .esop-right-wrap {
      margin-left: 0;
      margin-top: 0;
    }

    .esop-left-wrap .img-fluid {
      transform-origin: center;
      transform: translateX(-50%) scale(1);
      -webkit-transform: translateX(-50%) scale(1);
      -moz-transform: translateX(-50%) scale(1);
      -ms-transform: translateX(-50%) scale(1);
      -o-transform: translateX(-50%) scale(1);
      width: 100%;
      top: 10px;
      left: 50%;
      margin: auto;
    }

    .circle-sec {
      width: 320px;
      height: 320px;
      top: calc(50% + 20px);
    }
  }
  .esop-sec12 {
    .esop-center-wrap {
      top: 0;
    }

    .ui-card-slider {
      transform-origin: 0 100px;
      transform: translateX(-50%) scale(1.6);
      -webkit-transform: translateX(-50%) scale(1.6);
      -moz-transform: translateX(-50%) scale(1.6);
      -ms-transform: translateX(-50%) scale(1.6);
      -o-transform: translateX(-50%) scale(1.6);
      left: 16%;
      right: auto;
    }
  }
  .esop-sec13 {
    .comman-impact-bottom-ul {
      width: 100%;

      li {
        width: 50%;
        margin-right: 0px !important;
        margin-top: 20px;
      }
    }
  }
  .portfolio-webapp-esop-body .h3,
  .portfolio-webapp-esop-body h3 {
    font-size: 22px;
  }
  .our-work-page {
    .our-work-row {
      .work-card.card-portfolio-webapp-esop {
        .portfolio-webapp-esop-1 {
          top: 110px;
          left: -98px;
          max-width: 620px;
        }

        .portfolio-webapp-esop-3 {
          top: 94px;
          left: -32px;
          max-width: 66px;
        }
      }
    }
  }

  @keyframes esop-slider-outer-wrap-ani {
    from {
      transform: translateY(-0);
      -webkit-transform: translateY(-0);
      -moz-transform: translateY(-0);
      -ms-transform: translateY(-0);
      -o-transform: translateY(-0);
    }
    to {
      transform: translateY(-700px);
      -webkit-transform: translateY(-700px);
      -moz-transform: translateY(-700px);
      -ms-transform: translateY(-700px);
      -o-transform: translateY(-700px);
    }
  }

}
