// responsive view variable
@mixin xxs {
  @media screen and (max-width: 575px) {
      @content;
  }
}

@mixin xs {
  @media screen and (max-width: 767px) {
      @content;
  }
}

@mixin sm {
  @media screen and (min-width: 768px) and (max-width: 991px) {
      @content;
  }
}

@mixin lgBelow {
  @media screen and (max-width: 1199px) {
      @content;
  }
}

@mixin tabMobile {
  @media screen and (max-width: 991px) {
      @content;
  }
}

@mixin tabdesktop {
  @media screen and (min-width: 768px) {
      @content;
  }
}

@mixin desktopXl {
  @media screen and (min-width: 992px) {
      @content;
  }
}

@mixin desktop1200 {
  @media screen and (min-width: 1200px) {
      @content;
  }
}

@mixin md {
  @media screen and (min-width: 992px) and (max-width: 1199px) {
      @content;
  }
}

@mixin lg {
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
      @content;
  }
}

@mixin xl {
  @media screen and (min-width: 1400px) {
      @content;
  }
}

@media screen and (min-width: 1800px) {
#fligital-bottom-stick{
      position: relative;
      top: -50px;
      z-index: 9;

}
.marketing-website-img-1 {
	position: absolute !important;
	top: auto !important;
	left: auto !important;
	bottom: -130px !important;
	z-index: 5;
	right: -190px !important;
}
.center-adj-big{
  padding-top: 160px;
}
}
// @import "./base.scss";

// comman footer section
.xl-show {
  display: none;
}

.comman-footer {
  position: relative;
  z-index: 9;

  .heart-text {
    text-align: center;
    margin-top: 20px;
    display: block;
    cursor: auto;

    &:hover {
      span {
        opacity: 1;
      }

      i {
        color: $red;
      }
    }

    span {
      color: $white;
      opacity: 0.4;
      transition: all 0.2s linear;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      -ms-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
    }

    i.fa-heart-icon {
      font-size: 24px;
      vertical-align: middle;
      color: #484848;
      opacity: 1;
      font-style: normal;
      color: $red;
      animation: heartBeat 1.8s infinite;
      transform-origin: center;
      -webkit-animation: heartBeat 1.8s infinite;
      display: inline-block;
      margin: 0 3px;
    }
  }

  .comman-footer-row2 {
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    top: auto;

    ul {
      margin: 0;
    }
  }

  h2 {
    color: $white;
    margin-bottom: 40px;
    font-size: 110px;
  }

  .row {
    &:first-child {
      height: calc(100vh - 200px);
    }
  }

  .bottom-menu-bottom-left,
  .bottom-menu-bottom-right {
    ul {
      margin: 0 !important;
    }

    li {
      &:not(:last-child) {
        margin-right: 30px;
      }

      p.copy-para {
        color: $white;
        opacity: 0.9;
        margin: 0;
      }

      a {
        transition: all 0.2s linear 0s;
        color: $white;
      }
    }
  }
}

/* comman-header */
.logo-wrap-our-team span,
.company-name-mobile {
  font-family: "Poppins", sans-serif !important;
}

.company-name-mobile span:after {
  display: none !important;
}

.logo-wrap-our-team.active-white-text {
  &:hover,
  &:active,
  &:focus {
    p {
      color: #fff !important;
    }

    span {
      color: #fff !important;
    }

    img {
      filter: brightness(0) invert(1);
      -webkit-filter: brightness(0) invert(1);
    }
  }

  p {
    color: #fff !important;
  }

  img {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
  }
}

.logo-wrap-a {
  position: fixed;
  top: 32px;
  left: 20px;
  z-index: 999 !important;
  display: block;
}

.logo-wrap-our-team {
  position: fixed;
  top: 40px;
  left: 25px;
  z-index: 99;
  display: none;

  p {
    font-family: "Poppins", sans-serif !important;
  }
}

.comman-header.nav-down {
  .logo-wrap-our-team {
    display: block !important;
  }

  .logo-wrap-a {
    display: none !important;
  }
}

.comman-header-menu-right {
  position: fixed;
  top: 63px;
  right: 65px;
  z-index: 999;
  display: block;

  .bttn.btn-link-custom.btn-line.st {
    font-family: $font-family-poppins !important;

    &:hover,
    &:active,
    &:focus {
      color: $black !important;
    }

    span {
      &::after {
        // display: none !important;
      }
    }
  }
}

.mega-menu.mega-menu-close {
  padding: 6px 15px;
}

.container.mw-1000 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  margin-top: 40px;
}

.comman-header {
  a {
    transition: all 0.1s ease-in-out 0s;
    -webkit-transition: all 0.1s ease-in-out 0s;
    -moz-transition: all 0.1s ease-in-out 0s;
    -ms-transition: all 0.1s ease-in-out 0s;
    -o-transition: all 0.1s ease-in-out 0s;
  }

  .comman-header-menu-ul {
    margin-bottom: 0;

    .btn-line span {
      &:after {
        margin-left: 3px;
      }
    }
  }

  .row {
    padding: 0;
  }

  .container {
    max-width: 1470px;
  }

  .mega-menu {
    padding: 0px 0px 0 30px;
    cursor: pointer;

    &:hover {
      path {
        fill: $orange;
      }

      svg.mega-menu-img2 {
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
      }
    }
  }
}

.active-white.comman-header .comman-header-menu-ul .btn-link-custom:hover span,
.active-white.comman-header .comman-header-menu-ul .btn-link-custom:active span,
.active-white.comman-header .comman-header-menu-ul .btn-link-custom:focus span {
  color: $white !important;
}

.active-white.comman-header .comman-header-menu-ul .btn-link-custom:hover,
.active-white.comman-header .comman-header-menu-ul .btn-link-custom:active,
.active-white.comman-header .comman-header-menu-ul .btn-link-custom:focus {
  color: $white !important;
}

.active-white.comman-header .comman-header-menu-ul .btn-link-custom {
  color: $white;
}

.active-white.comman-header .logo-img path {
  fill: $white;
}

.active-logo-white.comman-header .logo-img path {
  fill: $white;
}

.active-menu-white.comman-header .comman-header-menu-ul .btn-link-custom,
.active-menu-white.comman-header .comman-header-menu-ul .btn-link-custom:hover,
.active-menu-white.comman-header .comman-header-menu-ul .btn-link-custom:active,
.active-menu-white.comman-header .comman-header-menu-ul .btn-link-custom:focus,
.active-menu-white.comman-header .comman-header-menu-ul .btn-link-custom:hover span,
.active-menu-white.comman-header .comman-header-menu-ul .btn-link-custom:active span,
.active-menu-white.comman-header .comman-header-menu-ul .btn-link-custom:focus span {
  color: $white;
}

.active-menu-white.comman-header .mega-menu-img1 path {
  fill: $white;
}

.active-white.comman-header .mega-menu-img1 path {
  fill: $white;
}

.comman-top-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #1E2329 !important;
  z-index: 999;
  width: 100vw;
  height: 100%;
  display: block;
  transition: all 500ms ease-in-out;
  clip-path: circle(30px at calc(100% - 90px) 90px);
  -webkit-clip-path: circle(30px at calc(100% - 90px) 90px);
  visibility: hidden;

  .mega-menu.mega-menu-close {
    opacity: 0;
  }

  &.active-class {
    .mega-menu.mega-menu-close {
      opacity: 1;
    }
  }

  .row {
    padding: 0;
    height: 100%;
  }

  a {
    cursor: pointer;
    transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
  }

  .top-menu-left {
    margin-bottom: 50px;

    li {
      &:not(:last-child) {
        margin-bottom: 30px;
      }

      a {
        color: #FFFFFF;

        &.active {
          span::after {
            opacity: 1;
            width: 58px;
          }
        }
      }
    }
  }

  .top-menu-right {
    float: right;
    margin-top: 16px;

    li {
      &:not(:last-child) {
        margin-bottom: 50px;
      }

      a {
        color: #FFFFFF;

        &.active {
          span::after {
            opacity: 1;
            width: 58px;
          }
        }
      }
    }
  }

  .top-menu-bottom {
    li {
      a {
        color: #FFFFFF;
      }

      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }

  .top-menu-left {
    .btn-line span::after {
      top: 42px;
    }
  }

  .top-menu-right {
    .btn-line span::after {
      top: 16px;
    }

    .top-menu-right {
      .btn-line:focus span::after,
      .btn-line:active span::after {
        opacity: 1;
        width: 32px;
      }
    }
  }

  .logo-img {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
  }

  .mega-menu.mega-menu-close {
    .mega-menu-img2 {
      path {
        fill: $white;
      }
    }
  }
}

.comman-top-menu-dark {
  background: $white;

  .top-menu-left {
    a {
      color: $black !important;
    }
  }

  .top-menu-right {
    a {
      color: $black !important;
    }
  }

  .top-menu-bottom {
    li {
      a {
        color: $black !important;
      }
    }
  }

  .logo-img {
    filter: none !important;
    -webkit-filter: none !important;
  }

  .mega-menu.mega-menu-close {
    .mega-menu-img2 {
      path {
        fill: $black;
      }
    }
  }
}

.active-class {
  visibility: visible !important;
  clip-path: circle(100%) !important;
  -webkit-clip-path: circle(100%) !important;
}

body {
  overflow-x: hidden;
  color: $black;

  .comman-section {
    padding: 100px 0;
    position: relative;

    .vh100-100 {
      min-height: calc(100vh - 200px);
    }
  }
}

.comman-card-top-ul {
  margin-bottom: 25px;

  li {
    color: $black;
    margin-right: 54px !important;
    position: relative;

    &:last-child {
      margin-right: 0 !important;
    }

    &:first-child {
      &.text-white {
        &::after {
          background: $white;
        }
      }

      &::after {
        content: "";
        position: absolute;
        right: -40px;
        top: 14px;
        width: 27px;
        height: 1px;
        background: $black;
      }
    }
  }
}

.comman-card-bottom-ul {
  margin-bottom: 30px;

  li {
    position: relative;
    padding-left: 20px;
    margin-right: 30px;

    &::before {
      content: "";
      position: absolute;
      left: 0px;
      top: 10.5px;
      width: 6px;
      height: 6px;
      background: #d2143a;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
    }

    &.text-orange {
      &::before {
        background: $orange;
      }
    }

    &.text-orange {
      &::before {
        background: #FF6600 ;
      }
    }

    &.text-grey2 {
      &::before {
        background: $grey2;
      }
    }

    &.text-blue2 {
      &::before {
        background: $blue2;
      }
    }

    &.text-blue3 {
      &::before {
        background: $blue3;
      }
    }

    &.text-blue4 {
      &::before {
        background: $blue4;
      }
    }

    &.text-black {
      &::before {
        background: $black;
      }
    }

    &.text-green-p {
      &::before {
        background: $green-p;
      }
    }

    &.text-purple-p {
      &::before {
        background: $purple-p;
      }
    }

    &.text-red-p {
      &::before {
        background: $red-p;
      }
    }

    &.text-blue-p {
      &::before {
        background: $blue-p;
      }
    }
  }

  .star-icon {
    font-size: 11px;
    vertical-align: 3px;
  }
}

.comman-section {
  .comman-card-info {
    max-width: 630px;

    h2.sec-heading {
      color: $blue5;
    }

    p.sec-para {
      color: $light-dark-c;
      margin-bottom: 30px;
      // font-family: $font-family-lora;
    }

    .bttn {
      min-width: 200px;
      margin-top: 18px;
      text-align: center;

      span {
        &::after {
          margin-left: 30px;
        }
      }

      
    }
    .podcast-all-btn{
      @include xs{
        left: 0px;
      }
      span {
        &::after {
          margin-left: 10px;
        }
    } 
    }
    
  }

  .comman-card-img-wrap {
    position: relative;
    min-height: 400px;

    img {
      position: absolute;
    }
  }
}

// card animation
.card-img-wrap {
  position: relative;
  overflow: hidden;
  display: inline-block;

  img {
    transition: all 0.2s linear 0s !important;
    -webkit-transition: all 0.2s linear 0s !important;
    -moz-transition: all 0.2s linear 0s !important;
    object-fit: cover;
    position: relative;
    z-index: 5;
    -ms-transition: all 0.2s linear 0s !important;
    -o-transition: all 0.2s linear 0s !important;
  }

  &:hover img {
    -webkit-backface-visibility: hidden;
    transform: scale(1.02) translateZ(0);
    -webkit-transform: scale(1.02) translateZ(0);
    -moz-transform: scale(1.02) translateZ(0);
    -ms-transform: scale(1.02) translateZ(0);
    -o-transform: scale(1.02) translateZ(0);
  }

  &:before {
    position: absolute;
    top: 0;
    left: -95%;
    z-index: 2;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(
        left,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 100%
    );
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 100%
    );
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
    z-index: 9;
  }

  &:hover::before {
    -webkit-animation: shine 0.75s;
    animation: shine 0.75s;
  }
}

/*@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}*/

.custom-nav-wrap {
  li {
    &:not(:last-child) {
      margin-right: 60px;
    }
  }
}

.comman-card-img-wrap {
  .home-sec1-1 {
    top: -32px;
    left: auto;
    z-index: 4;
    right: -30px;
  }

  .home-sec1-2 {
    top: -80px;
    left: -30px;
    z-index: 3;
  }

  .home-sec1-3 {
    top: -156px;
    left: -142px;
    z-index: 2;
  }

  .home-sec2-1 {
    top: -40px;
    left: -54px;
    z-index: 4;
  }

  .home-sec2-2 {
    top: -70px;
    left: -100px;
    z-index: 3;
  }

  .home-sec2-3 {
    // top: -35px;
    // left: -86px;
    // z-index: 2;
  }

  .home-sec2-4 {
    top: -90px;
    left: 47px;
    z-index: 1;
  }

 

  .home-sec2 {
    .carousel-fade {
      .carousel-item {
        background-color: $white;
      }
    }
  }

  .home-sec3 {
    .comman-card-info {
      margin-left: -30px;
    }
  }

  .home-sec3-1 {
    top: -50px;
    left: -100px;
    z-index: 4;
  }

  

  .home-sec3-2 {
    top: -82px;
    left: 26px;
    z-index: 3;
  }

  .home-sec3-3 {
    top: -50px;
    left: 0px;
    z-index: 2;
  }

  .home-sec3-4 {
    top: 44px;
    left: 93px;
    z-index: 1;
  }

  .home-sec4-1 {
    top: -23px;
    left: -90px;
    z-index: 4;
  }

  .home-sec4-2 {
    top: 110px;
    left: -130px;
    z-index: 3;
  }

  .home-sec4-3 {
    top: -18px;
    left: auto;
    z-index: 2;
    right: -150px;
  }

  .home-sec5-1 {
    top: 227px;
    left: -183px;
    z-index: 4;
  }

  .home-sec5-2 {
    // top: -60px;
    top: 50px;
    left: -144px;
    z-index: 3;
  }

  .home-sec5-3 {
    top: -75px;
    left: 156px;
    z-index: 2;
  }

  .home-sec5-4 {
    top: -44px;
    left: 274px;
    z-index: 1;
  }

  .aaloch-sec-1 {
    z-index: 4;
    top: -68px;

    left: -150px;
  }

  .aaloch-sec-2 {
    z-index: 3;
    top: -170px;
    left: -50px;
  }

  .aaloch-sec-3 {
    z-index: 2;
    top: -112px;
    left: -50px;
  }

  .aaloch-sec-4 {
    z-index: 1;
    top: -44px;
    left: 128px;
  }

  .ambika-sec-1 {
    z-index: 5;
    top: 77px;
    left: -100px;
  }

  .ambika-sec-2 {
    z-index: 4;
    top: -77px;
    left: 0;
  }

  .ambika-sec-3 {
    z-index: 3;
    top: 144px;
    left: -15px;
  }

  .ambika-sec-4 {
    z-index: 2;
    top: -64px;
    left: -118px;
  }

  .ambika-sec-5 {
    z-index: 1;
    top: -44px;
    left: 122px;
  }

  .janvan-sec-1 {
    z-index: 3;
    top: 47px;
    left: -118px;
  }

  .janvan-sec-2 {
    z-index: 2;
    top: -67px;
    left: -62px;
  }

  .janvan-sec-3 {
    z-index: 1;
    top: -93px;
    left: 4px;
  }

  .insisive-sec-1 {
    z-index: 3;
    top: auto;
    left: -95px;
    bottom: -103px;
  }

  .insisive-sec-2 {
    z-index: 2;
    top: -42px;
    left: -160px;
  }

  .insisive-sec-3 {
    z-index: 1;
    top: -75px;
    left: 82px;
  }

  .insisive-sec-4 {
    z-index: 1;
    top: -33px;
    left: auto;
    right: -47px;
  }

  .smartlink-sec-1 {
    z-index: 3;
    top: auto;
    left: 70px;
    bottom: -480px;
  }

  .smartlink-sec-2 {
    z-index: 2;
    top: -52px;
    left: -115px;
  }

  .smartlink-sec-3 {
    z-index: 1;
    top: -84px;
    left: 16px;
  }

  .smartlink-sec-4 {
    z-index: 0;
    top: -61px;
    left: auto;
    right: -58px;
  }

  .career-sec-1 {
    z-index: 9;
    top: auto;
    left: -40px;
    bottom: -136px;
  }

  .career-sec-2 {
    z-index: 2;
    top: -52px;
    left: -93px;
  }

  .career-sec-3 {
    z-index: 1;
    top: -8px;
    left: 97px;
  }

  .crubba-thumb-1 {
    display: block;
    left: -100px;
    top: 0;
    z-index: 4;
  }

  .crubba-thumb-2 {
    display: block;
    left: -100px;
    top: 250px;
  }

  .crubba-thumb-3 {
    display: block;
    top: -30px;
    left: 130px;
  }

  .crubba-thumb-4 {
    display: block;
    top: -70px;
    left: 210px;
  }

  .vigan-thumb-1 {
    display: block;
    top: 80px;
    left: -70px;
  }

  .vigan-thumb-2 {
    display: block;
    top: -30px;
    left: 50px;
  }

  .vigan-thumb-3 {
    display: block;
    top: 10px;
    left: 190px;
    z-index: -1;
  }

  .mytrux-thumb-1 {
    display: block;
    top: 0;
    left: 0;
  }

  .mytrux-thumb-2 {
    display: block;
    top: 40px;
    left: 130px;
  }

  .mytrux-thumb-3 {
    display: block;
    top: 300px;
    left: -250px;
  }

  .bagshaha-thumb-1 {
    display: block;
    top: 0;
    left: -120px;
  }

  .bagshaha-thumb-2 {
    display: block;
    top: -30px;
    left: -120px;
  }

  .bagshaha-thumb-3 {
    display: block;
    top: 0;
    left: 180px;
  }
}

.home-sec2 {
  position: relative;
  overflow: hidden;
}

.expertise-sect1 {
  background: rgb(255, 255, 255);

  .comman-card-img-wrap2 {
    min-height: 728px;
  }

  .expertise-tbl {
    table-layout: fixed;
    margin-top: 20px;
    width: 100%;
    border: 0;
    margin-left: -4px;

    tr {
      border: 0;

      td {
        border: 0;
        vertical-align: top;

        &:first-child {
          width: 300px;
        }
      }
    }
  }

  .comman-card-img-wrap {
    .expertise1-icon {
      position: absolute;
      top: 350px;
      left: -33px;
      z-index: 9;
    }

    .expertise1-img {
      position: relative;
      z-index: 2;
    }

    .expertise1-bg {
      position: absolute;
      top: -13px;
      left: 270px;
      z-index: 1;
    }
  }
}

.expertise-sect2 {
  padding-bottom: 0 !important;

  .expertise-sect-row {
    margin-bottom: 120px;
  }

  .expertise-container {
    padding-top: 135px;
  }

  .expertise-sect-left {
    .txt-adj {
      position: relative;
      z-index: 5;
    }

    .expertise-sect-left-head {
      position: relative;

      .expertise-icon {
        position: absolute;
        top: -14px;
        left: -90px;
        z-index: 0;
      }

      h5 {
        position: relative;
        z-index: 3;
      }
    }

    .expertise-sect-left-body {
      position: relative;

      .expertise-icon-big {
        position: absolute;
        top: -60px;
        left: 140px;
      }

      .bttn {
        margin-top: 35px;
        position: relative;
        z-index: 3;
        background-color: $white;
        text-align: center;
      }
    }
  }

  .expertise-sect-right {
    .expertise-icon-big {
      position: absolute;
      top: 120px;
      right: -40px;
      z-index: 0;
    }

    p {
      margin-bottom: 40px;
    }

    h6 {
      margin-bottom: 25px;
      color: $black;
      margin-top: 10px;
    }

    .expertise-ul-list {
      margin-left: 16px;
      margin-bottom: 50px;

      &.ul-yellow-adj {
        li {
          &::before {
            background: $orange;
          }
        }
      }

      &.ul-purple-adj {
        li {
          &::before {
            // background: $purple;
          }
        }
      }

      li {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 7px;
          height: 7px;
          left: -16px;
          top: 9px;
          border: none;
          background: #FF6600;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
        }

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        color: $black;
      }
    }

    h4 {
      margin-bottom: 27px;
    }
  }

  .expertise-sect-row-1-wrap {
    .expertise-sect-left .expertise-sect-left-head .expertise-icon {
      top: -26px;
      left: -80px;
      z-index: 0;
    }

    .expertise-sect-left .expertise-sect-left-body .expertise-icon-big {
      top: -58px;
      left: 130px;
    }
  }

  .expertise-sect-row-2-wrap {
    .expertise-sect-left .expertise-sect-left-head .expertise-icon {
      top: -45px;
      left: -80px;
      z-index: 0;
    }

    .expertise-sect-left .expertise-sect-left-body .expertise-icon-big {
      top: 8px;
      left: 130px;
    }
  }

  .expertise-sect-row-3-wrap {
    .expertise-sect-left .expertise-sect-left-head .expertise-icon {
      top: -52px;
      left: -80px;
      z-index: 0;
    }

    .expertise-sect-left .expertise-sect-left-body .expertise-icon-big {
      top: -5px;
      left: 90px;
    }
  }

  .expertise-sect-row-4-wrap {
    .expertise-sect-left .expertise-sect-left-head .expertise-icon {
      top: -52px;
      left: -80px;
      z-index: 0;
    }

    .expertise-sect-left .expertise-sect-left-body .expertise-icon-big {
      top: 12px;
      left: 130px;
    }
  }
}

.expertise-sect-9 {
  h3 {
    margin-bottom: 70px;
  }

  .expertise-sect9-col-center {
    border: 1px solid #dfdfdf;
  }

  .expertise-sect9-col {
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
  }

  .comman-card-info {
    max-width: 350px;
    margin: 40px auto 60px;
  }

  .table-expertise {
    border-collapse: collapse;
    width: 950px;
    margin: auto;
    border-style: hidden;

    td {
      border: 1px solid #dfdfdf;
    }
  }
}

.expertise-list-wrap,
.career-details-card {
  h4 {
    margin-bottom: 20px;
  }

  ul {
    max-width: 400px;
    padding-left: 26px;

    li {
      position: relative;

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      &::before {
        position: absolute;
        top: 10px;
        left: -27px;
        content: "";
        opacity: 1;
        width: 13px;
        height: 0px;
        border-top: 2px solid $orange;
      }
    }
  }

  p {
    max-width: 541px;
  }
}

.expertise-sec2 {
  border-bottom: 1px solid rgba(36, 36, 36, 0.1);
}

.career-sec2 {
  .career-sec2-left {
    .fixed-left {
      top: 0;
      transform: none;
      margin-top: 220px;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
    }
  }

  .career-sec2-right {
    .career-sec2-right-inner {
      margin-top: 130px;
    }

    .comman-card-info {
      max-width: 610px;
      margin-left: 80px;
      border-bottom: 1px solid rgba(black, 0.1);
      padding-bottom: 35px;
      padding-top: 35px;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border-bottom: 0;
      }

      .btn-line {
        span {
          &::after {
            margin-left: 15px;
            border-top: 2px solid $orange;
          }
        }
      }

      h4 {
        margin-bottom: 0;

        em {
          margin-left: 23px;
        }
      }

      p.p4 {
        color: $light-dark-c;
        margin-bottom: 20px;
      }
    }
  }
}

.career-sec3 {
  text-align: center;

  .comman-card-info {
    h2 {
      margin-bottom: 48px;
    }
  }

  .addr-wrap {
    max-width: 380px;
    margin: auto;

    .addr-img-wrap {
      position: relative;
      max-width: 130px;
      margin: auto;
      height: 83px;

      .addr-img1 {
        position: absolute;
        top: 54px;
        right: -2px;
        z-index: 1;
      }

      .addr-img2 {
        position: relative;
        z-index: 3;
      }

      .addr-img3 {
        position: absolute;
        position: absolute;
        top: -6px;
        left: 10px;
        z-index: 1;
      }
    }

    .addr-indo-wrap {
      margin-top: 26px;

      .p1 {
        margin-bottom: 10px;
      }

      .p3 {
        color: $light-dark-c;
        margin-bottom: 15px;
      }

      .addr-list-inline {
        margin-bottom: 40px;
        white-space: nowrap;

        .list-inline-item {
          &:not(:last-child) {
            padding-right: 20px;
            border-right: 1px solid rgba($light-dark-c, 0.5);
            margin-right: 15px;
            line-height: 15px;
          }

          a {
            color: $black;
            transition: all 0.2s linear;
            -webkit-transition: all 0.2s linear;
            -moz-transition: all 0.2s linear;
            -ms-transition: all 0.2s linear;
            -o-transition: all 0.2s linear;

            &:hover,
            &:active,
            &:focus {
              color: $orange;
            }
          }
        }
      }
    }
  }
}

.career-details-sec1 {
  .career-details-sec1-row {
    margin-top: 135px;

    .btn-back-link {
      margin-bottom: 44px;
      display: inline-block;
    }

    .exp-h6 {
      margin-top: 23px;
      margin-right: 150px;

      span {
        color: $light-dark-c;
        margin-right: 24px;
      }

      em {
        font-style: normal;
      }
    }

    .comman-card-info {
      .sec-heading {
        margin-bottom: 10px;
      }
    }
  }

  .career-details-sec2-row {
    margin-top: 65px;

    .career-details-card {
      margin-bottom: 80px;

      h4 {
        margin-bottom: 12px;
      }

      p {
        max-width: 800px;
      }

      ul {
        max-width: 750px;
        margin-top: 20px;
      }
    }
  }
}

.verylong {
  height: 2000px;
}

.process-section1,
.industries-section1 {
  .process-section1-row {
    // margin-top: 135px;
  }

  .comman-card-info-left {
    margin-top: -98px;
    margin-left: 15px;
    max-width: 550px !important;
    background: rgba($color: $white, $alpha: 0.8);
  }

  .process-img7-wrap {
    svg {
      position: absolute;
      display: block;
      overflow: visible;

      right: 0;
    }
  }

  .process-img7 {
    position: absolute;
    display: block;
    top: 20px;
    right: 218px;
    user-select: none;
    -moz-user-select: none;
  }

  .h1 {
    color: $black;
  }

  .comman-card-info {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;

    &:not(:last-child) {
      margin-bottom: 133px;
    }

    .process-img-wrapper2 {
      margin-bottom: 40px;
    }

    .process-img-wrapper {
      position: relative;
      height: 70px;
      max-width: 100px;
      margin-bottom: 50px;

      h4 {
        margin-bottom: 0;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
      }

      .process-img1 {
        top: 0;
        left: 0;
        z-index: 1;
      }

      .process-img2 {
        top: 7px;
        left: 20px;
        z-index: 3;
      }

      .process-img3 {
        top: 33px;
        left: 90px;
        z-index: 2;
      }

      .process-img4 {
        top: 0;
        left: 0;
        z-index: 1;
      }

      .process-img5 {
        top: 14px;
        left: 20px;
        z-index: 3;
      }

      .process-img6 {
        top: 50px;
        left: 90px;
        z-index: 2;
      }
    }
  }
}

.contact-section1 {
  .sec-heading {
    margin-bottom: 70px;
  }

  .addr-wrap {
    max-width: 550px;
    width: 100%;
    margin-left: 100px;

    .addr-img-wrap {
      position: relative;
      max-width: 130px;
      margin: 0;
      height: 83px;

      .addr-img1 {
        position: absolute;
        top: 54px;
        right: -2px;
        z-index: 1;
      }

      .addr-img2 {
        position: relative;
        z-index: 3;
      }

      .addr-img3 {
        position: absolute;
        position: absolute;
        top: -6px;
        left: 10px;
        z-index: 1;
      }
    }

    .addr-indo-wrap {
      margin-top: 26px;
      max-width: 480px;

      .p1 {
        margin-bottom: 10px;
      }

      .p3 {
        color: $light-dark-c;
        margin-bottom: 15px;
      }

      .addr-list-inline {
        margin-bottom: 40px;
        white-space: nowrap;

        .list-inline-item {
          &:not(:last-child) {
            padding-right: 20px;
            border-right: 1px solid rgba($light-dark-c, 0.5);
            margin-right: 20px;
          }

          a {
            color: $black;
            transition: all 0.2s linear;
            -webkit-transition: all 0.2s linear;
            -moz-transition: all 0.2s linear;
            -ms-transition: all 0.2s linear;
            -o-transition: all 0.2s linear;

            &:hover,
            &:active,
            &:focus {
              color: $orange;
            }
          }
        }
      }
    }
  }
}

#gmap_canvas2 > div:nth-child(2),
#gmap_canvas > div:nth-child(2) {
  display: none !important;
  opacity: 0 !important;
}

.contact-map-md-section {
  display: none;
}

.contact-map {
  overflow: hidden;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -150vw;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 999;
  transition: all 0.5s ease-in 0s;
  -webkit-transition: all 0.5s ease-in 0s;
  -moz-transition: all 0.5s ease-in 0s;
  -ms-transition: all 0.5s ease-in 0s;
  -o-transition: all 0.5s ease-in 0s;

  &.activeMap {
    left: 0;
  }

  .contact-map-inner {
    height: 100vh;
    // -webkit-filter: grayscale(100%);
    // -moz-filter: grayscale(100%);
    // -ms-filter: grayscale(100%);
    // -o-filter: grayscale(100%);
    // filter: grayscale(100%);
  }

  .google_map-btn {
    position: absolute;
    top: 70px;
    left: 70px;
    z-index: 99;
    color: $white;
    background: rgba(0, 0, 0, 0.5);
    padding: 4px 13px;
    border-radius: 3px;

    &:hover {
      color: $white;
    }

    img {
      filter: brightness(0) invert(1);
    }
  }
}

.form-control-col-btn2 {
  .reset-btn {
    margin-left: 50px;
  }
}

.contact-section2 {
  .my-budget-wrap {
    padding-left: 140px;
    min-width: 400px;
  }

  .contact-form-inner {
    h5 {
      margin-bottom: 20px;
    }

    table {
      width: 600px;
      table-layout: fixed;

      td {
        width: 50%;
        padding-bottom: 25px;
      }
    }
  }

  .interest-chk-error {
    bottom: -20px;
  }

  .service-chk-error {
    bottom: -20px;
  }
}

.career-form-wrap {
  max-width: 1055px;
  position: relative;
  margin: 60px auto 0;
  /*.form-control-col {
    &:nth-child(odd) {
      padding-right: 50px;
    }
    &:nth-child(even) {
      padding-left: 50px;
    }
  }*/
  .form-control-col-btn {
    margin-top: 40px;
  }

  .contact-breadcrum-outer {
    position: relative;
    z-index: 3;

    .btn-back-link {
      position: absolute;
      top: 3px;
      left: 0;
      z-index: 3;
    }

    .contact-breadcrum2 {
      margin-bottom: 38px;
      margin-left: 32px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      li {
        position: relative;
        padding-right: 30px;
        margin-right: 20px;
        vertical-align: middle;
        margin-bottom: 0 !important;
        height: 25px;
        max-width: 30%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }

        &:not(:last-child) {
          &::after {
            content: "";
            position: absolute;
            background: url("../images/svg/bread-crum-icon.svg") no-repeat 0 0;
            top: 7px;
            right: 0;
            width: 7px;
            height: 14px;
            background-size: cover;
          }
        }
      }
    }
  }

  .contact-breadcrum {
    margin-bottom: 38px;
    white-space: nowrap;

    li {
      position: relative;
      padding-right: 30px;
      margin-right: 20px;
      vertical-align: middle;

      &.first-inline-item {
        width: 28px;
        height: 28px;
        position: relative;

        a {
          vertical-align: 0;
          position: absolute;
          top: -6px;
          left: 0;
          z-index: 3;
        }
      }

      span {
        display: inline-block;
      }

      &:not(:last-child) {
        &::after {
          content: "";
          position: absolute;
          background: url("../images/svg/bread-crum-icon.svg") no-repeat 0 0;
          top: 4px;
          right: 0;
          width: 7px;
          height: 14px;
          background-size: cover;
        }
      }
    }
  }
}

.comman-impact-section {
  .container {
    max-width: 760px;
  }

  .h44 {
    color: $white;
    margin-bottom: 70px;

    .line-span-b::after {
      bottom: -18px;
    }
  }

  hr {
    border-top: 1px solid #4e4e4e;
    margin: 65px auto 0px;
  }

  .comman-impact-list-inline {
    min-width: 450px;
    text-align: center;
    margin: auto;

    li {
      width: 45%;
      padding: 0;
      margin: 0;

      .c-l-orange1 {
        color: $orange;
        // font-family: $font-family-poppins;
      }
    }
  }

  .comman-impact-top {
    max-width: 730px;
    margin: auto;

    hr {
      border-top: 1px solid #4e4e4e;
      margin: 65px auto 0px;
    }
  }

  .comman-impact-bottom {
    max-width: 730px;
    margin: 65px auto 0;

    .card-img-wrap,
    .comman-impact-img {
      width: 105px;
      height: 105px;
      object-fit: cover;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      margin: auto;
    }

    hr {
      border-top: 1px solid #4e4e4e;
      margin: 65px auto 0px;
    }

    .c-l-grey2 {
      color: #9c9c9c;
    }
  }
}

.carousel-indicators {
  bottom: -75px;

  li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin-right: 5px;
    margin-left: 5px;
  }
}

#home-carousel-indicators-ul {
  margin-top: 0;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -705px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.circle-svg-wrap {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  //animation: stroke 35s linear forwards;
  //-webkit-animation: stroke 35s linear forwards;
}

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

.carousel-indicators-ul {
  position: absolute;
  top: 50%;
  left: -93px;
  transform: translateY(-50%);
  height: 150px;
  display: inline-block;
  width: 20px;
  margin: 0;
  z-index: 59;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  margin-top: -183px;

  li {
    opacity: 1;
    border: 2px solid rgba($color: #a7a7a7, $alpha: 0.6);
    width: 16px;
    height: 16px;
    display: block;
    margin: 22px 0;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    position: relative;
    cursor: pointer;

    a {
      width: 35px;
      height: 35px;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 49;
      transform: translate(-15px, -15px);
    }

    &.active {
      width: 22px;
      height: 22px;
      margin-left: -5px;

      svg {
        position: absolute;
        top: 10px;
        left: 13px;
        transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
      }

      .circle-svg-wrap {
        animation: stroke 70s linear forwards 0.2s;
        -webkit-animation: stroke 70s linear forwards 0.2s;
      }
    }
  }
}

.comman-card-left {
  padding-right: 100px;
}

.comman-card-right {
  padding-left: 100px;
}

// home page

#home-carosual2 {
  .carousel-inner {
    overflow: inherit;
  }
}

.home-sec5 {
  background: #292E34;
}

.home-sec7 {
  .process-img-wrap {
    height: 440px;
  }
}

.home-sec-tab {
  .h6 {
    color: $white;
  }

  .tab-content {
    margin-left: 120px;
  }

  .flex-column {
    .nav-link {
      color: $white;
      opacity: 0.5;
      padding: 0;

      span {
        &::after {
          top: 16px;
        }
      }

      &:hover {
        opacity: 1;

        span {
          &::after {
            opacity: 1;
            // width: 50px;
          }
        }
      }

      &.active {
        opacity: 1;

        span {
          &::after {
            opacity: 0;
            width: 50px;
            display: none !important;
          }
        }
      }

      &:not(:last-child) {
        margin-bottom: 65px;
      }
    }
  }

  .comman-card-info {
    .sec-heading {
      color: $white !important;
    }

    .sec-para {
      color: $white !important;
      // font-family: $font-family-lora;
    }
  }

  .testi-comman-card-info {
    max-width: 500px;
    margin: auto;
    text-align: center;

    .card-img-wrap {
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      overflow: hidden;
      margin-bottom: 20px;
      width: 100px;
      height: 100px;
      object-fit: cover;
    }

    h6 {
      color: $white;
    }

    .p3 {
      color: $white;
      margin-top: 20px;
      // font-family: $font-family-lora;
      text-align: left;

      .fas {
        font-size: 10px;
        vertical-align: 10px;
        display: none !important;
      }
    }
  }
}

.home-sec4 {
  background: $black;

  .slick-track {
    height: inherit !important;
  }

  .slick-list,
  .slick-slider {
    overflow: hidden;
  }

  .comman-card-info {
    .h6,
    h2 {
      color: $white;
    }
  }

  @-webkit-keyframes slidertop {
    from {
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
    }
    to {
      transform: translateY(-180%);
      -webkit-transform: translateY(-180%);
      -moz-transform: translateY(-180%);
      -ms-transform: translateY(-180%);
      -o-transform: translateY(-180%);
    }
  }
  @keyframes slidertop {
    from {
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
    }
    to {
      transform: translateY(-180%);
      -webkit-transform: translateY(-180%);
      -moz-transform: translateY(-180%);
      -ms-transform: translateY(-180%);
      -o-transform: translateY(-180%);
    }
  }

  .client-logo-wrap-outer {
    animation: slidertop 34s linear infinite alternate-reverse;
    -webkit-animation: slidertop 34s linear infinite alternate-reverse;
    height: calc(100vh - 200px);

    &:hover {
      animation-play-state: paused;
    }
  }

  .client-logo-wrap-outer-col {
    height: 670px;
    overflow: hidden;
    position: relative;
    left: 70px;
  }

  .client-logo-wrap {
    max-width: 560px;
    width: 100%;
    margin-left: 70px;
    margin-top: 30px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;

    ul {
      width: 100%;
      margin: 0;

      li {
        width: 50%;
        text-align: center;
        float: left;
        margin: 70px 0;
        transition: all 0.2s linear 0s;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        -webkit-transition: all 0.2s linear 0s;
        -moz-transition: all 0.2s linear 0s;
        -ms-transition: all 0.2s linear 0s;
        -o-transition: all 0.2s linear 0s;

        img {
          height: 45px;
          max-width: 120px;
          object-fit: cover;
          width: 100%;
          object-fit: contain;
        }

        &:hover {
          -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
        }
      }
    }
  }
}

.home-sec6 {
  background: $white;

  .award-logo-wrap {
    width: 100%;
    padding-top: 80px;
    padding-left: 30px;

    .slick-caroual-awards {
      margin-top: 0px;
    }

    .slick-track {
      padding: 0 20px 0 10px;
    }

    .slick-slider {
      overflow: hidden;
    }

    .slick-list {
      padding-top: 30px;
      overflow: hidden;

      &::before {
        position: absolute;
        left: 0;
        top: -5px;
        right: 0;
        width: 100%;
        height: 15px;
        z-index: 9;
        content: "";
        background: rgba(0, 0, 0, 0) linear-gradient(white 64.6%, rgba(255, 255, 255, 0) 100%) repeat scroll 0% 0%;
      }
    }

    ul {
      max-width: 300px;
      float: left;

      li {
        margin-bottom: 40px;
      }
    }

    /* ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: end;
      align-items: stretch;
      align-content: stretch;
      margin: 0;
      li {
        width: calc(50% - 20px);
        text-align: center;
        transition: all 0.2s linear 0s;
        -webkit-transition: all 0.2s linear 0s;
        -moz-transition: all 0.2s linear 0s;
        -ms-transition: all 0.2s linear 0s;
        -o-transition: all 0.2s linear 0s;
        &:nth-child(even) {
          margin-top: 30px;
        }
        &:hover {
          -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
        }
      }
    }*/
  }
}

.award-card {
  background: $white;
  box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  padding: 35px;
  max-width: 300px;
  position: relative;
  min-height: 300px;
  text-align: left;

  h6 {
    margin-bottom: 6px;

    // font-family: $font-family-poppins;
  }

  p.p3.text-orange {
    margin-bottom: 25px;
    // font-family: $font-family-poppins;
    margin-top: 8px;
  }

  .card-img-wrap {
    margin-bottom: 10px;

    img {
      max-width: 70px;
    }
  }

  p.p4.text-light-dark-c {
    // font-family: $font-family-lora;
  }
}

.comman-card-img-wrap2 {
  position: relative;
  margin-left: 0px;
  left: -50px;
  top: 76px;

  #culture-carousel {
    max-width: 500px;
    position: relative;
    z-index: 4;

    &:after {
      content: "";
      position: absolute;
      top: -1px;
      left: 17px;
      width: 227px;
      height: 325px;
      background: transparent;
      border-top: 17px solid $white;
      border-left: 17px solid $white;
      z-index: 0;
    }
  }

  #culture-carousel2 {
    position: absolute;
    top: -145px;
    left: -168px;
    max-width: 345px;
    z-index: 3;
  }

  .home-sec8-3 {
    position: absolute;
    bottom: -60px;
    left: -58px;
    z-index: 2;
  }

  .home-sec8-4 {
    position: absolute;
    top: -47px;
    left: auto;
    right: -38px;
    z-index: 1;
  }
}

// about page

.about-head-card-top-ul {
  margin-top: 40px;

  li:not(:last-child) {
    margin-right: 80px;
  }

  .text-purple {
    min-width: 90px;
  }
}

.comman-card-img-wrap {
  .about-sec1-1-wrap {
    height: 400px;
  }

  .about-sec1-1 {
    top: -60px;
    left: 16px;
    z-index: 3;
  }

  .about-sec1-2 {
    top: -50px;
    left: 126px;
    z-index: 2;
  }

  .about-sec1-3 {
    bottom: -46px;
    left: 53px;
    z-index: 1;
  }
}

.about-sec1 {
  .btn-line span::after {
    height: 1px;
    width: 25px;
  }

  .comman-card-img-wrap-upd {
    .about-sect-1-wrap {
      text-align: center;

      .about-sect-1 {
      }

      .about-sect-para {
        // font-family: "PT Serif", serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        letter-spacing: 1px;
        color: #000000;
      }
    }
  }
}

.about-sec2 {
  position: relative;
  overflow: hidden;

  .card-img-inner-wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    z-index: 9;

    .img-fluid {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.about-sect2 {
  background: #1e1e1e url("../images/about/about-sub-banner1.jpg") no-repeat center center;
  background-size: auto;
  background-size: cover;
  min-height: 100vh;
  position: relative;

  .card-img-inner-wrap {
    max-width: 685px;
    margin: auto;
  }
}

.about-sec3 {
  .comman-card-info {
    max-width: 500px;
    // min-height: 300px;
    margin: auto;

    .comman-card-img-wrapper {
      position: relative;
      width: 80px;
      float: none;
      height: 56px;
      margin-bottom: 33px;

      .vision-img1 {
        position: absolute;
        top: -33px;
        left: 0;
        z-index: 2;
      }

      .vision-img2 {
        position: relative;
        top: -15px;
        left: 20px;
        z-index: 1;
      }

      .mission-img1 {
        position: absolute;
        top: -7px;
        left: -8px;
        z-index: 2;
      }

      .mission-img2 {
        position: relative;
        top: -14px;
        left: 0;
        z-index: 1;
      }
    }

    h3 {
      color: $white;
    }

    .p1 {
      color: $white;
    }
  }
}

.about-sec4 {
  background: $black;

  .ani-wrap50-3 {
    position: absolute;
    top: 0;
    left: auto;
    right: 0px;
    bottom: 0;
    height: 100%;
  }

  .comman-card-info {
    .h6 {
      color: $white;
    }

    h2 {
      color: $white;
    }
  }

  .our-values-wrap {
    max-width: 500px;
    margin: 100px 20px 0 100px;

    .our-values-card {
      margin-bottom: 83px;

      h4 {
        margin-bottom: 4px;
      }

      .p2 {
        color: $light-dark-c;
      }
    }
  }
}

.about-sec5 {
  .carousel-indicators-ul {
    li {
      border: 2px solid #5b5b5b;
    }
  }

  .comman-card-info {
    .h6 {
      color: $white;
    }

    .p1 {
      color: $white;
      position: relative;
      padding-top: 55px;
      max-width: 500px;

      span {
        background: $black;
        position: relative;
        z-index: 3;
        display: inline;
      }

      .about-expressions1 {
        position: absolute;
        top: 5px;
        left: 0;
      }
    }

    .comman-card-footer {
      position: relative;
      margin-top: 35px;
      padding-top: 20px;

      &::before {
        content: "";
        width: 39px;
        height: 1px;
        background: $orange;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .our-expressions-wrap {
    margin-top: 60px;
    margin-left: -67px;

    video {
      position: relative;
      z-index: 2;
      height: 704px;
      box-shadow: -5px 0px 30px rgba(0, 0, 0, 0.2);
    }

    .about-sec5-1 {
      position: absolute;
      top: 210px;
      right: -5px;
      z-index: 1;
    }
  }
}

.about-sec7 {
  .comman-card-info {
    max-width: 700px;
    margin: 0 auto 50px;
    text-align: center;

    .text-light-dark-c {
      margin-top: 10px;
    }
  }
}

.comman-team-card {
  position: relative;
  text-align: center;
  // margin-top: 100px;
  .comman-team-card-head {
    width: 340px;
    height: 425px;
    position: relative;
  }

  img.st {
    position: absolute;
    left: 0px;
    top: 0px;

    &.dhananjay-a-1 {
      left: 55px;
      top: 56px;
    }

    &.dhananjay-a-2 {
      left: 0;
      top: 45px;
    }

    &.sunil-b-1 {
      left: 34px;
      top: 56px;
    }

    &.sunil-b-2 {
      left: 24px;
      top: 86px;
    }

    &.rohit-s-1 {
      left: 34px;
      top: 38px;
    }

    &.rohit-s-2 {
      left: 34px;
      top: 85px;
    }

    &.priyanka-p-1 {
      left: 14px;
      top: 38px;
    }

    &.priyanka-p-2 {
      left: -10px;
      top: 85px;
    }

    &.akhil-p-1 {
      left: 0px;
      top: 40px;
    }

    &.akhil-p-2 {
      left: 170px;
      top: 100px;
    }

    &.ashay-d-1 {
      left: 60px;
      top: 36px;
    }

    &.ashay-d-2 {
      left: 30px;
      top: 84px;
    }

    &.james-s-1 {
      left: 16px;
      top: 40px;
    }

    &.james-s-2 {
      left: 147px;
      top: 50px;
    }

    &.abdul-r-1 {
      left: 60px;
      top: 36px;
    }

    &.abdul-r-2 {
      left: -30px;
      top: 84px;
    }

    &.nayan-n-1 {
      left: 0px;
      top: 40px;
    }

    &.nayan-n-2 {
      left: 190px;
      top: 100px;
    }

    &.harshit-k-1 {
      left: 0px;
      top: 40px;
    }

    &.harshit-k-2 {
      left: 157px;
      top: 90px;
    }

    &.simran-m-1 {
      left: 50px;
      top: 40px;
    }

    &.simran-m-2 {
      left: -10px;
      top: 85px;
    }
  }

  .team-card-left1 {
    position: absolute;
    left: 20px;
    top: 30px;
    z-index: 1;
    opacity: 0;
  }

  .team-card-left2 {
    position: absolute;
    left: -30px;
    top: -10px;
    z-index: 2;
  }

  .team-card-center1 {
    position: absolute;
    left: 30px;
    top: -54px;
    z-index: 1;
    opacity: 0;
  }

  .team-card-center2 {
    position: absolute;
    left: 60px;
    top: -30px;
    z-index: 2;
  }

  .team-card-right1 {
    position: absolute;
    left: 10px;
    top: 40px;
    z-index: 1;
    opacity: 0;
  }

  .team-card-right2 {
    position: absolute;
    left: 107px;
    top: 30px;
    z-index: 2;
  }

  .team-card-img1 {
    position: relative;
    z-index: 3;
    max-width: 100%;
  }

  &:hover {
    .team-card-right1,
    .team-card-left1,
    .team-card-center1 {
      opacity: 1;
    }
  }

  .comman-team-card-foot {
    margin-top: 30px;

    h6.restlessRegular {
      font-size: 42px;
      margin-bottom: 18px;
      padding-top: 10px;
      font-family: restlessRegular !important;
      font-weight: 400 !important;
    }
  }
}

.about-sec6 {
  .comman-card-img-wrap3-outer {
    height: 900px;
  }

  .comman-card-img-wrap3 {
    position: relative;
    max-width: 804px;
    margin: 220px auto 0;

    .about-sec8-3 {
      position: absolute;
      bottom: -62px;
      left: -66px;
      z-index: 0;
      width: 212px;
    }

    #about-sec6-carousel {
      position: relative;
      z-index: 2;
      width: 804px;
      top: -30px;

      &::after {
        content: "";
        position: absolute;
        bottom: -70px;
        right: -20px;
        width: 616px;
        height: 560px;
        background: transparent;
        z-index: 0;
        border-top: 22px solid #fff;
        border-right: 22px solid #fff;
      }
    }

    #about-sec6-carousel2 {
      position: absolute;
      top: -158px;
      left: -145px;
      z-index: 2;
      width: 302px;

      &::after {
        content: "";
        position: absolute;
        bottom: 10px;
        right: -20px;
        width: 215px;
        height: 177px;
        background: transparent;
        z-index: 0;
        border-bottom: 20px solid $white;
        border-right: 23px solid $white;
      }
    }

    #about-sec6-carousel3 {
      position: absolute;
      width: 440px;
      top: -170px;
      right: -253px;
      z-index: 1;
    }

    #about-sec6-carousel4 {
      position: absolute;
      width: 460px;
      bottom: -180px;
      right: -207px;
      z-index: 4;

      &::after {
        content: "";
        position: absolute;
        top: -20px;
        left: -20px;
        width: 311px;
        height: 311px;
        background: transparent;
        border-top: 20px solid $white;
        border-left: 20px solid $white;
        z-index: 0;
      }
    }
  }
}
.linkin-adj1{
  margin-left: 45px !important;
}
.leader-managment-p-adj{
  margin-top: -10px;
}

// work page
.work-comman-card-info {
  margin-top: 120px;

  .bttn span::after {
    margin-left: 10px !important;
  }
}

// crubba portfolio
// $font-family-playfair
.portfolio-info-wrap {
  h2,
  h3,
  h4,
  .h44 {
    // font-family: $font-family-playfair;
    $color: $black2;
    margin-bottom: 20px;
  }

  .p3 {
    // font-family: $font-family-poppins;
  }
}

.crubba-sec1 {
  overflow: hidden;
  background: #252525 url("../images/portfolio/portfolio-website-crubba/crubba-sec1-bg.jpg") no-repeat 0 0;
  background-size: cover;

  .portfolio-info-wrap {
    .crubba-sec9-inner1 {
      position: absolute;
      bottom: 0;
      right: -270px;
      z-index: 1;
      animation: floating 5s linear 2s infinite;
      -webkit-animation: floating 5s linear 2s infinite;
    }

    h2 {
      margin-bottom: 30px;
    }
  }

  .crubba-sec1-right {
    max-width: 550px;
  }

  .portfolio-img-wrap {
    position: relative;
    min-height: 523px;

    .crubba-sec9-inner2 {
      position: relative;
      top: 30px;
      left: -12px;
      z-index: 5;
    }

    .crubba-sec9-inner3 {
      position: absolute;
      bottom: -40px;
      left: -20px;
      z-index: 6;
      animation: floating 5s linear 0s infinite;
      -webkit-animation: floating 5s linear 0s infinite;
    }

    .crubba-sec9-inner4 {
      position: absolute;
      top: -130px;
      left: -140px;
      max-width: inherit;
      z-index: 2;
      animation: rotation-ani 15s infinite linear;
      -webkit-animation: rotation-ani 15s infinite linear;
    }

    .crubba-sec9-inner5 {
      position: absolute;
      top: 0px;
      left: -37px;
      max-width: inherit;
      z-index: 1;
      animation: rotation-ani2 12s infinite linear 2s;
      -webkit-animation: rotation-ani2 12s infinite linear 2s;
    }
  }

  .crubba-sec9-inner1 {
    position: absolute;
    bottom: 0;
    right: -180px;
    z-index: 1;
    animation: floating 5s linear 2s infinite;
    -webkit-animation: floating 5s linear 2s infinite;
  }
}

.crubba-sec2 {
  .portfolio-info-wrap {
    max-width: 500px;
    margin: 150px auto 0;

    .h44 {
      margin-bottom: 20px;
    }
  }

  .crubba-sec2-img1 {
    max-width: 600px;
  }

  .crubba-sec2-img2 {
    position: absolute;
    top: 140px;
    left: -120px;
    z-index: 6;
    animation: floating 5s linear 0s infinite;
    -webkit-animation: floating 5s linear 0s infinite;
  }

  .crubba-sec2-center-outer {
    text-align: center;
  }

  .crubba-sec2-img3 {
    position: absolute;
    top: 180px;
    right: -110px;
    max-width: inherit;
    z-index: 2;
    animation: floating 15s infinite linear;
    -webkit-animation: floating 15s infinite linear;
  }
}

.crubba-sec3 {
  .crubba-sec3-left {
    max-width: 500px;
  }

  .crubba-sec3-right {
    position: relative;
    height: 523px;

    .crubba-sec3-right1 {
      position: relative;
      z-index: 4;
    }

    .crubba-sec3-right2 {
      position: absolute;
      top: -33px;
      left: -5px;
      animation: rotation-ani 15s infinite linear;
      -webkit-animation: rotation-ani 15s infinite linear;
      z-index: 3;
    }

    .crubba-sec3-right3 {
      position: absolute;
      top: -43px;
      left: -5px;
      animation: rotation-ani2 15s infinite linear;
      -webkit-animation: rotation-ani2 15s infinite linear;
      z-index: 2;
    }

    .crubba-sec3-right4 {
      position: absolute;
      top: -43px;
      left: -5px;
      animation: rotation-ani 12s infinite linear;
      -webkit-animation: rotation-ani 12s infinite linear;
      z-index: 1;
    }
  }
}

.crubba-sec3-upd {
  background: url("../images/portfolio/portfolio-website-crubba/crubba-sec2-left.png") no-repeat 0 bottom;
  background-size: 360px auto;
}

.crubba-sec4 {
  position: relative;
  overflow: hidden;

  .viewer::after {
    display: none !important;
  }

  text-align: center;

  .crubba-sec4-bottom {
    margin-top: 40px;
  }

  .crubba-sec4-bottom1 {
    position: absolute;
    bottom: 0;
    left: -70px;
  }

  .crubba-sec4-bottom2 {
    position: relative;
    margin-right: -180px;
    display: block;
    float: right;
  }
}

.crubba-sec5 {
  background: url("../images/portfolio/portfolio-website-crubba/crubba-sec5-bottom-after.png") no-repeat right bottom 130px;
  text-align: center;
  position: relative;
  padding-bottom: 0 !important;

  .vh100-100 {
    overflow: hidden;
  }

  .crubba-sec5-bottom1-outer {
    height: 763px;
  }

  .crubba-sec5-bottom1 {
    position: relative;
    float: right;
    margin-right: -60px;
    margin-top: 30px;
  }

  .crubba-sec5-bottom2 {
    position: relative;
    float: left;
    bottom: -130px;
    left: -353px;
    max-width: inherit;
  }

  .crubba-sec5-bottom3 {
    position: absolute;
    top: -35px;
    left: -10px;
  }
}

.crubba-sec6 {
  background: url("../images/portfolio/portfolio-website-crubba/crubba-sec6-bg.jpg") no-repeat 0 0;
  background-size: cover;
  text-align: center;

  .crubba-sec6-center {
    max-width: 665px;
    margin: auto;
  }

  .crubba-sec6-bottom {
    background: url("../images/portfolio/portfolio-website-crubba/crubba-sec6-bottom3.svg") no-repeat center center;
    background-size: initial;
    padding: 40px;
    height: 505px;

    .owl-caroual-comman {
      display: block;
      width: 870px;
      margin: auto;
    }

    .owl-theme .owl-dots .owl-dot span {
      background: $white;
      opacity: 0.5;
    }

    .owl-theme .owl-dots .owl-dot.active span,
    .owl-theme .owl-dots .owl-dot:hover span {
      background: $white;
      opacity: 1;
    }

    .owl-theme .owl-nav.disabled + .owl-dots {
      margin-top: 30px;
    }
  }

  .crubba-sec6-1 {
    position: absolute;
    top: 20px;
    right: -230px;
    max-width: inherit;
    z-index: 2;
  }

  .portfolio-info-wrap {
    max-width: 645px;
    margin: 0 auto 30px;
  }
}

.crubba-sec7 {
  background: url("../images/portfolio/portfolio-website-crubba/crubba-sec7-bg.png") no-repeat center top;
  background-size: 100% auto;

  .portfolio-info-wrap2 {
    max-width: 643px;
    margin: auto;
    text-align: center;
  }

  .crubba-sec7-img1 {
    position: absolute;
    bottom: 150px;
    left: 0px;
    z-index: 6;
    animation: floating 5s linear 0s infinite;
    -webkit-animation: floating 5s linear 0s infinite;
  }

  .crubba-sec6-bottom-before {
    position: absolute;
    bottom: 140px;
    right: -60px;
    z-index: 6;
  }

  .crubba-sec7-bottom {
    margin-top: 46px;
    position: relative;
    height: 780px;

    .crubba-sec7-bottom1 {
      position: absolute;
      top: 77px;
      left: -50px;
      z-index: 9;
    }

    .crubba-sec7-bottom2 {
      position: absolute;
      top: 293px;
      right: 10px;
      z-index: 10;
    }

    .crubba-sec7-bottom3-wrap {
      position: relative;
      z-index: 3;

      .crubba-sec7-bottom3 {
        margin: auto;
        position: relative;
      }

      .crubba-sec7-bottom4-hover {
        position: absolute;
        z-index: 5;
        top: 43px;
        left: 50%;
        bottom: 0;
        width: 864px;
        height: 550px;
        background: url("../images/portfolio/portfolio-website-crubba/crubba-sec7-bottom4.png") no-repeat 0 0;
        background-size: auto;
        background-size: cover;
        transition: background-position 8s linear;
        -webkit-transition: background-position 8s linear;
        -moz-transition: background-position 8s linear;
        -ms-transition: background-position 8s linear;
        -o-transition: background-position 8s linear;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        margin-left: 7px;

        &:hover {
          background-position-y: 100%;
        }
      }
    }
  }
}

.crubba-sec8 {
  .crubba-sec8-bottom {
    height: 1270px;
  }

  .comman-card-bottom-ul {
    position: relative;
    z-index: 5;
  }
}

.crubba-sec9 {
  padding-bottom: 0;
  overflow: hidden;

  .crubba-sec9-img-wrap {
    position: absolute;
    top: 20px;
    right: 0;
    z-index: 1;

    &:hover {
      .crubba-slider-mobile {
        background-position-y: bottom;
      }
    }

    img {
      display: inline-block;
      height: 100vh;
    }

    .crubba-slider-mobile {
      position: absolute;
      top: 50%;
      left: 50%;
      background: url("../images/portfolio/portfolio-website-crubba/crubba-slider-mobile-min.jpg") no-repeat 0 0;
      width: 225px;
      height: 438px;
      transform: translate(-50%, -50%);
      margin-left: -96px;
      margin-top: -3px;
      background-size: cover;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transition: all 25s linear;
      -webkit-transition: all 25s linear;
      -moz-transition: all 25s linear;
      -ms-transition: all 25s linear;
      -o-transition: all 25s linear;
    }
  }

  .crubba-sec9-img2 {
    position: absolute;
    bottom: 0;
    left: -90px;
    z-index: 0;
    width: 300px;
  }

  .crubba-sec9-center {
    max-width: 710px;
    margin: auto;
  }

  .crubba-sec9-bottom {
    position: relative;
    top: 0;
    float: right;
    right: 0;
    height: 730px;
  }
}

.crubba-sec10 {
  .crubba-sec10-center {
    text-align: center;
  }

  .crubba-sec10-bottom {
    height: 1260px;
  }
}

.crubba-sec11 {
  background: url("../images/portfolio/portfolio-website-crubba/crubba-sec11-bottom2.png") no-repeat right -110px top 320px;

  .crubba-sec11-center {
    max-width: 1120px;
    margin: auto;
  }

  .crubba-sec11-bottom {
    position: relative;
    left: -20px;
    height: 880px;
  }
}

.crubba-sec12 {
  background: #fafcfe url("../images/portfolio/portfolio-website-crubba/crubba-sec12-bg.svg") no-repeat 0 center;
  background-size: contain;
  overflow: hidden;
  padding-bottom: 0;
  height: 1922px !important;

  .mobile-row {
    position: relative;
    z-index: 9;
    height: 1580px;
    display: block;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 750px;
    background: #7ce2bb;
    z-index: 0;
  }

  .crubba-sec12-center {
    max-width: 1120px;
    margin: auto;

    .h44 {
      margin-bottom: 20px;
    }
  }

  .crubba-sec12-1 {
    position: relative;
    left: -90px;
  }

  .crubba-sec12-2 {
    margin: -70px 0 0 245px;
    display: block;
  }

  .crubba-sec12-3 {
    display: block;
    margin: 60px auto -10px;
  }

  .crubba-sec12-4 {
    display: block;
    margin: auto;
  }

  .crubba-sec12-5 {
    display: block;
    margin: auto;
    position: absolute;
    top: -4px;
    left: -184px;
    z-index: 3;
  }

  .crubba-sec12-6 {
    display: block;
    margin: 102px auto 0 80px;
  }

  .crubba-sec12-7 {
    display: block;
    margin: -25px auto 0 -60px;
  }

  .crubba-sec12-5-wrap {
    position: relative;
    height: 730px;
    margin-top: -240px;
    margin-left: -50px;

    .crubba-sec12-5-wrap-hover {
      background: url("../images/portfolio/portfolio-website-crubba/crubba-sec12-5-hover.png") no-repeat 0 0;
      position: absolute;
      top: 157px;
      left: 20px;
      right: 0;
      bottom: 0;
      width: 320px;
      height: 647px;
      background-size: cover;
      z-index: 9;
      transition: all 15s linear 0s;
      -webkit-transition: all 15s linear 0s;
      -moz-transition: all 15s linear 0s;
      -ms-transition: all 15s linear 0s;
      -o-transition: all 15s linear 0s;

      &:hover {
        background-position-y: 100%;
      }
    }
  }
}

.crubba-impact-section {
  background: url("../images/portfolio/portfolio-website-crubba/crubba-impact-bg.jpg") no-repeat 0 0;
  background-size: cover;

  .h44 {
    // font-family: $font-family-playfair;
  }

  .crubba-impact1-circle {
    position: absolute;
    width: 210px;
    height: 210px;
    top: -42px;
    left: 60px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      &:first-child {
        animation: rotation-ani2 15s infinite linear;
        -webkit-animation: rotation-ani2 15s infinite linear;
        margin-left: -3px;
      }

      &:nth-child(2) {
        animation: rotation-ani1 12s infinite linear;
        -webkit-animation: rotation-ani1 12s infinite linear;
        margin-left: 3px;
      }

      &:nth-child(3) {
        animation: rotation-ani2 8s infinite linear;
        -webkit-animation: rotation-ani2 8s infinite linear;
      }
    }
  }
}

// easy-plan portfolio
.easy-plan-sec1 {
  .easy-plan-banner-img {
    max-width: initial;
  }

  .easy-plan-right-wrap {
    height: 460px;
  }
}

.easy-plan-sec2 {
  .easy-plan-left-wrap {
    max-width: 500px;
  }

  .easy-plan-right-wrap {
    max-width: 500px;
    float: right;
  }
}

.easy-plan-sec3 {
  position: relative;
  overflow: hidden;
  background: #f4f3f9;
  height: 100vh;

  .easy-plan-strategy-left-col-img {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    padding-left: 0;
    padding-right: 0;
    width: 100%;

    .easy-plan-strategy-left {
      width: 100%;

      .card-img-wrap {
        width: 100%;

        img {
          width: 100%;
          height: 100vh;
          object-fit: cover;
        }
      }
    }
  }

  .easy-plan-strategy-right {
    max-width: 500px;
    margin: auto;
    float: right;

    ul {
      margin-left: 20px;
      list-style: disc !important;
      color: $light-dark-c;
    }

    li {
      list-style: disc !important;
      color: $light-dark-c;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
}

.easy-plan-sec4 {
  position: relative;
  overflow: hidden;

  .easy-plan-strategy-right {
    margin-top: 175px;
  }

  .owl-theme .owl-dots .owl-dot span {
    background: #d7d7d7;
  }

  .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    background: #383a47;
  }

  .owl-caroual-comman {
    display: block;
    position: absolute;
    top: 140px;
    left: 50%;
    width: 300px;
    margin: auto;
    margin-left: auto;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    z-index: 49;
    height: 700px;
    margin-left: 110px;

    &::after {
      content: "";
      position: absolute;
      top: -20px;
      left: -20px;
      background: url("../images/portfolio/portfolio-mobile-app-easy-plan/easy-plan-onbording-mobile.png") no-repeat 0 0;
      width: 351px;
      height: 695px;
      z-index: -1;
    }

    img {
      width: 280px;
      display: block;
      margin: auto;
    }
  }

  .easy-plan-strategy-left {
    position: relative;
    height: 974px;
    transform: scale(0.8);
    transform-origin: right center;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    top: -80px;

    .easy-plan-strategy-left-inner {
      position: relative;

      .easy-plan-onboarding1 {
        position: absolute;
        max-width: initial;
        top: 50px;
        left: 215px;
      }

      .easy-plan-onboarding2 {
        position: absolute;
        max-width: initial;
        top: 0px;
        left: 166px;
        animation: rotation-ani 15s infinite linear;
        -webkit-animation: rotation-ani 15s infinite linear;
      }
    }
  }

  .easy-plan-strategy-left-inner2 {
    position: relative;
    top: 133px;
    float: right;
    left: 12px;
    margin-bottom: 100px;
  }
}

.easy-plan-sec5 {
  position: relative;

  .ease-plan-chat-wrap {
    position: relative;
    height: 700px;

    .easy-plan-chat1 {
      position: absolute;
      top: -73px;
      left: -105px;
    }

    .easy-plan-chat2 {
      position: absolute;
      bottom: 50px;
      left: -63px;
    }

    .easy-plan-chat3 {
      position: absolute;
      bottom: -27px;
      right: 190px;
    }

    .easy-plan-chat4 {
      position: absolute;
      top: 70px;
      right: -52px;
    }

    .ease-plan-chat-mobile-wrap {
      position: relative;

      .easy-plan-chat-mobile {
        position: relative;
      }

      .ease-plan-chat-mobile-hover {
        position: absolute;
        top: 43px;
        left: 23px;
        right: 0;
        bottom: 0;
        width: 280px;
        height: 590px;
        background: url("../images/portfolio/portfolio-mobile-app-easy-plan/ease-plan-chat-mobile-hover.jpg") no-repeat 0 0;
        background-size: cover;
        transition: all 8s linear;
        -webkit-transition: all 8s linear;
        -moz-transition: all 8s linear;
        -ms-transition: all 8s linear;
        -o-transition: all 8s linear;

        &:hover {
          background-position-y: 100%;
        }
      }
    }
  }
}

.easy-plan-sec6 {
  overflow: hidden;

  .easy-plan-sec6-inner {
    position: relative;
    top: 0;
    left: -47px;
    height: 950px;
    transform: scale(0.9);
    transform-origin: right -260px;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);

    .easy-plan-sec6-inner1 {
      position: absolute;
      z-index: 4;
      top: 290px;
      left: 116px;
    }

    .easy-plan-sec6-inner2 {
      position: absolute;
      z-index: 3;
      top: 130px;
      left: 320px;
    }

    .easy-plan-sec6-inner5 {
      position: relative;
      z-index: 2;
      max-width: initial;
      animation: rotation-ani 15s infinite linear;
      -webkit-animation: rotation-ani 15s infinite linear;
    }
  }
}

.easy-plan-sec7 {
  overflow: hidden;

  .easy-plan-sect6-inner3 {
    display: inline-block;
    position: absolute;
    left: 110px;
    top: 160px;
    max-width: inherit !important;
  }

  .easy-plan-sect6-inner2 {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 300px;
    max-width: inherit !important;
    z-index: 49;
    transition: all 10s linear 0s;
    -webkit-transition: all 10s linear 0s;
    -moz-transition: all 10s linear 0s;
    -ms-transition: all 10s linear 0s;
    -o-transition: all 10s linear 0s;
  }

  .easy-plan-sect6-inner1 {
    position: absolute;
    z-index: -1;
    max-width: initial;
    animation: rotation-ani 15s infinite linear;
    -webkit-animation: rotation-ani 15s infinite linear;
    display: block !important;
    top: -190px;
    left: -220px;
  }

  .easy-plan-goal-right {
    margin-top: 40%;
  }

  .easy-plan-sec6-inner {
    position: relative;
    top: 0;
    left: -47px;
    height: 950px;
    transform: scale(0.9);
    transform-origin: right -260px;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);

    &:hover {
      .easy-plan-sect6-inner2 {
        top: -420px;
      }
    }

    .easy-plan-sec6-inner1 {
      position: absolute;
      z-index: 4;
      top: 290px;
      left: 116px;
    }

    .easy-plan-sec6-inner2 {
      position: absolute;
      z-index: 3;
      top: 130px;
      left: 320px;
    }

    .easy-plan-sec6-inner5 {
      position: relative;
      z-index: 2;
      max-width: initial;
      animation: rotation-ani 15s infinite linear;
      -webkit-animation: rotation-ani 15s infinite linear;
    }
  }
}

.easy-plan-sec8 {
  position: relative;

  & > .container {
    padding-bottom: 100px;
  }

  .easy-plan-track-right {
    position: relative;
    margin-top: 200px;
  }

  .easy-plan-sec8-inner {
    position: relative;
    margin-top: 240px;
    height: 500px;

    .easy-plan-sec8-inner1 {
      position: absolute;
      z-index: 4;
      top: -160px;
      left: 270px;
      max-width: inherit;
    }

    .easy-plan-sec8-inner2 {
      position: absolute;
      z-index: 3;
      top: -100px;
      left: 140px;
      max-width: inherit;
    }

    .easy-plan-sec8-inner3 {
      position: absolute;
      z-index: 2;
      top: -70px;
      left: -240px;
      max-width: inherit;
    }

    .easy-plan-sec8-inner4 {
      position: relative;
      z-index: 1;
      top: 0;
      left: -286px;
    }
  }
}

.easy-plan-sec9 {
  overflow: hidden;

  .easy-plan-sec9-inner {
    position: relative;
    height: 728px;

    .easy-plan-sec9-inner1 {
      position: absolute;
      bottom: -45px;
      left: -280px;
      z-index: 4;
    }

    .easy-plan-sec9-inner2 {
      position: relative;
      top: 0;
      left: 182px;
      z-index: 3;
    }

    .easy-plan-sec9-inner3 {
      position: absolute;
      top: 48px;

      left: 75px;
      z-index: 1;
      animation: rotation-ani 15s infinite linear;
      -webkit-animation: rotation-ani 15s infinite linear;
    }

    .easy-plan-sec9-inner4 {
      position: absolute;
      bottom: 0px;
      right: 10px;
      z-index: 2;
    }
  }
}

.easy-plan-sec10 {
  overflow: hidden;
  background: $white;

  .easy-plan-track-left {
    margin-top: 180px;
  }

  .easy-plan-sec10-inner {
    position: relative;
    height: 730px;

    .easy-plan-sec10-inner1 {
      position: relative;
      top: -10px;
      left: 10px;
      z-index: 4;
    }

    .easy-plan-sec10-inner2 {
      position: absolute;
      z-index: 2;
      top: 105px;
      left: -46px;
      animation: rotation-ani 15s infinite linear;
      -webkit-animation: rotation-ani 15s infinite linear;
    }
  }
}

// moneyguru portfolio
.moneyguru-header-section {
  .moneyguru-banner-section-after {
    background: #f1f3f7;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 68.5%;
  }

  .moneyguru-header-left {
    width: 700px;

    img {
      margin-bottom: 25px;
    }

    .h44 {
      color: $black;
    }
  }

  .moneyguru-header-right-wrap {
    height: 758px;
  }
}

.moneyguru-business-section {
  background: url("../images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-business-bg.svg") no-repeat right -300px top 0;

  .moneyguru-overview-wrap {
    max-width: 518px;
    height: 340px;
  }
}

.moneyguru-strategy-section {
  .moneyguru-strategy-right {
    margin-left: 87px;
  }

  .moneyguru-strategy-left-col-img {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    padding-left: 0;
    padding-right: 0;
    width: 100%;

    .moneyguru-strategy-left {
      width: 100%;

      .card-img-wrap {
        width: 100%;

        img {
          width: 100%;
          height: 100vh;
          object-fit: cover;
        }
      }
    }
  }
}

.moneyguru-wireframes-section {
  .moneyguru-wireframes-head {
    background: linear-gradient(
        180deg,
        $white 64.6%,
        rgba(255, 255, 255, 0) 128.69%
    );
    height: 135px;
  }

  .moneyguru-wireframes-head {
    background: linear-gradient(
        180deg,
        $white 64.6%,
        rgba(255, 255, 255, 0) 128.69%
    );
    height: 135px;
  }

  .moneyguru-wireframes-bottom {
    margin-left: 25px;
    position: relative;
    z-index: 9;
    height: 3150px;
  }

  .moneyguru-wireframes-top.active {
    height: 274px;
    z-index: 39;
    padding-top: 100px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
  }

  .moneyguru-wireframes-top.active::before {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 274px;
    z-index: 9;
    content: "";
    background: rgba(0, 0, 0, 0) linear-gradient(rgb(255, 255, 255) 64.6%, rgba(255, 255, 255, 0) 100%) repeat scroll 0% 0%;
    z-index: 0;
  }

  .moneyguru-wireframes-top.active:after {
    position: absolute;
    left: 0;
    bottom: -72vh;
    right: 0;
    width: 100%;
    height: 274px;
    z-index: 9;
    content: "";
    background: linear-gradient(
        180deg,
        $white 12.41%,
        rgba(255, 255, 255, 0) 100%
    );
    transform: matrix(1, 0, 0, -1, 0, 0);
    z-index: 0;
  }
}

.moneyguru-story-section {
  z-index: 39;
  position: relative;

  .moneyguru-story-left {
    background: url("../images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-story.png") no-repeat 0 0;
    width: 930px;
    height: 1000px;
    margin-top: -80px;
    margin-left: -112px;
    position: relative;

    .invest-now-item {
      height: 340px;

      .invest-now-img {
        width: 100%;
        margin: auto;
      }

      h5 {
        color: #000000;
        margin-bottom: 10px;
      }

      p {
        color: #767676;
        max-width: 200px;
        margin: auto;
      }
    }

    .moneyguru-story-left-inner {
      .carousel-indicators {
        bottom: -70px;

        li {
          box-sizing: content-box;
          -ms-flex: 0 1 auto;
          flex: 0 1 auto;
          margin-right: 3px;
          margin-left: 3px;
          text-indent: -999px;
          cursor: pointer;
          transition: opacity 0.6s ease;
          width: 8px;
          height: 8px;
          background: #dadada;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          border: 0px;
          opacity: 1 !important;
        }

        li.active {
          background: #ffaf01 !important;
        }
      }

      position: absolute;
      top: 200px;
      left: 420px;
      width: 250px;
      text-align: center;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
    }
  }

  .moneyguru-story-right {
    max-width: 500px;
    margin-top: 0;
  }
}

.moneyguru-insights-section {
  .moneyguru-insights-right-col {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding-right: 0;

    .moneyguru-insights-right {
      height: 900px;

      video {
        float: right;
      }
    }
  }

  .moneyguru-insights-left {
    max-width: 630px;
  }
}

.moneyguru-investments-section {
  .moneyguru-investments-left {
    height: 900px;
  }

  .moneyguru-investments-left-col {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    video {
      margin-left: 150px;
    }
  }

  .moneyguru-investments-right {
    max-width: 550px;
  }
}

.moneyguru-investing-section {
  .moneyguru-investing-left-col {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    padding-left: 0;

    .moneyguru-investing-left {
      height: 900px;
    }

    video {
      margin-left: 60px;
    }
  }

  .moneyguru-investing-right {
    float: right;
    margin-right: 50px;
  }
}

.moneyguru-gateway-section {
  .moneyguru-gateway-right {
    height: 900px;
  }
}

.moneyguru-design-section {
  background: url("../images/portfolio/portfolio-mobile-app-moneyguru/moneyguru-design-bg.jpg") no-repeat center 0;
  background-size: cover;

  &::after {
    background: rgba(55, 55, 55, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    content: "";
  }

  .moneyguru-investing-top {
    position: relative;
    z-index: 9;
  }
}

.moneyguru-design-list-inline {
  margin-top: 40px;

  li {
    &:nth-child(2) {
      position: relative;
      margin-left: 15px;
      margin-right: 15px;

      &::before {
        content: "";
        position: relative;
        width: 8px;
        height: 8px;
        background: #c4c4c4;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        display: inline-block;
        top: -4px;
        left: -13px;
      }

      &::after {
        content: "";
        position: relative;
        width: 8px;
        height: 8px;
        background: #c4c4c4;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        display: inline-block;
        top: -4px;
        right: -12px;
      }
    }
  }
}

.moneyguru-comprehensive-section {
  .moneyguru-comprehensive-top {
    .portfolio-info-wrap2 {
      max-width: 900px;
      margin: auto;
    }
  }

  .moneyguru-comprehensive-bottom {
    margin-top: 20px;
    text-align: center;

    video {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: auto;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
    }
  }
}

.moneyguru-illustrations-icon {
  & + h6 {
    color: #bfc2cb;
    text-align: center;
  }

  .moneyguru-illustrations-icon {
    height: 120px;
  }

  video {
    width: 100%;
    margin: 0 auto 8px;
    height: 120px;
    display: block;
  }
}

.moneyguru-impact-section {
}

// mytrux-logo portfolio
.mytrux-logo-sec1 {
  padding-bottom: 0;
  background: $white url("../images/portfolio/portfolio-branding-mytrux/mytrux-logo-bg.png") no-repeat center;
  background-size: cover;

  .mytrux-logo-img {
    margin-bottom: 65px;
  }
}

.mytrux-logo-sec2 {
  .mytrux-website-sec9-4 {
    margin-top: 145px;
  }
}

.mytrux-logo-sec3 {
  .mytrux-logo-left-wrap {
    max-width: 500px;
  }

  .portfolio-info-wrap {
    position: relative;

    .mytrux-logo-sec3-2 {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .mytrux-logo-right-wrap {
    margin-right: 68px;
    left: 30px;
    height: 610px;

    .mytrux-logo-sec3-1 {
      position: relative;
      z-index: 3;
      margin-top: 10px;
      margin-left: -3px;
    }

    .mytrux-logo-sec3-2 {
      position: absolute;
      top: -2px;
      right: -7px;
      z-index: 2;
    }
  }
}

.mytrux-logo-sec4 {
  .h44 {
    margin-bottom: 90px;
  }

  .mytrux-logo-sec4-right {
    margin-left: -16px;

    img {
      height: calc(100vh - 50px);
    }
  }

  .mytrux-logo-left-wrap {
    .media {
      margin-left: 50px;
      height: 130px;

      .align-self-center {
        position: relative;

        .mytrux-logo-sec4-1 {
          position: relative;
        }

        .mytrux-logo-sec4-2 {
          position: absolute;
          max-width: initial;
          top: 32px;
          left: 40px;
        }

        .mytrux-logo-sec4-3 {
          position: absolute;
          max-width: initial;
          top: 32px;
          left: 40px;
        }

        .mytrux-logo-sec4-5 {
          position: absolute;
          max-width: initial;
          bottom: -27px;
          left: 60px;
        }

        .p4 {
          margin-left: 30px;
        }
      }
    }
  }

  .mytrux-logo-sec4-6-outer {
    height: 250px;
  }
}

.mytrux-logo-sec5 {
  .mytrux-logo-sec5-right {
    text-align: center;
  }
}

.mytrux-logo-sec6 {
  text-align: center;

  .h44 {
    margin-bottom: 70px;
  }

  .mytrux-logo-sec6-1 {
    animation: rotation-ani 15s infinite linear;
    -webkit-animation: rotation-ani 15s infinite linear;
  }

  .mytrux-logo-sec6-2 {
    animation: rotation-ani 15s infinite linear 0.4s;
    -webkit-animation: rotation-ani 15s infinite linear 0.4s;
  }

  .mytrux-logo-sec6-inner {
    text-align: center;
    height: 440px;

    h5 {
      color: $white;

      top: 50%;
      position: absolute;
      transform: translateY(-50%);
      text-align: center;
      width: 100%;
      z-index: 9;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
    }
  }
}

.mytrux-logo-sec7 {
  text-align: center;

  .mytrux-logo-sec7-1-outer {
    height: 170px;
  }

  .mytrux-logo-sec7-2-outer {
    height: 300px;
  }

  .h44 {
    margin-bottom: 120px;
  }
}

.mytrux-logo-sec7 {
  #comparison {
    background: #0f2541;

    figure {
      position: absolute;
      background: url("../images/portfolio/portfolio-branding-mytrux/mytrux-logo-sec7-2.svg") 460px center no-repeat;
      background-size: 400px auto;
    }

    #divisor {
      &::after {
        background-color: $white;
      }

      background: $white url("../images/portfolio/portfolio-branding-mytrux/mytrux-logo-sec7-1.svg") 460px center no-repeat;
      background-size: 400px auto;
    }
  }
}

.fligital-logo-sec {
  #comparison {
    background: #0f2541;

    figure {
      position: absolute;
      background: url("../images/portfolio/portfolio-branding-mytrux/mytrux-logo-sec7-2.svg") 460px center no-repeat;
      background-size: 400px auto;
    }

    #divisor {
      &::after {
        background-color: $white;
      }

      background: $white url("../images/portfolio/portfolio-branding-mytrux/mytrux-logo-sec7-1.svg") 460px center no-repeat;
      background-size: 400px auto;
    }
  }
}

.fligital-logo-sec7{
  #comparison {
    background: #0f2541;

    figure {
      position: absolute;
      background: url("../images/portfolio/portfolio-fligital/Logo.png") 460px center no-repeat;
      background-size: 620px auto;
    }

    #divisor {
      &::after {
        background-color: $white;
      }

      background: $white url("../images/portfolio/portfolio-fligital/Logo.png") 460px center no-repeat;
      background-size: 630px auto;
    }
  }
}

.fligital-website-sect7-hover {
	position: absolute;
	z-index: 5;
	top: 27px; 
	left: 50%;
	bottom: 0;
	width: 766px;
	height: 480px; 
	background: url("../images/portfolio/portfolio-fligital/H2.png") no-repeat 0 0;
	background-size: auto;
	background-size: cover;
	transition: background-position 30s linear;
	-webkit-transition: background-position 30s linear;
	-moz-transition: background-position 30s linear;
	-ms-transition: background-position 30s linear;
	-o-transition: background-position 30s linear;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	margin-left: 4px;
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-webkit-border-radius:10px ;
	-moz-border-radius:10px ;
	-ms-border-radius:10px ;
	-o-border-radius:10px ;
}
.fligital-website-sect7-hover:hover {
	background-position-y: 100%;
}


.mytrux-webapp-sec7-1-outer {
	margin-bottom: -53px;
}

.lg-mt-60{
  @include tabdesktop{
    margin-top: -60px;
  } 
}
.color-palette-background-adj{
  top: 120px;
  position: relative;
  @include xs{
    display: none;
  }
}
.mytrux-logo-sec8 {
  text-align: center;

  .mytrux-logo-sec8-1-outer {
    height: 206px;
    margin-left: -100px;
  }

  .mytrux-logo-sec8-2-outer {
    height: 136px;
  }
}

.mytrux-logo-sec9 {
  text-align: center;

  .portfolio-info-wrap2 {
    max-width: 650px;
    margin: auto;

    h3 {
      margin-bottom: 220px;
    }
  }

  h3 {
    margin-bottom: 220px;
  }

  .p4 {
    color: $light-grey-c;
    text-align: center;
    margin-top: 180px;
  }

  .mytrux-logo-sec9-1-outer {
    height: 60px;
  }

  .mytrux-logo-sec9-2-outer {
    height: 60px;
  }
}

.mytrux-logo-sec10 {
  text-align: center;

  h3 {
    margin-bottom: 140px;
  }

  .app-icon-ul {
    height: 280px;

    li {
      display: inline-block;
      margin: 0px 26px;

      .mytrux-logo-sec10-1 {
        margin-top: -50px;
      }

      &:nth-child(3) {
        top: -8px;
        position: relative;
      }

      &:nth-child(4) {
        top: -8px;
        position: relative;
      }

      &:nth-child(5) {
        top: -8px;
        position: relative;
      }
    }
  }
}

.mytrux-logo-sec11 {
  overflow: hidden;

  .mytrux-logo-right-wrap {
  }

  .mytrux-logo-left-wrap {
    max-width: 500px;
  }

  .mytrux-logo-right-wrap {
    position: relative;

    .mytrux-logo-sec11-1 {
      position: relative;
      margin: auto;
      display: block;
      z-index: 4;
      top: 190px;
    }

    .mytrux-logo-sec11-2 {
      position: absolute;
      bottom: -360px;
      max-width: initial;
      left: -150px;
      z-index: 2;
      animation: rotation-ani 35s infinite linear;
      -webkit-animation: rotation-ani 35s infinite linear;
    }
  }
}

.mytrux-logo-sec12 {
  text-align: center;
}

.mytrux-logo-sec12-1-outer {
  height: 708px;
}

.mytrux-logo-sec13 {
  text-align: center;
  background: #172537;
}

.mytrux-logo-sec13-1-wrap {
  height: 738px;
}

.mytrux-logo-sec14 {
  text-align: center;
  background: #102035;
  position: relative;
  overflow: hidden;

  .mytrux-logo-sec14-inner-wrap {
    height: 433px;
  }

  .mytrux-logo-sec14-1 {
    position: relative;
    z-index: 3;
  }

  .mytrux-logo-sec14-2 {
    position: absolute;
    bottom: -480px;
    left: -300px;
    z-index: 2;
  }
}

.mytrux-logo-sec15 {
  text-align: center;
  background: $white url("../images/portfolio/portfolio-branding-mytrux/mytrux-logo-sec15-bg.jpg") no-repeat center;
  background-size: cover;

  .h44 {
    color: #dd0b51;
  }

  .mytrux-logo-center-wrap {
    max-width: 750px;
    margin: auto;
  }
}

.mytrux-logo-impact-section {
  .h44 {
    color: $black;

    margin-bottom: 0;
  }

  .comman-impact-top {
    hr {
      border-top: 1px solid rgba($light-dark-c, 0.2);
      margin: 45px auto 0px;
    }
  }

  .comman-impact-bottom {
    margin: 50px auto 0;
  }
}

// mytrux-website portfolio

.mytrux-website-sec1 {
  padding-bottom: 0;

  .container {
    overflow: hidden;
  }

  background-size: contain;

  .mytrux-website-center-wrap {
    h2 {
      margin-bottom: 20px;
    }
  }

  .mytrux-website-bottom-wrap {
    height: 1150px;

    .mytrux-banner-img {
      max-width: initial;
      margin: -110px auto 0 -190px;
      display: block;
    }
  }
}

.mytrux-website-sec2 {
  background: #0f2541;

  .portfolio-info-wrap {
    max-width: 500px;
  }

  .mytrux-website-bottom-right-wrap {
    ul {
      margin-top: 40px;
      white-space: nowrap;

      li {
        width: 32%;

        .business-challenges-img {
          animation: rotation-ani2 15s infinite linear;
          -webkit-animation: rotation-ani2 15s infinite linear;
        }

        &:first-child {
          position: relative;
          left: 22px;

          .business-challenges-img {
            animation: rotation-ani 15s infinite linear;
            -webkit-animation: rotation-ani 15s infinite linear;
          }
        }

        &:last-child {
          position: relative;
          right: 22px;

          .business-challenges-img {
            animation: rotation-ani 15s infinite linear;
            -webkit-animation: rotation-ani 15s infinite linear;
          }
        }

        .mytrux-busi {
          position: relative;
          text-align: center;
          min-height: 190px;

          .mytrux-banner-img {
            position: relative;
            z-index: 3;
            top: 36px;
          }

          .business-challenges-img {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
          }
        }

        p {
          text-align: center;
          color: $white;
        }
      }
    }
  }
}

.mytrux-website-sec3 {
  background: #0f2541 url("../images/portfolio/portfolio-branding-mytrux/mytrux-website-sec3-bg.jpg") no-repeat center;
  background-size: cover;

  .portfolio-info-wrap {
    margin-top: 130px;
  }
}

.mytrux-website-sec3-1 {
  .mytrux-website-center-wrap {
    .p4 {
      max-width: 1000px;
    }

    h5 {
      margin-top: 36px;
    }
  }

  ul {
    margin-top: 18px;

    li {
      margin-bottom: 12px;
      padding-left: 10px;

      &:after {
        display: block;
        clear: both;
        content: "";
      }

      .circle-ani {
        float: left;
        margin-right: 28px;
        margin-top: 20px;
      }

      .li-content {
        float: left;
        margin: 0;
        color: $light-grey-c !important;
        width: calc(100% - 50px);
      }
    }
  }
}

.mytrux-website-sec4 {
  text-align: center;

  .mytrux-website-center-wrap {
    margin-bottom: 50px;
  }

  .mytrux-website-sec4-1 {
    max-width: initial;
    margin-left: 140px;
  }
}

.mytrux-website-sec5 {
  text-align: center;
}

.mytrux-website-sec5-1-outer {
  height: 438px;
}

.mytrux-website-sec6 {
  .mytrux-website-left-wrap {
    max-width: 500px;
    margin: 0 auto 68px;
  }

  .mytrux-website-sec6-1-outer {
    height: 808px;
  }

  .mytrux-website-sec6-2-outer {
    height: 1400px;
  }
}

.mytrux-website-sec7 {
  text-align: center;

  .mytrux-website-center-wrap {
    max-width: 504px;
    margin: auto;
  }

  .mytrux-website-sec7-1-outer {
    height: 3100px;
  }
}

.mytrux-website-sec9 {
  position: relative;
  overflow: hidden;

  .container {
    position: relative;
    height: 2750px;
    display: block;
  }

  .mytrux-website-sec9-1 {
    max-width: initial;
    margin-left: -395px;
    margin-top: 70px;
    position: relative;
    z-index: 5;
  }

  .mytrux-website-sec9-1-outer {
    height: 1370px;
    z-index: 5;
    position: relative;
  }

  .portfolio-info-wrap {
    max-width: 510px;
    margin: auto;
  }

  .mytrux-website-right-wrap {
    margin-top: 215px;
    margin-left: 56px;
  }

  .mytrux-website-sec9-4 {
    max-width: initial;
    position: absolute;
    margin-top: 240px;
    margin-left: -84px;
    z-index: 3;
  }

  .mytrux-website-left-wrap {
    margin-top: 50px;
    margin-right: 24px;
  }

  .mytrux-website-sec9-2 {
    max-width: initial;
    margin-top: -330px;
    margin-left: 50px;
  }

  .mytrux-website-sec9-2-outer {
    height: 670px;
  }

  .mytrux-website-sec9-3 {
    max-width: initial;
    margin-top: -200px;
    margin-left: -393px;
  }

  .mytrux-website-sec9-3-outer {
    height: 470px;
  }

  .mytrux-website-right-wrap3 {
    margin-top: 78px;

    margin-left: 56px;
  }
}

.mytrux-website-sec10 {
  text-align: center;

  .h44 {
    color: #dd0b51;
  }

  .mytrux-website-top-wrap {
    position: relative;
    height: 970px;

    .mytrux-website-sec10-1 {
      position: relative;
      z-index: 5;
    }

    .mytrux-website-sec10-1-1 {
      position: absolute;
      top: 0px;
      right: 207px;
      z-index: 6;
    }

    .mytrux-website-sec10-2 {
      position: absolute;
      top: 104px;
      z-index: 4;
      left: 287px;
      margin-top: 4px;
      animation: rotation-ani 15s infinite linear;
      -webkit-animation: rotation-ani 15s infinite linear;
    }

    .mytrux-website-sec10-3 {
      position: absolute;
      top: 60px;
      left: 220px;
    }
  }

  .mytrux-website-center-wrap {
    max-width: 750px;
    margin: 150px auto 0;
  }
}

.mytrux-impact-section {
  .h44 {
    color: $black;

    margin-bottom: 0;
  }

  .comman-impact-top {
    hr {
      border-top: 1px solid rgba($light-dark-c, 0.2);
      margin: 45px auto 0px;
    }
  }

  .comman-impact-bottom {
    margin: 50px auto 0;
  }
}

// rsmart portfolio
.rsmart-sect1 {
  position: relative;
  overflow: hidden;

  .rsmart-overview-after-img {
    position: absolute;
    bottom: -160px;
    max-width: inherit;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
  }

  .rsmart-overview-before-img {
    position: absolute;
    top: -10px;
    max-width: inherit;
    margin-left: -90px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
  }
}

.rsmart-sect2 {
  overflow: hidden;
  position: relative;

  .rsmart-overview-after-img {
    position: absolute;
    top: -56px;
    max-width: inherit;
    left: -120px;
  }

  .rsmart-overview-before-img {
    position: absolute;
    bottom: 118px;
    max-width: inherit;
    margin-left: 0;
    right: -140px;
  }

  .rsmart-left-wrap {
    max-width: 500px;
  }

  .rsmart-right-wrap {
    max-width: 500px;
    float: right;
  }
}

.rsmart-sect3 {
  .rsmart-challenges-after {
    position: absolute;
    top: 60px;
    max-width: inherit;
    left: 0;
  }

  .rsmart-challenges-before {
    position: absolute;
    top: -50px;
    max-width: inherit;
    right: 230px;
  }
}

// portfolio-info-wrap

.rsmart-sect4 {
  text-align: center;

  .rsmart-center-wrap {
    text-align: center;

    p {
      max-width: 703px;
      margin: 30px auto;
    }
  }

  .viewer,
  .ng-draggable {
    height: 240px;
  }
}

.rsmart-sec5 {
  padding-bottom: 0;
  position: relative;

  .rsmart-architecture-wrap {
  }

  .rsmart-center-wrap {
    text-align: center;
    max-width: 800px;
    margin: 0 auto 44px;
  }

  .rsmart-sec5-after {
    position: absolute;
    top: -60px;
    max-width: inherit;
    left: 0;
  }

  .rsmart-challenges-before {
    position: absolute;
    top: -50px;
    max-width: inherit;
    right: 230px;
  }
}

.rsmart-sect6 {
  .portfolio-info-wrap2 {
    max-width: 540px;
  }

  .rsmart-sect6-2 {
    display: inline-block;
    max-width: inherit;
    height: calc(100vh - 200px);
    margin-left: 0;
  }

  .rsmart-sec5-after {
    position: absolute;
    top: -60px;
    max-width: inherit;
    left: 0;
  }

  .rsmart-challenges-before {
    position: absolute;
    top: -50px;
    max-width: inherit;
    right: 230px;
  }
}

.rsmart-sect7 {
  .rsmart-sect7-after {
    position: absolute;
    bottom: 0;
    max-width: inherit;
    left: 50%;
    transform: translateX(-50%);
    margin-left: 120px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
  }

  .portfolio-info-wrap2 {
    max-width: 430px;
    margin-top: -80px;
  }

  .rsmart-strategy-img {
    display: inline-block;
    height: 100%;
    width: 420px;
  }
}

.rsmart-sect8 {
  .portfolio-info-wrap2 {
    max-width: 970px;
    margin: auto;
  }

  .rsmart-app-view1-img {
    display: block;
    width: 300px;
    float: right;
    margin-top: 27px;
    margin-right: 20px;
  }

  .rsmart-app-view2-img {
    display: block;
    width: 300px;
    float: left;
    margin-top: 20px;
    margin-left: 20px;
  }
}

// xts portfolio

.xts-sec1 {
  padding-bottom: 0;
  overflow: hidden;

  .xts-left-wrap {
    h2 {
      // font-family: $font-family-poppins !important;
    }
  }

  .xts-right-wrap {
    .img-fluid {
      max-width: inherit;
      position: absolute;
      top: 0;
      right: -250px;
    }
  }
}

.xts-sec2 {
  .xts-left-wrap {
    max-width: 500px;
  }

  .xts-right-wrap {
    max-width: 500px;
    float: right;
  }
}

.xts-sec3 {
  background-color: #000;

  .xts-left-wrap {
    max-width: 500px;
  }

  .xts-right-wrap {
    max-width: 500px;
    float: right;

    .p4 {
      color: $light-dark-c;
    }

    ul {
      li {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 20px;
          left: -26px;
          width: 12px;
          height: 2px;
          background: #f74d09;
        }

        &:not(:last-child) {
          margin-bottom: 45px;
        }
      }
    }
  }
}

.xts-sec4 {
  background: $black;

  .comman-card-info {
    max-width: 520px;

    h3 {
      margin-bottom: 20px;
    }

    .p4 {
      color: $light-grey-c;
    }

    .comman-card-footer {
      margin-top: 80px;

      .img-fluid {
        margin-top: 20px;
      }
    }
  }

  .xts-right-wrap {
    img.xts-sec4-1 {
      position: relative;
      z-index: 2;
      height: 704px;
    }
  }
}

.xts-sec6 {
  position: relative;
  overflow: hidden;

  .xts-color-app1 {
    margin-left: -310px;
  }

  .xts-color-app-wrap {
    position: relative;

    .xts-color-app2 {
      margin-top: 80px;
      max-width: inherit;
      margin-left: -88px;
      z-index: 5;
      position: relative;
    }

    .xts-color-app-bg {
      position: absolute;
      bottom: 0;
      max-width: inherit;
      right: -520px;
      top: -520px;
      z-index: 3;
    }
  }
}

.slick-carousel-action {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -30px;
  width: 25px;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);

  span {
    display: block;
    text-align: center;
  }

  button {
    display: block;
    border: 0;
    background: transparent;
    outline: none;
    width: 100%;
  }
}

.xts-sec7 {
  position: relative;

  .xts-type-app-wrap {
    position: relative;

    .xts-type-app2 {
      position: absolute;
      top: 50%;

      transform: translateY(-50%);
      left: 0;
    }
  }
}

/*
// font family
.portfolio-insisive-body,
.portfolio-vigan-body,
.portfolio-alloch-body,
.portfolio-notion-edge-body,
.portfolio-smartlinks-body,
.portfolio-authntick-body,
.portfolio-branding-mytrux-body,
.portfolio-mytrux-website-body,
.portfolio-easy-plan-body,
.portfolio-moneyguru-body,
.portfolio-rsmart-body {
  .portfolio-info-wrap {
    h2,
    h3,
    h4,
    .h44 {
      font-family: $font-family-poppins;
    }
    .h44 {
      font-weight: $font-weight-bold  !important;;
    }
    p {
      font-family: $font-family-lora;
    }
  }
}
*/

.range-slider-main {
  margin-top: 25px;
  margin-left: -3px;

  .slider.slider-vertical {
    height: 370px;
    width: 20px;
  }

  .slider-track {
    position: absolute;
    cursor: pointer;
    background-image: none;
    border-right: 2px solid $black;
    filter: none;
    opacity: 1;
    background-repeat: repeat-x;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    width: 7px !important;

    & > div {
      border-radius: 0;
    }
  }

  .slider-selection.tick-slider-selection {
    background-image: none;
    filter: none;
    border-right: 0;
    background-repeat: repeat-x;
    background: #dfdfdf;
    width: 3px;
    margin-left: 5px;
  }

  .slider-handle {
    background: transparent url("../images/svg/range-handle.svg") no-repeat 0 0 !important;
    background-size: auto;
    width: 75px;
    height: 75px;
    margin-left: -26px;
    box-shadow: none !important;
    margin-top: -30px !important;
  }

  .slider-tick.round {
    display: none;
  }

  .slider-tick-label {
    padding-left: 4px;
    color: $black;
    margin-left: 35px !important;
    cursor: pointer;
    height: 72px;
  }

  .slider-tick-label-container {
    margin-top: -7px !important;
    user-select: none;
    height: 100%;
  }

  .slider.slider-vertical .slider-track .slider-selection {
    background-image: none;
    filter: none;
    border-right: 0;
    background-repeat: repeat-x;
    background: #dfdfdf;
    width: 3px;
    margin-left: 5px;
  }
}

.authntick-sec1 {
  position: relative;
  overflow: hidden;

  .authntick-fingerprint1 {
    position: absolute;
    bottom: -310px;
    left: 16px;
  }

  .authntick-fingerprint2 {
    position: absolute;
    top: -300px;
    left: 718px;
  }

  .authntick-logo {
    display: inline-block;
    margin-left: -10px;
    margin-top: -8px;
  }

  h1 {
    color: $white;
  }

  p.p4 {
    color: $white;
    margin-left: 25px;
  }

  .authntick-right-wrap {
    img {
      display: inline-block;
      max-width: inherit;
      margin-left: 258px;
      margin-top: 20px;
    }
  }
}

.authntick-sec2 {
  overflow: hidden;

  .authntick-right-wrap {
    max-width: 500px;
    float: right;
  }

  .authntick-sec2-1 {
    position: absolute;
    bottom: -194px;
    left: -20px;
  }
}

.authntick-sec3 {
  overflow: hidden;
  position: relative;

  .authntick-sec3-bg {
    background: url("../images/portfolio/portfolio-webapp-authntick/authntick-sec3-bg.svg") no-repeat center 0;
    background-size: auto;
    background-size: auto;
    width: 100%;
    height: 2337px;
    position: absolute;
    top: -750px;
    -webkit-animation: mover 90s infinite alternate;
    animation: mover 90s infinite alternate;
    z-index: 3;
  }

  .container {
    position: relative;
    z-index: 5;
  }
}

.authntick-sec4 {
  position: relative;
  overflow: hidden;

  .authntick-left-wrap {
    max-width: 464px;
  }

  .authntick-sec4-3 {
    position: absolute;
    top: 36px;
    left: -82px;
  }

  .authntick-sec4-2-outer {
    .authntick-sec4-2 {
      display: inline-block;
      position: absolute;
      max-width: inherit;
      left: -90px;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 40px;
    }
  }
}

.authntick-sec5 {
  position: relative;
  overflow: hidden;
  background: #d71538 url("../images/portfolio/portfolio-webapp-authntick/authntick-sec5-bg.jpg") no-repeat center 0;
  background-size: cover;
}

.authntick-sect5 {
  background: #d71538;
  text-align: center;

  .authntick-sect5-laptop-wrap {
    img {
      height: 500px;
      width: auto;
    }
  }

  .authntick-sect5-overlay-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    -webkit-transform: translate(-50%, -50%) !important;
    -moz-transform: translate(-50%, -50%) !important;
    -ms-transform: translate(-50%, -50%) !important;
    -o-transform: translate(-50%, -50%) !important;
    margin-top: -10px;
    z-index: 9;
    margin-left: -310px;

    img {
      width: 300px;
    }
  }
}

.authntick-sec6 {
  .portfolio-info-wrap2 {
    max-width: 480px;
  }

  .authntick-sec6-1 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 160px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    z-index: 1;
  }

  .authntick-sec6-2 {
    max-width: inherit;
    position: relative;
    z-index: 3;
  }

  .authntick-left-wrap {
    p {
      max-width: 464px;
    }
  }
}

.authntick-sec7 {
  .authntick-left-wrap {
    float: right;
  }

  .portfolio-info-wrap {
    p {
      max-width: 464px;
    }
  }

  .ani-wrap50-3 {
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    bottom: 0;
    height: 100%;
  }

  [data-aos="new-animation5"].aos-animate {
    width: 29%;
  }

  .ui-card-slider {
    position: relative;
    width: 320px;
    height: 230px;
    overflow: hidden;
    float: right;
    margin-right: 120px;
    margin-top: 14px;
    transform: scale(2.5) translateZ(0);
    transform-origin: center center;
    -webkit-transform: scale(2.5) translateZ(0);
    -moz-transform: scale(2.5) translateZ(0);
    -ms-transform: scale(2.5) translateZ(0);
    -o-transform: scale(2.5) translateZ(0);

    img {
      filter: none;
      -webkit-filter: none;
      -moz-filter: blur(0px);
      -ms-filter: blur(0px);
      -webkit-backface-visibility: hidden;
      cursor: pointer;
    }
  }
}

.authntick-sec8 {
  position: relative;
  overflow: hidden;

  .portfolio-info-wrap {
    p {
      max-width: 464px;
    }
  }

  .authntick-sec8-2-outer {
    .authntick-wizard-carosual {
      width: 804px;
      position: relative;
      margin-top: 115px;
      margin-left: 150px;
    }
  }
}

.authntick-sec9 {
  position: relative;
  text-align: center;
  overflow: hidden;
  padding-bottom: 0 !important;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 140px;
    background: $red;
    z-index: 0;
  }

  .authntick-center-wrap {
    max-width: 705px;
  }

  .container-fluid {
    padding: 0;
  }

  .portfolio-info-wrap {
    margin-bottom: 40px;
  }

  .authntick-result-carosual {
    .owl-item {
      img {
        width: 90%;
        margin: 50px auto 0px;
      }
    }

    .owl-item.active.center {
      img {
        width: 100%;
        margin: 0 auto;
      }
    }
  }
}

.portfolio-smartlinks-body {
  h4 {
    font-weight: $font-weight-bold !important;
  }
}

.smartlinks-sec1 {
  position: relative;
  overflow: hidden;

  .smartlinks-logo-after {
    position: absolute;
    bottom: -40px;
    right: -480px;
    z-index: 1;
  }

  .smartlinks-logo {
    margin-bottom: 65px;
  }
}

.bagshaha-sect2 {
  position: relative;
  overflow: hidden;

  .bagshaha-sec2-center {
  }

  .smartlinks-logo-after2 {
    position: absolute;
    bottom: -40px;
    right: -380px;
    z-index: 1;
    width: 700px;
  }
}

.smartlinks-sect2 {
  position: relative;
  overflow: hidden;

  .smartlinks-logo-after2 {
    position: absolute;
    bottom: -40px;
    right: -480px;
    z-index: 1;
  }
}

.smartlinks-sec3 {
  .smartlinks-left-wrap {
    max-width: 500px;
  }
}

.smartlinks-sect4 {
  position: relative;
  overflow: hidden;

  .smartlinks-sec4-right {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    z-index: 9;
  }
}

.smartlinks-sec5 {
  .portfolio-info-wrap {
    .smartlinks-sec5-1 {
      margin-top: 50px;
    }
  }
}

.smartlinks-sec6 {
  .smartlinks-sec6-1 {
    margin-top: 100px;
  }
}

.smartlinks-sec8 {
  .smartlinks-sec8-1 {
    margin-top: 100px;
  }
}

.smartlinks-sec7 {
  #comparison {
    figure {
      position: absolute;
      background: url("../images/portfolio/portfolio-branding-smartlink/smartlinks-sec7-1.svg") 600px center no-repeat;
      background-size: 600px auto;
    }

    #divisor {
      background: #24243e url("../images/portfolio/portfolio-branding-smartlink/smartlinks-sec7-2.svg") 600px center no-repeat;
      background-size: 600px auto;
    }
  }
}

#comparison {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  figure {
    position: absolute;
    font-size: 0;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  #divisor {
    position: absolute;
    width: 50%;
    border-right: 1px solid $light-grey-c;
    bottom: 0;
    height: 100%;
    background-color: #24243e;
    overflow: hidden;

    &::before,
    &::after {
      content: "";
      position: absolute;
      right: -2px;
      width: 0;
      height: calc(50% - 25px);
      background: white;
      z-index: 3;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  #handle {
    position: absolute;
    height: 50px;
    width: 50px;
    top: 70%;
    left: 50%;
    cursor: col-resize;
    transform: translateY(-50%) translateX(-50%);
    z-index: 1;
    background: url("../images/portfolio/portfolio-branding-smartlink/drag.svg") no-repeat 0 0;
  }

  input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: absolute;
    top: 70%;
    left: -25px;
    transform: translateY(-50%);
    background-color: transparent;
    width: calc(100% + 50px);
    z-index: 2;
    opacity: 0;
    height: 100px;

    &:focus,
    &:active {
      border: none;
      outline: none;
    }
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: transparent;
    border: 4px solid white;
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.3);
  }
}

.smartlinks-sec9 {
  background: #27273c url("../images/portfolio/portfolio-branding-smartlink/smartlinks-sec9-1.jpg") no-repeat right center;
  background-size: contain;
}

.smartlinks-sec10 {
  background: linear-gradient(105deg, #27273c, #27273c 50%, white 50%, white);
  position: relative;
  overflow: hidden;
}

.smartlinks-sec11 {
  background: #27273c url("../images/portfolio/portfolio-branding-smartlink/smartlinks-sec11-bg.jpg") no-repeat center center;
  background-size: cover;

  .portfolio-info-wrap {
    max-width: 750px;
    margin: auto;
    text-align: center;

    h4 {
      color: #37bbfe;
    }

    p {
      color: $white;
    }
  }
}

.smartlinks-sec12 {
  h4 {
    margin-bottom: 15px;
  }

  .comman-impact-top hr {
    border-top: 1px solid rgba($color: $light-grey-c, $alpha: 0.2);
  }
}

.notion-edge-sec1 {
  .notion-edge-logo-bg {
    display: inline-block;
    position: absolute;
    bottom: -60px;
    left: -580px;
    z-index: 1;
  }
}

.notion-edge-sec2 {
  background: #181e24 url("../images/portfolio/portfolio-branding-notion-edge/notion-edge-sec2-bg.jpg") no-repeat center center;
  background-size: cover;

  .portfolio-info-wrap {
    text-align: center;
    max-width: 750px;
    margin: auto;

    h4 {
      color: #117bed;
    }

    p {
      color: $white;
    }
  }
}

.notion-edge-sec3 {
  .notion-edge-left-wrap {
    max-width: 500px;
  }

  .notion-edge-right-wrap {
    text-align: center;
  }
}

.notion-edge-sec5-1-wrap {
  position: relative;
  width: 600px;
  height: 600px;
  margin: 30px auto 0;

  .brand-color-outer {
    position: absolute;
    top: 0;
    left: 0;
  }

  .brand-color1-outer {
    top: 0;
    left: 167px;
  }

  .brand-color2-outer {
    top: 160px;
    left: 330px;
  }

  .brand-color3-outer {
    top: 322px;
    left: 167px;
  }

  .brand-color4-outer {
    top: 160px;
    left: 5px;
  }
}

.notion-edge-sec6 {
  overflow: hidden;
  position: relative;

  h4 {
    margin-bottom: 100px;
  }

  .notion-edge-sec6-1 {
    margin-top: 100px;
  }

  .notion-edge-sec6-3 {
    position: absolute;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    bottom: -40px;
  }
}

.notion-edge-sec7 {
  h4 {
    margin-bottom: 150px;
  }
}

.notion-edge-sec8 {
  background: #14181c;

  #comparison {
    figure {
      position: absolute;
      background: url("../images/portfolio/portfolio-branding-notion-edge/notion-edge-sec8-2.svg") 700px center no-repeat;
      background-size: 500px auto;
    }

    #divisor {
      background: $white url("../images/portfolio/portfolio-branding-notion-edge/notion-edge-sec8-1.svg") 700px center no-repeat;
      background-size: 500px auto;
    }
  }
}

.notion-edge-sec9 {
  background: #2e3740 url("../images/portfolio/portfolio-branding-notion-edge/notion-edge-sec10.jpg") no-repeat center center;
  background-size: contain;
}

.notion-edge-sec10 {
  background: #24292c url("../images/portfolio/portfolio-branding-notion-edge/notion-edge-sec11.jpg") no-repeat center center;
  background-size: cover;
}

.notion-edge-sec11 {
  background: $white;

  .portfolio-info-wrap {
    max-width: 750px;
    margin: auto;
    text-align: center;
  }
}

.notion-edge-sec12 {
  .comman-impact-top {
    hr {
      border-color: rgba($color: $light-grey-c, $alpha: 0.2);
    }
  }
}

.alloch-sec1 {
  position: relative;
  overflow: hidden;

  .portfolio-info-banner-l.portfolio-info-wrap2 {
    margin-top: -160px;
  }

  .alloch-right-wrap {
    text-align: center;
    margin-top: 80px;
  }

  .alloch-left-wrap {
    margin-top: -140px;
    margin-left: 36px;

    h2 {
      margin-bottom: 30px;
    }
  }

  .p1 {
    max-width: 510px;
  }

  .alloch-sec1-1 {
    margin-left: 35px;
  }

  .alloch-sec1-2 {
    position: absolute;
    top: -80px;
    left: -20px;
  }
}

.alloch-sec2 {
  background: #f7f7f7;
  position: relative;
  overflow: hidden;

  .alloch-left-wrap {
    margin-top: -80px;
    margin-left: 18px;
  }

  .alloch-sec2-1 {
    display: inline-block;
    position: relative;
    top: 103px;
    left: -8px;
    z-index: 3;
  }

  .alloch-sec2-2 {
    display: inline-block;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    width: 240px;
    float: right;
    margin-right: -60px;
    margin-top: 0px;
  }

  .alloch-sec2-3 {
    position: absolute;
    top: -670px;
    left: -367px;
    max-width: inherit;

    .alloch-sec2-3-inner {
      max-width: inherit;
    }
  }

  .alloch-right-wrap {
    position: relative;
    top: -230px;

    &::after {
      content: "";
      position: absolute;
      top: -500px;
      right: -425px;
      width: 1227px;
      height: 1227px;
      background: #5c202e;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      z-index: 2;
    }
  }
}

.alloch-sec3 {
  background: #f7f7f7;
  position: relative;
  overflow: hidden;

  .img-fluid {
    max-width: inherit;
  }

  .alloch-sec-circle-outer {
    display: inline-block;
    position: absolute;
    top: -300px;
    left: -305px;
    z-index: 1;
  }

  .alloch-sec3-1-outer {
    position: relative;
    z-index: 3;
    top: -100px;
    left: 10px;
  }

  .alloch-sec3-2-outer {
    position: relative;
    top: -100px;
    left: 90px;
  }

  .alloch-sec3-3-outer {
    position: relative;
    top: -10px;
    left: -40px;
    z-index: 3;
  }

  .alloch-sec3-4-outer {
    position: relative;
    bottom: 210px;
    right: -297px;
    z-index: 1;
  }
}

.alloch-sec4 {
  background: #f7f7f7;
  position: relative;
  overflow: hidden;

  .img-fluid {
    max-width: inherit;
  }

  .alloch-sec-circle-outer {
    display: inline-block;
    position: absolute;
    top: -200px;
    left: -10px;
    z-index: 1;
  }

  .alloch-sec4-1-outer {
    position: relative;
    z-index: 3;
    top: -6px;
    left: -26px;
  }

  .alloch-sec4-5-outer {
    position: absolute;
    bottom: -3px;
    left: -170px;
  }

  .alloch-sec4-2-outer {
    position: relative;
    top: 10px;
    left: 80px;
    z-index: 9;
  }

  .alloch-sec4-3-outer {
    position: relative;
    top: 10px;
    left: 30px;
    z-index: 3;
  }

  .alloch-sec3-4-outer {
    position: relative;
    bottom: 210px;
    right: -297px;
    z-index: 1;
  }
}

.alloch-sec5 {
  background: #f7f7f7;
  position: relative;
  overflow: hidden;

  .img-fluid {
    max-width: inherit;
  }

  .alloch-sec-circle-outer {
    display: inline-block;
    position: absolute;
    top: -320px;
    left: -320px;
    z-index: 1;
  }

  .alloch-sec5-1-outer {
    position: relative;
    z-index: 3;
    top: -126px;
    left: 50px;
  }

  .alloch-sec5-5-outer {
    position: absolute;
    bottom: -3px;
    left: -170px;
  }

  .alloch-sec5-2-outer {
    position: relative;
    top: -123px;
    left: 85px;
    z-index: 9;
  }

  .alloch-sec5-3-outer {
    position: relative;
    top: -12px;
    left: -46px;
    z-index: 9;
  }

  .alloch-sec5-4-outer {
    position: relative;
    top: -215px;
    left: 290px;
    z-index: 3;
  }
}

.alloch-sec6 {
  background: #1d1d1e url("../images/portfolio/portfolio-mobile-app-alloch/alloch-sec6-1.jpg") no-repeat center center;
  background-size: cover;
}

.alloch-sec7 {
  position: relative;
  overflow: hidden;

  .alloch-sec7-left {
    margin-top: 80px;
    margin-left: 20px;
    max-width: 500px;
  }

  .alloch-sec7-1-outer {
    text-align: right;
    margin-right: -15px;
    margin-top: 10px;
    z-index: 11;

    position: relative;
  }

  .alloch-sec7-2-outer {
    position: absolute;
    bottom: 37px;
    z-index: 9;
    left: -65px;
  }

  .alloch-sec7-3-outer {
    position: absolute;
    bottom: 147px;
    z-index: 9;
    left: -126px;
  }

  .alloch-sec7-4-outer {
    position: absolute;
    bottom: 0;
    left: 0;

    .img-fluid {
      opacity: 1;
    }
  }

  .alloch-sec7-5-outer {
    position: absolute;
    top: 90px;
    z-index: 3;
    right: -510px;
    max-width: inherit;
  }

  .alloch-sec7-right {
    position: relative;
  }
}

.alloch-sec8 {
  position: relative;
  overflow: hidden;
  background: #f6f6f6;

  .portfolio-info-wrap {
    position: relative;
    width: 900px;
    height: 700px;
    margin: auto;
    display: block;

    .alloch-sec8-1-outer {
      position: absolute;
      top: 42px;
      left: 0;
      z-index: 1;
    }

    .alloch-sec8-2-outer {
      position: absolute;
      top: 87px;
      left: 505px;
      z-index: 1;
    }

    .alloch-sec8-3-outer {
      position: absolute;
      top: 448px;
      left: 70px;
      z-index: 1;
    }

    .alloch-sec8-4-outer {
      position: absolute;
      top: 390px;
      left: 454px;
      z-index: 1;
    }
  }
}

.alloch-sec9 {
  text-align: center;
  position: relative;
  overflow: hidden;
  background: #f6f6f6;

  .h44 {
    margin-bottom: 23px;
  }

  .img-fluid {
    max-width: inherit;
  }

  .portfolio-info-wrap {
    position: relative;
  }

  .alloch-sec9-1-outer {
    position: absolute;
    top: 0px;
    left: 104px;
    z-index: 3;
  }

  .alloch-sec9-2-outer {
    position: absolute;
    top: 160px;
    left: 114px;
    z-index: 3;
  }

  .alloch-sec9-3-outer {
    position: absolute;
    top: 0px;
    left: 118px;
    z-index: 3;
  }

  .alloch-sec9-5-outer {
    text-align: right;
    margin-right: -60px;
    position: relative;
    top: 27px;
    z-index: 1;
  }

  .alloch-sec9-6-outer {
    position: relative;
    top: 175px;
    left: -27px;
    z-index: 1;
  }

  .alloch-sec9-7-outer {
    text-align: left;
    position: relative;
    left: -60px;
    top: 26px;
    z-index: 1;
  }
}

@keyframes pulse {
  to {
    opacity: 0;
    transform: scale(1.5);
  }
}

@keyframes pulse {
  to {
    opacity: 0;
    -webkit-transform: scale(1.5);
  }
}

.alloch-yellow-circle-ani,
.alloch-orange-circle-ani,
.alloch-pink-circle-ani {
  height: auto;
  position: relative;
  width: auto;
  display: inline-block;
  margin: auto;
  top: -90px;
  left: 2px;

  &::before,
  &::after {
    animation: pulse 1.3s linear infinite;
    border-radius: 9999px;
    box-sizing: border-box;
    content: " ";
    height: 172px;
    left: 2px;
    opacity: 1;
    position: absolute;
    top: 0;
    transform: scale(1);
    width: 172px;
    z-index: 39;
  }

  &::after {
    animation-delay: 0.5s;
  }

  &::before {
    animation-delay: 1s;
  }
}

.alloch-yellow-circle-ani {
  &::before,
  &::after {
    border: #ffa903 solid 1px;
  }
}

.alloch-orange-circle-ani {
  &::before,
  &::after {
    border: #fc7902 solid 1px;
  }
}

.alloch-pink-circle-ani {
  &::before,
  &::after {
    border: #fb4a63 solid 1px;
  }
}

.alloch-sec10 {
  position: relative;
  overflow: hidden;
  background: #f6f6f6;

  .img-fluid {
    max-width: inherit;
  }

  .h44 {
    position: relative;
    top: 10px;
    text-align: center;
  }

  .alloch-sec10-1-outer {
    position: relative;
    top: 0;
    text-align: center;
    left: 50px;
    z-index: 3;
  }

  .alloch-sec10-2-outer {
    top: 32px;
    position: relative;
    left: 54px;
    z-index: 3;
  }

  .alloch-sec10-3-outer {
    top: 20px;
    position: relative;
    left: 107px;
    z-index: 2;
  }

  .alloch-sec10-4-outer {
    bottom: 32px;
    position: absolute;
    right: -211px;
    z-index: 3;
  }

  .alloch-sec10-5-outer {
    bottom: 192px;
    position: absolute;
    right: -55px;
    z-index: 3;
  }

  .alloch-sec10-6-outer {
    position: absolute;
    top: -247px;
    left: -740px;
    z-index: 1;
  }
}

.alloch-sec11 {
  position: relative;
  overflow: hidden;
  background: #f6f6f6;

  .alloch-sec11-left {
  }

  .alloch-sec11-right {
    &::after {
      content: "";
      position: absolute;
      top: -510px;
      left: -30px;
      width: 1784px;
      height: 1784px;
      background: #5c202e;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      z-index: 2;
    }
  }

  .alloch-sec11-2-outer {
    position: relative;
    z-index: 1;
    top: -20px;
    left: 0;
  }

  .alloch-sec11-3-outer {
    position: relative;
    z-index: 9;
    top: 75px;
    left: 66px;
  }

  .alloch-sec11-4-outer {
    position: relative;
    z-index: 9;
    top: -73px;
    left: 66px;
  }

  .alloch-sec11-5-outer {
    position: absolute;
    top: 91px;
    right: -520px;
    z-index: 3;
  }
}

.alloch-sec12 {
  text-align: center;
  background: #5c202e;
  position: relative;
  overflow: hidden;

  .portfolio-info-wrap {
    max-width: 750px;
    margin: auto;
    position: relative;
    z-index: 3;

    .h44 {
      color: #fda903;
    }

    p.p4 {
      color: $white;
    }
  }

  .alloch-sec12-1-outer {
    position: absolute;
    top: 240px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
}

.alloch-sec13 {
  background: #5c202e;
  position: relative;
  overflow: hidden;

  .alloch-sec12-1-outer {
    position: absolute;
    top: -670px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  .comman-impact-bottom {
    z-index: 3;
    position: relative;
    margin: 0px auto 0;
  }
}

.vigan-sec1 {
  background: #25252e url("../images/portfolio/portfolio-branding-vigan/vigan-sec1-bg.jpg") no-repeat center center;
  background-size: cover;

  h1 {
    margin-top: 40px;
  }
}

.vigan-sec2 {
  position: relative;
  overflow: hidden;

  .vigan-sec2-left {
    max-width: 532px;
  }

  .vigan-left-wrap {
    max-width: 532px;

    .h44 {
      margin-bottom: 20px;
    }
  }

  .vigan-right-wrap {
    position: relative;
    right: 42px;
    top: -12px;

    .img-fluid {
      max-width: inherit;
    }
  }
}

.vigan-sec3 {
  position: relative;

  .vigan-sec3-1-outer1 {
    position: absolute;
    bottom: -10px;
    right: -60px;
    z-index: 1;
    top: auto !important;
  }

  .portfolio-info-wrap {
    max-width: 500px;
    height: 380px;
  }

  .vigan-left-wrap {
    margin-left: 13px;
  }

  .vigan-right-wrap {
    float: right;
  }
}

.vigan-sec4 {
  position: relative;
  background: url("../images/portfolio/portfolio-branding-vigan/vigan-sec4-bg.jpg") no-repeat 0 0;
  background-size: cover;

  .portfolio-info-wrap {
    max-width: 500px;

    p.p4 {
      color: $light-grey-c;
    }

    h5 {
      margin-top: 35px;
      margin-bottom: 5px;
    }
  }
}

.vigan-sec5 {
  position: relative;
  background: #040f25;
  overflow: hidden;

  .vigan-sec5-1-outer {
    text-align: center;

    .vigan-sec5-1 {
      margin: auto;
      display: block;
    }
  }

  .vigan-sec5-2-outer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    margin-top: -20px;
    -webkit-transform: translate(-50%, -50%) !important;
    -moz-transform: translate(-50%, -50%) !important;
    -ms-transform: translate(-50%, -50%) !important;
    -o-transform: translate(-50%, -50%) !important;
    margin-left: 320px;

    .img-fluid {
      max-width: inherit;
    }
  }

  .vigan-sec5-3-outer {
    margin-top: 40px;
  }

  .portfolio-info-wrap {
    max-width: 500px;
  }
}

.vigan-sec6 {
  background: #040f25 url("../images/portfolio/portfolio-branding-vigan/vigan-logo-mark-bg.png") no-repeat right -220px center;
  background-size: cover;

  .vigan-color {
    margin-top: 40px;
  }

  .vigan-right-wrap {
    width: 470px;
    margin-top: 18px;
    margin-left: 155px;
  }
}

.vigan-sec7 {
  .vigan-right-wrap {
    position: absolute;
    top: -240px;
    left: 0;

    .img-fluid {
      max-width: inherit;
    }
  }
}

.vigan-sec8 {
  background: #3062d1 url("../images/portfolio/portfolio-branding-vigan/vigan-sec8-1.jpg") no-repeat center center;
  background-size: cover;
}

.vigan-sec9 {
  background: #3062d1 url("../images/portfolio/portfolio-branding-vigan/vigan-sec9-1.jpg") no-repeat center center;
  background-size: cover;
}

.vigan-sec10 {
  background: #ccc url("../images/portfolio/portfolio-branding-vigan/vigan-sec10-1.jpg") no-repeat center center;
  background-size: cover;
}

.vigan-sec11 {
  background: #3062d1 url("../images/portfolio/portfolio-branding-vigan/vigan-sec11-1.jpg") no-repeat center center;
  background-size: cover;
}

.vigan-sec12 {
  background: #3062d1 url("../images/portfolio/portfolio-branding-vigan/vigan-sec12-1.jpg") no-repeat center center;
  background-size: cover;
}

.insisive-sec1 {
  background: #0c0e4e url("../images/portfolio/portfolio-branding-insisive/insisive-banner.jpg") no-repeat center center;
  background-size: cover;
  text-align: center;
}

.insisive-sec2 {
  position: relative;

  .insisive-left-wrap {
    max-width: 500px;
  }

  .insisive-right-wrap {
    max-width: 500px;
    float: right;
  }
}

.insisive-sec3 {
  text-align: center;
  background: #f3f3f3;

  .insisive-center-wrap {
    margin-bottom: 80px;
  }

  .insisive-idea-center-wrap {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      background: url("../images/portfolio/portfolio-branding-insisive/equalto.svg") no-repeat 0 0;
      width: 18px;
      height: 15px;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 50px;
      right: -30px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
    }

    &::before {
      content: "";
      position: absolute;
      background: url("../images/portfolio/portfolio-branding-insisive/plus.svg") no-repeat 0 0;
      width: 15px;
      height: 15px;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 50px;
      left: -20px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
    }
  }
}

.insisive-sec4 {
  text-align: center;

  .insisive-center-wrap {
    h4 {
      margin-bottom: 80px;
    }
  }
}

.insisive-sec5 {
  text-align: center;

  #comparison {
    figure {
      position: absolute;
      background: url("../images/portfolio/portfolio-branding-insisive/insisive-logo-type1.svg") 600px center no-repeat;
      background-size: 600px auto;
    }

    #divisor {
      background: #0c0e4e url("../images/portfolio/portfolio-branding-insisive/insisive-logo-type2.svg") 600px center no-repeat;
      background-size: 600px auto;
    }
  }
}

.insisive-sec6 {
  text-align: center;

  .portfolio-info-wrap {
    h4 {
      margin-bottom: 90px;
    }
  }

  .insisive-symbol-wrap {
    position: relative;
    width: 522px;
    height: 403px;
    margin: auto;

    .insisive-symbol-center {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
    }

    .insisive-symbol-left {
      position: absolute;
      top: 140px;
      left: -50px;
    }

    .insisive-symbol-right {
      position: absolute;
      top: 140px;
      right: -50px;
    }
  }
}

.insisive-sec7 {
  text-align: center;

  h4 {
    margin-bottom: 80px;
  }
}

.insisive-sec8 {
  text-align: center;

  h4 {
    margin-bottom: 80px;
  }
}

.insisive-sec9 {
  text-align: center;
  background: #f3f3f3 url("../images/portfolio/portfolio-branding-insisive/insisive-final-output.jpg") no-repeat center center;
  background-size: contain;

  h4 {
    margin-bottom: 80px;
  }
}

.insisive-sec10 {
  text-align: center;
  background: #272c32 url("../images/portfolio/portfolio-branding-insisive/insisive-paper.jpg") no-repeat center center;
  background-size: cover;
}

.insisive-sec11 {
  text-align: center;
  background: #f3f3f3 url("../images/portfolio/portfolio-branding-insisive/insisive-footer-bg.png") repeat 0 0;
  background-size: auto;

  .insisive-footer-outer {
    position: relative;

    .insisive-footer1-outer {
      position: absolute;
      top: 112px;
      left: 173px;
      z-index: 9;
    }
  }
}

.moneyguru-sec1 {
  .portfolio-info-wrap2 {
    .moneyguru-banner-img {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 500px;
      margin-top: 40px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
    }
  }
}

.moneyguru-sec2 {
  .moneyguru-sec2-left {
    position: relative;

    .moneyguru-sec2-1 {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 3;
    }
  }

  .moneyguru-sec2-right {
    max-width: 440px;
    float: right;
  }
}

.moneyguru-sec3 {
  .moneyguru-sec3-bg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    z-index: 3;
    width: 450px;
    margin-top: 110px;
    margin-left: 330px;
  }
}

.moneyguru-sec4 {
  text-align: center;

  .moneyguru-sec4-top {
    margin-bottom: 50px;
    margin-top: 80px;
  }

  .moneyguru-sec4-bottom {
    .moneyguru-sec4-img {
      height: 360px;
    }
  }
}

.moneyguru-sec12 {
  .moneyguru-sec12-left {
    max-width: 550px;
  }
}

.impact-section-upd {
  .portfolio-info-wrap2 {
    margin: auto;
  }

  .comman-impact-bottom {
    max-width: 500px;
    margin: auto;
    text-align: center;

    .fas {
      font-size: 12px;
      vertical-align: 12px;
      display: none !important;
    }

    p {
      margin-top: 10px;
      text-align: left;
    }

    .comman-impact-img {
      width: 105px;
      height: 105px;
      object-fit: cover;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      margin: 0 auto 15px;
      display: block !important;
    }
  }

  .comman-impact-list-inline {
    margin-top: 50px;

    li {
      &:first-child {
        margin-right: 120px;
      }
    }

    img.lazy {
      margin-bottom: 30px;
    }

    h3 {
      margin-bottom: 0;
    }
  }
}

.failure-section {
  background: #f4f2f3;
  position: relative;
  overflow: hidden;

  .failure-sec-left {
    max-width: 480px;
  }

  .failure-ul {
    margin-top: 60px;

    li {
      position: relative;
      color: #717171;
      padding-left: 27px;

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      &::before {
        content: "";
        position: absolute;
        top: 12px;
        left: 0;
        width: 12px;
        height: 0px;
        border: 1px solid $orange;
      }
    }
  }

  .failure-sec-right {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    z-index: 9;
    margin-top: 15px;
  }
}

.crubba-sec8 {
  position: relative;

  .crubba-sec4-bottom1 {
    position: absolute;
    top: 230px;
    left: -180px;
    z-index: 6;
    width: 300px;
  }

  .crubba-sec3-right {
    img {
      height: 100vh;
    }

    .crubba-sec3-right4 {
      position: absolute;
      top: 20px;
      right: 0;
      z-index: 3;
      height: 100vh;
    }

    .crubba-sec3-right3 {
      position: absolute;
      top: 20px;
      right: 0;
      z-index: 1;
      height: 100vh;
    }

    .crubba-sec3-right2 {
      position: absolute;
      top: 20px;
      right: 0;
      z-index: 2;
      height: 100vh;
    }
  }
}

.bagshaha-sec1 {
  .bagshaha-logo-img {
    margin-bottom: 65px;
  }
}

.bagshaha-sec2 {
  .bagshaha-sec2-center {
    text-align: center;
    max-width: 700px;
    margin: auto;
  }

  .bagshaha-sec1-1 {
    margin-top: 80px;
  }
}

.bagshaha-sec4 {
  position: relative;
  overflow: hidden;

  .bagshaha-sec4-right {
    margin-left: 85px;
    text-align: center;

    .bagshaha-sec4-right-b {
      padding-top: 100px;
      position: relative;

      img {
        position: relative;
        z-index: 5;
      }
    }
  }
}

.bagshaha-sec5 {
  .bagshaha-sec5-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100vh;

    .bagshaha-sec5-right-tbl {
      width: 100%;
      height: 100%;
      table-layout: fixed;
      border-collapse: collapse;

      td {
        width: 50%;
        height: 33.3%;
        vertical-align: top;
        position: relative;
        padding: 30px;

        p {
          position: relative;
          z-index: 10;
          color: $white;
          margin-bottom: 0;
        }
      }

      .td1 {
        text-align: right;
      }

      .td2 {
        text-align: left;

        p {
          color: #0e223c;
          position: absolute;
          bottom: 30px;
          left: 30px;
        }
      }

      .td3 {
        text-align: right;
      }

      .td4 {
        text-align: left;
      }
    }
  }
}

.bagshaha-sec6 {
  .bagshaha-sec6-3-outer {
    position: absolute;
    top: 0;
    right: -220px;

    .bagshaha-sec6-2 {
      display: inline-block;
      height: 100vh;
    }
  }
}

.bagshaha-sec10 {
  background: url("../images/portfolio/portfolio-branding-bagshaha/bagshaha-sec10-bg.jpg") no-repeat 0 0;
  background-size: cover;

  .portfolio-info-wrap {
    h3 {
      margin-top: 125px;
    }
  }
}

.bagshaha-sec11 {
  .bagshaha-sec11-2-outer {
    .bagshaha-sec11-2 {
      position: absolute;
      top: -50%;
      right: 0;
      transform: translateY(-50%);
      height: 100vh;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
    }
  }
}

.insisive-website-sec1 {
  .insisive-website-sec1-right {
    max-width: 600px;
  }

  .insisive-website-sec1-1 {
    max-width: initial;
  }
}

.insisive-website-sec3 {
  .insisive-website-sec3-3-outer {
    position: absolute;
    top: 0;
    right: -220px;

    img {
      display: inline-block;
      height: 100vh;
    }
  }
}

.insisive-website-sec4 {
  .insisive-website-sec4-bottom-img {
    max-width: 100%;
  }
}

.insisive-website-sec6 {
  .insisive-website-sec6-1-outer {
    .insisive-website-sec6-1 {
      max-width: initial;
      height: 410px;
      margin-left: 80px;
      margin-top: 10px;
    }
  }
}

.insisive-website-sec7 {
  background: #f5f7f9;

  .insisive-website-sec7-right {
    float: left;
    padding-left: 30px;
  }

  .insisive-website-sec7-1-outer {
    .insisive-website-sec7-1 {
      max-width: initial;
      height: 620px;
      margin-left: -320px;
    }
  }
}

.insisive-website-sec8 {
  background: #f5f7f9;
  position: relative;
  overflow: hidden;

  .insisive-website-sec8-right {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    z-index: 9;
  }
}

.mytrux-webapp-sec1 {
  position: relative;

  .mytrux-webapp-sec1-1 {
    display: inline-block;
    max-width: inherit;
    width: 700px;
    margin-left: -100px;
    margin-top: 30px;
  }
}

.mytrux-webapp-sec3 {
  position: relative;

  .mytrux-banner-line {
    position: absolute;
    bottom: -110px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9;
  }
}

.mytrux-webapp-sec4 {
  .mytrux-webapp-sec4-center {
    max-width: 500px;
    text-align: center;
    margin: 30px auto 0;
  }

  .mytrux-webapp-sec4-bottom {
    margin-top: 30px;

    .viewer,
    .ng-draggable {
      height: 320px;
    }
  }
}

.mytrux-webapp-sec6 {
  position: relative;
  overflow: hidden;

  .mytrux-webapp-sec6-right {
    .mytrux-web-sec6-1 {
      position: absolute;
      top: 146px;
      right: 240px;
      z-index: 3;
      height: 100vh;
      transform: scale(0.8);
      -webkit-transform: scale(0.8);
      -moz-transform: scale(0.8);
      -ms-transform: scale(0.8);
      -o-transform: scale(0.8);
      transform-origin: right center;
    }

    .mytrux-web-sec6-2 {
      position: absolute;
      top: 30px;
      right: 0;
      z-index: 2;
      height: 100vh;
      transform: scale(0.8);
      -webkit-transform: scale(0.8);
      -moz-transform: scale(0.8);
      -ms-transform: scale(0.8);
      -o-transform: scale(0.8);
      transform-origin: right center;
    }
  }
}

.mytrux-webapp-sec7 {
  position: relative;
  overflow: hidden;

  .portfolio-info-wrap2 {
    max-width: 500px;
    text-align: center;
  }

  [data-aos="new-animation5"].aos-animate {
    width: 100%;
  }
}

.mytrux-webapp-sec8 {
  position: relative;
  overflow: hidden;

  .portfolio-info-wrap2 {
    max-width: 600px;
    text-align: center;
    margin: auto;

    p {
      max-width: 500px;
      margin: auto;
    }
  }

  .mytrux-webapp-sec8-b {
    .mytrux-webapp-sec8-1 {
      .img-fluid {
        max-width: initial;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        margin-top: 60px;
      }
    }

    .mytrux-webapp-sec8-2 {
      .img-fluid {
        max-width: initial;
        position: absolute;
        bottom: 0;
        z-index: 9;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        max-width: 100%;
      }
    }

    .mytrux-webapp-sec8-3 {
      .img-fluid {
        max-width: initial;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
      }
    }
  }
}

.term-section1 {
  a {
    text-decoration: underline;
    color: #e6870e !important;

    &:hover {
      color: #e6870e;
    }
  }

  .term-card-wrap-outer {
    margin-top: 110px;
    max-width: 1120px;

    .term-card-wrap1 {
      margin-bottom: 85px;

      h1 {
        color: #000000;
        margin-bottom: 30px;
      }

      h6 {
        color: $black;
        text-transform: lowercase;

        &::first-letter {
          text-transform: uppercase;
        }

        margin-bottom: 30px;
      }

      .p4 {
        color: $light-dark-c;
      }

      span {
        font-weight: $font-weight-semibold;
      }

      a {
        text-decoration: underline;
        color: $light-grey-c;
        transition: all 0.2s linear;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -ms-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;

        &:hover {
          color: #e6870e;
        }
      }
    }
  }

  .term-card-wrap-inner {
    &:not(:last-child) {
      margin-bottom: 90px;
    }

    .h4 {
      margin-bottom: 20px;

      span {
        text-transform: lowercase;
        display: inline-block;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }

    span {
    }

    a {
      text-decoration: underline;
      color: #e6870e !important;

      &:hover {
        color: #e6870e;
      }
    }

    .ol-list {
      padding-left: 30px;
      color: $light-grey-c;

      li {
        position: relative;
        color: $light-dark-c;

        &::before {
          content: "";
          position: absolute;
          width: 12px;
          height: 0px;
          left: -32px;
          top: 15px;
          border: 1px solid $orange;
        }

        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }

      .ol-list {
        padding-left: 26px;
        margin-top: 38px;

        li {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            width: 7px;
            height: 7px;
            left: -23px;
            top: 15px;
            border: none;
            background: $light-dark-c;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
          }

          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }

        .ol-list {
          padding-left: 26px;
          margin-top: 38px;
          list-style: decimal !important;

          li {
            position: relative;
            list-style: decimal !important;
            padding-left: 6px;

            &::before {
              display: none;
            }

            &:not(:last-child) {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}

.work-sec2 {
  .vh100-100 {
    > div {
      margin-top: 120px;
    }
  }
}

.about-sec1 {
  .comman-card-info {
    p.sec-para {
      max-width: inherit !important;
    }
  }
}

.my-budget-wrap-col-xs {
  margin-bottom: 0;

  .slider-handle {
    background: transparent url("../images/svg/range-handle-xs.svg") no-repeat 0 0 !important;
    background-size: auto;
    width: 65px;
    height: 60px;
    margin-left: -35px !important;
    box-shadow: none !important;
    margin-top: -22px !important;
  }

  .slider-tick-container,
  .slider-tick-label-container {
    display: none !important;
  }

  .my-budget-text-xs {
    font-style: normal;
  }

  .slider-track {
    background: #dfdfdf;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    height: 2px !important;
  }

  .slider-selection {
    background-image: none;
    filter: none;
    border-right: 0;
    background-repeat: repeat-x;
    background: $black;
    box-shadow: none;
    border-radius: 0;
  }

  .slider.slider-horizontal {
    width: 100%;
    margin: 0;

    .tooltip.tooltip-main.top.hide {
      display: none !important;
    }
  }
}

.alloch-sec17 {
  position: relative;
  overflow: hidden;
  background-color: #f7f7f7;

  .alloch-sec3-4-outer {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
  }

  .alloch-sec7-right.portfolio-info-wrap {
    table {
      position: absolute;
      left: 0;
      top: 0;
      transform: scale(0.8, 0.8) translateY(-50%);
      transform-origin: 0 0;
      -webkit-transform: scale(0.8, 0.8) translateY(-50%);
      -moz-transform: scale(0.8, 0.8) translateY(-50%);
      -ms-transform: scale(0.8, 0.8) translateY(-50%);
      -o-transform: scale(0.8, 0.8) translateY(-50%);
      z-index: 9;
    }
  }
}

.alloch-sec18 {
  background: #f7f7f7;
  text-align: center;

  .alloch-sec7-left.portfolio-info-wrap2 {
    margin: 0 auto 70px;
  }
}

.term-col-wrap {
  label.error {
    position: static;
  }
}

.g-capcha-col-wrap {
  margin-top: -50px;
}

.term-col-wrap {
  margin-top: -36px;

  .custom-checkbox {
    .label-checkbox {
      .checkmark {
        top: 5px;
      }
    }
  }
}

.next-page-section {
  .join-team-wrap {
    position: relative;
    text-align: center;
    margin-top: 60px;

    h6 {
      margin-bottom: 36px;
    }

    p {
      color: $light-grey-c;
      max-width: 500px;
      margin: auto;
    }

    .next-page-img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.error-page-section1 {
  .portfolio-info-wrap2 {
    max-width: 574px;

    .bttn {
      margin-top: 60px;
    }
  }

  .error-img-wrap {
    position: relative;

    .error-page {
    }

    .error-icon {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 10px;
      animation: rotation-ani 35s infinite linear;
      -webkit-animation: rotation-ani 35s infinite linear;
      margin-left: 30px;
    }
  }
}

.on-scroll-filter-menu-wrap {
  position: fixed;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  z-index: 9;
  user-select: none;

  .custom-nav-wrap {
    a {
      background: rgba(255, 255, 255, 0.9);
    }

    li:not(:last-child) {
      margin-bottom: 25px;
    }
  }
}

.concept-wrap {
  margin-top: 30px;

  h5 {
    color: $black;
    margin-bottom: 10px;
  }

  h6 {
    color: $purple;
  }
}

.overseas-thumb-adj-1{
  position: absolute;
  top: 24px;
  left: -75px;
  z-index: 8;
}
.overseas-thumb-adj-2{
  position: absolute;
top: 81px;
left: 306px;
}
.overseas-thumb-adj-3{
  position: absolute;
top: 99px;
right: 68px;
}


.fligital-sec1 {
  background: #062878 url("../images/portfolio/portfolio-fligital/fligital-banner-bg.png") no-repeat center center;
  background-size: cover;

  .fligital-log-center-wrap {
    .fligital-logo-img {
      margin-bottom: 30px;
      width: 200px;
    }
  }
}
.logo-mark-img-adj1{
  position: relative;
}
.logo-mark-img-adj2{
 position: absolute;
top: -232px;
left: 379px;
}
.fligital-sec9 {
  background: #3062d1 url("../images/portfolio/portfolio-fligital/E1.jpg") no-repeat center center;
  background-size: cover;
}

.phycheme-sec1 {
  background: #062878 url("../images/portfolio/portfolio-branding-phycheme/phycheme-sec1.jpg") no-repeat center center;
  background-size: cover;

  .phycheme-log-center-wrap {
    .phycheme-logo-img {
      margin-bottom: 30px;
      width: 200px;
    }
  }
}


.marquee-img-adj {
	width: auto !important;
	margin: auto;
  filter: grayscale(100%);
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
}


// new code
.modal-content {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: transparent;
	background-clip: padding-box;
	border: none;
	outline: 0;
}
.marquee-img-adj-1{
  width: auto !important;
	margin: auto;
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
}

.owl-stage.ng-tns-c25-1 {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
}

.phycheme-sec4 {
  .owl-caroual-comman-fade {
    text-align: center;

    .phycheme4-4-img {
      min-height: 300px;
    }

    .owl-dots {
      display: none !important;
    }

    .owl-item img {
      display: block;
      width: auto;
      height: auto;
      margin: auto;
    }

    p {
      text-align: center;
      color: $white;
    }
  }
}

.phycheme-sec5 {
  .owl-carousel .owl-item img,
  .phycheme-logo-concept {
    height: 100vh;
    width: 100%;
    object-fit: cover;
    display: block;
  }

  .owl-dots {
    display: none !important;
  }
}

.phycheme-sec8 {
  .crubba-sec3-ul {
    list-style-type: none;

    li {
      // font-family: $font-family-lora !important;
      list-style-type: none !important;
      margin-left: 0;
      padding-left: 15px;
      margin-bottom: 15px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0px;
        top: 13.5px;
        width: 6px;
        height: 6px;
        background: $white;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        opacity: 0.4;
      }
    }
  }
}

.phycheme-sec9 {
  position: relative;
  overflow: hidden;

  .phycheme-sec9-1-outer {
    position: relative;
  }

  .phycheme-sec9-ul {
    width: 480px;
    height: 250px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);

    li {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;

      &:nth-child(1) {
        opacity: 1;
        top: -110px;
        left: -111px;
        animation: rotation-ani 35s infinite linear;
        -webkit-animation: rotation-ani 35s infinite linear;
      }

      &:nth-child(2) {
        opacity: 1;
        top: -111px;
        left: 130px;
      }

      &:nth-child(3) {
        opacity: 1;
        top: -173px;
        left: 290px;
        animation: floating 5s linear 3s infinite;
        -webkit-animation: floating 5s linear 3s infinite;
      }

      &:nth-child(4) {
        opacity: 1;
        top: -146px;
        left: 401px;
        z-index: 15;
        animation: rotation-ani 35s infinite linear;
        -webkit-animation: rotation-ani 35s infinite linear;
      }

      &:nth-child(5) {
        opacity: 1;
        top: 116px;
        left: 500px;
      }

      &:nth-child(6) {
        opacity: 1;
        top: 253px;
        left: 480px;
        animation: floating 5s linear 3s infinite;
        -webkit-animation: floating 5s linear 3s infinite;
      }

      &:nth-child(7) {
        opacity: 1;
        top: 177px;
        left: 260px;
        z-index: 15;
        animation: floating 5s linear 4s infinite;
        -webkit-animation: floating 5s linear 4s infinite;
      }

      &:nth-child(8) {
        opacity: 1;
        top: 418px;
        left: 100px;
      }

      &:nth-child(9) {
        opacity: 1;
        top: 148px;
        left: -143px;
        animation: floating 5s linear 1s infinite;
        -webkit-animation: floating 5s linear 1s infinite;
      }

      &:nth-child(10) {
        opacity: 1;
        top: 0;
        left: 0;
      }
    }
  }
}

.phycheme-sec10 {
  background: #134dd2 url("../images/portfolio/portfolio-branding-phycheme/phycheme-sec10.jpg") no-repeat center center;
  background-size: cover;
}

.phycheme-sec11 {
  position: relative;
  overflow: hidden;

  .phycheme-center-wrap {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.phycheme-sec12 {
  .phycheme-left-wrap4 {
    position: relative;

    .phycheme-sec12-4 {
      height: calc(100vh - 100px);
      position: relative;
      margin: auto;
      display: block;
    }
  }
}

.phycheme-sec13 {
  .phycheme-left-wrap {
    position: absolute;
    bottom: 0;
    left: 0;

    .phycheme-sec13-1 {
    }
  }
}

.phycheme-sec14 {
  position: relative;
  overflow: hidden;

  .phycheme-left-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.moneyguru-sec-website1 {
  .portfolio-info-banner-r {
    position: relative;
    text-align: center;

    .moneyguru-banner-img {
      position: relative;
      z-index: 3;
    }

    .moneyguru-banner-after {
      position: absolute;
      top: 33px;
      left: 50%;
      width: 280px;
      z-index: 1;
      margin-left: 50px;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
    }
  }
}

.moneyguru-sec-website2 {
  .overview-sec-center {
    position: relative;
    z-index: 3;
  }

  .moneyguru-overview {
    display: inline-block;
    position: absolute;
    top: -70px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
  }
}

.moneyguru-sec-website4 {
  .moneyguru-architecture-bottom {
    .viewer,
    .ng-draggable {
      height: 310px;
      padding: 0 30px 30px;
    }
  }
}

.moneyguru-sec-website5 {
  .moneyguru-landing-outer {
    text-align: center;

    .moneyguru-landing {
      margin-left: -90px;
    }
  }
}

.moneyguru-sec-website6 {
  .moneyguru-insights-right {
    .moneyguru-platform {
      display: inline-block;
      max-width: 680px;
      margin-top: 120px;
      margin-left: -110px;
    }
  }
}

.moneyguru-sec-website7,
.moneyguru-sec-website8 {
  .moneyguru-insights-left.portfolio-info-wrap2 {
    max-width: 500px;
  }

  .moneyguru-insights-right-col {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding-right: 0;

    .moneyguru-insights-right {
      float: right;

      img {
        float: right;
        display: block;
        max-width: 500px;
        margin-right: -140px;
      }
    }
  }
}

.moneyguru-sec-website9 {
  position: relative;
  overflow: hidden;

  .portfolio-info-wrap2 {
    margin: 100px auto 0;
    max-width: 705px;
  }

  .moneyguru-landing-outer {
    position: relative;
    max-width: 1040px;
    margin: 0 auto -20px;

    .mytrux-details-up {
      position: absolute;
      top: 120px;
      left: -25px;
      z-index: 0;
    }
  }
}

.insisive-branding-sec8 {
  background: $white url("../images/portfolio/portfolio-branding-insisive/insisive-branding-sec8.jpg") no-repeat center center;
  background-size: auto;
  background-size: cover;
}

.insisive-branding-sec9 {
  background: #f3f3f3 url("../images/portfolio/portfolio-branding-insisive/insisive-branding-sec9.jpg") no-repeat center center;
  background-size: auto;
  background-size: cover;
}

.insisive-branding-sec10 {
  background: #2a3035 url("../images/portfolio/portfolio-branding-insisive/insisive-branding-sec10.jpg") no-repeat center center;
  background-size: auto;
  background-size: cover;
}

.portfolio-branding-insisive-body {
  .insisive-logo-after-img {
    position: absolute;
    bottom: 0px;
    right: -30px;
    width: 370px;
    z-index: 1;
  }

  .bagshaha-sec2 {
    background: $white url("../images/svg/pattern.png") repeat 0 0;
  }

  .bagshaha-sec4 {
    .bagshaha-sec4-right {
      .bagshaha-sec4-right-b {
        img {
          padding-top: 50px;
          margin-top: 50px;
        }
      }
    }
  }

  .bagshaha-sec9 {
    .bagshaha-sec6-left.portfolio-info-wrap2 {
      float: right;
    }
  }
}

.portfolio-branding-notion-edge-body {
  .smartlinks-sect2 {
    background: url("../images/portfolio/portfolio-branding-notion-edge/notion-edge-idea-overview.svg") no-repeat center 77vh;
    background-size: contain;
  }
}

.portfolio-branding-vimson-body {
  .notion-edge-sec1 {
    .notion-edge-logo-bg {
      display: inline-block;
      position: absolute;
      bottom: 100px;
      left: auto;
      z-index: 0;
      width: 600px;
      right: -200px;
    }
  }
}

.portfolio-webapp-insisive-body {
  .insisive-website-sec1 {
    .img-fluid.bagshaha-logo-img.logo-img.lazy {
      width: 40px;
    }

    h1 {
      margin-top: 15px;
      margin-bottom: 35px;
    }

    h5 {
      color: #c2c2c2;
    }

    .insisive-webapp-banner-outer {
      position: relative;
      z-index: 3;
      top: 14px;
      left: 0;
    }

    .insisive-logo-after {
      position: absolute;
      bottom: 150px;
      right: -90px;
      z-index: 1;
    }
  }

  .insisive-webapp-4 {
    position: relative;
    overflow: hidden;

    .portfolio-info-wrap2 {
      margin-top: 100px;
      max-width: 550px;

      p {
        max-width: 500px;
      }
    }

    .insisive-straight-up-outer {
      position: absolute;
      top: -80px;
      right: -150px;
      bottom: auto;
      left: 0;
      z-index: 9;

      img {
        max-width: inherit;
        width: 100%;
      }
    }
  }

  .insisive-website-sec8 {
    background: #13152c;

    .insisive-website-sec8-left {
      h3 {
        white-space: nowrap;
      }
    }

    .insisive-website-sec8-right {
      position: absolute;
      top: 50%;
      right: 0;
      bottom: 0;
      transform: translateY(-50%);
      z-index: 3;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      margin-top: -60px;
    }
  }

  .insisive-website-sec26 {
    .insisive-website-sec2-1 {
      height: 360px !important;
      object-fit: cover;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.portfolio-website-bynry-body {
  .moneyguru-sec2 .moneyguru-sec2-right {
    float: left;
  }

  .authntick-sec9 .authntick-center-wrap {
    max-width: 100%;
  }

  .bynry-website-sec1 {
    position: relative;
    overflow: hidden;

    .portfolio-info-banner-l.portfolio-info-wrap2 {
      position: relative;
      z-index: 5;
    }

    .bynry-banner-wrap {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      margin-top: -35px;
      width: auto;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      z-index: 3;

      .img-fluid {
        height: 100vh;
      }
    }
  }

  .bynry-website-sec2 {
    .moneyguru-sec2-left {
      margin-top: 60px;
      margin-left: 0;

      .img-fluid {
        max-width: initial;
      }
    }
  }

  .bynry-website-sec4 {
    &:after {
      background: #051941 !important;
    }

    .authntick-center-wrap {
      h3 {
        white-space: nowrap;
      }
    }

    .authntick-sec9-2-outer {
      img {
        display: inline-block;
        // width: 840px;
      }
    }
  }

  .bynry-website-sec5 {
    .insisive-website-sec8-right {
      .img-fluid {
        display: inline-block;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .bynry-website-sec6 {
    .img-fluid.bynry-landing-page.lazy {
      max-width: 850px;
      margin-top: 50px;
      margin-left: -150px;
    }
  }

  .bynry-website-sec7 {
    .bagshaha-sec9-2-outer {
      .bynry-devices {
        display: inline-block;
        max-width: 900px;
        margin-left: -70px;
      }
    }
  }
}

.industries-list {
  padding-left: 30px;
  color: $light-grey-c;

  li {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 12px;
      height: 0px;
      left: -25px;
      top: 13px;
      border: 1px solid #FF6600;
    }

    &:not(:last-child) {
      margin-bottom: 27px;
    }

    color: $black;
  }
}

.rsmart-sect7-1 {
  position: relative;
  overflow: hidden;

  .rsmart-landing-outer {
    .rsmart-landing {
      display: inline-block;
      height: 440px !important;
      object-fit: cover;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

/*
.map-info-box {
  h5 {
    font-size: 22px;
    line-height: normal;
    margin-bottom: 10px;
    font-weight: 600;
    font-family: $font-family-poppins !important;
    color: $black;
  }
  .map-name {
    font-size: 18px;
    line-height: normal;
    font-weight: 500;
    font-family: $font-family-poppins !important;
    color: $black;
    margin-bottom: 10px;
  }
  .map-address {
    font-size: 14px;
    margin-bottom: 10px;
    color: $light-dark-c;
    font-family: $font-family-poppins !important;
    position: relative;
    padding-left: 30px;
    i {
      font-size: 22px;
      position: absolute;
      top: 0;
      left: 0;
      color: $light-dark-c;
    }
  }
  .location-icon {
    height: 20px;
    margin-right: 10px;
  }
  .bttn.btn-line.btn-link-custom {
    margin-top: 10px;
    display: block;
    .fa.fa-map-marker {
      font-size: 22px;
      color: $light-dark-c;
      margin-right: 18px;
    }
  }
}
*/

.about-sec5-1 {
  .about-expressions {
    position: absolute;
    top: -43px;
    right: -27px;
    width: 211px;
  }
}

.about-sec5-2 {
  .about-expressions {
    position: absolute;
    top: -47px;
    right: -30px;
    width: 211px;
  }
}

.join-section {
  h2 {
    margin-bottom: 40px;
  }
}

// -------------- banner
.home-sec1 {
  .comman-card-img-wrap-upd.hide-mobile {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform-origin: right 0;
  }
}

.home-page-body {
  .comman-card-img-wrap-upd {
    width: 350px;
    height: 350px;
    position: relative;
    display: block;
    margin: auto;

    img {
      position: absolute;
    }

    .home-sect-1 {
      top: 40px;
      left: 114px;
      z-index: 9;
    }

    .home-sect-2 {
      top: -138px;
      left: -160px;
      z-index: 8;
    }

    .home-sect-3 {
      top: -38px;
      left: -147px;
      z-index: 6;
    }

    .home-sect-4 {
      top: -100px;
      left: -272px;
      z-index: 5;
    }

    .home-sect-5 {
      top: 485px;
      left: 256px;
      z-index: 15;
    }

    .home-sect-6 {
      top: 45px;
      left: 48px;
      z-index: 25;
      position: absolute;
      overflow: hidden;
      width: 0;

      img.home-sect-6-img {
        position: relative !important;
        width: 335px;
        left: 12px;
      }
    }

    [data-aos="width-plus"].aos-animate {
      width: 100%;
    }
  }
}

.career-page-body {
  .comman-card-img-wrap-upd {
    width: 350px;
    height: 350px;
    position: relative;
    display: block;
    margin: auto;

    img {
      position: absolute;
    }

    .career-sect-1 {
      top: -46px;
      left: -87px;
      z-index: 12;
    }

    .career-sect-2 {
      top: -67px;
left: -105px;
    }

    .career-sect-3 {
      top: -44px;
      left: 3px;
    }

    .career-sect-4 {
      top: 372px;
      left: 123px;  
      z-index: 3;
    }
  }
}

.process-page-body {
  .comman-card-img-wrap-upd {
    width: 350px;
    height: 350px;
    position: relative;
    display: block;
    margin: auto;

    img {
      position: absolute;
    }

    .process-sect-1 {
      top: 26px;
      left: 6px;
      z-index: 12;
    }

    .process-sect-2 {
      top: 53px;
      left: 56px;
    }

    .process-sect-3 {
      top: 100px;
      left: 215px;
    }

    .process-sect-4 {
      top: 16px;
      left: 36px;
      z-index: 3;
    }

    .process-sect-5 {
      top: 186px;
      left: -44px;
      z-index: 15;
    }
  }
}

.our-work-row {
  .portfolio-thumb-all {
    img {
      filter: none;
      -webkit-filter: none;
      -moz-filter: blur(0px);
      -ms-filter: blur(0px);
      -webkit-backface-visibility: hidden;
    }

    .work-card {
      position: relative;
      margin-bottom: 143px;

      .work-card-img {
        position: relative;

        .work-card-img-bg {
          position: relative;
          width: 490px;
          height: 600px;
          margin: auto;

          img {
            position: absolute;
          }
        }
      }

      .fligital-work-card-img {
        .work-card-img-bg-inner {
          height: 100%;
          width: 100%;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            background: #f2f2f2;
            width: 100%;
            height: 100%;
          }
        }

        .work-card-img-bg {
          overflow: hidden;
        }

        .fligital-overlay-img {
          margin-top: 60px;
          transform: scale(1.3);
          -webkit-transform: scale(1.3);
          -moz-transform: scale(1.3);
          -ms-transform: scale(1.3);
          -o-transform: scale(1.3);
          z-index: 5;
        }

        .fligital-thumb1 {
          position: absolute;
          bottom: 50px;
          left: -60px;
          z-index: 9;
        }

        .fligital-thumb2 {
          position: absolute;
          top: 10px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 7;
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          -o-transform: translateX(-50%);
        }

        .fligital-thumb3 {
          position: absolute;
          bottom: 50px;
          right: -60px;
          z-index: 7;
        }
      }
    }

    .work-card-body {
      width: 490px;
      margin: 28px auto 0;

      ul {
        margin: 0;
        padding: 0;

        li {
          margin: 0;
          padding: 0;
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: #1E2329;
          position: relative;
          display: inline-block;

          &:nth-child(2) {
            padding-left: 48px;

            &::after {
              content: "";
              position: absolute;
              z-index: 3;
              height: 2px;
              width: 28px;
              background: #1E2329;
              top: 9px;
              left: 7px;
            }
          }
        }
      }

      h5 {
        // font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        color: #1E2329;
        margin-top: 16px;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
    }
  }

  .card-portfolio-webapp-authntick {
    .work-card-img {
      .work-card-img-bg {
        .portfolio-webapp-authntick-1 {
          top: 10px;
          left: 0;
          z-index: 3;
        }

        .portfolio-webapp-authntick-2 {
          bottom: -4px;
          right: 40px;
          z-index: 2;
        }

        .portfolio-webapp-authntick-3 {
          top: 40px;
          left: 20px;
          z-index: 1;
        }
      }
    }
  }

  .work-card.card-portfolio-mobile-app-moneyguru {
    .work-card-img {
      .work-card-img-bg {
        .portfolio-mobile-app-moneyguru-1 {
          top: 30px;
          left: 0;
          z-index: 3;
        }

        .portfolio-mobile-app-moneyguru-2 {
          bottom: 28px;
          right: -46px;
          z-index: 2;
        }

        .portfolio-mobile-app-moneyguru-3 {
          top: 74px;
          left: 50px;
          z-index: 1;
        }
      }
    }
  }

  .work-card.card-portfolio-mobile-app-alloch {
    .work-card-img {
      .work-card-img-bg {
        .portfolio-mobile-app-alloch-1 {
          top: 80px;
          left: 136px;
          z-index: 3;
        }

        .portfolio-mobile-app-alloch-2 {
          bottom: 24px;
          right: -43px;
          z-index: 2;
        }

        .portfolio-mobile-app-alloch-3 {
          top: 50px;
          left: 50px;
          z-index: 1;
        }
      }
    }
  }

  .work-card.card-portfolio-website-mytrux {
    .work-card-img {
      .work-card-img-bg {
        .portfolio-webapp-mytrux-1 {
          top: 60px;
          left: -110px;
          z-index: 3;
        }

        .portfolio-webapp-mytrux-2 {
          bottom: 33px;
          left: -35px;
          z-index: 2;
        }

        .portfolio-webapp-mytrux-3 {
          top: 44px;
          right: 35px;
          z-index: 1;
        }
      }
    }
  }

  .work-card.card-portfolio-website-mytrux {
    .portfolio-website-mytrux-1 {
      top: 48px;
      left: -39px;
      z-index: 9;
    }

    .portfolio-website-mytrux-2 {
      top: 110px;
      right: 0;
      z-index: 5;
    }

    .portfolio-website-mytrux-3 {
      top: 230px;
      left: 182px;
      z-index: 3;
    }
  }

  .work-card.card-portfolio-branding-smartlink {
    .work-card-img {
      .work-card-img-bg {
        .portfolio-branding-smartlink-1 {
          top: 200px;
          left: 30px;
          z-index: 3;
        }

        .portfolio-branding-smartlink-2 {
          top: 32px;
          left: -100px;
          z-index: 2;
        }

        .portfolio-branding-smartlink-3 {
          top: 90px;
          right: 60px;
          z-index: 1;
        }
      }
    }
  }

  .work-card.card-portfolio-webapp-insisive {
    .work-card-img {
      .work-card-img-bg {
        .portfolio-webapp-insisive-1 {
          top: 80px;
          left: -76px;
          z-index: 3;
        }

        .portfolio-webapp-insisive-2 {
          top: 33px;
          left: -26px;
          z-index: 2;
        }

        .portfolio-webapp-insisive-3 {
          bottom: 93px;
          right: 42px;
          z-index: 1;
        }
      }
    }
  }

  .work-card.card-portfolio-branding-vigan {
    .work-card-img {
      .work-card-img-bg {
        .portfolio-branding-vigan-1 {
          top: 130px;
          left: 70px;
          z-index: 4;
        }

        .portfolio-branding-vigan-2 {
          bottom: 46px;
          right: -37px;
          z-index: 3;
        }

        .portfolio-branding-vigan-3 {
          bottom: 168px;
          right: 100px;
          z-index: 2;
        }

        .portfolio-branding-vigan-4 {
          top: 90px;
          left: 50px;
          z-index: 1;
        }

        .portfolio-branding-vigan-11 {
          top: 0;
          right: 0;
          z-index: 4;
        }

        .portfolio-branding-vigan-12 {
          bottom: 73px;
          right: -34px;
          z-index: 3;
        }

        .portfolio-branding-vigan-13 {
          bottom: 0;
          right: 40px;
          z-index: 2;
        }
      }
    }
  }

  .work-card.card-portfolio-website-crubba {
    .work-card-img {
      .work-card-img-bg {
        .portfolio-website-crubba-1 {
          top: 72px;
          left: -55px;
          z-index: 3;
        }

        .portfolio-website-crubba-2 {
          top: 43px;
          left: 132px;
          z-index: 2;
        }

        .portfolio-website-crubba-3 {
          top: 54px;
          left: 180px;
          z-index: 1;
        }
      }
    }
  }

  .work-card.card-portfolio-branding-bagshaha {
    .work-card-img {
      .work-card-img-bg {
        .portfolio-branding-bagshaha-1 {
          top: 120px;
          right: -175px;
          z-index: 3;
        }

        .portfolio-branding-bagshaha-2 {
          top: 80px;
          left: 85px;
          z-index: 2;
        }

        .portfolio-branding-bagshaha-3 {
          top: 105px;
          left: 55px;
          z-index: 1;
        }
      }
    }
  }

  .work-card.card-portfolio-branding-mytrux {
    .work-card-img {
      .work-card-img-bg {
        .portfolio-branding-mytrux-1 {
          top: 0px;
          left: 0px;
          z-index: 3;
        }

        .portfolio-branding-mytrux-2 {
          bottom: 37px;
          right: -42px;
          z-index: 2;
        }

        .portfolio-branding-mytrux-3 {
          top: 40px;
          left: 46px;
          z-index: 1;
        }
      }
    }
  }

  .work-card.card-portfolio-mobile-app-easy {
    .work-card-img {
      .work-card-img-bg {
        .portfolio-mobile-app-easy-plan1 {
          top: 70px;
          left: 135px;
          z-index: 3;
        }

        .portfolio-mobile-app-easy-plan2 {
          top: 123px;
          left: 60px;
          z-index: 2;
        }

        .portfolio-mobile-app-easy-plan3 {
          top: 47px;
          right: 50px;
        }
      }
    }
  }

  .work-card.card-portfolio-website-moneyguru {
    .work-card-img {
      .work-card-img-bg {
        .portfolio-website-moneyguru1 {
          top: 106px;
          right: 0;
          z-index: 3;
        }

        .portfolio-website-moneyguru2 {
          top: 50px;
          left: 45px;
          z-index: 2;
        }
      }
    }
  }

  .work-card.card-portfolio-website-bynry {
    .work-card-img {
      .work-card-img-bg {
        .portfolio-website-bynry1 {
          top: 0px;
          left: -130px;
        }

        .portfolio-website-bynry2 {
          bottom: 43px;
          left: -34px;
          z-index: 3;
        }
      }
    }
  }

  .work-card.card-portfolio-branding-phycheme {
    .work-card-img {
      .work-card-img-bg {
        .portfolio-branding-phycheme1 {
          top: 343px;
          left: -87px;
          z-index: 5;
        }

        .portfolio-branding-phycheme2 {
          top: 35px;
          left: 0px;
          z-index: 4;
        }

        .portfolio-branding-phycheme3 {
          top: 55px;
          right: -20px;
          z-index: 9;
        }
      }
    }
  }

  .work-card.card-portfolio-branding-vimson {
    .portfolio-branding-vimson-1 {
      bottom: 0;
      right: 0;
    }

    .portfolio-branding-vimson-2 {
      top: 484px;
      left: -32px;
      z-index: 3;
    }
  }

  .work-card.card-portfolio-webapp-rsmart {
    .work-card-img {
      .work-card-img-bg {
        .portfolio-webapp-rsmart1 {
          top: 50px;
          left: 0;
          z-index: 3;
        }

        .portfolio-webapp-rsmart2 {
          top: 120px;
          left: 56px;
          z-index: 2;
        }

        .portfolio-webapp-rsmart3 {
          bottom: 80px;
          left: 18px;
          z-index: 1;
        }
      }
    }
  }

  .work-card.card-portfolio-website-insisive {
    .work-card-img {
      .work-card-img-bg {
        .portfolio-website-insisive1 {
          top: 123px;
          left: -52px;
          z-index: 3;
        }

        .portfolio-website-insisive2 {
          top: 208px;
          left: 0;
          z-index: 2;
        }

        .portfolio-website-insisive3 {
          top: 102px;
          left: 220px;
          z-index: 1;
        }

        .portfolio-website-insisive4 {
          top: 26px;
          left: 416px;
          z-index: 1;
        }

        .portfolio-website-insisive5 {
          top: 64px;
          left: 468px;
          z-index: 1;
        }
      }
    }
  }

  .work-card.card-portfolio-branding-notion-edge {
    .work-card-img {
      .work-card-img-bg {
        .portfolio-branding-notion-edge1 {
          top: 45px;
          right: -100px;
          z-index: 3;
        }

        .portfolio-branding-notion-edge2 {
          top: 184px;
          left: -100px;
          z-index: 2;
        }

        .portfolio-branding-notion-edge3 {
          bottom: 66px;
          left: -29px;
          z-index: 1;
        }
      }
    }
  }

  .work-card.card-portfolio-branding-insisive {
    .work-card-img {
      .work-card-img-bg {
        .portfolio-branding-insisive1 {
          top: 218px;
          left: -58px;
          z-index: 3;
        }

        .portfolio-branding-insisive2 {
          top: 100px;
          right: 48px;
          z-index: 2;
        }

        .portfolio-branding-insisive3 {
          top: 57px;
          left: 60px;
          z-index: 1;
        }
      }
    }
  }
}

.our-expressions-upd {
  position: relative;
  height: 480px;

  img {
    position: absolute;
  }

  .our-expressions-updated-profile {
    top: -92px;
    z-index: 3;
    left: 12px;
  }

  .our-expressions-updated1-1 {
    top: 35px;
    left: 13px;
    z-index: 2;
  }

  .our-expressions-updated1-2 {
    top: 57px;
    left: -20px;
    z-index: 1;
  }

  .our-expressions-updated1-3 {
    top: 475px;
    left: -13px;
    z-index: 2;
  }

  .our-expressions-updated1-4 {
    top: 490px;
    left: 366px;
    z-index: 1;
  }

  .our-expressions-updated-profile2 {
    top: -86px;
    left: 9px;
    z-index: 5;
  }

  .our-expressions-updated2-1 {
    top: -158px;
    left: -200px;
    z-index: 4;
  }

  .our-expressions-updated2-2 {
    top: -77px;
    left: 45px;
    z-index: 3;
  }

  .our-expressions-updated2-3 {
    top: 471px;
    left: 351px;
    z-index: 2;
  }
}

.our-work-page {
  .comman-header-menu-right,
  .logo-wrap-a {
    z-index: 999;
  }

  .work-comman-card-info {
    .custom-nav-wrap-outer {
      min-height: 40px;
    }

    li {
      position: relative;

      .bttn {
        padding-bottom: 15px;
        position: static;

        .icon-app {
          margin-right: 10px;
          vertical-align: middle;
        }
      }
    }

    .custom-nav-wrap-outer {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin-right: 0px;
        width: 80px;
        height: 100%;
        background: -moz-linear-gradient(
            left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
            left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1); /* IE6-9 */
      }

      &.nav-up,
      &.nav-down {
        position: fixed;
        top: -22px;
        left: 0;
        width: 100%;
        right: 0;
        padding: 20px 30px;
        margin: 0;
        z-index: 99;
        background: rgba(255, 255, 255, 0.9);
        -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);

        .custom-nav-wrap {
          max-width: 800px;
          margin: 60px auto 30px !important;
        }
      }
    }
  }
}

.restlessRegularText {
  font-size: 100px;
  display: inline-block;
  position: absolute;
  margin-left: 11px;
  margin-top: 20px;
  z-index: 3;
  font-family: restlessRegular !important;
  color: #ff5500;
  font-weight: 400 !important;
}

.question-icon {
  margin-left: -20px;
}

.services-user-exp-card {
  transform-origin: right center !important;

  .services-design-1 {
    top: 140px;
    left: 106px;  
    z-index: 7;
  }

  .services-design-2 {
    top: 380px;
    left: 280px;
    z-index: 9;
  }

  .services-design-3 {
    top: 24px;
    left: -63px;
    z-index: 5;
  }

  .services-design-4 {
    top: 38px;
    left: -60px;
    z-index: 4;
  }

  .services-design-5 {
    top: 98px;
    left: -85px;
    z-index: 3;
  }
}

.about-sec-img-right {
  transform: scale(0.8);
  transform-origin: center top;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  position: relative;
  height: 480px;
  margin-top: 40px;

  .img-fluid {
    display: block !important;
    margin: auto !important;
  }
}

.about-sec-name {
  font-size: 44px;
  font-family: restlessRegular !important;
  font-weight: 400 !important;
  margin-top: 30px;
  margin-bottom: 10px;
}

.services-user-experience-design-wrap {
  .services-user-experience-design1 {
    display: block;
    top: 143px;
    left: 312px;
    z-index: 9;
  }

  .services-user-experience-design2 {
    display: block;
    top: 345px;
    left: 330px;
    z-index: 8;
  }

  .services-user-experience-design3 {
    display: block;
    top: 216px;
    left: -143px;
    z-index: 7;
  }

  .services-user-experience-design4 {
    top: 126px;
    left: -115px;
    z-index: 6;
    display: block;
  }

  .services-user-experience-design5 {
    top: 66px;
    left: 65px;
    z-index: 5;
    display: block;
  }

  .services-user-experience-design6 {
    top: 64px;
    left: 20px;
    z-index: 5;
    display: block;
  }

  .services-user-experience-design7 {
    top: 394px;
    left: 314px;
    z-index: 12;
    display: block;
  }
}

.services-brand-strategy-identity-design-wrap {
  .services-brand-strategy-identity-design-1 {
    display: block;
    top: -41px;
    z-index: 9;
    left: 100px;
  }

  .services-brand-strategy-identity-design-2 {
    display: block;
    top: 61px;
left: -30px;
    z-index: 8;
  }

  .services-brand-strategy-identity-design-3 {
    display: block;
    top: 90px;
    left: 235px;
    z-index: 7;
  }

  .services-brand-strategy-identity-design-4 {
    top: 62px;
    left: -18px;
    z-index: 6;
    display: block;
  }

  .services-brand-strategy-identity-design-5 {
    top: -110px;
    left: -182px;
    z-index: 5;
    display: block;
  }
}

.portfolio-website-insisive2-body {
  // .insisive-website-sect1-1 {
  //   margin-top: -220px;
  // }
  .insisive-website-sect2-img {
    position: absolute;
    left: 80px;
    bottom: 0;
    z-index: 3;
  }

  .insisive-website-sect2-center {
    max-width: 470px;
    margin: 0 auto;
    text-align: center;
  }

  .insisive-website-sect3-img {
    display: inline;
    position: absolute;
    left: 80px;
    bottom: 0;
    z-index: 3;
  }

  .insisive-website-sect4-2-img {
    display: inline;
    position: absolute;
    left: 80px;
    bottom: 0;
    z-index: 3;
  }

  .white-line {
    li {
      opacity: 0.1;
      border-left: 1px solid #ffffff;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 3;
      width: 1px;

      &:first-child {
        left: 20%;
      }

      &:nth-child(2) {
        left: 40%;
      }

      &:nth-child(3) {
        left: 60%;
      }

      &:nth-child(4) {
        left: 80%;
      }

      &:nth-child(5) {
        left: 100%;
      }
    }
  }

  .insisive-website-sect4-img {
    margin-top: 150px;
  }

  .insisive-website-sect5-right {
    float: right;
    margin-top: -160px;
  }

  .img-fluid.insisive-website-sect5-img.lazy {
    margin-left: 47px;
  }

  .insisive-website-sect6 {
    .img-fluid.lazy.insisive-website-sect6-1 {
      margin-bottom: 60px;
      max-width: 460px;
    }

    .img-fluid.lazy.insisive-website-sect6-2 {
      max-width: 450px;
      margin: auto;
      display: block !important;
    }

    .portfolio-info-wrap2 {
      min-height: 250px !important;
    }
  }

  .insisive-website-sect7 {
    position: relative;
    overflow: hidden;
  }

  .insisive-website-sect7-wrap {
    margin-top: 40px;
    position: relative;
    z-index: 3;

    &:hover {
      .insisive-website-sect7-hover {
        background-position-y: 100%;
      }
    }

    .insisive-website-sect7-hover {
      position: absolute;
      z-index: 5;
      top: 27px;
      left: 50%;
      bottom: 0;
      width: 670px;
      height: 425px;
      background: url("../images/portfolio/portfolio-website-insisive/insisive-website-sect7-hover.jpg") no-repeat 0 0;
      background-size: cover;
      transition: background-position 30s linear;
      -webkit-transition: background-position 30s linear;
      -moz-transition: background-position 30s linear;
      -ms-transition: background-position 30s linear;
      -o-transition: background-position 30s linear;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
    }
  }

  .insisive-website-sect8 {
    position: relative;
    overflow: hidden;
  }

  .insisive-website-sect8-wrap {
    margin-top: 210px;
    position: relative;

    .insisive-website-sect8-lazy {
      display: inline;
      position: absolute;
      top: -67px;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
    }
  }

  .insisive-website-sect8-carousel {
    .owl-item img {
      width: auto;
      margin: auto;
    }

    .owl-dots {
      display: none;
    }
  }
}

.portfolio-webapp-mytrux-body {
  .mytrux-webapp-sect1 {
    background: url("../images/portfolio/portfolio-webapp-mytrux/mytrux-webapp-sect1-bg.png");

    .mytrux-webapp-sect1-2 {
      position: absolute;
      left: -240px;
      top: 126px;
      z-index: 3;
    }

    .mytrux-webapp-sect1-1-wrap .mytrux-webapp-sect1-1 {
      position: absolute;
      top: 50%;
      right: -47px;
      transform: translateY(-50%);
      z-index: 9;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      margin-top: 0;
    }
  }

  .mytrux-webapp-sect2 {
    position: relative;
    overflow: hidden;

    .mytrux-website-sect2-1 {
      position: absolute;
      bottom: -245px;
      right: 100px;
      z-index: 3;
    }
  }

  .mytrux-webapp-sect3 {
    .mytrux-webapp-sect3-left-list {
      white-space: nowrap;
      margin-left: -50px;
      margin-top: 0;

      li {
        &:not(:first-child) {
          margin-left: -30px;
        }
      }
    }

    .mytrux-webapp-sect3-right-list {
      li {
        position: relative;
        padding-left: 40px;
        margin-bottom: 40px;

        &::before {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          background: url("../images/portfolio/portfolio-webapp-mytrux/circle.svg") no-repeat;
          top: 8px;
          left: -3px;
        }
      }
    }
  }

  .mytrux-webapp-sect4 {
    position: relative;
    overflow: hidden;

    .portfolio-info-wrap2 {
      margin-top: 120px;
    }

    .mytrux-webapp-sect4-1 {
      position: absolute;
      top: auto;
      left: -93px;
      max-width: inherit;
      margin-top: 118px;
    }

    .mytrux-webapp-sect4-2 {
      max-width: inherit;
      margin-top: 64px;
      margin-left: 37px;
    }

    .mytrux-webapp-sect4-3 {
      max-width: inherit;
      margin-top: 84px;
      margin-left: 37px;
    }
  }

  .mytrux-webapp-sect5 {
    .portfolio-info-wrap2 {
      margin-top: 0;
    }

    .mytrux-webapp-sect5-1 {
      max-width: inherit;
      margin-top: -40px;
      margin-left: -132px;
    }

    .mytrux-webapp-sect5-2 {
      max-width: inherit;
      position: absolute;
      bottom: -270px;
      left: -180px;
    }
  }

  .mytrux-webapp-sect6 {
    .mytrux-webapp-sect6-1-wrap {
      .mytrux-webapp-sect6-1 {
        max-width: initial;
        margin-top: 30px;
      }

      p {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 15.1048px;
        text-align: center;
        color: #ffffff;
        position: absolute;
        top: 27px;
        left: 50%;
        transform: translateX(-50%);
        margin-left: 116px;
      }
    }

    .mytrux-webapp-sect6-2-wrap {
      .mytrux-webapp-sect6-2 {
        max-width: initial;
        margin-top: 152px;
        margin-left: -22px;
      }

      p {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 15.1048px;
        text-align: center;
        color: #ffffff;
        position: absolute;
        top: 151px;
        left: 50%;
        transform: translateX(-50%);
        margin-left: 93px;
      }
    }
  }

  .mytrux-webapp-sect7 {
    .mytrux-webapp-sect7-1-wrap {
      padding-top: 90px;
    }
  }
}

.swal2-container {
  .swal2-icon.swal2-success [class^="swal2-success-line"] {
    display: block;
    position: absolute;
    z-index: 2;
    height: 0.3125em;
    border-radius: 0.125em;
    background-color: #f98e2f;
  }

  .swal2-icon.swal2-success {
    border-color: #f98e2f;
    color: #f98e2f;
  }

  .swal2-icon.swal2-success .swal2-success-ring {
    position: absolute;
    z-index: 2;
    top: -0.25em;
    left: -0.25em;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    border: 0.25em solid rgba(249, 142, 47, 0.3);
    border-radius: 50%;
  }

  .swal2-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .swal2-icon {
    position: relative;
    box-sizing: content-box;
    justify-content: center;
    width: 5em;
    height: 5em;
    margin: 1.25em auto 15px;
    border: 0.25em solid transparent;
    border-radius: 50%;
    font-family: inherit;
    line-height: 5em;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

ul.list-inline.comman-header-menu-ul.comman-header-menu-right {
  a.mega-menu.mega-menu-close {
  }
}

.easy-plan-thumbnail1.lazy {
  top: 55px;
  right: 0;
  z-index: 5;
  left: auto;
}

.easy-plan-thumbnail2 {
  top: 102px;
  right: 40px;
  z-index: 2;
  left: auto;
}

.easy-plan-thumbnail3.lazy {
  top: 30px;
  right: 55px;
  z-index: 3;
}

.design-expe-modal {
  .modal-dialog {
    max-width: 650px;
    margin: 1.75rem auto;

    .modal-title {
      font-weight: 600 !important;
    }
  }
}

.work-card-img {
  img.design-expe-card1-after {
    position: absolute;
    top: 20px;
    left: -26px;
    z-index: 3;
  }

  img.design-expe-card2-after {
    position: absolute;
    bottom: -3px;
    right: -40px;
    z-index: 3;
  }

  img.design-expe-card4-after {
    position: absolute;
    top: 28px;
    right: -37px;
    z-index: 3;
  }

  &:hover {
    .circle {
      border: 2px solid $orange;

      &:before {
        animation: ripple 2s linear infinite;
        opacity: 1;
      }

      &:after {
        animation: ripple 2s linear 1s infinite;
        -webkit-animation: ripple 2s linear 1s infinite;
        opacity: 1;
      }

      .text {
        color: $orange;
      }
    }
  }

  .circle {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: rgba($color: $white, $alpha: 0.8);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    border: 2px solid $black;

    .design-expe-card1-after {
      position: absolute;
      top: 20px;
      left: -28px;
    }

    .design-expe-card2-after {
      position: absolute;
      top: 20px;
      left: -28px;
    }

    .design-expe-card3-after {
      position: absolute;
      top: 20px;
      left: -28px;
    }

    .text {
      text-align: center;
      font-size: 20px;
      color: $black;
      margin-top: 17px;
    }

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid $orange;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      opacity: 0;
    }
  }

  @keyframes ripple {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
      opacity: 1;
    }
    100% {
      transform: scale(1.6);
      opacity: 0;
    }
  }
}

.our-work-row .portfolio-thumb-all .work-card .work-card-img {
  position: relative;
  //min-height: 300px;
  min-height: 270px;
}

app-design-experiments {
  .portfolio-thumb-all .work-card .work-card-img {
    //background: #f0f1f5;
    background: #fff;
  }
}

.esop-sec1 {
  .esop-triangle1 {
    display: inline;
    position: absolute;
    top: 40px;
    right: 490px;
  }

  .esop-triangle2 {
    display: inline;
    position: absolute;
    bottom: 33px;
    left: 128px;
  }

  .esop-logo {
    margin-bottom: 40px;
  }

  h1 {
    margin-bottom: 30px;
  }

  .esop-header-img {
    max-width: inherit;
    margin-top: -20px;
    margin-left: 170px;
  }
}

.esop-sec2 {
  background: $white url("../images/portfolio/portfolio-webapp-esop/esop-empty-state.svg") no-repeat center center;
}

.esop-sec4 {
  background: #15269b url("../images/portfolio/portfolio-webapp-esop/esop-findability-bg.png") no-repeat right -330px bottom -285px;

  .esop-findability-wrap {
    text-align: center;
    margin-top: 45px;
    margin-left: 60px;
  }
}

.esop-sec6 {
  position: relative;
  overflow: hidden;

  .esop-slider-circle {
    width: 748px;
    height: 748px;
    display: block;
    background: #f8f8f8;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }

  .esop-left-wrap {
    height: calc(100vh - 200px);
    position: relative;

    .esop-slider-circle {
      width: 748px;
      height: 748px;
      background: #f8f8f8;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    .comman-scrollbar-wrap {
      transition: all 10s linear 0s;
      -webkit-transition: all 10s linear 0s;
      -moz-transition: all 10s linear 0s;
      -ms-transition: all 10s linear 0s;
      -o-transition: all 10s linear 0s;
      width: 620px;
      position: absolute;
      z-index: 15;

      .esop-slider-outer-wrap {
        margin-left: 40px;
        animation: esop-slider-outer-wrap-ani 25s linear alternate infinite;
        -webkit-animation: esop-slider-outer-wrap-ani 25s linear alternate infinite;

        .mr30 {
          margin-right: 30px;
        }

        .mt140 {
          margin-top: 140px;
        }

        .esop-slider-ul {
          width: 250px;
          float: left;

          li {
            margin-bottom: 30px;
          }
        }

        .esop-slider {
          width: 250px;
          height: 300px;
          background: #ffffff;
          box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.06);
          border-radius: 8px;
          text-align: center;
          position: relative;
          z-index: 18;
          padding-top: 90px;
        }
      }
    }
  }

  .esop-right-wrap {
    margin-left: 150px;
    margin-top: -30px;
  }
}

@keyframes esop-slider-outer-wrap-ani {
  from {
    transform: translateY(-0);
    -webkit-transform: translateY(-0);
    -moz-transform: translateY(-0);
    -ms-transform: translateY(-0);
    -o-transform: translateY(-0);
  }
  to {
    transform: translateY(-1480px);
    -webkit-transform: translateY(-1480px);
    -moz-transform: translateY(-1480px);
    -ms-transform: translateY(-1480px);
    -o-transform: translateY(-1480px);
  }
}

.esop-sec7 {
  position: relative;
  overflow: hidden;

  .esop-center-wrap {
    max-width: 876px;
    margin: auto;
    text-align: center;
  }

  .bottom-col-set {
    bottom: -135px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
   height: 340px;
    background: #11d092;
    z-index: 0;
  }
}

.esop-sec8 {
  background: #15269b;

  .esop-right-wrap {
    .esop-attention-wrap {
      display: inline;
      position: absolute;
      max-width: inherit;
      top: -105px;
      right: -74px;
    }

    .esop-right-row {
      width: 700px;
      position: relative;
      top: 26px;
      left: -57px;

      div {
        float: left;
      }
    }
  }
}

.circle-sec {
  width: 764.94px;
  height: 764.94px;
  background: #909090;
  opacity: 0.1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-top: 13px;
}

.esop-sec9 {
  position: relative;
  overflow: hidden;

  .esop-left-wrap {
    .img-fluid {
      max-width: inherit;
      margin-left: -315px;
      position: relative;
      top: 50px;
    }
  }

  .esop-right-wrap {
    margin-left: 45px;
  }
}

.esop-sec10 {
  position: relative;
  overflow: hidden;

  .esop-left-wrap {
    margin-top: 110px;
    margin-left: 13px;
  }

  .esop-right-wrap {
    .img-fluid {
      max-width: inherit;
      margin-left: -70px;
      position: relative;
      top: 50px;
    }
  }
}

.esop-sec11 {
  position: relative;
  overflow: hidden;

  .esop-left-wrap {
    .img-fluid {
      max-width: inherit;
      margin-left: -200px;
      position: relative;
      top: 23px;
    }
  }

  .esop-right-wrap {
    margin-left: 145px;
    margin-top: 90px;
  }
}

.esop-sec12 {
  background: #ffb017 url("../images/portfolio/portfolio-webapp-esop/esop-empty-state.svg") no-repeat center center;

  .esop-center-wrap {
    position: relative;
    top: 160px;
  }

  // .ui-card-slider .center-slide{opacity: 1;}
  .ui-card-slider .right-slide {
    opacity: 0.6;
    cursor: pointer;
    left: 170px;
  }

  .ui-card-slider .left-slide {
    opacity: 0.6;
    cursor: pointer;
    left: 10px;
  }

  .ui-card-slider .slide img {
    width: 100%;
    height: auto;
  }

  .ui-card-slider {
    position: relative;
    width: 320px;
    height: 290px;
    overflow: hidden;
    transform: scale(4);
    transform-origin: 0 top;
    -webkit-transform: scale(4);
    -moz-transform: scale(4);
    -ms-transform: scale(4);
    -o-transform: scale(4);
  }
}


.personas-sec-slider {
  background: #F5F5F5;
  // .ui-card-slider .center-slide{opacity: 1;}
  .ui-card-slider .right-slide {
    opacity: 0.6;
    cursor: pointer;
    left: 170px;
  }

  .ui-card-slider .left-slide {
    opacity: 0.6;
    cursor: pointer;
    left: 10px;
  }

  .ui-card-slider .slide img {
    width: 100%;
    height: auto;
  }

  .ui-card-slider {
    position: relative;
    width: 320px;
    height: 290px;
    overflow: hidden;
    transform: scale(4);
    transform-origin: 0 top;
    -webkit-transform: scale(4);
    -moz-transform: scale(4);
    -ms-transform: scale(4);
    -o-transform: scale(4);
    transform-origin: 0px 50px;
  }
}



.flexifyme-sec-slider {
  background: #ffff url("../images/portfolio/portfolio-website-flexifyme/onboard-background.png") no-repeat center center;
  background-size: cover !important;

  .esop-center-wrap {
    position: relative;
    top: 0px;
  }

  .illustration-bg {         
    background: red url("../images/portfolio/portfolio-website-flexifyme/illustration-bg.png");
    background-size: cover !important;
  }  

  // .ui-card-slider .center-slide{opacity: 1;}
  .ui-card-slider .right-slide {
    opacity: 0.6;
    cursor: pointer;
    left: 170px;
  }

  .ui-card-slider .left-slide {
    opacity: 0.6;
    cursor: pointer;
    left: 10px;
  }

  .ui-card-slider .slide img {
    width: 100%;
    height: auto;
  }

  .ui-card-slider {
    position: relative;
    width: 320px;
    height: 290px;
    overflow: hidden;
    transform: scale(3);
    transform-origin: 0 top;
    -webkit-transform: scale(3);
    -moz-transform: scale(3);
    -ms-transform: scale(3);
    -o-transform: scale(3);
    transform-origin: -70px 100px;
}
}

.esop-sec13 {
  .comman-impact-bottom {
    text-align: left;
  }

  .comman-impact-bottom-ul {
    width: 600px;

    li {
      width: 170px;
      float: left;

      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }
}

.bttn.disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.concerro-sec1 {
  background: #f4f4f4;
  position: relative;

  .concerro-pattern-wrap {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) !important;
    -webkit-transform: translateX(-50%) !important;
    -moz-transform: translateX(-50%) !important;
    -ms-transform: translateX(-50%) !important;
    -o-transform: translateX(-50%) !important;
    z-index: 3;
  }
}

.concerro-sec2 {
  position: relative;

  .concerro-pattern-wrap {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) !important;
    -webkit-transform: translateX(-50%) !important;
    -moz-transform: translateX(-50%) !important;
    -ms-transform: translateX(-50%) !important;
    -o-transform: translateX(-50%) !important;
    z-index: 3;
  }
}

.concerro-sec4 {
  .concerro-right-wrap {
    text-align: center;

    .concerro-brainstorming-1 {
      top: -230px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
    }

    .concerro-brainstorming-2 {
      top: 150px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
    }
  }
}

.concerro-sec5 {
  background: #3062d1 url("../images/portfolio/portfolio-branding-concerro/concerro-pattern-bg.jpg") no-repeat center center;
  background-size: cover;
}

.concerro-sec6 {
  text-align: center;

  .concerro-left-wrap.portfolio-info-wrap2 {
    max-width: 767px;
    margin: auto;
  }
}

.concerro-sec8 {
  background: #1f2532 url("../images/portfolio/portfolio-branding-concerro/concerro-health-bg.jpg") no-repeat center center;
  background-size: cover;
}

.concerro-sec9 {
  background: #1f2532 url("../images/portfolio/portfolio-branding-concerro/concerro-sec9.jpg") no-repeat center center;
  background-size: cover;
}

.concerro-sec10 {
  background: #1f2532 url("../images/portfolio/portfolio-branding-concerro/concerro-sec10.jpg") no-repeat center center;
  background-size: cover;
}

// wellness-insignia portfolio
.wellness-logo-sec1 {
  padding-bottom: 0;
  background: $white url("../images/portfolio/portfolio-branding-insignia/wellness-logo-bg.png") no-repeat center;
  background-size: 66%;

  .wellness-logo-img {
    margin-bottom: 65px;
  }
}

.wellness-logo-sec2 {
  .wellness-website-sec9-4 {
    margin-top: 145px;
  }
}

.wellness-sect5 {
  position: relative;
  overflow: hidden;

  .wellness-sec5-left {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    z-index: 9;
  }
}

// .wellness-sec7 {
//   padding: 0 !important;
// }
.wellness-sec8 {
  background: #561e5e url("../images/portfolio/portfolio-branding-insignia/wellness-sec7.jpg") no-repeat center center;
  background-size: cover;
}

.wellness-sec9 {
  background: #561e5e url("../images/portfolio/portfolio-branding-insignia/wellness-sec8.jpg") no-repeat center center;
  background-size: cover;
}

.wellness-sect4 {
  position: relative;
  overflow: hidden;

  .wellness-sec4-right {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    z-index: 9;
  }
}

// .slide_container {
//   width: 300%;
//   height: 100%;
//   display: flex;
//   flex-wrap: no-wrap;
// }

// .panel {
//   will-change: transform;
// 	width: 100%;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-weight: 600;
//   font-size: 1.5em;
//   text-align: center;
//   color: white;
//   position: relative;
//   box-sizing: border-box;
//   padding: 10px;
//   &.one {
//     background-color: #c82736;
//     background-image: radial-gradient(circle at 19% 90%, rgba(190, 190, 190,0.04) 0%, rgba(190, 190, 190,0.04) 17%,transparent 17%, transparent 100%),radial-gradient(circle at 73% 2%, rgba(78, 78, 78,0.04) 0%, rgba(78, 78, 78,0.04) 94%,transparent 94%, transparent 100%),radial-gradient(circle at 45% 2%, rgba(18, 18, 18,0.04) 0%, rgba(18, 18, 18,0.04) 55%,transparent 55%, transparent 100%),radial-gradient(circle at 76% 60%, rgba(110, 110, 110,0.04) 0%, rgba(110, 110, 110,0.04) 34%,transparent 34%, transparent 100%),radial-gradient(circle at 68% 56%, rgba(246, 246, 246,0.04) 0%, rgba(246, 246, 246,0.04) 16%,transparent 16%, transparent 100%),radial-gradient(circle at 71% 42%, rgba(156, 156, 156,0.04) 0%, rgba(156, 156, 156,0.04) 47%,transparent 47%, transparent 100%),radial-gradient(circle at 46% 82%, rgba(247, 247, 247,0.04) 0%, rgba(247, 247, 247,0.04) 39%,transparent 39%, transparent 100%),radial-gradient(circle at 50% 47%, rgba(209, 209, 209,0.04) 0%, rgba(209, 209, 209,0.04) 45%,transparent 45%, transparent 100%),linear-gradient(90deg, #e53949,#cc2232);
//   }

//   &.two {
//     background-color: #8d3dae;
//     background-image: radial-gradient(circle at 47% 14%, rgba(205, 205, 205,0.04) 0%, rgba(205, 205, 205,0.04) 43%,transparent 43%, transparent 100%),radial-gradient(circle at 35% 12%, rgba(215, 215, 215,0.04) 0%, rgba(215, 215, 215,0.04) 4%,transparent 4%, transparent 100%),radial-gradient(circle at 1% 35%, rgba(24, 24, 24,0.04) 0%, rgba(24, 24, 24,0.04) 37%,transparent 37%, transparent 100%),radial-gradient(circle at 21% 1%, rgba(0, 0, 0,0.04) 0%, rgba(0, 0, 0,0.04) 26%,transparent 26%, transparent 100%),radial-gradient(circle at 23% 82%, rgba(249, 249, 249,0.04) 0%, rgba(249, 249, 249,0.04) 60%,transparent 60%, transparent 100%),radial-gradient(circle at 11% 54%, rgba(251, 251, 251,0.04) 0%, rgba(251, 251, 251,0.04) 23%,transparent 23%, transparent 100%),radial-gradient(circle at 69% 68%, rgba(234, 234, 234,0.04) 0%, rgba(234, 234, 234,0.04) 10%,transparent 10%, transparent 100%),linear-gradient(90deg, #8d3dae,#8d3dae);
//   }
//   &.three {
//     background-color: #28a92b;
//     background-image: radial-gradient(circle at 46% 40%, rgba(228, 228, 228,0.06) 0%, rgba(228, 228, 228,0.06) 13%,transparent 13%, transparent 100%),radial-gradient(circle at 11% 41%, rgba(198, 198, 198,0.06) 0%, rgba(198, 198, 198,0.06) 19%,transparent 19%, transparent 100%),radial-gradient(circle at 52% 23%, rgba(14, 14, 14,0.06) 0%, rgba(14, 14, 14,0.06) 69%,transparent 69%, transparent 100%),radial-gradient(circle at 13% 85%, rgba(148, 148, 148,0.06) 0%, rgba(148, 148, 148,0.06) 44%,transparent 44%, transparent 100%),radial-gradient(circle at 57% 74%, rgba(232, 232, 232,0.06) 0%, rgba(232, 232, 232,0.06) 21%,transparent 21%, transparent 100%),radial-gradient(circle at 59% 54%, rgba(39, 39, 39,0.06) 0%, rgba(39, 39, 39,0.06) 49%,transparent 49%, transparent 100%),radial-gradient(circle at 98% 38%, rgba(157, 157, 157,0.06) 0%, rgba(157, 157, 157,0.06) 24%,transparent 24%, transparent 100%),radial-gradient(circle at 8% 6%, rgba(60, 60, 60,0.06) 0%, rgba(60, 60, 60,0.06) 12%,transparent 12%, transparent 100%),linear-gradient(90deg, #28a92b, #10a614);
//   }
// }

.card-portfolio-website-concerro-health {
  .thumb-concerro-health1.lazy {
    top: 60px;
    left: -49px;
    z-index: 2;
  }

  .thumb-concerro-health2.lazy {
    top: 30px;
    right: 0;
    z-index: 3;
  }
}

.work-card.card-portfolio-website-wellness-insignia {
  .thumb-wellness-insignia1.lazy {
    top: 10px;
    left: -8px;
    z-index: 2;
  }

  .thumb-wellness-insignia2.lazy {
    top: 47px;
    left: -37px;
    z-index: 3;
  }
}

.work-card.card-portfolio-website-engati {
  .thumb-engati1.lazy {
    bottom: 0;
    left: 46px;
    z-index: 2;
  }

  .thumb-engati2.lazy {
    bottom: 20px;
    left: -89px;
    z-index: 3;
  }

  .thumb-engati3.lazy {
    bottom: 72px;
    left: 43px;
    z-index: 4;
  }

  .thumb-engati4.lazy {
    top: 106px;
    right: 0px;
    z-index: 5;
  }

  .thumb-engati5.lazy {
    top: 134px;
    left: 54px;
    z-index: 1;
  }
}

.coming-soon-hover {
  cursor: not-allowed;

  .coming-soon-h5 {
    color: #fff;
    margin: 0;
    padding: 0;
    text-align: center;
    position: absolute;
    top: 90px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 15;
    opacity: 0;
    -webkit-transform: translate(-50%, 0%);
    -moz-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    -o-transform: translate(-50%, 0%);
  }

  &:hover {
    .thumb-concerro-health1.lazy,
    .thumb-wellness-insignia1.lazy {
      opacity: 1;
    }

    .coming-soon-h5 {
      opacity: 1;
    }
  }
}

.concerro-sec4 .concerro-right-wrap .concerro-brainstorming-2,
div.portfolio-branding-concerro-body .concerro-sec1 div.concerro-center-wrap img.concerro-logo.img-fluid.rotate-ani {
  animation: rotation-ani 30s infinite linear;
  -webkit-animation: rotation-ani 30s infinite linear;
}


.source-trace-sec1 {
  position: relative;
  overflow: hidden;

  .p2 {
    max-width: 482px;
    margin-top: 40px;
    color: #717171;
  }

  .source-trace-right-wrap {
    position: relative;

    img {
      max-width: inherit;
      position: absolute;
      top: 0;
      left: -130px;
      z-index: 2;
    }
  }
}

.source-trace-sec2 {
  position: relative;
  overflow: hidden;

  .farmers-img {
    max-width: inherit;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
}

.source-trace-sec3 {
  position: relative;
  overflow: hidden;

  .overview-sec-center {
    position: relative;
  }

  .leave1-img {
    position: absolute;
    top: -120px;
    left: 40px;
    animation: floating 5s linear 0s infinite;
    -webkit-animation: floating 5s linear 0s infinite;
  }

  .leave2-img {
    position: absolute;
    bottom: -47px;
    right: -90px;
    animation: floating 7s linear 0s infinite;
    -webkit-animation: floating 7s linear 0s infinite;
  }

  .small-tracker-img {
    position: absolute;
    bottom: -200px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
  }
}

.source-trace-sec4 {
  position: relative;
  overflow: hidden;

  .leave3-img {
    position: absolute;
    top: 150px;
    left: -20px;
    z-index: 2;
    animation: floating 5s linear 0s infinite;
    -webkit-animation: floating 5s linear 0s infinite;
  }

  .corn-img {
    position: absolute;
    bottom: -80px;
    right: -120px;
    z-index: 2;
  }

  .farmers-img {
    max-width: inherit;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
}

.source-trace-sec5 {
  .p2 {
    max-width: 650px;
  }

  .info-architecture1 {
    margin-top: 30px;
  }
}

.source-trace-sec6 {
  background-color: #F7F7F9;

  .source-trace-center-wrap {
    margin-bottom: 20px;
  }

  .wireframe-img {
    margin-top: 30px;
  }
}

.source-trace-sec7 {
  background-color: #F7F7F9;

  .leave1-img {
    max-width: inherit;
    position: absolute;
    top: 40%;
    left: -30px;
    z-index: 2;
    animation: floating 5s linear 0s infinite;
    -webkit-animation: floating 5s linear 0s infinite;
  }

  .farmer-flow {
    margin-top: 20px;
  }

  .farmer-icon-img {
    margin-right: 20px;
  }

  .big-tracter {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  .leave2-img {
    max-width: inherit;
    position: absolute;
    top: 80px;
    right: 0;
    z-index: 2;
    animation: floating 5s linear 0s infinite;
    -webkit-animation: floating 5s linear 0s infinite;
  }
}

.source-trace-sec8 {
  position: relative;
  overflow: hidden;

  .plant-bg {
    position: absolute;
    bottom: -100px;
    left: -70px;
    z-index: 3;
  }

  .leave2-img {
    position: absolute;
    top: 220px;
    z-index: 3;
    animation: floating 5s linear 0s infinite;
    -webkit-animation: floating 5s linear 0s infinite;
  }

  .multi-dashboard {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 3;
    max-width: inherit;
    margin-top: 106px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
  }

  .leave-bg {
    position: absolute;
    bottom: -203px;
    left: 0;
    z-index: 3;
    max-width: inherit;
  }
}

.source-trace-sec9 {
  background-color: #F7F7F9;

  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #009456;
  }
}

.source-trace-sec9, .source-trace-sec10 {
  p {
    max-width: 750px;
    margin: 0 auto 30px;
  }

  .db1 {
    position: absolute;
    top: 70px;
    right: 50px;
    z-index: 3;
    animation: floating 5s linear 0s infinite;
    -webkit-animation: floating 5s linear 0s infinite;
  }

  .db2 {
    position: absolute;
    bottom: -30px;
    left: -70px;
    z-index: 3;
    animation: floating 7s linear 0s infinite;
    -webkit-animation: floating 7s linear 0s infinite;
  }
}

.source-trace-sec12 {
  position: relative;
  overflow: hidden;

  .gui-components {
    max-height: calc(100vh - 230px);
    margin: auto !important;
    display: block;
  }
}

.source-trace-sec11.esop-sec6 {
  .esop-left-wrap {
    .comman-scrollbar-wrap {
      .esop-slider-outer-wrap .esop-slider {
        padding-top: 80px;
      }

      .esop-slider-outer-wrap {
        margin-left: 40px;
        animation: none;
        -webkit-animation: none;
        margin-top: -90px;
      }
    }
  }
}

.wellness-logo-caroual {
  .wellness-caroual-item {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .wellness-caroual-item1 {
    background: #fff !important;
  }

  .wellness-caroual-item2 {
    background: #1A1A1A !important;
  }

  .wellness-caroual-item3 {
    background: linear-gradient(119.66deg, #FF8E4F 0.75%, #FB035C 75.68%, #B322AD 134.63%) !important;
  }

  .owl-item {
    img {
      width: auto !important;
      height: auto !important;
      margin: auto !important;
      display: block !important;
    }
  }
}

.podcast-all-btn {
  position: relative;
  top: 12px;
  left: 64px;
}
.pr-ceo-video-modal{
  .modal-body{
    padding: 0;
    background: black;
      box-shadow: none;
      border: 0;
  }
  .modal-content{
    padding: 0;
    background: transparent;
    box-shadow: none;
    border: 0;
    width: 70%;
    margin: auto;
}
}

.pr-ceo-video-modal .close, .pr-ceo-video-modal .close:hover {
  color: white;
  font-size: 66px;
  box-shadow: none;
  opacity: 1;
  position: absolute;
top: -63px;
right: -40px;
}


.pr-video-modal {
  .modal-body {
    padding: 0;
    background: transparent;
      box-shadow: none;
      border: 0;
  }
  .modal-content{
    padding: 0;
    background: transparent;
    box-shadow: none;
    border: 0;
    width: 70%;
    margin: auto;
}
.close,
.close:hover {
	color: white;
	font-size: 66px;
	box-shadow: none;
	opacity: 1;
  position: absolute;
top: -63px;
right: -39px;
}

.pr-video-modal-btn{
  display: block;
}
}

.pr-vidoe-play-icon{
  position: absolute;
  z-index: 23;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  margin-left: -54px;
  @media (min-width: 320px) and (max-width: 767.98px){
    top: 50%;
    width: 80px;
height: 80px;
margin-left: 0
  }
}

.comman-card-img-wrap-adj2{
  margin-top: 40px;
}

.comman-card-img-wrap-adj2 .pr-video-modal-btn {
	display: block;
    height: 577px;
    min-height: 400px;
    position: relative;
    width: 713px;
    top: 0;
    right: 100px;

  @media (min-width: 320px) and (max-width: 767.98px){
    min-height: 200px;
  }

  &:hover{
    &:after{
      opacity: 1;
    }
  }
  &::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 577px;
    background: rgba(black, 0.5);
    opacity: 0;
    @extend .st;
    z-index: 3;
  }
}

#home-carosual22 .carousel-inner {
	overflow: inherit;
}
.amazin-result-img1{
position: relative;
top: 97px;
right: 91px;
}
.amazin-result-img2{
position: absolute;
right: -18px;
bottom: -60px;
}
.flexifyme-banner-img {
	position: absolute !important;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
	z-index: 3;
}
.moneyguru-sec2-3.img-fluid.lazy.flexifyme-overview-img {
	position: relative;
	top: -120px;
}
.flow-diagrams-left-wrap{
  margin-top: 0px;
}
.mt-adj-100{
  margin-top: 100px;
}
.wireframe-img1{
  position:relative;
}
.wireframe-img2{
  position: absolute;
bottom: 153px;
left: -311px;
z-index: 3;
}
.playfair-right-div-wrap{
  margin-left: 0px !important;
  margin-top: 0px !important;
}
.playfair-left-div-wrap{
  margin-left: 0px !important;
  margin-top: 0px !important;
}
.mt-adj{
  margin-top: 90px;
}
.flexifyme-sec9-2-outer{
  margin-top: -130px;
}
.flexifyme-flowdiagram-t-60-img{
  top: -60px !important;
}
.flexifyme-diagram-adj{
  margin-top: 89px;
margin-left: -229px !important;
}
.about-img-adj{
  width: 90px;
  height: auto;
}
.design-expe-modal .modal-content {
	position: relative;
	width: 100%;
  background-color: transparent;
  border: none;
}
.close-adj{
  color: white;
font-size: 66px;
position: absolute;
top: -38px;
right: -15px;
}
.close-adj-1{
  color: white;
font-size: 42px;
position: absolute;
top: -19px;
right: -19px;
}

.career-page-body .comman-scrollbar-wrap {
  height: calc(100vh - 105px);
  overflow: auto;
}

.close:hover {
	color: white;
}

.marketing-website-img-1{
  position: relative;
  top: -480px;
  z-index: 5;
  left: 534px;
}
.marketing-website-img-2{
  position: absolute;
  top: 138px;
  right: -60px;
}
@media (min-width: 320px) and (max-width: 767.98px){
  .logo-mark-img-adj2 {
    position: absolute;
    top: 72px;
    width: 281px;
    left: 36px;
  }
  .logo-mark-mob-adj{
    margin-bottom: 50px !important;
  }
}

@media (min-width: 768px) and (max-width: 1599px){
  .color-palette-background-adj {
    top: 350px;
    position: relative;
  }
 
}

@media (min-width: 1200px) and (max-width: 1599px){

.lg-pt-150{
padding-top: 70px;
}

}


.intelhealth-banner{
//  background: url('../images/portfolio/portfolio-webapp-intelhealth/Slide-1/intelhealth-banner-new.png') no-repeat top right;
  background: url('../images/portfolio/portfolio-webapp-intelhealth/Slide-1/intelhealth-banner-new-1.png') no-repeat top right;
  //position: relative;
  background-size: 50% 100%;
}
.contextual-wrap{
     background: url('../images/portfolio/portfolio-webapp-intelhealth/Slide-20/bg.png') no-repeat top right;
  background-size: cover;
  background-position: center center;
}