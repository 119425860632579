// @import "./base.scss";
// Large devices (desktops, 992px and up)
@media (max-width: 1199.98px) {
  .order-md-last-adj {
    order: 2;
  }
  .comman-header {
    .logo-wrap-a svg {
      width: 30px;
    }
  }
  .comman-header-inner {
    .logo-wrap-a svg {
      width: 30px;
    }
  }
  .company-name-mobile {
    font-size: 15px !important;
    letter-spacing: 0.5px !important;
    font-weight: 600 !important;
  }
  .comman-top-menu {
    clip-path: circle(30px at calc(100% - 40px) 40px);
    -webkit-clip-path: circle(30px at calc(100% - 40px) 40px);

    .container-fluid.mw-1470 {
      position: fixed;
      top: 0;
      transition: all 0.2s linear;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      -ms-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      width: 100%;
      z-index: 890;
      padding: 15px;
    }
  }
  .comman-header {
    .company-name-mobile:hover {
      span {
        font-weight: 600 !important;
      }
    }

    position: fixed;
    top: 0;
    // transition: all 0.6s linear;
    // -webkit-transition: all 0.6s linear;
    // -moz-transition: all 0.6s linear;
    // -ms-transition: all 0.6s linear;
    // -o-transition: all 0.6s linear;
    width: 100%;
    z-index: 890;
    padding: 38px 0 18px;
    min-height: 80px;

    &.nav-up {
      top: -150px;

      .logo-img {
        opacity: 0 !important;
      }
    }

    &.nav-down {
      top: 0;
      background: rgba(255, 255, 255, 0.9);
      -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);

      .logo-img {
        opacity: 0 !important;
      }

      .company-name-mobile {
        opacity: 1 !important;
      }

      .comman-header-menu-ul {
        .btn-link-custom {
          color: $black;
        }

        .logo-img path,
        .mega-menu-img1 path {
          fill: $black;
        }
      }
    }
  }
  .comman-top-menu-dark.comman-top-menu,
  .comman-top-menu {
    background: #002B3B !important;

    .logo-wrap-a {
      filter: brightness(0) invert(1);

      .logo-img {
        filter: brightness(0) invert(1);
      }
    }

    .top-menu-left {
      a {
        color: $white !important;
      }
    }

    .top-menu-right {
      a {
        color: $white !important;
      }
    }

    .top-menu-bottom {
      li {
        a {
          color: $white !important;
        }
      }
    }

    .logo-img {
      filter: none !important;
      -webkit-filter: none !important;
    }

    .mega-menu.mega-menu-close {
      .mega-menu-img2 {
        path {
          fill: $white;
        }
      }
    }
  }
  .our-work-page .work-comman-card-info .custom-nav-wrap-outer.nav-up .custom-nav-wrap,
  .our-work-page .work-comman-card-info .custom-nav-wrap-outer.nav-down .custom-nav-wrap {
    max-width: 700px;
    margin: 30px auto 0px !important;
  }
  .our-work-page .work-comman-card-info .custom-nav-wrap li:not(:last-child) {
    margin-right: 5px;
  }
  .our-work-page .work-comman-card-info .custom-nav-wrap .bttn span::after {
    display: none;
  }
  .our-work-page .work-comman-card-info .custom-nav-wrap .bttn.active {
    color: $orange;
  }
  .bottom-col-set {
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
    z-index: 3;
    padding: 0;
  }
  .error-page-section1 {
    border-bottom: 3px solid #2f2f2f;
  }
  .about-sec2 {
    .show-mobile-adj {
      display: block !important;
    }

    .hide-mobile-adj {
      display: none !important;
    }
  }

  .bottom-col-set {
    .viewer.no-select {
      overflow: auto !important;
    }
  }
  .crubba-slider-mobile {
    display: none !important;
  }

  .our-work-page {
    .comman-header.nav-down {
      position: static !important;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  a.mega-menu.mega-menu-close {
    top: 22px;
    left: 10px;
    position: relative;
  }
  .cursor {
    display: none;
  }
  h1,
  .h1 {
    font-size: $h1-font-size - 16;
    line-height: 102px - 29.2 !important;
  }
  h2,
  .h2 {
    font-size: $h2-font-size - 14;
    line-height: 88px - 25.6 !important;
  }
  h3,
  .h3 {
    font-size: $h3-font-size - 14;
    line-height: 74px - 22 !important;
  }
  h4,
  .h4 {
    font-size: $h4-font-size - 4 !important;
    line-height: 50px - 13.6 !important;
  }

  h5,
  .h5 {
    font-size: $h5-font-size;
    line-height: 36.4px !important;
  }
  h6,
  .h6 {
    font-size: $h6-font-size;
  }

  .p1 {
    font-size: 24px;
    line-height: 38px;
    margin: 0;
  }
  .p2 {
    font-size: 20px;
    line-height: 32.4px;
    margin: 0;
  }
  .p3 {
    font-size: 16px;
    line-height: 25.9px;
    margin: 0;
  }
  .p4 {
    font-size: 14px;
    line-height: 22.6px;
    margin: 0;
  }
  .p5 {
    font-size: 12px;
    line-height: 19.4px;
    margin: 0;
  }

  .comman-header-menu-right {
    position: static;
    margin: 0;
  }
  .arrow-scroll-abs {
    left: 0px;
  }
  .comman-section-100vh {
    .comman-card-img-wrap {
      transform: scale(0.7) translateZ(0);
      -webkit-transform: scale(0.7) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.7) translateZ(0);
      -ms-transform: scale(0.7) translateZ(0);
      -o-transform: scale(0.7) translateZ(0);
      transform-origin: 0 center;

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }

    .comman-card-info {
      &.pl30 {
        padding-left: 15px !important;
      }

      .comman-card-top-ul {
        margin-bottom: 20px;
      }

      h2.sec-heading {
        margin-bottom: 15px;
      }

      p.sec-para {
        margin-bottom: 25px;

        br {
          display: none;
        }
      }

      .comman-card-bottom-ul {
        margin-bottom: 20px;
      }

      .bttn {
        margin-top: 15px;
      }
    }
  }
  body {
    .comman-section {
      padding: 50px 0;
      overflow: hidden;

      .comman-card-info {
        .comman-card-bottom-ul {
          li {
            position: relative;
            padding-left: 20px;
            margin-right: 15px;
          }
        }
      }

      .vh100-100 {
        min-height: calc(100vh - 100px);
        position: relative;
      }
    }
  }

  .comman-footer {
    .comman-footer-row1 {
      height: calc(100vh - 30px);
    }

    .comman-footer-row2 {
      bottom: -20px;
    }

    .bottom-menu-bottom-left,
    .bottom-menu-bottom-right {
      ul {
        margin: 0 !important;
      }

      li {
        &:not(:last-child) {
          margin-right: 15px;
        }
      }
    }
  }

  .comman-top-menu {
    .top-menu-left {
      li {
        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }
    }

    .top-menu-right {
      li {
        &:not(:last-child) {
          margin-bottom: 35px;
        }
      }
    }
  }
  .carousel-indicators-ul {
    left: -30px;
  }
  .comman-solution-challenge {
    .portfolio-info-wrap2.ml85 {
      margin-left: 45px !important;
    }
  }
  .comman-bg-section {
    min-height: 600px;
  }
  .impact-section-upd {
    .comman-impact-bottom.ml85 {
      margin-left: 45px !important;
    }

    .portfolio-info-wrap2 {
      margin-left: 60px;
    }
  }
  .comman-card-img-wrap-md {
    transform-origin: 90px center !important;
  }
  .comman-card-info-lg {
    max-width: 450px !important;
  }
  .award-card {
    min-height: 250px;
    padding: 15px;
  }
  .home-sec-tab {
    .flex-column {
      .nav-link span::after {
        display: none;
      }

      .nav-link:not(:last-child) {
        margin-bottom: 35px;
      }
    }

    .tab-content {
      margin-left: 30px;
    }
  }
  .home-sec3 {
    .comman-card-info {
      margin-top: 40px;
      margin-left: 0px;
    }
  }
  .home-sec4-2 {
    left: -80px !important;
  }
  .home-sec4 {
    .comman-card-info {
      margin-top: -50px !important;
    }

    .client-logo-wrap {
      margin-left: 0;
      margin-top: 40px;

      ul {
        li {
          margin: 40px 0;
        }
      }
    }

    .client-logo-wrap-outer-col {
      left: 0;
    }
  }

  .home-sec7 {
    .process-img-wrap img {
      display: inline-block;
      width: 700px;
      height: auto;
      max-width: inherit;
      margin-top: 50px;
      margin-left: -170px;
    }
  }
  .comman-card-img-wrap .home-sec1-1 {
    right: -140px;
  }
  .comman-card-img-wrap .home-sec5-1 {
    top: 300px;
    left: -113px;
    z-index: 4;
  }
  .comman-card-img-wrap .home-sec5-2 {
    top: -20px;
    left: -84px;
    z-index: 3;
  }
  .comman-card-right {
    padding-left: 25px;
  }
  .comman-card-left {
    padding-right: 25px;
  }
  .home-sec8 {
    .comman-card-img-wrap2 {
      transform: scale(0.65) translateZ(0);
      -webkit-transform: scale(0.65) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.65) translateZ(0);
      -ms-transform: scale(0.65) translateZ(0);
      -o-transform: scale(0.65) translateZ(0);
      transform-origin: center right;

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .about-sec1 {
    .btn-line span::after {
      width: 28px;
      top: 9px;
    }
  }
  .about-sec3 {
    .p2 {
    }

    .comman-card-info {
      .comman-card-img-wrapper {
        margin-bottom: 15px;
      }
    }
  }
  .about-sec4 {
    .our-values-wrap {
      max-width: 500px;
      margin: 0 0 0 50px;
      height: 670px;
      overflow: auto;

      .our-values-card {
        margin-bottom: 50px;
      }
    }
  }
  .about-sec5 {
    .comman-card-info {
      // margin-top: -140px;
    }

    .about-expressions {
      top: -13px !important;
      right: -30px !important;
      width: 180px;
    }

    .our-expressions-wrap {
      margin-top: 0;
      transform: scale(0.65) translateZ(0) !important;
      -webkit-transform: scale(0.65) translateZ(0) !important;
      -moz-transform: scale(0.65) translateZ(0) !important;
      -ms-transform: scale(0.65) translateZ(0) !important;
      -o-transform: scale(0.65) translateZ(0) !important;
      transform-origin: center 200px;
      margin-left: -75px;
      /*.comman-video:;
{
            transform: scale(0.70) translateZ(0);
            -webkit-transform: scale(0.70) translateZ(0); -webkit-backface-visibility: hidden;
            -moz-transform: scale(0.70) translateZ(0);
            -ms-transform: scale(0.70) translateZ(0);
            -o-transform: scale(0.70) translateZ(0);
            transform-origin: center 200px;
            }
            .about-sec5-1 {
                position: absolute;
                top: 150px;
                right: 85px;
                z-index: 1;
                height: 330px;
            }*/
    }
  }
  .about-sec6 {
    .comman-card-img-wrap3-outer {
      transform: scale(0.7) translateZ(0);
      -webkit-transform: scale(0.7) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.7) translateZ(0);
      -ms-transform: scale(0.7) translateZ(0);
      -o-transform: scale(0.7) translateZ(0);
      transform-origin: center center;
      height: 700px;
    }
  }
  .about-sec7 {
    .comman-card-info {
      margin: 80px auto 0;
    }

    .comman-team-card {
      margin-top: 0px;
    }
  }
  .comman-team-card {
    height: 450px;

    .comman-team-card-head {
      transform: scale(0.8) translateZ(0);
      transform-origin: center bottom;
      -webkit-transform: scale(0.8) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.8) translateZ(0);
      -ms-transform: scale(0.8) translateZ(0);
      -o-transform: scale(0.8) translateZ(0);

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .work-comman-card-info {
    margin-top: 70px;
  }
  .work-sec6 {
    .comman-card-info {
      h2.sec-heading {
        br {
          display: none;
        }
      }
    }
  }

  .process-section1,
  .industries-section1 {
    .comman-card-info-left {
      z-index: 0;
      max-width: 460px !important;
    }

    .process-section1-row {
      margin-top: 70px;
    }

    .comman-card-info {
      &:not(:last-child) {
        margin-bottom: 120px;
      }
    }

    .process-img7 {
      right: 310px;
      height: 950px;
      display: none !important;
    }
  }
  .work-sec2 {
    .comman-card-info {
      max-width: 460px;
    }
  }

  .career-sec2 {
    .career-sec2-right {
      height: 670px;
      overflow: auto;
    }
  }

  .contact-section1 {
    overflow: hidden;

    .sec-heading {
      margin-top: 0;
    }

    .addr-wrap {
      margin-left: 50px;
      margin-top: 30px;

      &.mb90 {
        margin-bottom: 20px !important;
      }

      .addr-img-wrap {
        position: relative;
        max-width: 130px;
        margin: 0;
        height: 53px;
        transform: scale(0.7) translateZ(0);
        transform-origin: 0 0;
        -webkit-transform: scale(0.7) translateZ(0);
        -webkit-backface-visibility: hidden;
        -moz-transform: scale(0.7) translateZ(0);
        -ms-transform: scale(0.7) translateZ(0);
        -o-transform: scale(0.7) translateZ(0);

        img {
          filter: none;
          -webkit-filter: none;
          -moz-filter: blur(0px);
          -ms-filter: blur(0px);
          -webkit-backface-visibility: hidden;
        }
      }
    }
  }
  .g-capcha-col-wrap {
    margin-top: 0;
  }
  .term-col-wrap {
    margin-top: -7px;
  }

  .contact-section2 {
    overflow: hidden;

    .range-slider-main .slider.slider-vertical {
      height: 290px;
      width: 20px;
    }

    .my-budget-wrap {
      padding-left: 40px;
    }

    .career-form-wrap {
      margin-top: 80px;
    }
  }
  .career-form-wrap {
    margin-top: 120px;
  }
  .expertise-sec1 {
    .vh100-100 {
      overflow: hidden;
    }

    .comman-card-info {
      .sec-heading {
        br {
          display: none;
        }
      }
    }

    .comman-card-img-wrap2 {
      height: 300px;
      min-height: inherit;
      left: -100px;
      top: -200px;
    }

    img.expertise1 {
      transform: scale(0.75) translateZ(0);
      -webkit-transform: scale(0.75) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.75) translateZ(0);
      -ms-transform: scale(0.75) translateZ(0);
      -o-transform: scale(0.75) translateZ(0);
      transform-origin: center center;
      min-height: inherit;
      top: 0;
    }
  }

  .expertise-sec4 {
    padding-top: 0;
  }
  .expertise-sec2-1 {
    h2 {
      margin-top: 65px;
    }
  }
  .expertise-sec4,
  .expertise-sec5 {
    .vh100-100 {
      padding-top: 120px;
    }
  }

  .contact-section1 {
    .addr-wrap {
      &.mb90 {
        margin-bottom: 50px !important;
      }

      .addr-indo-wrap {
        margin-top: 15px;

        .addr-list-inline {
          margin-bottom: 20px;
          white-space: nowrap;
        }
      }
    }
  }
  .contact-section2 {
    .contact-form-inner {
      table {
        td {
          width: 50%;
          padding-bottom: 15px;
        }
      }
    }
  }
  .career-form-wrap {
    margin-top: 120px;

    .mb50 {
      margin-bottom: 10px !important;
    }

    .form-control-col-btn {
      margin-top: 20px;
    }

    .pr-form-control {
      position: relative;
      margin-bottom: 50px;
    }
  }
  .career-details-sec1 {
    .career-details-sec1-row {
      margin-top: 85px;
    }

    .career-details-sec2-row {
      margin-top: 45px;

      .career-details-card {
        margin-bottom: 50px;
      }
    }
  }

  .crubba-sec1 {
    .portfolio-img-wrap {
      transform: scale(0.75) translateZ(0);
      -webkit-transform: scale(0.75) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.75) translateZ(0);
      -ms-transform: scale(0.75) translateZ(0);
      -o-transform: scale(0.75) translateZ(0);
      transform-origin: right center;
      position: relative;
      min-height: 453px;

      .crubba-sec9-inner4 {
        top: -90px;
        left: -120px;
        width: 650px;
      }

      .crubba-sec9-inner5 {
        top: 10px;
        left: -40px;
        width: 470px;
      }
    }

    .crubba-sec1-right {
      width: 420px;
    }
  }
  .crubba-sec2 {
    background-size: 360px auto;

    .crubba-sec2-img2 {
      position: absolute;
      top: 0px;
      left: -20px;
    }

    .crubba-sec2-img3 {
      position: absolute;
      top: 40px;
      right: 0;
    }
  }
  .crubba-sec3-upd {
    background-image: none !important;
  }
  .crubba-sec3 {
    .crubba-sec3-right {
      transform: scale(0.75) translateZ(0);
      -webkit-transform: scale(0.75) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.75) translateZ(0);
      -ms-transform: scale(0.75) translateZ(0);
      -o-transform: scale(0.75) translateZ(0);
      transform-origin: center center;
    }
  }

  .crubba-sec4 {
    padding-bottom: 0;

    .crubba-sec4-center {
      margin-top: 30px;
    }

    .crubba-sec4-bottom1 {
      width: 300px;
    }

    .crubba-sec4-bottom {
      margin-top: 40px;
    }
  }
  .crubba-sec5 {
    padding-bottom: 0 !important;
    background-position: right bottom 50px;
    background-size: 150px;

    .crubba-sec5-center {
      margin-top: 30px;
    }

    .crubba-sec5-bottom3 {
      position: absolute;
      top: 60px;
      left: -10px;
    }

    .crubba-sec5-col-height {
      height: 660px;
      overflow: hidden;
    }
  }
  .crubba-sec6 {
    .crubba-sec6-bottom {
      background-size: contain;
      padding: 20px;
      height: 380px;
      margin-top: 30px;

      .owl-caroual-comman {
        display: block;
        width: 700px;
        margin: auto;
      }
    }
  }

  .crubba-sec7 {
    & > .container {
      overflow: hidden;
    }

    .crubba-sec6-bottom-before {
      position: absolute;
      bottom: 170px;
      right: -60px;
      z-index: 6;
      width: 150px;
    }

    .crubba-sec7-bottom {
      transform: none;
      -webkit-transform: none;
      -webkit-backface-visibility: hidden;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform-origin: center top;
      min-height: inherit;
      height: 600px;

      .crubba-sec7-bottom3-wrap {
        .crubba-sec7-bottom4-hover {
          top: 27px;
          left: 50%;
          bottom: 0;
          width: 650px;
          height: 413px;
          margin-left: 0;
        }

        .crubba-sec7-bottom3 {
          width: 100%;
        }

        .crubba-sec7-bottom2 {
          position: absolute;
          top: 145px;
        }
      }

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }

  .crubba-sec8 {
    .crubba-sec3-right {
      .img-fluid {
        transform: scale(0.65) translateZ(0);
        -webkit-transform: scale(0.65) translateZ(0);
        -webkit-backface-visibility: hidden;
        -moz-transform: scale(0.65) translateZ(0);
        -ms-transform: scale(0.65) translateZ(0);
        -o-transform: scale(0.65) translateZ(0);
        transform-origin: right center;
      }
    }

    .crubba-sec8-center {
      margin-left: 20px;
      margin-top: 40px;
    }

    .crubba-sec8-bottom1 {
      max-width: 100%;
      display: block;
    }

    .crubba-sec4-bottom1 {
      position: absolute;
      top: 230px;
      left: -90px;
      z-index: 6;
      width: 230px;
    }
  }
  .crubba-sec9 {
    background-size: 280px auto;
    background-position-y: 100px;

    .crubba-sec3-left {
      margin-top: -100px;
    }

    .vh100-100 {
      min-height: 500px !important;
    }

    .crubba-sec9-img-wrap {
      .crubba-sec9-img {
        width: 600px;
        height: auto;
      }
    }

    .crubba-sec9-img2 {
      left: -50px;
      z-index: 0;
      width: 250px;
    }

    .crubba-sec9-bottom2 {
      max-width: 1010px;
    }
  }

  .crubba-sec11 {
    background-size: 280px auto;
    background-position-y: 250px;
    padding-bottom: 0;

    .container-fluid {
      overflow: hidden;
    }

    .crubba-sec11-bottom {
      position: relative;
      left: -20px;
      min-height: inherit;
      height: 800px;
    }

    .crubba-sec11-bottom1 {
      max-width: 1100px;
    }
  }
  .crubba-sec12 {
    &::after {
      height: 680px;
    }

    .mobile-row {
      height: 1400px;
    }

    .crubba-sec12-1 {
      position: relative;
      left: -80px;
      width: 320px;
    }

    .crubba-sec12-2 {
      margin: -70px 0 0 35px;
      max-width: 100%;
    }

    .crubba-sec12-3 {
      display: block;
      margin: 60px auto -10px;
      max-width: 100%;
    }

    .crubba-sec12-4 {
      max-width: 100%;
    }

    .crubba-sec12-6 {
      display: block;
      margin: 182px auto 0 40px;
      width: 400px;
    }

    .crubba-sec12-7 {
      max-width: 100%;
    }

    .crubba-sec12-5-wrap {
      position: relative;
      transform: scale(0.9) translateZ(0) !important;
      -webkit-transform: scale(0.9) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.9) translateZ(0) !important;
      -ms-transform: scale(0.9) translateZ(0) !important;
      -o-transform: scale(0.9) translateZ(0) !important;
      transform-origin: center top;
      height: 600px;
      margin-left: -10px;

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }

  .comman-impact-section {
    .h44 {
      margin-bottom: 40px;
    }

    .comman-impact-top {
      hr {
        margin: 45px auto -30px;
      }
    }

    .comman-impact-bottom {
      .h5-20 {
        margin-top: 30px;
      }
    }

    .crubba-impact1-circle {
      top: -50px;
      transform: scale(0.85) translateZ(0);
      -webkit-transform: scale(0.85) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.85) translateZ(0);
      -ms-transform: scale(0.85) translateZ(0);
      -o-transform: scale(0.85) translateZ(0);

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }

  .failure-section {
    .vh100-100 {
      min-height: 500px !important;
    }

    .failure-sec-right {
      top: auto;
    }
  }
  .error-page-section1 {
    .portfolio-info-wrap2 {
      max-width: 574px;

      .bttn {
        margin-top: 40px;
      }
    }

    .error-img-wrap {
      position: relative;

      .error-page {
        max-width: 100%;
        width: auto;
        display: block;
      }

      .error-icon {
        max-width: 100%;
        width: 150px;
        display: block;
        top: 0;
        right: 60px;
        margin-left: 0;
      }
    }
  }

  .moneyguru-sec1 {
    padding-bottom: 0 !important;

    [data-aos="new-animation4"].aos-animate {
      width: 75%;
    }
  }
  .moneyguru-sec3 {
    .moneyguru-sec3-bg {
      margin-top: 110px;
      margin-left: 220px;
    }
  }
  .moneyguru-header-section {
    .moneyguru-header-right-wrap {
      min-height: inherit;
    }

    .moneyguru-banner-img {
      width: 500px;
    }
  }

  .moneyguru-strategy-section {
    .moneyguru-strategy-left-col-img {
      position: absolute;
      top: -40px;
      left: -50px;
      right: 0;
      bottom: 0;
      height: 100vh;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }

    .moneyguru-strategy-right {
      margin-left: 0;
    }
  }

  .moneyguru-story-section {
    overflow: hidden;

    .moneyguru-story-left {
      margin-top: 30px;
      margin-left: -70px;
      transform: scale(0.7) translateY(-50%);
      transform-origin: 0px 0px;
      -webkit-transform: scale(0.7) translateY(-50%);
      -moz-transform: scale(0.7) translateY(-50%);
      -ms-transform: scale(0.7) translateY(-50%);
      -o-transform: scale(0.7) translateY(-50%);
      position: absolute;
      top: 50%;
      left: 0;
    }
  }
  .moneyguru-insights-section {
    overflow: hidden;

    .moneyguru-insights-right-col {
      .moneyguru-insights-right {
        height: 100%;

        video {
          width: 470px;
          transform: translateY(-50%);
          position: absolute;
          top: 50%;
          left: 60px;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
        }
      }
    }
  }
  .moneyguru-investments-section {
    .moneyguru-investments-left {
      height: 100%;
      margin-left: -140px;
    }

    .moneyguru-investments-left-col {
      video {
        margin-left: 0;
        width: 600px;
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
      }
    }
  }
  .moneyguru-gateway-section {
    .moneyguru-gateway-right {
      height: 100%;

      video {
        width: 600px;
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
      }
    }
  }
  .moneyguru-investing-section {
    overflow: hidden;

    .moneyguru-investing-left-col {
      .moneyguru-investing-left {
        height: 100%;
      }

      video {
        margin-left: -120px;
        width: 600px;
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
      }
    }
  }
  .moneyguru-comprehensive-section {
    .moneyguru-comprehensive-bottom {
      height: 100%;

      video {
        position: static;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        width: 750px;
      }
    }
  }
  .easy-plan-sec3 {
    .container {
      max-width: inherit !important;
    }

    .easy-plan-strategy-right {
      margin-right: 78px;
    }
  }
  .easy-plan-sec4 {
    height: 730px;
    overflow: hidden;

    .easy-plan-strategy-left {
      -webkit-backface-visibility: hidden;
      transform: scale(0.65) translateZ(0) !important;
      min-height: 500px;
      transform-origin: center 40px;
      -webkit-transform: scale(0.65) translateZ(0) !important;
      -moz-transform: scale(0.65) translateZ(0) !important;
      -ms-transform: scale(0.65) translateZ(0) !important;
      -o-transform: scale(0.65) translateZ(0) !important;

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }

    .easy-plan-strategy-left-inner2 {
      top: 220px;
      margin-bottom: 0;
    }

    .easy-plan-onboarding1 {
      position: absolute;
      max-width: initial;
      top: 50px;
      left: 100px;
      max-width: 1000px;
    }

    .easy-plan-onboarding2 {
      position: absolute;
      max-width: initial;
      top: -10px;
      left: 50px;
      max-width: 1110px;
    }
  }

  .easy-plan-sec5 {
    .ease-plan-chat-wrap {
      min-height: 500px;
      transform-origin: center 0px;
      transform: scale(0.7) translateY(-50%);
      -webkit-transform: scale(0.7) translateY(-50%);
      -moz-transform: scale(0.7) translateY(-50%);
      -ms-transform: scale(0.7) translateY(-50%);
      -o-transform: scale(0.7) translateY(-50%);
      position: absolute;
      top: 50%;

      .easy-plan-chat3 {
        position: absolute;
        bottom: -40px;
        right: -50px;
      }
    }
  }

  .easy-plan-sec6 {
    .easy-plan-goal-right {
      margin-top: 180px;
      margin-bottom: 100px;
    }

    .easy-plan-sec6-inner {
      min-height: 500px;
      transform-origin: 0 250px;
      transform: scale(0.55) translateY(-50%);
      -webkit-transform: scale(0.55) translateY(-50%);
      -moz-transform: scale(0.55) translateY(-50%);
      -ms-transform: scale(0.55) translateY(-50%);
      -o-transform: scale(0.55) translateY(-50%);
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -110px;
    }
  }

  .easy-plan-sec7 {
    height: 650px;
    overflow: hidden;

    .easy-plan-sect6-inner1 {
      position: absolute;
      z-index: 2;
      max-width: initial;
      animation: rotation-ani 15s infinite linear;
      -webkit-animation: rotation-ani 15s infinite linear;
      display: block !important;
      top: -190px;
      left: -140px;
    }

    .easy-plan-sect6-inner3 {
      left: 80px;
    }

    .easy-plan-sect6-inner2 {
      left: 260px;
    }
  }

  .easy-plan-sec8 {
    .easy-plan-sec8-inner {
      min-height: 500px;
      transform-origin: center 250px;
      transform: scale(0.55) translateY(-50%);
      -webkit-transform: scale(0.55) translateY(-50%);
      -moz-transform: scale(0.55) translateY(-50%);
      -ms-transform: scale(0.55) translateY(-50%);
      -o-transform: scale(0.55) translateY(-50%);
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -60px;
    }
  }
  .easy-plan-sec9 {
    .easy-plan-sec9-inner {
      min-height: 600px;
      transform: scale(0.7) translateZ(0);
      -webkit-transform: scale(0.7) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.7) translateZ(0);
      -ms-transform: scale(0.7) translateZ(0);
      -o-transform: scale(0.7) translateZ(0);
      transform-origin: center 200px;
      height: 620px;

      .easy-plan-sec9-inner1 {
        position: absolute;
        bottom: -122px;
        left: -182px;
        z-index: 4;
      }

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .easy-plan-sec1 {
    .easy-plan-banner-img {
      max-width: inherit;
      width: 380px;
      margin-left: -50px;
      margin-top: 70px;
    }
  }
  .easy-plan-sec10 {
    .easy-plan-sec10-inner {
      height: 600px;
      min-height: inherit;
      transform: scale(0.7) translateZ(0);
      -webkit-transform: scale(0.7) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.7) translateZ(0);
      -ms-transform: scale(0.7) translateZ(0);
      -o-transform: scale(0.7) translateZ(0);
      transform-origin: center 200px;

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }

  .easy-plan-sec7 {
    .easy-plan-sec7-inner {
      .easy-plan-sec7-inner1,
      .easy-plan-sec7-inner2,
      .easy-plan-sec7-inner3,
      .easy-plan-sec7-inner4 {
        width: 80%;
      }
    }
  }

  .rsmart-sec3 {
    .rsmart-right-wrap {
      min-height: 600px;
      -webkit-backface-visibility: hidden;
      transform: scale(0.65) translateZ(0) !important;
      -webkit-transform: scale(0.65) translateZ(0) !important;
      -moz-transform: scale(0.65) translateZ(0) !important;
      -ms-transform: scale(0.65) translateZ(0) !important;
      -o-transform: scale(0.65) translateZ(0) !important;
      transform-origin: center 260px;

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }

  .rsmart-sec6 {
    .container-fluid {
      .rsmart-left-wrap {
        min-height: 510px;
        padding-top: 50px;
      }
    }
  }
  .rsmart-sec8 {
    overflow: hidden;

    .rsmart-app-wrap {
      height: inherit !important;
    }

    .rsmart-right-wrap {
      transform: scale(0.8) translateZ(0);
      -webkit-transform: scale(0.8) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.8) translateZ(0);
      -ms-transform: scale(0.8) translateZ(0);
      -o-transform: scale(0.8) translateZ(0);
      transform-origin: center 0;
      top: 0;

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .rsmart-sec9 {
    overflow: hidden;

    .rsmart-left-wrap,
    .rsmart-right-wrap {
      height: 850px;
      display: block;
    }

    .rsmart-app-view1-img,
    .rsmart-app-view2-img {
      width: 360px;
    }
  }

  .rsmart-sec10 {
    .rsmart-left-wrap {
      transform: scale(0.8) translateZ(0);
      -webkit-transform: scale(0.8) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.8) translateZ(0);
      -ms-transform: scale(0.8) translateZ(0);
      -o-transform: scale(0.8) translateZ(0);
      transform-origin: center center;
    }

    .rsmart-right-wrap {
      li:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .mytrux-logo-sec3 {
    .mytrux-logo-right-wrap {
      transform: scale(0.8) translateZ(0);
      -webkit-transform: scale(0.8) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.8) translateZ(0);
      -ms-transform: scale(0.8) translateZ(0);
      -o-transform: scale(0.8) translateZ(0);
      transform-origin: center center;
      min-height: inherit;
      height: 530px;
    }
  }
  .mytrux-logo-sec6 {
    .mytrux-logo-sec6-inner {
      h5 {
        margin-top: -20px;
      }
    }

    .mytrux-logo-sec6-1,
    .mytrux-logo-sec6-2 {
      width: 400px;
      margin: auto;
    }
  }
  .mytrux-logo-sec9 {
    .p2 {
      margin-top: 100px;
    }
  }
  .mytrux-logo-sec11 {
    .mytrux-logo-left-wrap {
      margin-top: -150px;
    }

    .mytrux-logo-right-wrap {
      position: relative;
      transform: scale(0.7) translateZ(0);
      transform-origin: center 0;
      -webkit-transform: scale(0.7) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.7) translateZ(0);
      -ms-transform: scale(0.7) translateZ(0);
      -o-transform: scale(0.7) translateZ(0);

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .mytrux-logo-sec14 {
    .mytrux-logo-sec14-2 {
      position: absolute;
      bottom: -320px;
      left: -220px;
      z-index: 2;
      width: 660px;
    }
  }

  .mytrux-logo-sec13-1-wrap,
  .mytrux-logo-sec12-1-outer {
    height: 500px;
    min-height: inherit;

    .img-fluid {
      max-width: 100%;
      height: 100%;
    }
  }
  .mytrux-website-sec1 {
    padding-bottom: 0 !important;

    .mytrux-website-center-wrap {
      margin-top: 70px;
    }
  }

  .mytrux-website-sec7 {
    .mytrux-website-sec7-1-outer {
      height: 2900px;
    }
  }
  .mytrux-website-sec9 {
    .container {
      height: 2500px;
      display: block;
    }
  }
  .mytrux-website-sec10 {
    .mytrux-website-top-wrap {
      margin-left: -70px;
      transform: scale(0.6) translateZ(0);
      transform-origin: center 0;
      -webkit-transform: scale(0.6) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.6) translateZ(0);
      -ms-transform: scale(0.6) translateZ(0);
      -o-transform: scale(0.6) translateZ(0);
      height: 550px;

      .mytrux-website-sec10-1-1 {
        display: block;
        margin-right: -140px;
        margin-top: 20px;
      }

      .mytrux-website-sec10-3 {
        position: absolute;
        top: 50px;
        left: 225px;
      }

      .mytrux-website-sec10-1 {
        width: 760px;
        margin-right: -100px;
        margin-top: 120px;
      }

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .mytrux-website-sec9 {
    .mytrux-website-sec9-1 {
      max-width: initial;
      margin-left: -250px;
      margin-top: 70px;
      position: relative;
      z-index: 5;
      width: 850px;
    }

    .mytrux-website-sec9-3 {
      max-width: initial;
      margin-top: -220px;
      margin-left: -230px;
      width: 800px;
    }

    .mytrux-website-sec9-2 {
      margin-left: 6px;
    }
  }

  .authntick-sec1 {
    .authntick-fingerprint2 {
      position: absolute;
      top: -150px;
      left: 420px;
      max-width: 340px;
    }

    .authntick-fingerprint1 {
      position: absolute;
      bottom: -280px;
      left: -104px;
      max-width: 340px;
    }

    .authntick-left-wrap {
      width: 440px;
    }

    .authntick-right-wrap {
      img {
        max-width: 100%;
        margin-left: 60px;
        margin-top: 20px;
      }
    }
  }
  .authntick-sec2 {
    .authntick-sec2-1 {
      max-width: 340px;
    }

    .authntick-left-wrap {
      padding-right: 10px;
    }
  }
  .authntick-sec3 {
    .authntick-sec3-bg {
      background-size: contain;
    }
  }
  .authntick-sec4 {
    .authntick-sec4-3 {
      max-width: 340px;
    }

    .authntick-sec4-2-outer {
      .authntick-sec4-2 {
        max-width: 100%;
        left: 0;
      }
    }
  }
  .authntick-sec6 {
    .authntick-sec6-2-outer {
      right: -70px;
      position: relative;
    }

    .authntick-sec6-2 {
      max-width: 100%;
      position: relative;
      z-index: 3;
    }

    .authntick-sec6-1 {
      max-width: 350px;
    }
  }
  .authntick-sec7 {
    [data-aos="new-animation5"].aos-animate {
      width: 24%;
    }

    .ui-card-slider {
      width: 340px;
      height: 230px;
      transform: scale(1.5) translateZ(0);
      -webkit-transform: scale(1.5) translateZ(0);
      -moz-transform: scale(1.5) translateZ(0);
      -ms-transform: scale(1.5) translateZ(0);
      -o-transform: scale(1.5) translateZ(0);
      margin-right: 60px;
    }
  }
  .authntick-sec8 {
    .authntick-sec8-2-outer {
      .authntick-wizard-carosual {
        width: 500px;
        margin-top: 115px;
        margin-left: 0;
      }
    }
  }
  .authntick-sec9 {
    .bottom-col-set {
      bottom: -5px;
    }
  }
  .vigan-sec2 {
    .vigan-right-wrap {
      .img-fluid {
        max-width: 100%;
        margin-left: 60px;
      }
    }
  }
  .vigan-sec3 {
    .vigan-sec3-1-outer1 {
      .vigan-sec2-1 {
        width: 300px;
      }
    }
  }
  .vigan-sec7 {
    .vigan-right-wrap {
      position: absolute;
      top: -30px;
      left: 0;

      .img-fluid {
        max-width: 100%;
      }
    }
  }
  .comman-comparison {
    height: 650px;
  }
  .mytrux-logo-sec7 {
    #comparison {
      #divisor,
      figure {
        background-position-x: 280px;
      }
    }
  }

  .mytrux-logo-sec9 {
    .portfolio-info-wrap2 h3 {
      margin-bottom: 100px;
    }

    .p2 {
      margin-top: 50px;
    }
  }
  .mytrux-logo-sec10 {
    .app-icon-ul {
      height: inherit;
      white-space: nowrap;
      margin: 0;

      li {
        margin: 0;

        img {
          width: 70%;
        }

        &:nth-child(3) {
          top: -10px;
          position: relative;
        }

        &:nth-child(4) {
          top: -13px;
          position: relative;
        }

        &:nth-child(5) {
          top: -13px;
          position: relative;
        }
      }
    }
  }
  .mytrux-logo-sec11 {
    height: 630px;

    .mytrux-logo-sec11-2 {
      width: 780px;
    }
  }
  .mytrux-webapp-sec1 {
    .mytrux-webapp-sec1-1 {
      width: 480px;
    }
  }
  .mytrux-webapp-sec3 {
    .mytrux-banner-line {
      bottom: -50px;
    }
  }
  .mytrux-webapp-sec4 {
    .mytrux-webapp-sec4-bottom {
      .viewer, .ng-draggable {
        height: 400px;
      }
    }
  }

  .mytrux-webapp-sec6 {
    .row.vh100-100 {
      min-height: 500px;
    }

    .mytrux-webapp-sec6-right {
      .mytrux-web-sec6-1 {
        right: 50px;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform-origin: right top;
        top: 200px;

        img {
          width: 600px;
        }
      }

      .mytrux-web-sec6-2 {
        right: -20px;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform-origin: right top;
        top: 140px;

        img {
          width: 700px;
        }
      }
    }
  }

  .mytrux-webapp-sec8 {
    .bottom-col-set {
      transform: scale(0.7) !important;
      transform-origin: center bottom;
      -webkit-transform: scale(0.7) !important;
      -moz-transform: scale(0.7) !important;
      -ms-transform: scale(0.7) !important;
      -o-transform: scale(0.7) !important;
    }
  }

  .mytrux-website-sec10 {
    .mytrux-website-top-wrap {
      .mytrux-website-sec10-1-1 {
      }
    }
  }
  .insisive-website-sec1 {
    .insisive-website-sec1-1 {
      max-width: 500px;
    }
  }
  .insisive-website-sec2 {
    .insisive-website-sec2-1 {
      height: 220px;
      object-fit: cover;
      margin-top: 60px;
    }
  }

  .insisive-website-sec6 {
    .insisive-website-sec6-1-outer {
      .insisive-website-sec6-1 {
        max-width: initial;
        height: 280px;
        margin-left: 30px;
        margin-top: 10px;
      }
    }
  }
  .insisive-website-sec7 {
    .insisive-website-sec7-1-outer {
      .insisive-website-sec7-1 {
        max-width: initial;
        height: 540px;
        margin-left: -280px;
      }
    }
  }
  .insisive-website-sec8 {
    padding-bottom: 0;

    .insisive-website-sec8-right {
      .img-fluid {
        height: 100%;
        max-width: inherit;
        width: auto;
      }
    }

    .portfolio-info-wrap2 {
      margin-top: -50px;
    }
  }
  .alloch-sec1 {
    height: 638px;
    overflow: hidden;
    position: relative;

    .alloch-right-wrap {
      .alloch-sec1-1 {
        width: 260px;
      }

      .alloch-sec1-2 {
        position: absolute;
        top: -70px;
        left: 20px;
        width: 220px;
      }
    }

    .portfolio-info-banner-l.portfolio-info-wrap2 {
      margin-top: -80px;
    }
  }
  .alloch-sec2 {
    .vh100-100 {
      min-height: 480px;
    }

    .alloch-left-wrap {
      margin-top: 0;
      margin-left: 18px;
      max-width: 400px;
    }

    .alloch-sec2-2 {
      display: none;
    }

    .alloch-right-wrap {
      .alloch-sec2-3-inner {
        display: none !important;
      }

      &::after {
        transform: scale(0.55);
        transform-origin: center center;
        -webkit-transform: scale(0.55);
        -moz-transform: scale(0.55);
        -ms-transform: scale(0.55);
        -o-transform: scale(0.55);
      }
    }
  }

  .alloch-sec7 {
    .vh100-100 {
      min-height: 650px !important;
    }

    .alloch-sec7-right {
      transform: scale(0.7);
      transform-origin: center 0;
      -webkit-transform: scale(0.7);
      -moz-transform: scale(0.7);
      -ms-transform: scale(0.7);
      -o-transform: scale(0.7);
      height: 550px;
    }

    .alloch-sec7-4-outer {
      .alloch-sec7-4 {
        width: 220px;
      }
    }
  }

  .alloch-sec17 {
    .alloch-sec7-right.portfolio-info-wrap {
      table {
        .alloch-sec3-4-outer {
          position: absolute;
          bottom: 90px;

          .alloch-sec3-4 {
            width: 220px;
          }
        }
      }
    }
  }
  .alloch-sec10 {
    .portfolio-info-wrap.alloch-sec10-left {
      transform: scale(0.7);
      transform-origin: center center;
      -webkit-transform: scale(0.7);
      -moz-transform: scale(0.7);
      -ms-transform: scale(0.7);
      -o-transform: scale(0.7);
    }
  }
  .term-col-wrap {
    margin-top: 4px;

    label.error {
      left: 50px;
      bottom: -30px;
    }
  }

  .next-page-section {
    .join-team-wrap {
      margin-top: 0px;

      h6 {
        margin-bottom: 20px;
      }

      .next-page-img {
        width: 360px;
        top: 30px;
      }
    }
  }

  .rsmart-sect1 {
    .rsmart-overview-before-img {
      top: -140px;
      margin-left: 90px;
    }

    .rsmart-overview-after-img {
      bottom: -290px;
      margin-left: 240px;
    }
  }

  .rsmart-sect3 {
    .rsmart-challenges-after {
      position: absolute;
      top: 20px;
      max-width: inherit;
      left: -160px;
    }

    .rsmart-challenges-before {
      right: 100px;
    }
  }
  .rsmart-sect6 {
    .rsmart-sec5-after {
      position: absolute;
      top: -60px;
      max-width: inherit;
      left: -160px;
    }
  }
  .comman-card-img-wrap {
    .crubba-thumb-1 {
      width: 710px;
    }

    .mytrux-thumb-1 {
      left: -20px;
    }

    .mytrux-thumb-2 {
      left: 100px;
    }

    .mytrux-thumb-3 {
      left: -310px;
    }

    .bagshaha-thumb-2 {
      left: -150px;
    }

    .bagshaha-thumb-3 {
      left: 140px;
    }
  }
  .vigan-sec6 {
    .vigan-right-wrap {
      width: 470px;
      margin-top: 20px;
      margin-left: 90px;
    }
  }
  .smartlinks-sec1 {
    .smartlinks-logo-after {
      position: absolute;
      bottom: 21px;
      right: -110px;
      z-index: 1;
      width: 500px;
    }
  }

  .smartlinks-sect2 {
    .smartlinks-logo-after2 {
      position: absolute;
      bottom: 21px;
      right: -110px;
      z-index: 1;
      width: 500px;
    }
  }

  .smartlinks-sec7 {
    #comparison {
      #divisor,
      figure {
        background-position-x: 200px;
      }
    }
  }
  .easy-plan-sec4 .owl-caroual-comman {
    position: absolute;
    top: 190px;
    left: 50%;
    width: 300px;
    margin: auto;
    margin-left: auto;
    transform: translateX(-50%) scale(0.75);
    -webkit-transform: translateX(-50%) scale(0.75);
    -moz-transform: translateX(-50%) scale(0.75);
    -ms-transform: translateX(-50%) scale(0.75);
    -o-transform: translateX(-50%) scale(0.75);
    z-index: 49;
    height: 700px;
    margin-left: 30px;
  }
  .phycheme-sec5 {
    padding: 0;

    .owl-carousel .owl-item img,
    .phycheme-logo-concept {
      height: 100%;
      width: 100%;
      object-fit: contain;
      display: inline-block;
    }
  }
  .phycheme-sec11 {
    height: 560px;
    overflow: hidden;
  }
  .moneyguru-sec-website1 {
    .portfolio-info-banner-r {
      .moneyguru-banner-img {
        margin-left: -80px;
      }

      .moneyguru-banner-after {
        margin-left: 0;
      }
    }
  }
  .moneyguru-sec-website6 {
    .moneyguru-insights-right {
      .moneyguru-platform {
        display: inline-block;
        max-width: 560px;
        margin-top: 80px;
        margin-left: -80px;
      }
    }
  }
  .moneyguru-sec-website7,
  .moneyguru-sec-website8 {
    .moneyguru-insights-left {
      white-space: nowrap;
    }

    .moneyguru-insights-right-col {
      .moneyguru-insights-right {
        img {
          float: right;
          display: block;
          max-width: 450px;
          margin-right: -40px;
        }
      }
    }
  }
  .moneyguru-sec-website9 {
    .moneyguru-landing-outer {
      .mytrux-details-up {
        position: absolute;
        top: 160px;
        left: 5px;
        z-index: 0;
        width: 150px;
      }
    }
  }
  .notion-edge-sec8 {
    #comparison {
      figure,
      #divisor {
        background-position: 270px center;
      }
    }
  }
  .portfolio-branding-vimson-body {
    .notion-edge-sec1 {
      .notion-edge-logo-bg {
        display: inline-block;
        position: absolute;
        bottom: 140px;
        left: auto;
        z-index: 0;
        width: 600px;
        right: -170px;
      }
    }
  }
  .portfolio-webapp-insisive-body {
    .insisive-website-sec1 {
      .insisive-webapp-banner {
        display: inline-block;
        width: 280px;
      }

      .insisive-logo-after {
        position: absolute;
        bottom: 220px;
        right: 0px;
        z-index: 1;
        width: 200px;
      }
    }

    .insisive-website-sec8 {
      .insisive-website-sec8-right {
        margin-top: -20px;
      }
    }

    .insisive-webapp-4 {
      .insisive-straight-up-outer {
        position: absolute;
        top: 110px;
        right: -200px;
        bottom: auto;
        left: 0;
        z-index: 9;
      }
    }
  }

  .portfolio-website-bynry-body {
    .bynry-website-sec1 {
      .bynry-banner-wrap {
        .img-fluid {
          height: 75vh;
        }
      }
    }

    .bynry-website-sec2 {
      .moneyguru-sec2-left {
        margin-left: 0;

        .img-fluid {
          max-width: 490px;
        }
      }
    }

    .bynry-website-sec4 {
      .authntick-center-wrap {
        h3 {
          white-space: nowrap;
        }
      }

      .authntick-sec9-2-outer {
        img {
          display: inline-block;
          width: 685px;
        }
      }
    }

    .bynry-website-sec5 {
      .insisive-website-sec8-right {
        .img-fluid {
          display: inline-block;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .bynry-website-sec6 {
      [data-aos="new-animation5"].aos-animate {
        width: 24%;
      }

      .img-fluid.bynry-landing-page.lazy {
        max-width: 550px;
        margin-top: 50px;
        margin-left: -50px;
      }
    }

    .bynry-website-sec7 {
      .bagshaha-sec9-2-outer {
        .bynry-devices {
          max-width: 460px;
          margin-left: 0;
        }
      }
    }
  }
  .expertise-sect2 {
    .expertise-container {
      padding-top: 0;
    }

    .expertise-sect-left {
      .expertise-sect-left-body {
        .expertise-icon-big {
          width: 260px;
        }
      }
    }
  }
  .authntick-sect5 {
    .authntick-sect5-laptop-wrap {
      img {
        height: auto;
        width: auto;
      }
    }

    .authntick-sect5-overlay-wrap {
      margin-left: -300px;
      margin-top: -30px;

      img {
        width: 280px;
      }
    }
  }
  .phycheme-sect3-right-adj {
    margin-top: -180px;
    margin-left: 60px;
  }
  .comman-solution-challenge {
    .portfolio-info-wrap2 {
      min-height: 340px;
    }
  }
  .portfolio-thumb-all {
    &:nth-child(odd) {
      padding-top: 50px !important;
    }

    .work-card {
      margin-bottom: 100px !important;

      .work-card-img {
        position: relative;
        transform: scale(0.8);
        transform-origin: center top;
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        -o-transform: scale(0.8);
        height: 480px;
      }

      .work-card-body {
        width: 360px;
      }
    }
  }
  .our-expressions-upd {
    position: relative;
    height: 480px;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
  }
  .home-sec1 {
    .comman-card-img-wrap-upd.hide-mobile {
      transform: scale(0.7);
      -webkit-transform: scale(0.7);
      -moz-transform: scale(0.7);
      -ms-transform: scale(0.7);
      -o-transform: scale(0.7);
      transform-origin: right 0;
    }
  }
  .home-sec6 {
    .award-logo-wrap {
      padding-left: 0;

      ul {
        // max-width: 230px;
        float: left;
      }
    }
  }
  .services-user-experience-design-wrap {
    transform-origin: center right !important;
  }
  .services-brand-strategy-identity-design-wrap {
    transform-origin: center right !important;
  }
  .process-page-body .comman-card-img-wrap-upd {
    transform: scale(0.8);
    transform-origin: right center;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
  }

  .services-user-experience-design-wrap {
    transform-origin: center right !important;
  }
  .portfolio-website-insisive2-body {
    .insisive-website-sect1-1 {
      margin: auto !important;
      display: block !important;
      text-align: center;
    }

    .insisive-website-sect2-img {
      width: 500px;
    }

    [data-aos="new-animation5"].aos-animate {
      width: 26.5%;
    }

    .img-fluid.insisive-website-sect5-img.lazy {
      margin-left: 0;
    }

    .insisive-website-sect7-wrap {
      height: 530px;
    }

    .insisive-website-sect2-center {
      max-width: 470px;
      margin: 100px 0 160px;
      text-align: center;
      float: right;
    }

    .insisive-website-sect7-wrap .insisive-website-sect7-hover {
      margin-left: 10px;
    }
  }
  .insisive-website-sec7 .insisive-website-sec7-right {
    float: left;
    padding-left: 0;
  }
  .img-fluid.smartlinks-sect4-1.lazy {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .portfolio-webapp-mytrux-body {
    .mytrux-webapp-sect1 {
      [data-aos="new-animation7"].aos-animate {
        width: 67%;
      }

      .mytrux-webapp-sect1-1 {
        margin-top: -10px !important;
        right: -27px !important;
      }

      .mytrux-webapp-sect1-2 {
        position: absolute;
        left: -70px;
        top: 156px;
        z-index: 3;
        width: 300px;
      }
    }

    .mytrux-webapp-sect2 {
      .mytrux-website-sect2-1 {
        position: absolute;
        bottom: -45px;
        right: -50px;
        z-index: 3;
        width: 270px;
      }
    }

    .mytrux-webapp-sect3 {
      .mytrux-webapp-sect3-left-list {
        transform: scale(0.7);
        -webkit-transform: scale(0.7);
        -moz-transform: scale(0.7);
        -ms-transform: scale(0.7);
        -o-transform: scale(0.7);
      }

      .mytrux-webapp-sect3-right-list {
        li {
          position: relative;
          padding-left: 40px;
          margin-bottom: 40px;
        }
      }
    }

    .comman-solution-challenge .portfolio-info-wrap2 {
      min-height: inherit;
    }

    .mytrux-webapp-sect4 {
      .mytrux-webapp-sect4-1,
      .mytrux-webapp-sect4-2,
      .mytrux-webapp-sect4-3 {
        max-width: 500px;
      }

      .mytrux-webapp-sect4-1 {
        left: -33px;
      }

      .mytrux-webapp-sect4-3 {
        margin-top: 44px;
      }
    }

    .mytrux-webapp-sect5 {
      .mytrux-webapp-sect5-1 {
        max-width: 630px;
        margin-top: -160px;
        margin-left: -62px;
      }

      .mytrux-webapp-sect5-2 {
        max-width: 730px;
        position: relative;
        top: -50px;
        left: -180px;
      }
    }

    .mytrux-webapp-sect6 {
      .mytrux-webapp-sect6-1-wrap {
        .mytrux-webapp-sect6-1 {
          max-width: 550px;
          margin-top: 30px;
          margin-left: -30px;
        }
      }

      .mytrux-webapp-sect6-2-wrap {
        .mytrux-webapp-sect6-2 {
          max-width: 550px;
          margin-top: 110px;
          margin-left: -100px;
        }
      }
    }

    .mytrux-webapp-sect7 {
      .mytrux-webapp-sect7-1-wrap {
        padding-top: 0;
      }
    }
  }
  .portfolio-webapp-mytrux-body .mytrux-webapp-sect6 .mytrux-webapp-sect6-1-wrap p {
    top: 15px;
    margin-left: 20px;
  }
  .portfolio-webapp-mytrux-body .mytrux-webapp-sect6 .mytrux-webapp-sect6-2-wrap p {
    top: 94px;
    margin-left: -50px;
  }
  .comman-section.scroll-div.home-sec6.pb0 .comman-scrollbar-wrap {
    height: 740px;
    overflow: auto;
  }
  .authntick-sec9 .authntick-result-carosual .owl-stage-outer.owl-height {
    height: 266px !important;
  }
  .comman-section.comman-section-100vh.insisive-website-sect8.scroll-div.xs-pt0 .insisive-website-sect8-wrap {
    margin-top: 80px !important;
    transform: scale(0.8) !important;
    -webkit-transform: scale(0.8) !important;
    -moz-transform: scale(0.8) !important;
    -ms-transform: scale(0.8) !important;
    -o-transform: scale(0.8) !important;
    height: 450px;
  }

  .esop-sec1 {
    .esop-triangle1 {
      top: 10px;
      right: 300px;
    }

    .esop-triangle2 {
      bottom: -37px;
      left: -70px;
    }

    .esop-header-img {
      max-width: 560px;
      margin-top: 0;
      margin-left: -30px;
    }
  }
  .esop-sec4 {
    background: #15269b url("../images/portfolio/portfolio-webapp-esop/esop-findability-bg.png") no-repeat right -400px bottom -285px;
    background-size: 1100px auto;
  }

  .esop-sec6 {
    .esop-left-wrap {
      .esop-slider-circle {
        width: 600px;
        height: 600px;
      }

      .comman-scrollbar-wrap {
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        -o-transform: scale(0.8);
        transform-origin: 120px 0;
      }
    }
  }
  .esop-sec7 {
    .bottom-col-set {
      bottom: -60px;
      max-width: 700px;
      margin: auto;
    }

    .esop-center-wrap {
      margin-top: 60px !important;
    }
  }
  .circle-sec {
    width: 650px;
    height: 650px;
  }
  .esop-sec8 {
    .esop-right-wrap {
      .esop-attention-wrap {
        top: -30px;
        right: -120px;
        max-width: 800px;
      }

      .esop-right-row.clearfix {
        transform: scale(0.70);
        transform-origin: 150px center;
      }
    }
  }

  .esop-sec9 {
    .esop-left-wrap {
      .img-fluid {
        margin-left: -240px;
        position: relative;
        top: 50px;
        max-width: 800px;
      }
    }
  }

  .esop-sec10 {
    .esop-left-wrap {
      margin-top: 110px;
      margin-left: 60px;
    }

    .esop-right-wrap {
      .img-fluid {
        max-width: 800px;
        margin-left: -40px;
        position: relative;
        top: 50px;
      }
    }
  }
  .esop-sec11 {
    .esop-left-wrap {
      .img-fluid {
        max-width: 800px;
        margin-left: -283px;
        position: relative;
        top: 23px;
      }
    }
  }
  .esop-sec2 {
    background-size: 90vh auto;
    background-position-y: 10px;
  }
  .esop-sec12 {
    height: 700px;
    background-size: 90vh auto;
    background-position-y: 10px;

    .esop-center-wrap {
      position: relative;
      top: 100px;
    }

    .ui-card-slider {
      position: relative;
      width: 320px;
      height: 270px;
      overflow: hidden;
      transform: scale(3);
      transform-origin: 0 top;
      -webkit-transform: scale(3);
      -moz-transform: scale(3);
      -ms-transform: scale(3);
      -o-transform: scale(3);
    }
  }
  .esop-sec13 {
    .comman-impact-bottom-ul {
      width: 600px;
      transform: scale(0.7);
      transform-origin: 0 0;
      -webkit-transform: scale(0.7);
      -moz-transform: scale(0.7);
      -ms-transform: scale(0.7);
      -o-transform: scale(0.7);
    }
  }
  .concerro-pattern-wrap .img-fluid {
    width: 400px;
  }
  .concerro-sec4 {
    .concerro-right-wrap {
      .concerro-brainstorming-1 {
        top: -170px;
      }

      .concerro-brainstorming-2 {
        top: 70px;
      }
    }
  }


  .source-trace-sec1 {
    .source-trace-right-wrap {
      img {
        top: 0;
        left: -160px;
        width: 560px;
      }
    }
  }
  .source-trace-sec2 {
    .farmers-img {
      width: 380px;
    }
  }
  .source-trace-sec3 {
    .small-tracker-img {
      bottom: -100px;
    }
  }
  .source-trace-sec4 {
    .corn-img {
      position: absolute;
      bottom: -70px;
      right: -130px;
      z-index: 2;
      width: 300px;
    }
  }
  .source-trace-sec5 {
    .info-architecture1 {
      max-width: 700px;
      display: block;
      margin: 10px auto 0;
    }
  }
  .source-trace-sec6 {
    .source-trace-center-wrap {
      margin-bottom: 20px;
    }

    .wireframe-img {
      margin-top: 30px;
    }
  }
  .source-trace-sec7 {
    .leave2-img {
      top: 60px;
    }

    .farmer-flow {
      margin-top: 0;
      max-width: 600px;
    }
  }
  .source-trace-sec8 {
    .leave2-img {
      top: 180px;
    }

    .multi-dashboard {
      max-width: 600px;
      margin-top: 156px;
      margin-left: -50px;
    }

    .leave-bg {
      bottom: -177px;
    }
  }
  .source-trace-sec10 {
    .dt-img {
      max-width: 850px;
    }

    .db2 {
      bottom: -10px;
      left: -70px;
      max-width: 280px;
    }
  }
  .source-trace-sec11.esop-sec6 {
    .esop-left-wrap {
      .comman-scrollbar-wrap {
        .esop-slider-outer-wrap {
          margin-left: 40px;
          animation: none;
          -webkit-animation: none;
          margin-top: -160px;
        }
      }
    }
  }
  .source-trace-sec12 {
    .gui-components {
      max-height: calc(100vh - 180px);
      margin: auto !important;
      display: block;
    }
  }

}

// Large devices (desktops, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  a.mega-menu.mega-menu-close {
    top: 22px;
    left: 10px;
    position: relative;
  }
  .sm-mt40 {
    margin-top: 40px !important;
  }
  .text-md-center-adj {
    text-align: center;
  }
  h1,
  h2,
  h3 {
    br {
      display: none !important;
    }
  }
  .logo-img-xs-white {
    path {
      fill: $white !important;
    }
  }
  .cursor {
    display: none;
  }
  h1,
  .h1 {
    font-size: $h1-font-size - 16;
    line-height: 102px - 29.2 !important;
  }
  h2,
  .h2 {
    font-size: $h2-font-size - 14;
    line-height: 88px - 25.6 !important;
  }
  h3,
  .h3 {
    font-size: $h3-font-size - 14;
    line-height: 74px - 22 !important;
  }
  h4,
  .h4 {
    font-size: $h4-font-size - 4 !important;
    line-height: 50px - 13.6 !important;
  }

  h5,
  .h5 {
    font-size: $h5-font-size;
    line-height: 36.4px !important;
  }
  h6,
  .h6 {
    font-size: $h6-font-size;
  }

  .p1 {
    font-size: 24px;
    line-height: 38px;
    margin: 0;
  }
  .p2 {
    font-size: 20px;
    line-height: 32.4px;
    margin: 0;
  }
  .p3 {
    font-size: 16px;
    line-height: 25.9px;
    margin: 0;
  }
  .p4 {
    font-size: 14px;
    line-height: 22.6px;
    margin: 0;
  }
  .p5 {
    font-size: 12px;
    line-height: 19.4px;
    margin: 0;
  }
  .comman-header-menu-right {
    position: static;
    margin: 0;
  }
  .arrow-scroll-abs {
    left: 0px;
    top: calc(100% - 90px);
    display: none;
  }
  .comman-top-menu {
    clip-path: circle(30px at calc(100% - 40px) 40px);
    -webkit-clip-path: circle(30px at calc(100% - 40px) 40px);

    .container-fluid.mw-1470 {
      position: fixed;
      top: 0;
      transition: all 0.2s linear;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      -ms-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      width: 100%;
      z-index: 890;
      padding: 15px;
    }
  }
  /*
  .comman-header {
    position: fixed;
    top: 0;
    transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    width: 100%;
    z-index: 890;
    padding: 15px 0;
    &.active-logo-white {
      .logo-img path {
        fill: $white;
      }
      .comman-header-menu-ul {
        .btn-link-custom {
          color: $white;
        }
        .mega-menu-img1 path {
          fill: $white;
        }
      }
    }
    &.nav-up {
      top: -150px;
    }
    &.nav-down {
      top: 0;
      background: rgba(255, 255, 255, 0.9);
      -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
      .logo-img path {
        fill: $black;
      }
      .comman-header-menu-ul {
        .btn-link-custom {
          color: $black;
        }
        .mega-menu-img1 path {
          fill: $black;
        }
      }
    }
  }*/

  .comman-section-100vh {
    .comman-card-img-wrap {
      transform: scale(0.6) translateZ(0);
      -webkit-transform: scale(0.6) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.6) translateZ(0);
      -ms-transform: scale(0.6) translateZ(0);
      -o-transform: scale(0.6) translateZ(0);
      transform-origin: 0 center;

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }

    .comman-card-info {
      br {
        display: none;
      }

      &.pl30 {
        padding-left: 15px !important;
      }

      .comman-card-top-ul {
        margin-bottom: 20px;
      }

      h2.sec-heading {
        margin-bottom: 15px;
      }

      p.sec-para {
        margin-bottom: 25px;

        br {
          display: none;
        }
      }

      .comman-card-bottom-ul {
        margin-bottom: 20px;
      }

      .bttn {
        margin-top: 15px;
      }
    }
  }
  body {
    .comman-section {
      padding: 50px 0;
      overflow: hidden;

      .comman-card-info {
        .comman-card-bottom-ul {
          li {
            position: relative;
            padding-left: 20px;
            margin-right: 15px;
          }
        }
      }

      .vh100-100 {
        min-height: 450px;
        position: relative;
        height: inherit !important;
      }
    }
  }

  .comman-footer {
    .comman-footer-row1 {
      height: calc(500px - 100px);
    }

    .comman-footer-row2 {
      bottom: -20px;

      > div {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center !important;

        .bottom-menu-bottom-right.list-inline {
          margin-top: 15px;
        }
      }
    }

    .bottom-menu-bottom-left,
    .bottom-menu-bottom-right {
      ul {
        margin: 0 !important;
      }

      li {
        &:not(:last-child) {
          margin-right: 15px;
        }
      }
    }
  }

  .comman-top-menu {
    .top-menu-left {
      li {
        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }
    }

    .top-menu-right {
      li {
        &:not(:last-child) {
          margin-bottom: 35px;
        }
      }
    }
  }
  .portfolio-info-wrap,
  .portfolio-info-wrap2 {
    h2,
    h3,
    p {
      br {
        display: none !important;
      }
    }
  }
  .portfolio-info-wrap2 {
    h3 {
      margin-bottom: 10px;
    }

    p {
    }
  }
  .comman-bg-section {
    min-height: 600px;
  }
  .impact-section-upd {
    .comman-impact-list-inline {
      li:first-child {
        margin-right: 50px;
      }
    }

    .comman-impact-bottom.ml85 {
      margin-left: 25px !important;
    }

    .portfolio-info-wrap2 {
      margin-left: 15px;
    }
  }
  .viewer-section {
    padding-bottom: 50px !important;
  }
  .comman-solution-challenge {
    .portfolio-info-wrap2.ml85 {
      margin-left: 45px !important;
    }
  }
  .process-section1,
  .industries-section1 {
    .comman-card-info-left {
      margin-top: 105px;
      top: 0;
      transform: none;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
    }
  }
  .award-card {
    min-height: 250px;
    padding: 15px;
  }
  .home-sec-tab {
    .flex-column {
      .nav-link span::after {
        display: none;
      }

      .nav-link:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    .tab-content {
      margin-left: 30px;
    }
  }
  .carousel-comman-wrap {
    [data-animation] {
      animation: none !important;
      -webkit-animation: none !important;

      br {
        display: none;
      }
    }
  }
  .comman-card-img-wrap-md {
    transform-origin: 120px center !important;
  }
  .home-sec1 {
    .comman-card-img-wrap {
      margin: auto;
      width: 480px;
      transform: scale(0.7) translateZ(0);
      -webkit-transform: scale(0.7) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.7) translateZ(0);
      -ms-transform: scale(0.7) translateZ(0);
      -o-transform: scale(0.7) translateZ(0);
      transform-origin: center center;
      min-height: 480px;
    }

    .h1 {
      br {
        display: none;
      }
    }
  }
  .home-sec2 {
    .carousel-indicators-ul {
      left: -30px;
    }
  }
  .home-sec3 {
    .comman-card-top-ul {
      li {
        margin-right: 0 !important;
      }
    }

    .comman-card-info {
      margin-top: 40px;
      margin-left: 0px;
    }
  }
  .home-sec4-2 {
    left: -80px !important;
  }
  .home-sec4 {
    .comman-card-info {
      margin-top: -50px !important;
    }

    .client-logo-wrap {
      margin-left: 0;
      margin-top: 40px;

      ul {
        li {
          margin: 40px 0;
        }
      }
    }

    .client-logo-wrap-outer-col {
      left: 0;
    }
  }

  .home-sec7 {
    .process-img-wrap {
      height: inherit;

      img {
        display: inline-block;
        width: 700px;
        height: auto;
        max-width: inherit;
        margin-top: 50px;
        margin-left: auto;
      }
    }
  }
  .comman-card-img-wrap .home-sec1-1 {
    right: -140px;
  }
  .comman-card-img-wrap .home-sec5-1 {
    top: 300px;
    left: -113px;
    z-index: 4;
  }
  .comman-card-img-wrap .home-sec5-2 {
    top: -20px;
    left: -84px;
    z-index: 3;
  }
  .comman-card-right {
    padding-left: 25px;
  }
  .comman-card-left {
    padding-right: 25px;
  }
  .home-sec8 {
    .comman-card-img-wrap2 {
      transform: scale(0.65) translateZ(0);
      -webkit-transform: scale(0.65) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.65) translateZ(0);
      -ms-transform: scale(0.65) translateZ(0);
      -o-transform: scale(0.65) translateZ(0);
      transform-origin: center center;
      left: auto;
      top: auto;
      margin-left: 90px;
      margin-top: 30px;

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .about-sec1 {
    .btn-line span::after {
      width: 28px;
      top: 9px;
    }

    .comman-card-img-wrap {
      margin: auto;
      width: 380px;
      min-height: inherit;
    }
  }
  .about-sec2 {
    .vh100-100 {
      min-height: 360px !important;
    }
  }
  .about-sec3 {
    background-color: #1e1e1e !important;

    .ani-wrap50-2,
    .ani-wrap50 {
      display: none !important;
    }

    .comman-card-info.comman-card-right {
      margin-top: 30px;
      padding-left: 0;
    }

    .comman-card-info {
      max-width: inherit;
      min-height: 200px;
      margin: auto;
    }

    .comman-card-info {
      .comman-card-img-wrapper {
        margin-bottom: 15px;
        margin-top: 20px;
      }
    }
  }
  .about-sec4 {
    .comman-card-info {
      .comman-card-top-ul {
        li {
          color: $black !important;
        }
      }

      h2 {
        color: $black !important;
      }
    }

    background: $white;
    position: relative;

    .comman-card-info {
      h2 {
        br {
          display: none;
        }
      }
    }

    .ani-wrap50-3 {
      display: none;
    }

    .our-values-wrap {
      max-width: 500px;
      margin: 70px 0 0;
      height: 670px;
      overflow: auto;

      .our-values-card {
        margin-bottom: 50px;
      }
    }
  }
  .about-sec5 {
    .comman-card-info {
      margin-top: 0;
    }

    .about-expressions {
      position: absolute;
      top: -14px;
      right: -20px;
      width: 180px;
    }

    .ani-wrap50 {
      width: 100%;
      height: 600px;
    }

    .our-expressions-wrap {
      margin-top: 0;
      transform: none !important;
      -webkit-transform: none !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      -o-transform: none !important;
      transform-origin: inherit;
      margin-left: auto;

      .about-sec5-1 {
        display: none;
      }

      /*.comman-video:;
    {
            transform: scale(0.70) translateZ(0);
            -webkit-transform: scale(0.70) translateZ(0); -webkit-backface-visibility: hidden;
            -moz-transform: scale(0.70) translateZ(0);
            -ms-transform: scale(0.70) translateZ(0);
            -o-transform: scale(0.70) translateZ(0);
            transform-origin: center 200px;
            }
            .about-sec5-1 {
                position: absolute;
                top: 150px;
                right: 85px;
                z-index: 1;
                height: 330px;
            }*/
    }
  }

  .about-sec6 {
    .comman-card-img-wrap3-outer {
      transform: scale(0.6) translateZ(0);
      -webkit-transform: scale(0.6) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.6) translateZ(0);
      -ms-transform: scale(0.6) translateZ(0);
      -o-transform: scale(0.6) translateZ(0);
      transform-origin: center 0;
      height: 500px;
    }
  }
  .about-sec7 {
    .comman-card-info {
      margin: 80px auto 0;
    }

    .comman-team-card {
      margin-top: 0px;
    }

    .about-team-row {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      margin-top: -20px;
    }

    .comman-team-card {
      .team-card-left1,
      .team-card-left2,
      .team-card-right1,
      .team-card-right2,
      .team-card-center1,
      .team-card-center2 {
        height: 170px;
      }

      .team-card-center1 {
        left: 70px;
      }

      .team-card-right1 {
        left: 50px;
      }
    }
  }
  .comman-team-card {
    margin-top: 0;
    height: 480px;

    .comman-team-card-head {
      margin-top: -80px;
      transform: scale(0.55) translateZ(0);
      transform-origin: center bottom;
      -webkit-transform: scale(0.55) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.55) translateZ(0);
      -ms-transform: scale(0.55) translateZ(0);
      -o-transform: scale(0.55) translateZ(0);
      margin-left: -60px;

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }

  .work-comman-card-info {
    margin-top: 70px;
  }
  .work-sec6 {
    .comman-card-info {
      h2.sec-heading {
        br {
          display: none;
        }
      }
    }
  }

  .process-section1,
  .industries-section1 {
    .comman-card-info-left {
      &.fixed-left {
        position: relative;
        z-index: 3;
        max-width: 480px !important;
        margin: 0 auto 100px !important;
      }

      z-index: 0;
      max-width: 340px !important;
      margin-left: 0;
    }

    .process-section1-row {
      margin-top: 70px;
    }

    .comman-card-info {
      &:not(:last-child) {
        margin-bottom: 120px;
      }
    }

    .process-img7 {
      right: 310px;
      height: 950px;
      display: none !important;
    }
  }
  .work-sec2 {
    .comman-card-info {
      max-width: 460px;
    }
  }
  .career-sec1 {
    .comman-card-img-wrap {
      margin: auto;
      width: 280px;
    }
  }
  .career-sec2 {
    .career-sec2-right {
      height: 670px;
      overflow: auto;

      .comman-card-info {
        margin-left: 0;
      }
    }
  }

  .contact-section1 {
    overflow: hidden;

    .ani-wrap100-top {
      width: 100% !important;
    }

    .sec-heading {
      margin-top: 0;
    }

    .addr-wrap-col-adj.hide-tab-view {
      display: none;
    }

    .addr-wrap {
      margin-left: auto;
      margin-top: 30px;

      &.mb90 {
        margin-bottom: 20px !important;
      }
    }
  }
  .g-capcha-col-wrap {
    margin-top: 0px;
  }

  .contact-section2 {
    overflow: hidden;

    .contact-form-inner table {
      width: 560px;
    }

    .term-col-wrap {
      margin-top: 4px;
    }

    .my-budget-wrap {
      padding-left: 0;
      min-width: 400px;
      margin-left: -50px;

      h4 {
        margin-left: -60px;
      }
    }

    .range-slider-main .slider.slider-vertical {
      height: 290px;
      width: 20px;
    }

    .career-form-wrap {
      margin-top: 0;
    }
  }
  .career-form-wrap {
    margin-top: 120px;
  }
  .expertise-sec1 {
    .vh100-100 {
      overflow: hidden;
    }

    .ani-wrap50-3 {
      height: 480px;
      width: 100% !important;
    }

    .comman-card-info {
      .sec-heading {
        br {
          display: none;
        }
      }
    }

    .comman-card-img-wrap2 {
      height: 630px;
      min-height: inherit;
      left: auto;
      top: -50px;
      margin-left: 30px;
    }

    img.expertise1 {
      transform: scale(0.75) translateZ(0);
      -webkit-transform: scale(0.75) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.75) translateZ(0);
      -ms-transform: scale(0.75) translateZ(0);
      -o-transform: scale(0.75) translateZ(0);
      transform-origin: center center;
      min-height: inherit;
      top: 0;
    }
  }
  .expertise-sec2 {
    .expertise-list-wrap.ml75 {
      margin-left: 0;
      margin-top: 50px;
    }
  }
  .expertise-sec4 {
    padding-top: 0;
  }
  .expertise-sec2-1 {
    h2 {
      margin-top: 0;
    }
  }
  .expertise-sec4,
  .expertise-sec5 {
    .vh100-100 {
      padding-top: 120px;
    }
  }

  .contact-section1 {
    .addr-wrap {
      &.mb90 {
        margin-bottom: 50px !important;
      }

      .addr-indo-wrap {
        margin-top: 15px;

        .addr-list-inline {
          margin-bottom: 20px;
          white-space: nowrap;
        }
      }
    }
  }
  .contact-section2 {
    .contact-form-inner {
      table {
        td {
          width: 50%;
          padding-bottom: 15px;
        }
      }
    }
  }
  .career-form-wrap {
    margin-top: 0;

    .mb50 {
      margin-bottom: 10px !important;
    }

    .form-control-col-btn {
      margin-top: 20px;
    }

    .pr-form-control {
      position: relative;
      margin-bottom: 50px;
    }
  }
  .career-details-sec1 {
    .career-details-sec1-row {
      margin-top: 85px;
    }

    .career-details-sec2-row {
      margin-top: 45px;

      .career-details-card {
        margin-bottom: 50px;
      }
    }

    .exp-h6 {
      text-align: left;
    }
  }

  .crubba-sec1 {
    .portfolio-img-wrap {
      transform: scale(0.75) translateZ(0);
      -webkit-transform: scale(0.75) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.75) translateZ(0);
      -ms-transform: scale(0.75) translateZ(0);
      -o-transform: scale(0.75) translateZ(0);
      transform-origin: right center;
      position: relative;
      min-height: 300px;

      .crubba-sec9-inner4 {
        top: -52px;
        left: -90px;
        width: 480px;
      }

      .crubba-sec9-inner3 {
        bottom: -150px;
        left: -80px;
      }

      .crubba-sec9-inner5 {
        top: 10px;
        left: -30px;
        width: 350px;
      }
    }

    .crubba-sec9-inner1 {
      bottom: -140px;
      right: 0;
      width: 140px;
    }

    .crubba-sec1-right {
      width: 430px;
    }
  }
  .crubba-sec2 {
    background-size: 360px auto;

    .crubba-sec2-img1 {
      max-width: 480px;
    }

    .crubba-sec2-img2 {
      position: absolute;
      top: 50px;
      left: -20px;
      width: 110px;
    }

    .crubba-sec2-img3 {
      position: absolute;
      top: 40px;
      right: 0;
      width: 100px;
    }
  }
  .crubba-sec3-upd {
    background-image: none !important;
  }
  .crubba-sec3 {
    .crubba-sec3-right {
      transform: scale(0.75) translateZ(0);
      -webkit-transform: scale(0.75) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.75) translateZ(0);
      -ms-transform: scale(0.75) translateZ(0);
      -o-transform: scale(0.75) translateZ(0);
      transform-origin: center center;
    }
  }

  .crubba-sec4 {
    padding-bottom: 0;

    .crubba-sec4-center {
      margin-top: 30px;
    }

    .crubba-sec4-bottom1 {
      width: 250px;
    }

    .crubba-sec4-bottom {
      margin-top: 40px;
    }

    .viewer, .ng-draggable {
      .crubba-sec4-bottom-img {
        height: 100vh;
      }
    }
  }
  .crubba-sec5 {
    padding-bottom: 0 !important;
    background-position: right bottom 50px;
    background-size: 150px;

    .crubba-sec5-center {
      margin-top: 30px;
    }

    .crubba-sec5-bottom3 {
      position: absolute;
      top: 60px;
      left: -10px;
    }

    .crubba-sec5-col-height {
      height: 660px;
      overflow: hidden;
    }
  }
  .crubba-sec6 {
    .crubba-sec6-1 {
      display: none !important;
    }

    .crubba-sec6-bottom {
      background-size: contain;
      padding: 20px;
      height: 380px;
      margin-top: 30px;

      .owl-caroual-comman {
        display: block;
        width: 630px;
        margin: 17px auto 0;
      }
    }
  }

  .crubba-sec7 {
    & > .container {
      overflow: hidden;
    }

    .crubba-sec7-img1 {
      bottom: auto;
      top: 100px;
      left: 0px;
    }

    .crubba-sec6-bottom-before {
      position: absolute;
      bottom: 170px;
      right: -60px;
      z-index: 6;
      width: 150px;
    }

    .crubba-sec7-bottom3 {
      width: 100%;
    }

    .crubba-sec7-bottom {
      transform: none;
      -webkit-transform: none;
      -webkit-backface-visibility: hidden;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform-origin: center top;
      min-height: inherit;
      height: 450px;

      .crubba-sec7-bottom2 {
        position: absolute;
        top: 143px;
        right: 10px;
        z-index: 10;
        width: 180px;
      }

      .crubba-sec7-bottom1 {
        position: absolute;
        top: 45px;
        left: -50px;
        z-index: 9;
        width: 300px;
      }

      .crubba-sec7-bottom3-wrap {
        .crubba-sec7-bottom4-hover {
          top: 20px;
          left: 50%;
          bottom: 0;
          width: 480px;
          height: 313px;
          margin-left: 0;
        }
      }

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }

  .crubba-sec8 {
    .crubba-sec3-right {
      display: none !important;
    }

    .crubba-sec8-center {
      margin-left: 20px;
      margin-top: 40px;
    }

    .crubba-sec8-bottom1 {
      max-width: 100%;
      display: block;
    }

    .crubba-sec4-bottom1 {
      position: absolute;
      top: 230px;
      left: -90px;
      z-index: 6;
      width: 230px;
    }
  }
  .crubba-sec9 {
    background-size: 280px auto;
    background-position-y: 100px;

    .crubba-sec3-left {
      margin-top: 0;
    }

    .vh100-100 {
      min-height: 380px !important;
    }

    .crubba-sec9-img-wrap {
      .crubba-sec9-img {
        width: 420px;
        height: auto;
      }
    }

    .crubba-sec9-img2 {
      left: -50px;
      z-index: 0;
      width: 200px;
    }

    .crubba-sec9-bottom2 {
      max-width: 1010px;
    }
  }

  .crubba-sec11 {
    background-size: 280px auto;
    background-position-y: 250px;
    padding-bottom: 0;

    .container-fluid {
      overflow: hidden;
    }

    .crubba-sec11-bottom {
      position: relative;
      left: -20px;
      min-height: inherit;
      height: 800px;
    }

    .crubba-sec11-bottom1 {
      max-width: 1100px;
    }
  }
  .crubba-sec12 {
    &::after {
      height: 680px;
    }

    .mobile-row {
      height: 1400px;
    }

    .crubba-sec12-1 {
      position: relative;
      left: -80px;
      width: 320px;
    }

    .crubba-sec12-2 {
      margin: -70px 0 0 35px;
      max-width: 100%;
    }

    .crubba-sec12-3 {
      display: block;
      margin: 60px auto -10px;
      max-width: 100%;
    }

    .crubba-sec12-4 {
      max-width: 100%;
    }

    .crubba-sec12-6 {
      display: block;
      margin: 182px auto 0 40px;
      width: 400px;
    }

    .crubba-sec12-7 {
      max-width: 100%;
    }

    .crubba-sec12-5-wrap {
      position: relative;
      transform: scale(0.9) translateZ(0) !important;
      -webkit-transform: scale(0.9) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.9) translateZ(0) !important;
      -ms-transform: scale(0.9) translateZ(0) !important;
      -o-transform: scale(0.9) translateZ(0) !important;
      transform-origin: center top;
      height: 600px;
      margin-left: -10px;

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .error-page-section1 {
    .portfolio-info-wrap2 {
      max-width: 574px;

      .bttn {
        margin-top: 40px;
      }
    }

    .error-img-wrap {
      position: relative;

      .error-page {
        max-width: 100%;
        width: auto;
        display: block;
      }

      .error-icon {
        max-width: 100%;
        width: 100px;
        display: block;
        top: 0;
        right: 40px;
        margin-left: 0;
      }
    }
  }
  .comman-impact-section {
    .h44 {
      margin-bottom: 40px;
    }

    .comman-impact-top {
      hr {
        margin: 45px auto -30px;
      }
    }

    .comman-impact-bottom {
      .h5-20 {
        margin-top: 30px;
      }
    }

    .crubba-impact1-circle {
      top: -50px;
      transform: scale(0.85) translateZ(0);
      -webkit-transform: scale(0.85) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.85) translateZ(0);
      -ms-transform: scale(0.85) translateZ(0);
      -o-transform: scale(0.85) translateZ(0);

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }

  .failure-section {
    .failure-ul {
      margin-top: 30px;

      li {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }

    .vh100-100 {
      min-height: 360px !important;
    }

    .failure-sec-right {
      top: auto;
    }
  }
  .impact-section-upd {
    .comman-card-top-ul {
      margin-bottom: 10px;
    }

    .comman-impact-bottom.ml85 {
      .comman-impact-list-inline {
        margin-top: 30px;
      }
    }
  }

  .moneyguru-sec1 {
    padding-bottom: 0 !important;

    br {
      display: none !important;
    }

    .portfolio-info-wrap2 .moneyguru-banner-img {
      left: 50px;
      width: 370px;
      margin-top: 10px;
    }

    [data-aos="new-animation4"].aos-animate {
      width: 75%;
    }
  }
  .moneyguru-sec3 {
    .moneyguru-sec3-bg {
      margin-top: 60px;
      margin-left: 170px;
      width: 300px;
    }
  }
  .moneyguru-header-section {
    .moneyguru-header-right-wrap {
      min-height: inherit;
    }

    .moneyguru-banner-img {
      width: 500px;
    }
  }

  .moneyguru-strategy-section {
    .moneyguru-strategy-left-col-img {
      position: absolute;
      top: -40px;
      left: -50px;
      right: 0;
      bottom: 0;
      height: 100vh;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }

    .moneyguru-strategy-right {
      margin-left: 0;
    }
  }

  .moneyguru-story-section {
    overflow: hidden;

    .moneyguru-story-left {
      margin-top: 30px;
      margin-left: -100px;
      transform: scale(0.6) translateY(-50%);
      transform-origin: 0px 0px;
      -webkit-transform: scale(0.6) translateY(-50%);
      -moz-transform: scale(0.6) translateY(-50%);
      -ms-transform: scale(0.6) translateY(-50%);
      -o-transform: scale(0.6) translateY(-50%);
      position: absolute;
      top: 50%;
      left: 0;
    }
  }
  .moneyguru-insights-section {
    overflow: hidden;

    .moneyguru-insights-right-col {
      .moneyguru-insights-right {
        height: 100%;

        video {
          width: 400px;
          transform: translateY(-50%);
          position: absolute;
          top: 50%;
          left: 0;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
        }
      }
    }
  }
  .moneyguru-investments-section {
    .moneyguru-investments-left {
      height: 100%;
      margin-left: -140px;
    }

    .moneyguru-investments-left-col {
      video {
        margin-left: 0;
        width: 550px;
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
      }
    }
  }
  .moneyguru-gateway-section {
    .moneyguru-gateway-right {
      height: 100%;

      video {
        width: 600px;
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
      }
    }
  }
  .moneyguru-investing-section {
    overflow: hidden;

    .moneyguru-investing-left-col {
      .moneyguru-investing-left {
        height: 100%;
      }

      video {
        margin-left: -120px;
        width: 550px;
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
      }
    }

    .moneyguru-investing-right {
      float: right;
      margin-right: 0;
      margin-left: 70px;
    }
  }
  .moneyguru-comprehensive-section {
    .moneyguru-comprehensive-bottom {
      height: 100%;

      video {
        position: static;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        width: 600px;
      }
    }
  }
  .moneyguru-sec12 {
    .mt70 {
      margin-top: 30px !important;
    }
  }

  .mytrux-logo-sec3 {
    .mytrux-logo-right-wrap {
      transform: scale(0.8) translateZ(0);
      -webkit-transform: scale(0.8) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.8) translateZ(0);
      -ms-transform: scale(0.8) translateZ(0);
      -o-transform: scale(0.8) translateZ(0);
      transform-origin: center center;
      min-height: inherit;
      height: 530px;
    }
  }
  .mytrux-logo-sec6 {
    .mytrux-logo-sec6-inner {
      h5 {
        margin-top: -20px;
      }
    }

    .mytrux-logo-sec6-1,
    .mytrux-logo-sec6-2 {
      width: 400px;
      margin: auto;
    }
  }
  .mytrux-logo-sec9 {
    .p2 {
      margin-top: 100px;
    }
  }
  .mytrux-logo-sec11 {
    .mytrux-logo-left-wrap {
      margin-top: -150px;
    }

    .mytrux-logo-right-wrap {
      position: relative;
      transform: scale(0.7) translateZ(0);
      transform-origin: center 0;
      -webkit-transform: scale(0.7) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.7) translateZ(0);
      -ms-transform: scale(0.7) translateZ(0);
      -o-transform: scale(0.7) translateZ(0);

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .mytrux-logo-sec14 {
    .mytrux-logo-sec14-2 {
      position: absolute;
      bottom: -320px;
      left: -220px;
      z-index: 2;
      width: 660px;
    }
  }

  .mytrux-logo-sec13-1-wrap,
  .mytrux-logo-sec12-1-outer {
    height: 500px;
    min-height: inherit;

    .img-fluid {
      max-width: 100%;
      height: 100%;
    }
  }
  .mytrux-website-sec1 {
    padding-bottom: 0 !important;

    .mytrux-website-center-wrap {
      margin-top: 70px;
    }
  }

  .mytrux-website-sec7 {
    .mytrux-website-sec7-1-outer {
      height: 2900px;
    }
  }
  .mytrux-website-sec9 {
    .container {
      height: 2500px;
      display: block;
    }
  }
  .mytrux-website-sec10 {
    .mytrux-website-top-wrap {
      transform: scale(0.7) translateZ(0);
      transform-origin: center 0;
      -webkit-transform: scale(0.7) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.7) translateZ(0);
      -ms-transform: scale(0.7) translateZ(0);
      -o-transform: scale(0.7) translateZ(0);
      height: 600px;
      min-height: inherit;

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .mytrux-website-sec9 {
    .mytrux-website-sec9-1 {
      max-width: initial;
      margin-left: -250px;
      margin-top: 70px;
      position: relative;
      z-index: 5;
      width: 850px;
    }

    .mytrux-website-sec9-3 {
      max-width: initial;
      margin-top: -220px;
      margin-left: -230px;
      width: 800px;
    }

    .mytrux-website-sec9-2 {
      margin-left: 6px;
    }
  }

  .authntick-sec1 {
    .authntick-fingerprint2 {
      position: absolute;
      top: -150px;
      left: 240px;
      max-width: 260px;
    }

    .authntick-fingerprint1 {
      position: absolute;
      bottom: -180px;
      left: -44px;
      max-width: 260px;
    }

    .authntick-left-wrap {
      width: 500px;
      margin: auto;

      br {
        display: none;
      }
    }

    .authntick-right-wrap {
      img {
        max-width: 100%;
        float: right;
        margin-top: 0;
        height: 370px;
        margin-right: -50px;
      }
    }
  }
  .authntick-sec2 {
    .authntick-sec2-1 {
      max-width: 260px;
      bottom: -200px;
      left: -120px;
    }

    .authntick-left-wrap {
      padding-right: 10px;
    }
  }
  .authntick-sec3 {
    .authntick-sec3-bg {
      background-size: contain;
    }
  }
  .authntick-sec4 {
    .authntick-sec4-3 {
      max-width: 260px;
      bottom: -200px;
      left: -120px;
    }

    .authntick-sec4-2-outer {
      .authntick-sec4-2 {
        max-width: 100%;
        left: 0;
      }
    }
  }
  .authntick-sec6 {
    .authntick-sec6-2-outer {
      right: -70px;
      position: relative;
    }

    .authntick-sec6-2 {
      max-width: 100%;
      position: relative;
      z-index: 3;
    }

    .authntick-sec6-1 {
      max-width: 350px;
    }
  }
  .authntick-sec7 {
    .authntick-left-wrap {
      float: right;
      margin-left: 50px;
    }

    [data-aos="new-animation5"].aos-animate {
      width: 24%;
    }

    .ui-card-slider {
      width: 340px;
      height: 230px;
      transform: scale(1.5) translateZ(0);
      -webkit-transform: scale(1.5) translateZ(0);
      -moz-transform: scale(1.5) translateZ(0);
      -ms-transform: scale(1.5) translateZ(0);
      -o-transform: scale(1.5) translateZ(0);
      margin-right: 0px;
    }
  }
  .authntick-sec8 {
    .authntick-sec8-2-outer {
      .authntick-wizard-carosual {
        width: 500px;
        margin-top: 115px;
        margin-left: 0;
      }
    }
  }
  .authntick-sec9 {
    .bottom-col-set {
      bottom: -15px;
    }
  }
  .vigan-sec2 {
    .vigan-sec2-left {
      max-width: inherit;
    }

    .vigan-right-wrap {
      text-align: center;
      right: 0;
      top: 0;
      margin-top: 30px;

      .img-fluid {
        max-width: 470px;
        margin-left: 0;
      }
    }
  }
  .vigan-sec3 {
    .vigan-sec3-1-outer1 {
      .vigan-sec2-1 {
        width: 200px;
      }
    }
  }
  .vigan-sec4 {
    .vigan-left-wrap {
      max-width: inherit;
    }

    .vigan-right-wrap {
      margin-top: 30px;

      .img-fluid {
        max-width: 470px;
        margin: auto;
        display: block !important;
      }
    }
  }
  .vigan-sec6 {
    padding-top: 60px !important;
    background-image: none !important;

    .vigan-left-wrap {
      max-width: inherit;
    }

    .vigan-right-wrap {
      width: 470px;
      margin-top: 18px;
      margin-left: auto;
      margin-right: auto;

      .img-fluid {
        max-width: 360px;
        margin: auto;
        display: block !important;
      }
    }
  }

  .vigan-sec7 {
    .vigan-left-wrap {
      max-width: inherit;
    }

    .vigan-right-wrap {
      position: relative;
      top: 0;
      left: 0;
      max-width: 400px;
      margin: auto;

      .img-fluid {
        max-width: 100%;
      }
    }
  }

  .vigan-sec7-1 {
    .vigan-left-wrap {
      max-width: inherit;
    }

    .vigan-right-wrap {
      margin-top: 30px;
      text-align: center;
    }
  }

  .comman-comparison {
    height: 650px;
  }
  .mytrux-logo-sec7 {
    #comparison {
      #divisor,
      figure {
        background-position-x: 200px;
      }
    }
  }

  .mytrux-logo-sec9 {
    .portfolio-info-wrap2 h3 {
      margin-bottom: 100px;
    }

    .p2 {
      margin-top: 50px;
    }

    .mytrux-logo-sec9-2,
    .mytrux-logo-sec9-1 {
      width: 250px;
    }
  }
  .mytrux-logo-sec10 {
    h3 {
      margin-bottom: 80px;
    }

    .app-icon-ul {
      height: inherit;
      white-space: nowrap;
      margin: 0;
      overflow: auto;

      li {
        margin: 0;

        img {
          width: 70%;
        }

        &:nth-child(3) {
          top: -10px;
          position: relative;
        }

        &:nth-child(4) {
          top: -13px;
          position: relative;
        }

        &:nth-child(5) {
          top: -13px;
          position: relative;
        }
      }
    }
  }
  .mytrux-logo-sec11 {
    height: 400px;

    .mytrux-logo-sec11-2 {
      width: 620px;
    }

    .mytrux-logo-right-wrap {
      margin-top: -150px;
    }
  }

  .mytrux-logo-sec4 {
    .mytrux-logo-sec4-right {
      img {
        height: 100%;
      }
    }
  }

  .mytrux-logo-sec6 {
    .mytrux-logo-sec6-inner {
      h5 {
        margin-top: -50px;
      }
    }
  }
  .bagshaha-sec5 {
    padding-bottom: 0;

    .bagshaha-sec5-right {
      height: 550px;
    }
  }
  .bagshaha-sec4 {
    .bagshaha-sec4-right {
      margin-left: 35px;
      text-align: center;

      .bagshaha-sec4-right-t {
        position: relative;
        top: -50px;
      }

      .bagshaha-sec4-right-b {
        padding-top: 90px;
        position: relative;
      }
    }
  }
  .bagshaha-sec11 {
    .bagshaha-sec11-2-outer {
      .bagshaha-sec11-2 {
        height: 360px;
      }
    }
  }
  .mytrux-webapp-sec1 {
    .mytrux-webapp-sec1-1 {
      width: 400px;
      margin-left: -80px;
    }
  }
  .mytrux-logo-sec2 {
    .mytrux-website-sec9-4 {
      margin-top: 75px;
    }
  }
  .mytrux-webapp-sec3 {
    .mytrux-banner-line {
      bottom: -50px;
    }
  }
  .mytrux-webapp-sec4 {
    .mytrux-webapp-sec4-bottom {
      .viewer, .ng-draggable {
        height: 400px;

        img {
          height: 100%;
        }
      }
    }
  }

  .mytrux-webapp-sec6 {
    .row.vh100-100 {
      min-height: inherit;
    }

    .mytrux-webapp-sec6-right {
      .mytrux-web-sec6-1 {
        right: 0;
        transform: translateZ(0) !important;
        -webkit-transform: translateZ(0) !important;
        -moz-transform: translateZ(0) !important;
        -ms-transform: translateZ(0) !important;
        -o-transform: translateZ(0) !important;
        transform-origin: inherit;
        position: static;
        top: 0;
        margin-top: 100px;

        img {
          width: 600px;
        }
      }

      .mytrux-web-sec6-2 {
        right: 0;
        transform: translateZ(0) !important;
        -webkit-transform: translateZ(0) !important;
        -moz-transform: translateZ(0) !important;
        -ms-transform: translateZ(0) !important;
        -o-transform: translateZ(0) !important;
        transform-origin: inherit;
        top: auto;
        bottom: 100px;
        position: absolute;

        img {
          width: 700px;
        }
      }
    }
  }
  .mytrux-webapp-sec7 {
    margin-top: -300px;
    position: relative;
    overflow: hidden;
    z-index: 3;
  }
  .mytrux-webapp-sec8 {
    .bottom-col-set {
      transform: scale(0.7) !important;
      transform-origin: center bottom;
      -webkit-transform: scale(0.7) !important;
      -moz-transform: scale(0.7) !important;
      -ms-transform: scale(0.7) !important;
      -o-transform: scale(0.7) !important;
    }
  }

  .mytrux-website-sec10 {
    .mytrux-website-top-wrap {
      height: 560px;

      .mytrux-website-sec10-1-1 {
        position: absolute;
        top: -10px;
        right: 36px;
        z-index: 6;
      }

      .mytrux-website-sec10-2 {
        top: 90px;
        left: 44px;
        margin-top: 4px;
        width: 580px;
      }

      .mytrux-website-sec10-3 {
        left: 0;
      }
    }
  }
  .mytrux-webapp-sec7 {
    .portfolio-info-wrap2 {
      margin: 0px auto 0;
    }
  }
  .insisive-website-sec1 {
    .insisive-website-sec1-1 {
      max-width: 500px;
      margin-left: -30px;
    }
  }
  .insisive-website-sec2 {
    .insisive-website-sec2-1 {
      height: 150px;
      object-fit: cover;
      margin-top: 60px;
    }
  }
  .insisive-website-sec3 {
    .insisive-website-sec3-3-outer {
      img {
        display: inline-block;
        height: 400px;
        max-width: inherit;
        width: auto;
      }
    }
  }
  .insisive-website-sec4 {
    .viewer, .ng-draggable {
      height: 300px;
      margin-top: 30px;
    }
  }
  .insisive-website-sec6 {
    .insisive-website-sec6-1-outer {
      .insisive-website-sec6-1 {
        max-width: initial;
        height: 280px;
        margin-left: 30px;
        margin-top: 10px;
      }
    }
  }
  .insisive-website-sec7 {
    .insisive-website-sec7-1-outer {
      .insisive-website-sec7-1 {
        max-width: initial;
        height: 420px;
        margin-left: -220px;
      }
    }
  }
  .insisive-website-sec8 {
    padding-bottom: 0;

    .insisive-website-sec8-right {
      .img-fluid {
        height: 100%;
        max-width: inherit;
        width: auto;
      }
    }

    .portfolio-info-wrap2 {
      margin-top: -50px;
    }
  }
  .alloch-sec1 {
    height: 638px;
    overflow: hidden;
    position: relative;

    .alloch-right-wrap {
      .alloch-sec1-1 {
        width: 220px;
      }

      .alloch-sec1-2 {
        position: absolute;
        top: -70px;
        left: 20px;
        width: 180px;
      }
    }

    .portfolio-info-banner-l.portfolio-info-wrap2 {
      margin-top: -80px;
    }
  }
  .alloch-sec2 {
    .alloch-sec2-1 {
      display: inline-block;
      position: relative;
      top: 220px;
      left: 22px;
      z-index: 3;
    }

    .vh100-100 {
      min-height: 480px;
    }

    .alloch-left-wrap {
      margin-top: 0;
      margin-left: 18px;
      max-width: 400px;
    }

    .alloch-sec2-2 {
      display: none;
    }

    .alloch-right-wrap {
      .alloch-sec2-3-inner {
        display: none !important;
      }

      &::after {
        top: -320px;
        right: -345px;
        transform: scale(0.55);
        transform-origin: right center;
        -webkit-transform: scale(0.55);
        -moz-transform: scale(0.55);
        -ms-transform: scale(0.55);
        -o-transform: scale(0.55);
      }
    }
  }
  .alloch-sec5 {
    .alloch-sec3-4-outer {
      .alloch-sec3-4 {
        width: 220px;
      }
    }
  }
  .alloch-sec7 {
    .vh100-100 {
      min-height: 650px !important;
    }

    .alloch-sec7-right {
      transform: scale(0.7);
      transform-origin: center 0;
      -webkit-transform: scale(0.7);
      -moz-transform: scale(0.7);
      -ms-transform: scale(0.7);
      -o-transform: scale(0.7);
      height: 550px;
    }

    .alloch-sec7-4-outer {
      .alloch-sec7-4 {
        width: 220px;
      }
    }
  }

  .alloch-sec17 {
    .alloch-sec7-right.portfolio-info-wrap {
      table {
        .alloch-sec3-4-outer {
          position: absolute;
          bottom: 90px;

          .alloch-sec3-4 {
            width: 220px;
          }
        }
      }
    }
  }
  .alloch-sec10 {
    .portfolio-info-wrap.alloch-sec10-left {
      transform: scale(0.6);
      transform-origin: 0 center;
      -webkit-transform: scale(0.6);
      -moz-transform: scale(0.6);
      -ms-transform: scale(0.6);
      -o-transform: scale(0.6);
    }
  }

  .about-sec5 {
    .carousel-indicators-ul {
      margin-left: 300px;
      left: 50%;
    }

    .our-expressions-wrap {
      video {
        position: relative;
        z-index: 2;
        height: 400px;
        margin: 20px auto 0;
        display: block;
      }
    }
  }
  .term-col-wrap {
    margin-top: 4px;

    label.error {
      left: 50px;
      bottom: -30px;
    }
  }

  .next-page-section {
    .join-team-wrap {
      margin-top: 0;

      h6 {
        margin-bottom: 20px;
      }

      .next-page-img {
        width: 240px;
        top: 70px;
      }
    }
  }
  .rsmart-sect1 {
    .rsmart-overview-before-img {
      top: -140px;
      margin-left: 90px;
    }

    .rsmart-overview-after-img {
      bottom: -290px;
      margin-left: 370px;
    }
  }
  .rsmart-sect2 {
    .rsmart-overview-before-img {
      display: none !important;
    }
  }

  .rsmart-sect3 {
    .rsmart-challenges-after {
      position: absolute;
      top: 20px;
      max-width: inherit;
      left: -160px;
    }

    .rsmart-challenges-before {
      right: 100px;
    }
  }
  .rsmart-sec5 {
    .rsmart-challenges-before {
      display: none !important;
    }
  }
  .rsmart-sect6 {
    .rsmart-sect6-2 {
      display: inline-block;
      max-width: inherit;
      height: auto;
      margin-left: 0;
      width: 420px;
    }

    .rsmart-sec5-after {
      position: absolute;
      top: -60px;
      max-width: inherit;
      left: -160px;
    }

    .rsmart-challenges-before {
      display: none !important;
    }
  }
  .rsmart-sect7 {
    .rsmart-sect7-after {
      bottom: -160px;
      margin-left: 140px;
    }

    .rsmart-strategy-img {
      margin-top: 50px;
    }
  }
  .rsmart-sect8 {
    .portfolio-info-wrap2 {
      max-width: 970px;
      margin: auto;
    }

    .rsmart-app-view1-img {
      display: block;
      width: 240px;
      float: right;
      margin-top: -0;
      margin-right: 0;
    }

    .rsmart-app-view2-img {
      display: block;
      width: 240px;
      float: left;
      margin-top: -0;
      margin-left: 0;
    }
  }
  .comman-card-img-wrap {
    .crubba-thumb-1 {
      width: 710px;
    }

    .vigan-thumb-3 {
      left: 150px;
    }

    .vigan-thumb-2 {
      left: 10px;
    }

    .mytrux-thumb-1 {
      left: -40px;
    }

    .mytrux-thumb-2 {
      left: 90px;
    }

    .mytrux-thumb-3 {
      left: -310px;
    }

    .bagshaha-thumb-2 {
      left: -150px;
    }

    .bagshaha-thumb-3 {
      left: 140px;
    }
  }
  .smartlinks-sec1 {
    .smartlinks-logo-after {
      position: absolute;
      bottom: 21px;
      right: -250px;
      z-index: 1;
      width: 500px;
    }
  }

  .smartlinks-sect2 {
    .smartlinks-logo-after2 {
      position: absolute;
      bottom: 21px;
      right: -250px;
      z-index: 1;
      width: 500px;
    }
  }

  .smartlinks-sec7 {
    #comparison {
      #divisor,
      figure {
        background-position-x: 180px;
        background-size: 420px auto;
      }
    }
  }

  .easy-plan-sec3 {
    .container {
      max-width: inherit !important;
    }

    .easy-plan-strategy-right {
      margin-right: 78px;
    }
  }
  .easy-plan-sec4 {
    height: 730px;
    overflow: hidden;

    .easy-plan-onboarding1 {
      position: absolute;
      max-width: initial;
      top: 50px;
      left: 100px;
      max-width: 1000px;
    }

    .easy-plan-onboarding2 {
      position: absolute;
      max-width: initial;
      top: -10px;
      left: 50px;
      max-width: 1110px;
    }

    .easy-plan-onboarding1 {
      position: absolute;
      max-width: initial;
      top: 50px;
      left: -15px !important;
    }

    .easy-plan-onboarding2 {
      left: -74px !important;
    }

    .easy-plan-strategy-left {
      -webkit-backface-visibility: hidden;
      transform: scale(0.65) translateZ(0) !important;
      min-height: 500px;
      transform-origin: center 40px;
      -webkit-transform: scale(0.65) translateZ(0) !important;
      -moz-transform: scale(0.65) translateZ(0) !important;
      -ms-transform: scale(0.65) translateZ(0) !important;
      -o-transform: scale(0.65) translateZ(0) !important;

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }

    .easy-plan-strategy-left-inner2 {
      top: 250px;
      margin-bottom: 0;
      left: 92px;

      .img-fluid {
        display: inline-block;
        width: 560px;
        max-width: inherit;
      }
    }
  }

  .easy-plan-sec5 {
    .ease-plan-chat-wrap {
      min-height: 500px;
      transform-origin: center 200px;
      transform: scale(0.7) translateY(-50%);
      -webkit-transform: scale(0.7) translateY(-50%);
      -moz-transform: scale(0.7) translateY(-50%);
      -ms-transform: scale(0.7) translateY(-50%);
      -o-transform: scale(0.7) translateY(-50%);
      position: absolute;
      top: 50%;

      .ease-plan-chat-mobile-wrap .ease-plan-chat-mobile-hover {
        position: absolute;
        top: 36px;
        left: 23px;
        right: 0;
        bottom: 0;
        width: 210px;
        height: 440px;
      }

      .easy-plan-chat3 {
        position: absolute;
        bottom: 120px;
        right: -50px;
      }
    }
  }

  .easy-plan-sec6 {
    .easy-plan-goal-right {
      margin-top: 180px;
      margin-bottom: 100px;
    }

    .easy-plan-sec6-inner {
      min-height: 500px;
      transform-origin: -70px 250px;
      transform: scale(0.4) translateY(-50%);
      -webkit-transform: scale(0.4) translateY(-50%);
      -moz-transform: scale(0.4) translateY(-50%);
      -ms-transform: scale(0.4) translateY(-50%);
      -o-transform: scale(0.4) translateY(-50%);
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -110px;
    }
  }

  .easy-plan-sec7 {
    height: 650px;
    overflow: hidden;

    .easy-plan-sect6-inner1 {
      position: absolute;
      z-index: -1;
      max-width: initial;
      animation: rotation-ani 15s infinite linear;
      -webkit-animation: rotation-ani 15s infinite linear;
      display: block !important;
      top: -190px;
      left: -140px;
    }

    .easy-plan-sect6-inner3 {
      left: 30px;
    }

    .easy-plan-sect6-inner2 {
      left: 200px;
    }
  }

  .easy-plan-sec8 {
    .easy-plan-sec8-inner {
      min-height: 500px;
      transform-origin: 100px 250px;
      transform: scale(0.45) translateY(-50%);
      -webkit-transform: scale(0.45) translateY(-50%);
      -moz-transform: scale(0.45) translateY(-50%);
      -ms-transform: scale(0.45) translateY(-50%);
      -o-transform: scale(0.45) translateY(-50%);
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -60px;
    }
  }
  .easy-plan-sec9 {
    .easy-plan-sec9-inner {
      min-height: 600px;
      transform: scale(0.6) translateZ(0);
      -webkit-transform: scale(0.6) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.6) translateZ(0);
      -ms-transform: scale(0.6) translateZ(0);
      -o-transform: scale(0.6) translateZ(0);
      transform-origin: 0 200px;
      height: 500px;

      .easy-plan-sec9-inner1 {
        position: absolute;
        bottom: -12px;
        left: -83px;
        z-index: 4;
      }

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .easy-plan-sec1 {
    .easy-plan-banner-img {
      max-width: inherit;
      width: 380px;
      margin-left: -130px;
      margin-top: 70px;
    }
  }
  .easy-plan-sec10 {
    .easy-plan-sec10-inner {
      height: 500px;
      min-height: inherit;
      transform: scale(0.7) translateZ(0);
      -webkit-transform: scale(0.7) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.7) translateZ(0);
      -ms-transform: scale(0.7) translateZ(0);
      -o-transform: scale(0.7) translateZ(0);
      transform-origin: center 200px;

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }

  .easy-plan-sec7 {
    .easy-plan-sec7-inner {
      .easy-plan-sec7-inner1,
      .easy-plan-sec7-inner2,
      .easy-plan-sec7-inner3,
      .easy-plan-sec7-inner4 {
        width: 80%;
      }
    }
  }
  .easy-plan-sec4 .owl-caroual-comman {
    position: absolute;
    top: 260px;
    left: 50%;
    width: 300px;
    margin: auto;
    margin-left: auto;
    transform: translateX(-50%) scale(0.75);
    -webkit-transform: translateX(-50%) scale(0.75);
    -moz-transform: translateX(-50%) scale(0.75);
    -ms-transform: translateX(-50%) scale(0.75);
    -o-transform: translateX(-50%) scale(0.75);
    z-index: 49;
    height: 700px;
    margin-left: 30px;
  }
  .phycheme-sec4 {
    .owl-caroual-comman-fade {
      .phycheme4-4-img {
        min-height: 200px;

        img {
          max-width: 210px;
        }
      }
    }
  }
  .phycheme-sec5 {
    .owl-carousel .owl-item img,
    .phycheme-logo-concept {
      height: auto;
      width: 100%;
      object-fit: cover;
      display: inline-block;
    }
  }
  .phycheme-sec9 {
    .phycheme-sec9-1-outer {
      transform: scale(0.65) !important;
      transform-origin: center 0 !important;
      -webkit-transform: scale(0.65) !important;
      -moz-transform: scale(0.65) !important;
      -ms-transform: scale(0.65) !important;
      -o-transform: scale(0.65) !important;
    }
  }
  .phycheme-sec12 {
    .phycheme-left-wrap4 {
      .phycheme-sec12-4 {
        height: inherit;
        position: relative;
        margin: auto;
        display: block;
      }
    }
  }
  .moneyguru-sec-website1 {
    text-align: center;

    .portfolio-info-banner-l.portfolio-info-wrap2 {
      max-width: 600px;
      margin: 60px auto 0;
    }

    .portfolio-info-banner-r {
      .moneyguru-banner-img {
        margin-left: 0;
      }

      .moneyguru-banner-after {
        margin-left: 0;
      }
    }

    .mt30 {
      margin-top: 20px !important;
    }
  }

  .moneyguru-sec-website5 {
    .moneyguru-landing-outer {
      .moneyguru-landing {
        max-width: 640px;
      }
    }
  }
  .moneyguru-sec-website6 {
    .moneyguru-insights-right {
      .moneyguru-platform {
        display: inline-block;
        max-width: 440px;
        margin-top: 70px;
        margin-left: -70px;
      }
    }
  }
  .moneyguru-sec-website7,
  .moneyguru-sec-website8 {
    .moneyguru-insights-left.portfolio-info-wrap2 {
      max-width: 300px;
    }

    .moneyguru-insights-left {
      white-space: inherit;
    }

    .moneyguru-insights-right-col {
      .moneyguru-insights-right {
        img {
          float: right;
          display: block;
          max-width: 400px;
          margin-right: -26px;
        }
      }
    }
  }
  .moneyguru-sec-website2 {
    .moneyguru-overview {
      top: -40px;
    }
  }
  .moneyguru-sec-website9 {
    .moneyguru-landing-outer {
      .mytrux-details-up {
        position: absolute;
        top: 130px;
        left: 10px;
        z-index: 0;
        width: 100px;
      }
    }
  }

  .portfolio-branding-insisive-body {
    .insisive-logo-after-img {
      position: absolute;
      bottom: 30px;
      right: 30px;
      width: 250px;
      z-index: 1;
    }

    .bagshaha-sec4 {
      .bagshaha-sec4-right {
        .bagshaha-sec4-right-b {
          img {
            padding-top: 10px;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .notion-edge-sec8 {
    #comparison {
      figure,
      #divisor {
        background-position: 150px center;
      }
    }
  }

  .portfolio-branding-vimson-body {
    .notion-edge-sec1 {
      .notion-edge-logo-bg {
        display: inline-block;
        position: absolute;
        bottom: 180px;
        left: auto;
        z-index: 0;
        width: 400px;
        right: -140px;
      }
    }

    .vimson-sec6 {
      .vh100-100 {
        min-height: 600px;

        .vimson-compare.img-fluid.lazy {
          width: 230px;
        }
      }
    }
  }
  .portfolio-webapp-insisive-body {
    .insisive-website-sec1 {
      h1 {
        br {
          display: none;
        }
      }

      .insisive-logo-after {
        position: absolute;
        bottom: 150px;
        right: -90px;
        z-index: 1;
        width: 200px;
      }
    }

    .insisive-webapp-4 {
      padding-bottom: 150px !important;
    }

    .insisive-website-sec8 {
      .insisive-website-sec8-right {
        margin-top: -20px;
      }
    }

    .insisive-webapp-4 {
      .insisive-straight-up-outer {
        position: absolute;
        top: 110px;
        right: -200px;
        bottom: auto;
        left: 0;
        z-index: 9;
      }
    }

    .insisive-website-sec26 {
      .insisive-website-sec2-1 {
        height: 230px !important;
        object-fit: cover;
      }
    }
  }
  .portfolio-website-bynry-body {
    .bynry-website-sec1 {
      .bynry-banner-wrap {
        .img-fluid {
          height: 440px;
          max-width: inherit;
          top: 40px;
          position: relative;
          left: 40px;
        }
      }
    }

    .bynry-website-sec2 {
      .moneyguru-sec2-left {
        margin-left: 0;

        .img-fluid {
          max-width: 400px;
        }
      }
    }

    .bynry-website-sec4 {
      .authntick-center-wrap {
        h3 {
          white-space: normal;
        }
      }

      .authntick-sec9-2-outer {
        img {
          display: inline-block;
          width: 600px;
        }
      }
    }

    .bynry-website-sec5 {
      .insisive-website-sec8-right {
        .img-fluid {
          display: inline-block;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .bynry-website-sec6 {
      [data-aos="new-animation5"].aos-animate {
        width: 26%;
      }

      .img-fluid.bynry-landing-page.lazy {
        max-width: 450px;
        margin-top: 10px;
        margin-left: -60px;
      }
    }

    .bynry-website-sec7 {
      .bagshaha-sec9-2-outer {
        .bynry-devices {
          max-width: 340px;
          margin-left: 0;
        }
      }
    }
  }
  .expertise-sect2 {
    .expertise-container {
      padding-top: 0;
    }

    .expertise-sect-left {
      .expertise-sect-left-head {
        .expertise-icon {
          position: absolute;
          top: -17px;
          left: -45px;
          z-index: 0;
          width: 90px;
        }
      }

      .expertise-sect-left-body {
        .expertise-icon-big {
          width: 220px;
          top: -20px;
          left: 140px;
        }
      }
    }

    .expertise-sect-right {
      .expertise-ul-list {
      }

      p {
        margin-bottom: 30px;
      }
    }
  }
  .expertise-sect1 {
    .comman-card-img-wrap {
      transform-origin: center center;
      margin-left: 30px;
    }
  }
  .expertise-sect2 {
    .expertise-sect-left .expertise-sect-left-body .bttn {
      margin-top: 15px;
      margin-bottom: 30px;
    }

    .expertise-sect-left .expertise-sect-left-head .expertise-icon {
      display: none !important;
    }

    .expertise-sect-left .expertise-sect-left-body .expertise-icon-big {
      display: none !important;
    }
  }
  .authntick-sect5 {
    .authntick-sect5-laptop-wrap {
      img {
        height: auto;
        width: auto;
      }
    }

    .authntick-sect5-overlay-wrap {
      margin-left: -230px;

      img {
        width: 230px;
      }
    }
  }
  .phycheme-sect3-right-adj {
    margin-top: -158px;
    margin-left: 60px;
  }
  .comman-solution-challenge {
    .portfolio-info-wrap2 {
      min-height: 380px;
    }
  }
  .portfolio-thumb-all {
    &:nth-child(odd) {
      padding-top: 50px !important;
    }

    .work-card {
      margin-bottom: 100px !important;

      .work-card-img {
        position: relative;
        transform: scale(0.6);
        transform-origin: left top;
        -webkit-transform: scale(0.6);
        -moz-transform: scale(0.6);
        -ms-transform: scale(0.6);
        -o-transform: scale(0.6);
        height: 360px;
      }

      .work-card-body {
        width: 330px;
      }
    }
  }
  .our-expressions-upd {
    position: relative;
    height: 480px;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
  }
  .expertise-sect-9 {
    .table-expertise {
      border-collapse: collapse;
      width: 100%;
      margin: auto;
      border-style: hidden;
    }

    .comman-card-info {
      padding: 0 30px;
    }
  }
  .home-sec1 {
    .comman-card-img-wrap-upd.hide-mobile {
      transform: scale(0.5);
      -webkit-transform: scale(0.5);
      -moz-transform: scale(0.5);
      -ms-transform: scale(0.5);
      -o-transform: scale(0.5);
      transform-origin: center 133px;
    }
  }
  .restlessRegularText {
    font-size: 85px;
  }
  .about-sec-img-right {
    transform: scale(0.6);
    transform-origin: center 80px;
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -ms-transform: scale(0.6);
    -o-transform: scale(0.6);
    position: relative;
    height: 400px;
  }
  .services-user-experience-design-wrap {
    margin-left: 140px !important;
  }
  .services-brand-strategy-identity-design-wrap {
    transform-origin: center right !important;
  }
  .process-page-body .comman-card-img-wrap-upd {
    transform: scale(0.8);
    transform-origin: center center;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
  }
  .failure-section .failure-sec-right {
    width: 47%;
  }
  .portfolio-website-insisive2-body {
    .insisive-website-sect1-1 {
      margin: 65px auto 40px !important;
      display: block !important;
      text-align: center;
    }

    .insisive-website-sect2-img {
      width: 500px;
      display: none;
    }

    [data-aos="new-animation5"].aos-animate {
      width: 26.5%;
    }

    .img-fluid.insisive-website-sect5-img.lazy {
      margin-left: 0;
    }

    .insisive-website-sect7-wrap {
      height: 530px;
    }

    .insisive-website-sect2-center {
      max-width: 470px;
      margin: 100px 0 160px;
      text-align: center;
      float: right;
    }

    .insisive-website-sect7-wrap .insisive-website-sect7-hover {
      margin-left: 10px;
    }

    .insisive-website-sect7-wrap {
      transform: scale(0.7) !important;
      -webkit-transform: scale(0.7) !important;
      -moz-transform: scale(0.7) !important;
      -ms-transform: scale(0.7) !important;
      -o-transform: scale(0.7) !important;

      img.insisive-website-sect7-lazy {
        margin-left: -120px;
      }
    }

    .insisive-website-sect8-wrap {
      transform: scale(0.7) !important;
      -webkit-transform: scale(0.7) !important;
      -moz-transform: scale(0.7) !important;
      -ms-transform: scale(0.7) !important;
      -o-transform: scale(0.7) !important;

      .insisive-website-sect8-lazy {
        top: -58px;
        width: 850px;
        margin-left: 10px;
      }
    }

    .insisive-website-sect8-carousel .owl-item img {
      margin: auto;
      width: 700px;
    }

    .insisive-website-sect8 {
      padding-bottom: 0;
      margin-top: -20px;
      position: relative;
      margin-bottom: -100px;
    }

    .insisive-website-sect6-1 {
      margin-bottom: 50px;
    }

    .insisive-website-sect8 h3 {
      margin-top: 10px;
    }
  }
  .insisive-website-sec7 .insisive-website-sec7-right {
    float: left;
    padding-left: 0;
    margin-left: 10px;
  }
  .img-fluid.smartlinks-sect4-1.lazy {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .portfolio-webapp-mytrux-body {
    .mytrux-webapp-sect1 {
      text-align: center;

      .comman-card-info {
        margin-top: 60px;
      }

      [data-aos="new-animation7"].aos-animate {
        width: 100%;
      }

      .mytrux-webapp-sect1-1 {
        margin-top: -10px !important;
        right: -27px !important;
        display: none !important;
      }

      .mytrux-webapp-sect1-2 {
        position: absolute;
        left: -70px;
        top: 156px;
        z-index: 3;
        width: 260px;
      }
    }

    .mytrux-webapp-sect2 {
      .mytrux-website-sect2-1 {
        position: absolute;
        bottom: -45px;
        right: -50px;
        z-index: 3;
        width: 260px;
        display: none !important;
      }
    }

    .text-md-ceter {
      text-align: center;
    }

    .mytrux-webapp-sect3 {
      .ani-wrap50 {
        background: #0f2541;
        width: 100% !important;
        height: 50% !important;
      }

      .mytrux-webapp-sect3-left-list {
        transform: scale(0.7);
        -webkit-transform: scale(0.7);
        -moz-transform: scale(0.7);
        -ms-transform: scale(0.7);
        -o-transform: scale(0.7);
        margin: 0 auto 100px;
        text-align: center;
      }

      .mytrux-webapp-sect3-right-list {
        li {
          position: relative;
          padding-left: 40px;
          margin-bottom: 30px;
        }
      }
    }

    .comman-solution-challenge .portfolio-info-wrap2 {
      min-height: inherit;
      margin: auto !important;

      h3 {
        text-align: center;
      }
    }

    .mytrux-webapp-sect4 {
      .portfolio-info-wrap2 {
        margin: 0 auto !important;
      }

      .mytrux-webapp-sect4-1,
      .mytrux-webapp-sect4-2,
      .mytrux-webapp-sect4-3 {
        max-width: 500px;
        position: relative;
        top: auto;
        left: 0 !important;
        margin: 40px auto 0;
        display: block !important;
      }
    }

    .mytrux-webapp-sect5 {
      .portfolio-info-wrap2 {
        margin: 40px auto 0;
        text-align: center;
      }

      .mytrux-webapp-sect5-1 {
        max-width: 630px;
        margin: auto !important;
        display: block !important;
      }

      .mytrux-webapp-sect5-2 {
        display: none !important;
      }
    }

    .mytrux-webapp-sect6 {
      .mytrux-webapp-sect6-1-wrap {
        .mytrux-webapp-sect6-1 {
          max-width: 550px;
          margin: 0 auto !important;
          display: block !important;
        }
      }

      .mytrux-webapp-sect6-2-wrap {
        .mytrux-webapp-sect6-2 {
          max-width: 550px;
          margin: 0 auto !important;
          display: block !important;
        }
      }
    }

    .mytrux-webapp-sect7 {
      .mytrux-webapp-sect7-1-wrap {
        padding-top: 0;
      }
    }
  }
  .portfolio-webapp-mytrux-body .mytrux-webapp-sect6 .mytrux-webapp-sect6-2-wrap p,
  .portfolio-webapp-mytrux-body .mytrux-webapp-sect6 .mytrux-webapp-sect6-1-wrap p {
    position: relative !important;
    transform: none !important;
    left: auto !important;
    top: auto !important;
    margin: auto !important;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -o-transform: none !important;
  }
  .comman-section.scroll-div.home-sec6.pb0 .comman-scrollbar-wrap {
    height: 740px;
    overflow: auto;
  }
  .authntick-sec9 .authntick-result-carosual .owl-stage-outer.owl-height {
    height: 205px !important;
  }
  .portfolio-website-insisive2-body .insisive-website-sect8-wrap {
    margin-top: 50px !important;
    position: relative;
  }
  .comman-section.comman-section-100vh.insisive-website-sect8.scroll-div.xs-pt0 .insisive-website-sect8-wrap {
    margin-top: 80px !important;
    transform: scale(0.8) !important;
    -webkit-transform: scale(0.8) !important;
    -moz-transform: scale(0.8) !important;
    -ms-transform: scale(0.8) !important;
    -o-transform: scale(0.8) !important;
    height: 450px;
  }

  .esop-sec1 {
    .esop-triangle1 {
      display: none !important;
    }

    .esop-triangle2 {
      display: none !important;
    }

    .esop-header-img {
      max-width: 570px;
      margin-top: 40px;
      margin-left: 170px;
      margin-bottom: 90px;
    }
  }
  .esop-sec4 {
    background: #15269b url("../images/portfolio/portfolio-webapp-esop/esop-findability-bg.png") no-repeat right -400px bottom -285px;
    background-size: 1100px auto;
  }

  .esop-sec6 {
    .esop-left-wrap {
      .esop-slider-circle {
        width: 600px;
        height: 600px;
      }

      .comman-scrollbar-wrap {
        transform: scale(0.7);
        -webkit-transform: scale(0.7);
        -moz-transform: scale(0.7);
        -ms-transform: scale(0.7);
        -o-transform: scale(0.7);
        transform-origin: -50px 0;
      }
    }

    .esop-right-wrap {
      margin-left: 60px;
      margin-top: 0;
      z-index: 9;
      position: relative;
    }
  }
  .esop-sec7 {
    &:after {
      height: 150px;
    }

    .bottom-col-set {
      bottom: -60px;
      max-width: 700px;
      margin: auto;
    }

    .esop-center-wrap {
      margin-top: 60px !important;
    }
  }
  .circle-sec {
    width: 500px;
    height: 500px;
  }
  .esop-sec8 {
    .esop-right-wrap {
      .esop-attention-wrap {
        top: 40px;
        right: -130px;
        max-width: 600px;
      }

      .esop-right-row.clearfix {
        transform: scale(0.55);
        transform-origin: 70px center;
        -webkit-transform: scale(0.55);
        -moz-transform: scale(0.55);
        -ms-transform: scale(0.55);
        -o-transform: scale(0.55);
      }
    }
  }

  .esop-sec9 {
    .esop-left-wrap {
      .img-fluid {
        margin-left: -170px;
        position: relative;
        top: 50px;
        max-width: 610px;
      }
    }
  }

  .esop-sec10 {
    .esop-left-wrap {
      margin-top: 110px;
      margin-left: 0;
    }

    .esop-right-wrap {
      .img-fluid {
        max-width: 610px;
        margin-left: -40px;
        position: relative;
        top: 50px;
      }
    }
  }
  .esop-sec11 {
    .esop-left-wrap {
      .img-fluid {
        max-width: 440px;
        margin-left: -93px;
        position: relative;
        top: 23px;
      }
    }

    .esop-right-wrap {
      margin-left: 0;
      margin-top: 50px;
    }
  }
  .esop-sec2 {
    background-size: 450px auto;
    background-position-y: 10px;
  }
  .esop-sec12 {
    height: 600px;
    background-size: 450px auto;
    background-position-y: 10px;

    .esop-center-wrap {
      position: relative;
      top: 100px;
    }

    .ui-card-slider {
      position: relative;
      width: 320px;
      height: 270px;
      overflow: hidden;
      transform: scale(2.6);
      transform-origin: 0 top;
      -webkit-transform: scale(2.6);
      -moz-transform: scale(2.6);
      -ms-transform: scale(2.6);
      -o-transform: scale(2.6);
      margin-left: -70px;
    }
  }
  .esop-sec13 {
    .comman-impact-bottom-ul {
      width: 600px;
      transform: scale(0.6);
      transform-origin: 0 0;
      -webkit-transform: scale(0.6);
      -moz-transform: scale(0.6);
      -ms-transform: scale(0.6);
      -o-transform: scale(0.6);
    }
  }
  .concerro-pattern-wrap .img-fluid {
    width: 240px;
  }
  .concerro-sec4 {
    .concerro-right-wrap {
      .concerro-brainstorming-1 {
        top: -170px;
      }

      .concerro-brainstorming-2 {
        top: 70px;
      }
    }
  }
  .concerro-sec4-1.lazy.img-fluid {
    max-width: 400px;
  }

  .source-trace-sec1 {
    .source-trace-right-wrap {
      img {
        top: 30px;
        left: -20px;
        width: 360px;
      }
    }
  }
  .source-trace-sec2 {
    .farmers-img {
      width: 300px;
    }
  }
  .source-trace-sec3 {
    .small-tracker-img {
      bottom: -100px;
    }

    .leave1-img {
      top: -60px;
      display: none !important;
    }

    .overview-sec-center {
      position: relative;
      margin-bottom: 80px;
    }
  }
  .source-trace-sec4 {
    .leave3-img {
      display: none !important;
    }

    .corn-img {
      display: none !important;
      position: absolute;
      bottom: -70px;
      right: -130px;
      z-index: 2;
      width: 300px;
    }
  }
  .source-trace-sec5 {
    .info-architecture1 {
      max-width: 700px;
      display: block;
      margin: 10px auto 0;
    }
  }
  .source-trace-sec6 {
    .source-trace-center-wrap {
      margin-bottom: 20px;
    }

    .wireframe-img {
      margin-top: 30px;
    }
  }
  .source-trace-sec7 {
    .leave1-img {
      top: 60px;
      display: none !important;
    }

    .leave2-img {
      top: 60px;
      display: none !important;
    }

    .farmer-flow {
      margin-top: 0;
      max-width: 600px;
      margin-bottom: 100px;
    }
  }
  .source-trace-sec8 {
    .leave2-img {
      top: 180px;
      display: none !important;
    }

    .multi-dashboard {
      max-width: 450px;
      margin-top: 50px;
      margin-left: -20px;
    }

    .leave-bg {
      bottom: -177px;
      display: none !important;
    }

    .plant-bg {
      display: none !important;
    }
  }
  .source-trace-sec10 {
    .dt-img {
      max-width: 700px;
    }

    .db1 {
      display: none !important;
    }

    .db2 {
      display: none !important;
      bottom: -10px;
      left: -70px;
      max-width: 280px;
    }
  }
  .source-trace-sec11.esop-sec6 {
    .esop-left-wrap {
      .comman-scrollbar-wrap {
        .esop-slider-outer-wrap {
          margin-left: 40px;
          animation: none;
          -webkit-animation: none;
          margin-top: 130px;
        }
      }
    }
  }
  .source-trace-sec12 {
    .gui-components {
      max-height: calc(100vh - 180px);
      margin: auto !important;
      display: block;
    }
  }

}

@media (min-width: 992px) {
  .no-wrap-md {
    white-space: nowrap;
  }
  .esop-sec13-left {
    margin-top: -60px !important;
  }
}

// max width 1199
@media (max-width: 991.98px) {
  .contact-map-md-section {
    display: block;
    text-align: center;
    // border-top: 1px solid rgba($color: $black, $alpha: 0.1);
    padding: 50px 0 70px;
  }
  .order-md-last-adj {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-last-adj {
    -ms-flex-order: 1;
    order: 1;
  }
  .contact-page-body .comman-header.nav-down .comman-header-menu-ul .logo-img path,
  .contact-page-body .comman-header.nav-down .comman-header-menu-ul .mega-menu-img1 path {
    fill: #242424;
  }

  .our-work-page {
    .our-work-row {
      .work-card.card-portfolio-webapp-esop {
        .portfolio-webapp-esop-1 {
          top: 110px;
          left: -98px;
          max-width: 620px;
        }

        .portfolio-webapp-esop-3 {
          top: 94px;
          left: -32px;
          max-width: 66px;
        }
      }
    }
  }

  /* Added on 30-10-2020 */
  .esop-sec1 {
    .esop-left-wrap {
      margin-top: 70px;
    }

    .comman-card-top-ul {
      margin-bottom: 0;
      margin-top: 12px;
      margin-left: 0;
    }

    h1.hide-mobile {
      display: block !important;
      margin-top: 24px !important;
    }
  }
}

@media (min-width: 768px) {
  .swal2-title {
    font-size: 22px !important;
    line-height: normal !important;
    color: #242424 !important;
    font-weight: 600 !important;
    font-family: "Poppins", sans-serif !important;
  }
  .show-mobile {
    display: none !important;
  }
  .hide-desktop {
    display: none !important;
  }
  .comman-header-wrap {
    .comman-top-menu {
      .top-menu-bottom {
        a {
          i {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .comman-footer {
    .comman-footer-row2 {
      ul {
        a {
          i {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .concerro-sec7 {
    overflow: hidden;
    position: relative;

    .concerro-center-wrap {
      .img-fluid {
        height: calc(100vh + 15px);
      }
    }
  }
}
