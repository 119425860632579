// @import "./base.scss";
// *{cursor: none !important;}
// Extra large devices (large desktops, 1200px and up)

@media (min-width: 1800px) {
  .crubba-sec5 {
    .crubba-sec5-col-height {
      position: relative;
    }
    .logo-list{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      img{
        margin-right: 20px;
      }
    }

 
  }

  .moneyguru-header-section {
    .moneyguru-header-right-wrap {
      top: 90px;
      position: relative;
    }
  }
  .about-sec5 {
    .our-expressions-wrap {
      margin-top: 60px;
      margin-left: -67px;
      position: relative;
      left: 60px;
    }
  }
  .career-sec1 {
    .comman-card-img-wrap {
      left: 30px;
    }
  }
  .mytrux-webapp-sect1 {
    [data-aos="new-animation7"].aos-animate {
      width: 65% !important;
    }
    .img-fluid.lazy.mytrux-webapp-sect1-1 {
      right: -157px !important;
    }
  }
  
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1600px) {
  a.mega-menu.mega-menu-close {
    position: relative;
    top: 0;
    left: 10px;
  }
  .authntick-sec9 .authntick-result-carosual .owl-stage-outer.owl-height {
    height: 476px !important;
  }
  .xl-no-wrap {
    white-space: nowrap;
  }
  .xl-mb100 {
    margin-bottom: 100px !important;
  }
  .xl-mt100 {
    margin-top: 100px !important;
  }
  .xl-w-100 {
    width: 100% !important;
  }
  .xl-show {
    display: block !important;
  }
  .xl-text-center {
    text-align: center;
  }
  .comman-section {
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
      max-width: 1260px !important;
    }
  }
  .comman-top-menu {
    .container.mw-1000 {
      max-width: 1050px;
    }
  }
  .home-sec1 {
    .comman-card-img-wrap {
      left: 90px;
    }
    .comman-card-info {
      p.sec-para {
        max-width: 620px;
      }
    }
  }
  .home-sec3 {
    .tab-content {
      position: relative;
      left: 20px;
    }
  }

  .home-sec5 {
    .tab-content {
      position: relative;
      left: 85px;
    }
  }
  .home-sec6 {
    .award-logo-wrap {
      position: relative;
      left: 10px;
    }
  }
  .comman-card-img-wrap2 {
    position: relative;
    margin-left: 0px;
    left: 125px;
    top: 76px;
    width: 470px;
  }
  .home-sec7 {
    .process-img-wrap {
      img {
        width: 850px;
        height: auto;
        max-width: inherit;
        margin-left: -50px;
        margin-top: -70px;
      }
    }
  }
  .about-sec3 {
    .comman-card-info {
      max-width: 600px;
      // min-height: 342px;
    }
  }
  .expertise-sec1 {
    .comman-card-img-wrap2 {
      left: 15px;
    }
  }
  .on-scroll-filter-menu-wrap {
    left: 54px;
  }

  .home-sec6 {
    &.h-2000 {
      height: 2300px !important;
    }
    .award-card {
      min-height: 340px;
      .p4.text-light-dark-c {
        min-height: 100px;
      }
    }
  }

  .mytrux-logo-sec2 {
    .mytrux-website-sec9-4 {
      margin: 145px auto 0;
      display: block !important;
    }
  }
  .mytrux-logo-sec4 .mytrux-logo-sec4-right img {
    max-width: inherit;
    width: 50vw;
    margin-top: -100px;
    height: 100vh;
    object-fit: cover;
  }
  .mytrux-logo-sec7 #comparison figure,
  .mytrux-logo-sec7 #comparison #divisor {
    background-position-x: 700px !important;
  }
  .mytrux-logo-sec9 .p2 {
    margin-top: 200px;
  }
  .mytrux-logo-sec11 .mytrux-logo-right-wrap {
    position: relative;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }

  .insisive-branding-sec8 {
    background: #f3f3f3
      url("../images/portfolio/portfolio-branding-insisive/insisive-branding-sec8.jpg")
      no-repeat center center;
    background-size: auto;
    background-size: inherit;
  }
  .about-sec6 {
    .comman-card-img-wrap3-outer {
      height: 650px;
    }
    .comman-card-img-wrap3 {
      margin: 170px auto 0 !important;
      transform: scale(0.9) translateZ(0);
      -webkit-transform: scale(0.9) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.9) translateZ(0);
      -ms-transform: scale(0.9) translateZ(0);
      -o-transform: scale(0.9) translateZ(0);
      transform-origin: center top;
    }
  }
  .phycheme-sec12 .phycheme-left-wrap4 .phycheme-sec12-4 {
    height: calc(100vh - 200px);
  }
  .smartlinks-sect4-1-img {
    display: inline;
    width: 100%;
    max-width: inherit;
    height: auto;
  }
  .alloch-sec2 .alloch-sec2-2 {
    display: inline-block;
    position: relative;
    top: 50px;
    left: -91px;
    z-index: 1;
    width: 440px;
    float: right;
    margin-right: -60px;
    margin-top: 0px;
  }
  .easy-plan-sec4 {
    .easy-plan-strategy-left-inner2 {
      position: relative;
      top: 200px;
      float: right;
      left: 92px;
      margin-bottom: 100px;
    }
    .easy-plan-strategy-left {
      top: -180px;
    }
    .owl-caroual-comman {
      top: 320px;
    }
  }
  .easy-plan-sec7 .easy-plan-sec6-inner {
    position: relative;
    top: 70px;
    left: 0;
    height: 700px;
    transform: none !important;
    transform-origin: right -260px;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -o-transform: none !important;
  }
  .easy-plan-sec8 .easy-plan-sec8-inner {
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
    margin-top: 140px;
  }
  .easy-plan-sec9 .easy-plan-sec9-inner {
    transform: scale(0.85);
    -webkit-transform: scale(0.85);
    -moz-transform: scale(0.85);
    -ms-transform: scale(0.85);
    -o-transform: scale(0.85);
  }

  .moneyguru-sec1 .portfolio-info-wrap2 .moneyguru-banner-img {
    width: auto;
    margin-top: 110px;
  }
  .moneyguru-sec3 .moneyguru-sec3-bg {
    width: auto;
    margin-top: 170px;
    margin-left: 510px;
  }

  .moneyguru-story-section .moneyguru-story-left .invest-now-item h5 {
    font-size: 16px;
    line-height: normal !important;
    margin-bottom: 10px;
  }
  .moneyguru-story-section {
    height: 101vh !important;
    .moneyguru-story-right {
      max-width: 500px;
      margin-top: -200px !important;
    }
    .moneyguru-story-left .invest-now-item p {
      font-size: 12px;
      line-height: normal !important;
    }
    .moneyguru-story-left .invest-now-item {
      height: 400px;
    }
  }

  .moneyguru-gateway-left.portfolio-info-wrap2 {
    max-width: 500px;
  }
  .comman-section.comman-section-100vh.moneyguru-gateway-section.scroll-div {
    height: 100vh !important;
  }
  .moneyguru-comprehensive-section {
    position: relative;
    overflow: hidden;
  }
  .moneyguru-investing-section .moneyguru-investing-left-col video {
    margin-left: 60px;
    margin-top: 70px;
  }
  .failure-section .failure-sec-right {
    margin-top: 80px;
    margin-right: -30px;
  }
  .moneyguru-comprehensive-section
    .moneyguru-comprehensive-top
    .portfolio-info-wrap2 {
    max-width: 1000px;
    margin: auto;
  }
  .moneyguru-investments-section .moneyguru-investments-left-col video {
    margin-left: 150px;
    margin-top: 30px;
  }
  .moneyguru-insights-section
    .moneyguru-insights-right-col
    .moneyguru-insights-right
    video {
    float: right;
    margin-top: 40px;
  }
  .mytrux-webapp-sec4 .mytrux-webapp-sec4-bottom {
    .ng-draggable, .viewer {height: 500px;}
  }
  .mytrux-webapp-sec6 .mytrux-webapp-sec6-right {
    .mytrux-web-sec6-1 {
      position: absolute;
      top: 336px;
      right: 240px;
      z-index: 3;
      height: inherit;
      transform: none;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform-origin: right center;
    }
    .mytrux-web-sec6-2 {
      position: absolute;
      top: 270px;
      right: 0;
      z-index: 2;
      height: inherit;
      transform: none;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform-origin: right center;
    }
    .mytrux-webapp-sec6-center.portfolio-info-wrap2 {
      margin-top: 200px;
    }
  }

  .mytrux-webapp-sec8 .mytrux-webapp-sec8-b .mytrux-webapp-sec8-1 .img-fluid {
    margin-top: 60px;
    margin-left: 230px;
  }
  .mytrux-webapp-sec8 .mytrux-webapp-sec8-b .mytrux-webapp-sec8-3 .img-fluid {
    margin-left: -230px;
  }
  .mytrux-website-sec10 .mytrux-website-top-wrap {
    transform: scale(0.9) translateZ(0);
    transform-origin: center 0;
    -webkit-transform: scale(0.9) translateZ(0);
    -webkit-backface-visibility: hidden;
    -moz-transform: scale(0.9) translateZ(0);
    -ms-transform: scale(0.9) translateZ(0);
    -o-transform: scale(0.9) translateZ(0);
    height: 800px;
    margin-top: -20px;
  }
  .failure-section .failure-sec-left {
    max-width: 550px;
  }
  .crubba-sec1 .portfolio-img-wrap {
    position: relative;
    min-height: 523px;
    transform: scale(0.8);
    transform-origin: right center;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
  }
  .crubba-sec2 .crubba-sec2-img1 {
    max-width: inherit;
  }
  .crubba-sec4 .crubba-sec4-bottom {
    .viewer, .ng-draggable {
      width: 100%;
      height: 500px;
      overflow: auto;
      margin-bottom: -130px;
    }
    .ng-draggable {overflow: none}
  }
  .crubba-sec6 .crubba-sec6-1 {
    position: absolute;
    top: 20px;
    right: -400px;
    max-width: inherit;
    z-index: 2;
  }
  .crubba-sec7 {
    height: 100vh !important;
  }
  .crubba-sec7-bottom {
    transform: scale(0.8) translateZ(0);
    -webkit-transform: scale(0.8) translateZ(0);
    -webkit-backface-visibility: hidden;
    -moz-transform: scale(0.8) translateZ(0);
    -ms-transform: scale(0.8) translateZ(0);
    -o-transform: scale(0.8) translateZ(0);
    transform-origin: center top;
    height: 600px !important;
  }
  .crubba-sec9 .crubba-sec9-img-wrap .crubba-slider-mobile {
    width: 350px;
    height: 653px;
    margin-left: -146px;
    margin-top: -11px;
  }

  .comman-section.comman-section-100vh.moneyguru-sec-website1.scroll-div.top-section-wrapper {
    .portfolio-info-banner-l.portfolio-info-wrap2 {
      max-width: 700px;
      h1,
      .h1 {
        font-size: 60px !important;
        font-weight: 700 !important;
        line-height: 80px !important;
      }
    }
  }
  .moneyguru-sec-website1 .portfolio-info-banner-r .moneyguru-banner-after {
    margin-left: 30px;
  }
  .moneyguru-sec-website2 .moneyguru-overview {
    top: 200px;
  }
  .moneyguru-sec-website4 .moneyguru-architecture-bottom .viewer,
  .moneyguru-sec-website4 .moneyguru-architecture-bottom .ng-draggable {
    height: 550px;
    padding: 0 30px 30px;
  }
  .comman-section.comman-section-100vh.moneyguru-sec-website5.bottom-col-section.scroll-div {
    .bottom-col-section .portfolio-info-wrap2 {
      margin: 240px auto 0;
      max-width: 705px;
    }
  }
  .comman-section.comman-section-100vh.moneyguru-sec-website6.scroll-div {
    .portfolio-info-wrap2 {
      max-width: 500px;
    }
  }
  .moneyguru-sec-website7 .moneyguru-insights-left.portfolio-info-wrap2,
  .moneyguru-sec-website8 .moneyguru-insights-left.portfolio-info-wrap2 {
    max-width: 570px;
  }
  .moneyguru-sec-website7
    .moneyguru-insights-right-col
    .moneyguru-insights-right
    img,
  .moneyguru-sec-website8
    .moneyguru-insights-right-col
    .moneyguru-insights-right
    img {
    float: none;
    display: block;
    max-width: 500px;
    margin-right: 0;
    margin-top: 220px;
  }
  .home-sec-tab .flex-column .nav-link span::after {
    top: 26px;
  }

  .process-section1 {
    // height: 4530px !important;
  }
  .industries-section1 {
    // height: 6330px !important;
  }
  .expertise-h2300 {
    // height: 2330px !important;
  }
  .expertise-h1730 {
    // height: 1740px !important;
  }
  .comman-section.comman-section-100vh.scroll-div.expertise-sect1.top-section-wrapper {
    .comman-card-img-wrap {
      margin-left: 130px;
    }
  }
  .error-page-section1 .error-img-wrap .error-icon {
    right: 100px;
  }
  .vigan-sec6 {
    background-position: right -170px center;
  }
  .alloch-sec18 .alloch-sec7-left.portfolio-info-wrap2 {
    margin: 0 auto 160px;
  }
  .easy-plan-sec10 .easy-plan-track-left {
    margin-top: 240px;
  }
  .moneyguru-sec4 .moneyguru-sec4-bottom .moneyguru-sec4-img {
    height: 400px;
  }
  .comman-section.comman-section-100vh.mytrux-webapp-sec6.scroll-div.xs-pb30 {
    .portfolio-info-wrap2 {
      margin-top: 200px;
    }
  }
  .crubba-sec8 .crubba-sec4-bottom1 {
    position: absolute;
    top: 280px;
    left: -370px;
    z-index: 6;
    width: 300px;
  }
  .crubba-sec9 .crubba-sec9-img2 {
    position: absolute;
    bottom: 40px;
    left: -60px;
    z-index: 0;
    width: 300px;
  }
  .comman-section.comman-section-100vh.moneyguru-sec-website5.bottom-col-section.scroll-div {
    .portfolio-info-wrap2 {
      margin: 250px auto 0 !important;
      max-width: 705px;
    }
  }
  .comman-section.comman-section-100vh.rsmart-sect1.scroll-div.top-section-wrapper {
    .img-fluid.rsmart-sec1-1 {
      margin-left: 130px;
    }
  }
  .rsmart-sect1 .rsmart-overview-before-img {
    margin-left: 130px;
  }
  .rsmart-sec5 .rsmart-architecture-wrap .rsmart-architecture-img {
    margin-top: 10px;
    transform: translateY(90px);
  }
  .rsmart-sec5 .rsmart-center-wrap {
    text-align: center;
    max-width: 800px;
    margin: 44px auto 0;
  }
  .rsmart-sect6 .rsmart-sect6-2 {
    display: inline-block;
    max-width: inherit;
    height: calc(100vh - 400px) !important;
    margin-left: 0;
  }
  .rsmart-sect7-1 .rsmart-landing-outer .rsmart-landing {
    display: inline-block;
    height: 600px !important;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
  }
  .rsmart-sect7 .rsmart-strategy-img {
    display: inline-block;
    height: 100%;
    width: 500px;
    margin-top: 150px;
  }
  .rsmart-sect8 .rsmart-app-view2-img {
    display: block;
    width: 420px;
    float: left;
    margin-top: 20px;
    margin-left: 20px;
  }
  .rsmart-sect8 .rsmart-app-view1-img {
    display: block;
    width: 420px;
    float: right;
    margin-top: 27px;
    margin-right: 20px;
  }
  .rsmart-sect8 .portfolio-info-wrap2 {
    max-width: 970px;
    margin: 0 auto 60px;
  }
  .portfolio-webapp-insisive-body
    .insisive-website-sec26
    .insisive-website-sec2-1 {
    height: 450px !important;
  }
  .insisive-website-sec1 .insisive-website-sec1-1 {
    max-width: initial;
    margin-left: 80px;
  }
  .comman-section.comman-section-100vh.insisive-website-sec4.viewer-section.scroll-div {
    .viewer, .ng-draggable {
      height: 600px !important;
      margin-top: 80px;
    }
  }
  .insisive-website-sec6
    .insisive-website-sec6-1-outer
    .insisive-website-sec6-1 {
    max-width: initial;
    height: inherit;
    margin-left: 80px;
    margin-top: 10px;
  }
  .comman-section.comman-section-100vh.insisive-website-sec8.scroll-div {
    .img-fluid.insisive-website-sec8-1 {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .bagshaha-sec6 .bagshaha-sec6-3-outer {
    position: absolute;
    top: 0;
    right: -330px;
  }
  .career-form-wrap {
    max-width: 1055px;
    position: relative;
    margin: 150px auto 0;
  }
  .g-recaptcha {
    margin-top: 30px;
  }

  .moneyguru-sec-website5 .moneyguru-landing-outer .moneyguru-landing {
    margin-left: -90px;
    max-width: inherit;
    width: 1000px;
  }
  .mytrux-webapp-sec1 .mytrux-webapp-sec1-1 {
    display: inline-block;
    max-width: inherit;
    width: 700px;
    margin-left: 0;
    margin-top: 30px;
  }
  .comman-team-card {
    margin-top: 0px;
    .comman-team-card-head {
      transform: scale(0.9) translateZ(0);
      transform-origin: center bottom;
      -webkit-transform: scale(0.9) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.9) translateZ(0);
      -ms-transform: scale(0.9) translateZ(0);
      -o-transform: scale(0.9) translateZ(0);
      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .logo-list{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img{
      margin-right: 20px;
    }
  }
}
// Large devices (desktops, 1200px and up)
@media (min-width: 1200px) {
  .concerro-sec4 .concerro-right-wrap .concerro-brainstorming-1 {
	margin-left: 50px;
}
  .mega-menu.mega-menu-open.active-class {
    opacity: 0 !important;
  }
  .comman-header-menu-right {z-index: 99999 !important;}
  .lg-mt-60{
    margin-top: -60px !important;
  }
  .lg-mt-230 {
    margin-top: -230px !important;
  }
  .lg-mt185 {
    margin-top: 185px !important;
  }
  .lg-mt160 {
    margin-top: 160px !important;
  }
  .logo-wrap-our-team {
    position: fixed;
    top: 65px;
    left: 65px;
    z-index: 99;
    display: none;
  }
  .logo-wrap-a {
    position: fixed;
    top: 57px;
    left: 65px;
    z-index: 99;
    display: block;
  }
  body {
    &::-webkit-scrollbar {
      width: 10px;
      padding: 2px;
      transition: all 0.2s linear;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      -ms-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      &:hover {
        width: 10px;
        transition: all 0.2s linear;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -ms-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
      }
    }
    &::-webkit-scrollbar-thumb {
      background: $black;
      height: 10px;
      border-radius: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
      transition: all 0.2s linear !important;
      -webkit-transition: all 0.2s linear !important;
      -moz-transition: all 0.2s linear !important;
      -ms-transition: all 0.2s linear !important;
      -o-transition: all 0.2s linear !important;
      &:hover {
        background: $black;
      }
    }
    &::-webkit-scrollbar-track {
      background: #eee;
      opacity: 1;
      transition: all 0.2s linear;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      -ms-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
    }
  }

  .checkbox-adj .label-checkbox {
    // white-space: nowrap;
  }
  .comman-top-menu .top-menu-right {
    right: 100px;
    position: relative;
  }
  .float-right-lg {
    float: right !important;
  }
  .mt130-lg {
    margin-top: 130px !important;
  }
  .mt20-lg {
    margin-top: 20px !important;
  }
  .mt40-lg {
    margin-top: 40px !important;
  }
  .top-60-lg {
    position: relative;
    top: 60px !important;
  }
  .no-wrap-lg {
    white-space: nowrap !important;
  }
  * {
    padding: 0;
    margin: 0;
    outline: none !important;
    // cursor: none !important;
  }

  .home-sec1 {
    .comman-card-info {
      max-width: 670px;
    }
  }
  .about-sec4 {
    .our-values-wrap-outer {
      // min-height: 1600px;
      position: relative;
    }
  }
  .about-sec7 {
    .about-sec7-container {
      // min-height: 2400px;
    }
  }

  // .industries-left-sec-height{
  //   min-height: 5600px !important;
  // }
  // .process-left-sec-height{
  //   min-height: 3420px !important;
  // }

  .comman-card-bottom-ul {
    li {
      margin-right: 30px !important;
    }
  }
  .rsmart-sect7 {
    .rsmart-right-wrap {
      position: relative;
      transform: translateY(30px);
      -webkit-transform: translateY(30px);
      -moz-transform: translateY(30px);
      -ms-transform: translateY(30px);
      -o-transform: translateY(30px);
    }
  }
  .about-sec2 {
    .show-mobile-adj {
      display: none !important;
    }
  }

  .term-section1 {
    .term-card-wrap-outer {
      min-height: 1800px;
    }
  }
  .viewer-section .bottom-col-set {
    position: relative;
    bottom: -40px;
  }

  .mytrux-webapp-sec4.viewer-section .bottom-col-set {
    position: relative;
    bottom: 0px;
  }
  .rsmart-sect4.viewer-section .bottom-col-set {
    position: relative;
    bottom: 0px;
  }

  .impact-section-upd .comman-impact-list-inline li:first-child {
    min-width: 140px;
  }
  .portfolio-website-moneyguru-body {
    .bagshaha-sec5 .bagshaha-sec5-right {
      position: absolute;
      top: 10px;
      right: 0;
      width: 50%;
      height: 100vh;
      bottom: 0px;
    }
  }
  .phycheme-sect3-right-adj {
    margin-top: -180px;
    margin-left: 60px;
  }
  // .home-sec6 .award-logo-wrap ul {
  //   min-height: 1160px;
  // }
  .comman-solution-challenge {
    .portfolio-info-wrap2 {
      min-height: 320px;
    }
  }
  .rsmart-sec5 {
    .rsmart-architecture-wrap {
      .rsmart-architecture-img {
        margin-top: 10px;
      }
    }
  }
  .expertise-sect2 {
    .expertise-sect-right {
      width: 400px;
      float: right;
    }
  }

  .portfolio-thumb-all {
    &:nth-child(odd) {
      padding-top: 50px !important;
    }
  }
  .h-1065 {
    height: 1065px !important;
  }
  .h-2000 {
    height: 2150px !important;
  }
  .phycheme-about-bg-img {
    width: 50vw !important;
  }
  .img-fluid.smartlinks-sect4-1.lazy {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .portfolio-webapp-mytrux-body .mytrux-webapp-sect6 .row.h-100 {
    margin-top: 50px;
  }
  .portfolio-webapp-mytrux-body
    .mytrux-webapp-sect3
    .mytrux-webapp-sect3-left-list {
    white-space: nowrap;
    margin-left: -50px;
    margin-top: 15px;
    transform: scale(0.7) !important;
    transform-origin: 170px 0;
    -webkit-transform: scale(0.7) !important;
    -moz-transform: scale(0.7) !important;
    -ms-transform: scale(0.7) !important;
    -o-transform: scale(0.7) !important;
  }
  .mytrux-webapp-sect8-b.portfolio-info-wrap2.ml85 {
    min-height: 470px;
  }
  .mytrux-webapp-sect3-left.portfolio-info-wrap2 {
    margin-top: 60px;
  }
}
@media (min-width: 992px) {

  .comman-section.comman-section-100vh.scroll-div.personas-sec-slider.bottom-col-section.mobile-adj .choose_slider_items {
    position: relative;
    left: -100px;
      top: 100px;
  }
  .comman-section.comman-section-100vh.scroll-div.esop-sec10.mobile-adj .flexifyme-flowdiagram-t-60-img {
    top: 0 !important;
  }
  .comman-section.comman-section-100vh.mytrux-webapp-sect4.scroll-div.mobile-adj1 .mytrux-webapp-sect4-2.img-fluid.lazy.wireframe-img1 {
    top: 60px;
  }
  
  .comman-section.comman-section-100vh.comman-solution-challenge.moneyguru-sec2.overflow-hidden.scroll-div .moneyguru-sec2-3.img-fluid.lazy.flexifyme-overview-img {
    top: -40px;
  }
}

// Large devices (desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1599px) {
  .circle-sec {
    width: 600px;
    height: 600px;
  }
  .portfolio-website-bynry-body
    .bynry-website-sec4
    .authntick-sec9-2-outer
    img {
    transform: scale(0.8);
    transform-origin: center bottom;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
  }
  a.mega-menu.mega-menu-close {
    top: 0;
    left: 10px;
    position: relative;
  }
  .authntick-sec9 .authntick-result-carosual .owl-stage-outer.owl-height {
    height: 346px !important;
  }
  .comman-section {
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
      max-width: 1110px !important;
    }
  }
  .arrow-scroll-abs {
    top: calc(100% - 120px);
  }
  .comman-header-menu-right {
    top: 63px;
    right: 65px;
  }
  .comman-top-menu .top-menu-left .btn-line span::after {
    top: 38px;
  }

  .comman-top-menu {
    clip-path: circle(30px at calc(100% - 90px) 70px);
    -webkit-clip-path: circle(30px at calc(100% - 90px) 70px);
    .mega-menu.mega-menu-close {
      position: relative;
    }
  }

  .comman-card-left {
    padding-right: 60px;
  }
  .comman-card-right {
    padding-left: 60px;
  }
  .comman-section .comman-card-info .comman-card-top-ul {
    margin-bottom: 13px;
  }
  h1,
  .h1 {
    font-size: $h1-font-size - 16;
    line-height: 72.8px !important;
  }
  h2,
  .h2 {
    font-size: $h2-font-size - 14;
    line-height: 62.4px !important;
  }
  h3,
  .h3 {
    font-size: $h3-font-size - 14;
    line-height: 52px !important;
  }
  h4,
  .h4 {
    font-size: $h4-font-size - 4 !important;
    line-height: 36.4px !important;
  }

  h5,
  .h5 {
    font-size: $h5-font-size;
    line-height: 36.4px !important;
  }
  h6,
  .h6 {
    font-size: $h6-font-size;
  }
  .p1 {
    font-size: 24px;
    line-height: 38px;
    margin: 0;
  }
  .p2 {
    font-size: 20px;
    line-height: 32.4px;
    margin: 0;
  }

  .p3 {
    font-size: 16px;
    line-height: 25.9px;
    margin: 0;
  }
  .p4 {
    font-size: 14px;
    line-height: 22.6px;
    margin: 0;
  }
  .p5 {
    font-size: 12px;
    line-height: 19.4px;
    margin: 0;
  }
  .lg-mt-20 {
    margin-top: -20px !important;
  }
  .comman-section-100vh {
    .comman-card-img-wrap {
      transform: translateZ(0) scale(0.8);
      transform-origin: left center;
      -webkit-backface-visibility: hidden;
      -webkit-transform: translateZ(0) scale(0.8);
      -moz-transform: translateZ(0) scale(0.8);
      -ms-transform: translateZ(0) scale(0.8);
      -o-transform: translateZ(0) scale(0.8);
      margin-left: 30px;
      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
    .comman-card-info {
      h2.sec-heading {
        margin-bottom: 20px;
        margin-left: -3px;
      }
      p.sec-para {
        margin-bottom: 25px;
        max-width: 550px;
      }
      .comman-card-bottom-ul {
        margin-bottom: 20px;
      }
      .bttn {
        margin-top: 15px;
      }
    }
  }
  .carousel-indicators-ul {
    top: 62%;
    left: -68px;
  }
  .carousel-indicators-ul-adj {
    left: -60px;
  }
  #home-carousel-indicators-ul {
    margin-top: 0;
    margin-left: -605px;
  }
  body {
    .comman-section {
      padding: 100px 0px 60px ;
      .vh100-100 {
        min-height: calc(100vh - 100px);
        position: relative;
      }
    }
  }
  .comman-top-menu .top-menu-bottom li:not(:last-child) {
    margin-right: 15px;
  }
  .comman-footer .bottom-menu-bottom-left li:not(:last-child),
  .comman-footer .bottom-menu-bottom-right li:not(:last-child) {
    margin-right: 15px;
  }
  .comman-footer {
    .comman-footer-row1 {
      height: calc(100vh - 30px);
    }
    .comman-footer-row2 {
      bottom: -20px;
    }
  }
  .comman-top-menu {
    .top-menu-left {
      margin-top: -10px;
    }
    .top-menu-left {
      li {
        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }
    }
    .top-menu-right {
      li {
        &:not(:last-child) {
          margin-bottom: 35px;
        }
      }
    }
  }
  .career-sec2 {
    .career-sec2-left {
      .fixed-left {
        margin-top: 170px;
      }
    }
  }
  .award-card {
    max-width: 260px;
  }
  .home-sec-tab .flex-column .nav-link {
    white-space: nowrap;
  }
  .home-sec-tab .flex-column .nav-link span::after {
    width: 28px;
  }
  .home-sec-tab .flex-column .nav-link.active span::after {
    opacity: 0;
    width: 28px;
  }
  .home-sec-tab .flex-column .nav-link:hover span::after {
    opacity: 1;
    width: 28px;
  }
  .home-sec-tab {
    .testi-comman-card-info {
      .p1 {
        margin-top: 35px;
      }
    }
    .flex-column {
      padding-top: 20px;
      .nav-link:not(:last-child) {
        margin-bottom: 40px;
      }
    }
    .tab-content {
      margin-left: 80px;
    }
  }
  .comman-card-img-wrap .home-sec1-1 {
    top: -72px;
  }

  .home-sec2 {
    .comman-card-info {
      position: relative;
      top: 20px;
      left: 2px;
    }
  }

  .home-sec3 {
    .comman-card-info {
      margin-top: 40px;
      margin-left: 0px;
    }
  }
  .home-sec4 {
    .client-logo-wrap-outer-col {
      height: 470px;
      overflow: hidden;
      position: relative;
      left: 40px;
      top: 20px;
    }
    .comman-card-info {
      margin-top: -50px !important;
    }
    .client-logo-wrap {
      margin-left: 6px;
      margin-top: 40px;
      ul {
        li {
          margin: 40px 0;
        }
      }
    }
  }
  .home-sec5 {
    .comman-card-info {
      margin-top: 30px !important;
    }
  }
  .process-section1 {
    .comman-card-info-left {
      margin-top: -27px;
    }
  }
  .home-sec7 {
    .process-img-wrap {
      img {
        display: inline-block;
        width: 700px;
        height: auto;
        max-width: inherit;
        margin-top: -10px;
        margin-left: -40px;
      }
    }
  }
  .comman-card-img-wrap2 {
    #culture-carousel::after {
      width: 167px;
    }
  }

  .home-sec8 {
    .comman-card-img-wrap2 {
      transform: scale(0.7) translateZ(0);
      -webkit-transform: scale(0.7) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.7) translateZ(0);
      -ms-transform: scale(0.7) translateZ(0);
      -o-transform: scale(0.7) translateZ(0);
      transform-origin: center right;
      left: 0;
      top: 46px;
      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }

  .home-sec5.home-sec-tab {
    .tab-content {
      margin-top: 50px;
    }
  }

  .about-sec1 {
    .comman-card-info {
      margin-top: 60px;
    }
  }
  .about-sec3 {
    .comman-card-info {
      max-width: 500px;
      // height: 370px;
      margin: auto;
    }
  }

  .about-sec4 {
    .our-values-wrap {
      max-width: 500px;
      margin: 30px 20px 0 73px;
    }
  }
  .about-sec5 {
    .comman-card-info {
      // margin-top: -140px;
    }
    .our-expressions-wrap {
      margin-top: 0;
      transform: scale(0.7) translateZ(0) !important;
      -webkit-transform: scale(0.7) translateZ(0) !important;
      -moz-transform: scale(0.7) translateZ(0) !important;
      -ms-transform: scale(0.7) translateZ(0) !important;
      -o-transform: scale(0.7) translateZ(0) !important;
      transform-origin: center 200px;
      margin-left: -75px;
      /*.comman-video:;
{
        transform: scale(0.70) translateZ(0);
        -webkit-transform: scale(0.70) translateZ(0); -webkit-backface-visibility: hidden; 
        -moz-transform: scale(0.70) translateZ(0);
        -ms-transform: scale(0.70) translateZ(0);
        -o-transform: scale(0.70) translateZ(0);
        transform-origin: center 200px;
         img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
}
        }
        .about-sec5-1 {
            position: absolute;
            top: 150px;
            right: 85px;
            z-index: 1;
            height: 330px;
        }*/
    }
  }
  .about-sec6 {
    .comman-card-img-wrap3-outer {
      height: 530px;
    }
    .comman-card-img-wrap3 {
      margin: 140px auto 0;
      transform: scale(0.65) translateZ(0);
      -webkit-transform: scale(0.65) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.65) translateZ(0);
      -ms-transform: scale(0.65) translateZ(0);
      -o-transform: scale(0.65) translateZ(0);
      transform-origin: center top;
    }
  }
  .about-sec7 {
    .comman-card-info {
      margin: 15px auto 50px;
    }
  }
  .comman-team-card {
    margin-top: 0px;
    .comman-team-card-head {
      transform: scale(0.8) translateZ(0);
      transform-origin: center bottom;
      -webkit-transform: scale(0.8) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.8) translateZ(0);
      -ms-transform: scale(0.8) translateZ(0);
      -o-transform: scale(0.8) translateZ(0);
      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .work-comman-card-info {
    margin-top: 70px;
  }
  .work-sec6 {
    .comman-card-info {
      h2.sec-heading {
        br {
          display: none;
        }
      }
    }
  }

  .process-section1,
  .industries-section1 {
    .comman-card-info {
      &:not(:last-child) {
        margin-bottom: 120px;
      }
    }
    .process-img7 {
      right: 310px;
      height: 950px;
    }
  }

  .contact-section1 {
    overflow: hidden;
    .sec-heading {
      margin-top: 0;
    }
    .addr-wrap {
      margin-left: 0;
      margin-top: 0;
      &.mb90 {
        margin-bottom: 20px !important;
      }
      .addr-img-wrap {
        position: relative;
        max-width: 130px;
        margin: 0;
        height: 53px;
        transform: scale(0.7) translateZ(0);
        transform-origin: 0 0;
        -webkit-transform: scale(0.7) translateZ(0);
        -webkit-backface-visibility: hidden;
        -moz-transform: scale(0.7) translateZ(0);
        -ms-transform: scale(0.7) translateZ(0);
        -o-transform: scale(0.7) translateZ(0);
        img {
          filter: none;
          -webkit-filter: none;
          -moz-filter: blur(0px);
          -ms-filter: blur(0px);
          -webkit-backface-visibility: hidden;
        }
      }
    }
  }
  .contact-section2 {
    overflow: hidden;
    .career-form-wrap {
      margin-top: 80px;
    }
  }
  .career-form-wrap {
    margin-top: 60px;
  }
  .expertise-sec1 {
    .vh100-100 {
      overflow: hidden;
    }
    .comman-card-info {
      .sec-heading {
        br {
          display: none;
        }
      }
    }
    .comman-card-img-wrap2 {
      left: -40px;
      top: 10px;
      height: 500px;
      min-height: inherit;
      img {
        max-width: 100%;
      }
    }
  }

  .expertise-sec4 {
    padding-top: 0;
  }
  .expertise-sec2-1 {
    h2 {
      margin-top: 65px;
    }
  }
  .expertise-sec4,
  .expertise-sec5 {
    .vh100-100 {
      padding-top: 120px;
    }
  }

  .contact-section1 {
    .addr-wrap {
      &.mb90 {
        margin-bottom: 30px !important;
      }
      .addr-indo-wrap {
        margin-top: 15px;
        .p1 {
          margin-bottom: 10px;
        }
        .p3 {
          margin-bottom: 5px;
        }
        a {
          .location-icon {
            height: 20px;
          }
        }
        .addr-list-inline {
          margin-bottom: 10px;
          white-space: nowrap;
        }
      }
    }
  }

  .contact-section2 {
    .my-budget-wrap {
      padding-left: 100px;
    }
    .contact-form-inner {
      table {
        td {
          width: 50%;
          padding-bottom: 15px;
          .radioOverride label.form-label {
          }
        }
      }
    }
  }
  .g-capcha-col-wrap {
    margin-top: -20px;
  }
  .term-col-wrap {
    margin-top: -7px;
  }
  .career-form-wrap {
    .contact-breadcrum {
      margin-bottom: 25px;
    }
    .range-slider-main {
      .slider.slider-vertical {
        height: 310px;
        width: 20px;
      }
      .slider-tick-label {
        margin-top: -1px;
      }
    }
    .mb50 {
      margin-bottom: 30px !important;
    }
    .form-control-col-btn {
      margin-top: 20px;
    }
    .pr-form-control {
      position: relative;
      margin-bottom: 50px;
    }
  }

  .career-details-sec1 {
    .career-details-sec1-row {
      margin-top: 85px;
    }
    .career-details-sec2-row {
      margin-top: 65px;
      .career-details-card {
        margin-bottom: 50px;
      }
    }
  }

  .crubba-sec1 {
    .portfolio-img-wrap {
      transform: scale(0.75) translateZ(0);
      -webkit-transform: scale(0.75) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.75) translateZ(0);
      -ms-transform: scale(0.75) translateZ(0);
      -o-transform: scale(0.75) translateZ(0);
      transform-origin: right center;
    }
  }
  .crubba-sec2 {
    background-size: 360px auto;
    .crubba-sec2-img2 {
      top: 140px;
      left: -10px;
    }
    .crubba-sec2-img3 {
      position: absolute;
      top: 180px;
      right: -10px;
    }
  }
  .crubba-sec3 {
    .crubba-sec3-right {
      transform: scale(0.75) translateZ(0);
      -webkit-transform: scale(0.75) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.75) translateZ(0);
      -ms-transform: scale(0.75) translateZ(0);
      -o-transform: scale(0.75) translateZ(0);
      transform-origin: center center;
    }
  }

  .crubba-sec4 {
    padding-bottom: 0;
    .crubba-sec4-center {
      margin-top: 30px;
    }
    .crubba-sec4-bottom1 {
      width: 300px;
    }
    .crubba-sec4-bottom {
      margin-top: 40px;
    }
    .viewer, .ng-draggable {
      width: 100%;
      height: 380px;
    }
  }
  .crubba-sec5 {
    padding-bottom: 0 !important;
    background-position: right bottom 50px;
    background-size: 150px;
    .crubba-sec5-center {
      margin-top: 30px;
    }
    .crubba-sec5-bottom3 {
      position: absolute;
      top: 60px;
      left: -10px;
    }
    .crubba-sec5-col-height {
      height: 660px;
      overflow: hidden;
    }
  }
  .crubba-sec6 {
    .crubba-sec6-bottom {
      background-size: contain;
      padding: 28px;
      height: 350px;
      margin-top: 40px;
      .owl-caroual-comman {
        display: block;
        width: 620px;
        margin: auto;
      }
    }
  }
  .crubba-sec7 {
    & > .container {
      overflow: hidden;
    }
    .crubba-sec7-bottom {
      transform: scale(0.55) translateZ(0);
      -webkit-transform: scale(0.55) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.55) translateZ(0);
      -ms-transform: scale(0.55) translateZ(0);
      -o-transform: scale(0.55) translateZ(0);
      transform-origin: center top;
      height: 350px;
      margin-left: -80px;
      .crubba-sec7-bottom2 {
        right: -50px;
      }

      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
    .crubba-sec7-bottom4-hover {
      margin-left: 42px !important;
    }
  }

  .crubba-sec8 {
    .crubba-sec8-center {
      margin-left: 20px;
      margin-top: 40px;
    }
    .crubba-sec8-bottom1 {
      max-width: 100%;
      display: block;
    }
  }
  .crubba-sec9 {
    background-size: 280px auto;
    background-position-y: 100px;
    .crubba-sec9-bottom2 {
      max-width: 1010px;
    }
  }

  .crubba-sec11 {
    background-size: 280px auto;
    background-position-y: 250px;
    padding-bottom: 0;
    .container-fluid {
      overflow: hidden;
    }
    .crubba-sec11-bottom {
      position: relative;
      left: -20px;
      height: 800px;
    }
    .crubba-sec11-bottom1 {
      max-width: 1100px;
    }
  }
  .crubba-sec12 {
    height: 1780px !important;
    &::after {
      height: 760px;
    }
    .mobile-row {
      height: 1400px;
    }
    .crubba-sec12-1 {
      position: relative;
      left: -80px;
      width: 320px;
    }
    .crubba-sec12-2 {
      margin: -70px 0 0 35px;
      max-width: 100%;
    }
    .crubba-sec12-3 {
      display: block;
      margin: 60px auto -10px;
      max-width: 100%;
    }
    .crubba-sec12-4 {
      max-width: 100%;
    }
    .crubba-sec12-6 {
      display: block;
      margin: 182px auto 0 40px;
      width: 400px;
    }
    .crubba-sec12-7 {
      max-width: 100%;
    }
    .crubba-sec12-5-wrap {
      position: relative;
      -webkit-backface-visibility: hidden;
      transform: scale(0.9) translateZ(0) !important;
      -webkit-transform: scale(0.9) translateZ(0) !important;
      -moz-transform: scale(0.9) translateZ(0) !important;
      -ms-transform: scale(0.9) translateZ(0) !important;
      -o-transform: scale(0.9) translateZ(0) !important;
      transform-origin: center top;
      height: 600px;
      margin-left: -10px;
      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }

  .comman-impact-section {
    .h44 {
      margin-bottom: 40px;
    }
    .comman-impact-top {
      hr {
        margin: 45px auto -30px;
      }
    }
    .crubba-impact1-circle {
      top: -50px;
      transform: scale(0.85) translateZ(0);
      -webkit-transform: scale(0.85) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.85) translateZ(0);
      -ms-transform: scale(0.85) translateZ(0);
      -o-transform: scale(0.85) translateZ(0);
      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .moneyguru-header-section {
    .moneyguru-header-left {
      width: 630px;
    }

    .moneyguru-banner-img {
      width: 500px;
      margin-top: 20px;
    }
  }
  .moneyguru-business-section .moneyguru-overview-wrap {
    margin-left: 40px;
  }
  .moneyguru-strategy-section {
    .moneyguru-strategy-left-col-img {
      position: absolute;
      top: -40px;
      left: -120px;
      right: 0;
      bottom: 0;
      height: 100vh;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      max-width: calc(50% + 120px);
    }
    .moneyguru-strategy-right {
      margin-left: 40px;
    }
  }

  .moneyguru-story-section {
    overflow: hidden;
    .moneyguru-story-left {
      margin-top: 30px;
      margin-left: 0;
      transform: scale(0.7) translateY(-50%);
      transform-origin: 0px 0px;
      -webkit-transform: scale(0.7) translateY(-50%);
      -moz-transform: scale(0.7) translateY(-50%);
      -ms-transform: scale(0.7) translateY(-50%);
      -o-transform: scale(0.7) translateY(-50%);
      position: absolute;
      top: 50%;
      left: 0;
    }
  }
  .moneyguru-insights-section {
    overflow: hidden;
    .moneyguru-insights-right-col {
      .moneyguru-insights-right {
        height: 640px;
        video {
          transform: scale(0.7) translateY(-50%);
          transform-origin: 0px 0px;
          -webkit-transform: scale(0.7) translateY(-50%);
          -moz-transform: scale(0.7) translateY(-50%);
          -ms-transform: scale(0.7) translateY(-50%);
          -o-transform: scale(0.7) translateY(-50%);
          position: absolute;
          top: 50%;
          left: 60px;
        }
      }
    }
  }
  .moneyguru-investments-section {
    .moneyguru-investments-left {
      height: 550px;
    }
    .moneyguru-investments-left-col {
      video {
        margin-left: 0;
        transform: scale(0.7) translateY(-50%);
        transform-origin: 0px 0px;
        -webkit-transform: scale(0.7) translateY(-50%);
        -moz-transform: scale(0.7) translateY(-50%);
        -ms-transform: scale(0.7) translateY(-50%);
        -o-transform: scale(0.7) translateY(-50%);
        position: absolute;
        top: 50%;
      }
    }
  }
  .moneyguru-gateway-section {
    .moneyguru-gateway-right {
      height: 550px;
      video {
        transform: scale(0.7) translateY(-50%);
        transform-origin: 0px 0px;
        -webkit-transform: scale(0.7) translateY(-50%);
        -moz-transform: scale(0.7) translateY(-50%);
        -ms-transform: scale(0.7) translateY(-50%);
        -o-transform: scale(0.7) translateY(-50%);
        position: absolute;
        top: 50%;
      }
    }
  }
  .moneyguru-investing-section {
    overflow: hidden;
    .moneyguru-investing-left-col {
      .moneyguru-investing-left {
        height: 560px;
      }
      video {
        margin-left: 0;
        transform: scale(0.7) translateY(-50%);
        transform-origin: 0px 0px;
        -webkit-transform: scale(0.7) translateY(-50%);
        -moz-transform: scale(0.7) translateY(-50%);
        -ms-transform: scale(0.7) translateY(-50%);
        -o-transform: scale(0.7) translateY(-50%);
        position: absolute;
        top: 50%;
      }
    }
    .moneyguru-investing-right {
      h6 {
        margin-left: 70px;
      }
    }
  }
  .moneyguru-comprehensive-section {
    position: relative;
    overflow: hidden;
    .moneyguru-comprehensive-bottom {
      height: 280px;
      video {
        width: 590px;
        height: auto;
      }
    }
  }
  .easy-plan-sec3 {
    .container {
      max-width: inherit !important;
    }
    .easy-plan-strategy-right {
      margin-right: 78px;
    }
  }

  .easy-plan-sec4 {
    .easy-plan-strategy-left {
      .easy-plan-strategy-left-inner {
        .easy-plan-onboarding1 {
          top: -40px;
        }
        .easy-plan-onboarding2 {
          top: -70px;
        }
      }
      -webkit-backface-visibility: hidden;
      transform: scale(0.65) translateZ(0) !important;
      height: 500px;
      transform-origin: center 40px;
      -webkit-transform: scale(0.65) translateZ(0) !important;
      -moz-transform: scale(0.65) translateZ(0) !important;
      -ms-transform: scale(0.65) translateZ(0) !important;
      -o-transform: scale(0.65) translateZ(0) !important;
      top: 0;
      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
    .easy-plan-strategy-left-inner2 {
      top: 50px;
      margin-bottom: 0;
      left: 100px;
    }
  }

  .easy-plan-sec5 {
    .ease-plan-chat-wrap {
      height: 500px;
      transform-origin: center -160px;
      transform: scale(0.7) translateY(-50%);
      -webkit-transform: scale(0.7) translateY(-50%);
      -moz-transform: scale(0.7) translateY(-50%);
      -ms-transform: scale(0.7) translateY(-50%);
      -o-transform: scale(0.7) translateY(-50%);
      position: absolute;
      top: 50%;
      .easy-plan-chat3 {
        position: absolute;
        bottom: -230px;
        right: -50px;
      }
    }
  }

  .easy-plan-sec6 {
    .easy-plan-sec6-inner {
      height: 500px;
      transform-origin: 0 -260px;
      transform: scale(0.6) translateY(-50%);
      -webkit-transform: scale(0.6) translateY(-50%);
      -moz-transform: scale(0.6) translateY(-50%);
      -ms-transform: scale(0.6) translateY(-50%);
      -o-transform: scale(0.6) translateY(-50%);
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: 10px;
    }
  }
  .easy-plan-sec8 {
    .easy-plan-sec8-inner {
      height: 500px;
      transform-origin: center 250px;
      transform: scale(0.6) translateY(-50%);
      -webkit-transform: scale(0.6) translateY(-50%);
      -moz-transform: scale(0.6) translateY(-50%);
      -ms-transform: scale(0.6) translateY(-50%);
      -o-transform: scale(0.6) translateY(-50%);
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: 30px;
    }
  }
  .easy-plan-sec9 {
    .easy-plan-sec9-inner {
      height: 600px;
      transform: scale(0.7) translateZ(0);
      -webkit-transform: scale(0.7) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.7) translateZ(0);
      -ms-transform: scale(0.7) translateZ(0);
      -o-transform: scale(0.7) translateZ(0);
      transform-origin: right 200px;
      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .easy-plan-sec10 {
    .easy-plan-sec10-inner {
      height: 600px;
      transform: scale(0.7) translateZ(0);
      -webkit-transform: scale(0.7) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.7) translateZ(0);
      -ms-transform: scale(0.7) translateZ(0);
      -o-transform: scale(0.7) translateZ(0);
      transform-origin: center 200px;
      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }

  .rsmart-sec3 {
    .rsmart-right-wrap {
      height: 600px;
      -webkit-backface-visibility: hidden;
      transform: scale(0.65) translateZ(0) !important;
      -webkit-transform: scale(0.65) translateZ(0) !important;
      -moz-transform: scale(0.65) translateZ(0) !important;
      -ms-transform: scale(0.65) translateZ(0) !important;
      -o-transform: scale(0.65) translateZ(0) !important;
      transform-origin: center 260px;
      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }

  .rsmart-sec6 {
    .container-fluid {
      .rsmart-left-wrap {
        height: 510px;
        padding-top: 50px;
      }
    }
  }
  .rsmart-sec8 {
    overflow: hidden;
    .rsmart-app-wrap {
      height: inherit !important;
    }
    .rsmart-right-wrap {
      transform: scale(0.8) translateZ(0);
      -webkit-transform: scale(0.8) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.8) translateZ(0);
      -ms-transform: scale(0.8) translateZ(0);
      -o-transform: scale(0.8) translateZ(0);
      transform-origin: center 0;
      top: 0;
      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .rsmart-sec9 {
    overflow: hidden;
    .rsmart-left-wrap,
    .rsmart-right-wrap {
      height: 850px;
      display: block;
    }
    .rsmart-app-view1-img,
    .rsmart-app-view2-img {
      width: 360px;
    }
  }

  .rsmart-sec10 {
    .rsmart-left-wrap {
      transform: scale(0.8) translateZ(0);
      -webkit-transform: scale(0.8) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.8) translateZ(0);
      -ms-transform: scale(0.8) translateZ(0);
      -o-transform: scale(0.8) translateZ(0);
      transform-origin: center center;
    }
    .rsmart-right-wrap {
      li:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .mytrux-logo-sec3 {
    .mytrux-logo-right-wrap {
      transform: scale(0.8) translateZ(0);
      -webkit-transform: scale(0.8) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.8) translateZ(0);
      -ms-transform: scale(0.8) translateZ(0);
      -o-transform: scale(0.8) translateZ(0);
      transform-origin: center center;
      height: inherit;
    }
  }
  .mytrux-logo-sec3 .mytrux-logo-right-wrap .mytrux-logo-sec3-2 {
    width: 440px;
  }
  .mytrux-logo-sec6 {
    .mytrux-logo-sec6-inner {
      h5 {
        margin-top: -20px;
      }
    }
    .mytrux-logo-sec6-1,
    .mytrux-logo-sec6-2 {
      width: 400px;
      margin: auto;
    }
  }
  .mytrux-logo-sec9 {
    .p2 {
      margin-top: 100px;
    }
  }
  .mytrux-logo-sec10 {
    .app-icon-ul li {
      display: inline-block;
      margin: 0px 5px;
      &:nth-child(3) {
        top: -8px;
        position: relative;
      }
      &:nth-child(4) {
        top: -8px;
        position: relative;
      }
      &:nth-child(5) {
        top: -8px;
        position: relative;
      }
    }
  }
  .mytrux-logo-sec11 {
    .mytrux-logo-left-wrap {
      margin-top: -150px;
    }
    .mytrux-logo-right-wrap {
      position: relative;
      transform: scale(0.7) translateZ(0);
      transform-origin: center 0;
      -webkit-transform: scale(0.7) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.7) translateZ(0);
      -ms-transform: scale(0.7) translateZ(0);
      -o-transform: scale(0.7) translateZ(0);
      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .mytrux-logo-sec14 {
    .mytrux-logo-sec14-2 {
      position: absolute;
      bottom: -320px;
      left: -220px;
      z-index: 2;
      width: 660px;
    }
  }

  .mytrux-logo-sec13-1-wrap,
  .mytrux-logo-sec12-1-outer {
    height: 500px;
    .img-fluid {
      max-width: 100%;
      height: 100%;
    }
  }
  .mytrux-website-sec1 {
    padding-bottom: 0 !important;
    .mytrux-website-center-wrap {
      margin-top: 70px;
    }
    .mytrux-website-bottom-wrap {
      height: 800px;
      .mytrux-banner-img {
        margin-top: -100px !important;
      }
    }
  }
  .bg-mytrux-head-ani {
    background: #0f2541
      url("../images/portfolio/portfolio-branding-mytrux/mytrux-banner-line.svg")
      no-repeat center bottom 430px;
    background-size: contain;
  }
  .mytrux-website-sec7 {
    .mytrux-website-sec7-1-outer {
      height: 2900px;
    }
  }
  .mytrux-website-sec9 {
    .container {
      height: 2500px;
      display: block;
    }
  }
  .mytrux-website-sec10 {
    .mytrux-website-top-wrap {
      transform: scale(0.6) translateZ(0);
      transform-origin: center 0;
      -webkit-transform: scale(0.6) translateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-transform: scale(0.6) translateZ(0);
      -ms-transform: scale(0.6) translateZ(0);
      -o-transform: scale(0.6) translateZ(0);
      height: 550px;
      img {
        filter: none;
        -webkit-filter: none;
        -moz-filter: blur(0px);
        -ms-filter: blur(0px);
        -webkit-backface-visibility: hidden;
      }
    }
  }
  .mytrux-website-sec9 {
    .mytrux-website-sec9-1 {
      max-width: initial;
      margin-left: -250px;
      margin-top: 70px;
      position: relative;
      z-index: 5;
      width: 800px;
    }
    .mytrux-website-sec9-3 {
      max-width: initial;
      margin-top: -220px;
      margin-left: -250px;
      width: 800px;
    }
    .mytrux-website-sec9-2 {
      margin-left: 47px !important;
    }
  }
  .mytrux-website-sec1 {
    .mytrux-website-bottom-wrap {
      .mytrux-banner-img {
        max-width: 100%;
        margin: auto;
        display: block;
      }
    }
  }

  .xts-sec1 {
    .xts-right-wrap {
      .img-fluid {
        max-width: 1040px;
      }
    }
  }
  .xts-sec4 {
    .xts-right-wrap {
      img.xts-sec4-1 {
        position: relative;
        z-index: 2;
        height: 500px;
      }
    }
  }
  .xts-sec6 {
    .xts-color-app-wrap {
      .xts-color-app-bg {
        position: absolute;
        bottom: 0;
        max-width: inherit;
        right: -343px;
        top: -280px;
        z-index: 3;
        height: 900px;
      }
    }
  }
  .easy-plan-sec1 {
    .easy-plan-banner-img {
      max-width: initial;
      width: 440px;
    }
  }
  .authntick-sec1 {
    .authntick-left-wrap {
      width: 480px;
    }
    .authntick-right-wrap {
      img {
        max-width: inherit;
        margin-left: 260px;
        margin-top: 20px;
        width: 600px;
      }
    }
    .authntick-fingerprint1 {
      position: absolute;
      bottom: -290px;
      left: -64px;
      width: 360px;
    }
    .authntick-fingerprint2 {
      position: absolute;
      top: -260px;
      left: 388px;
      width: 360px;
    }
  }
  .authntick-sec2 {
    .authntick-sec2-1 {
      position: absolute;
      bottom: -190px;
      left: -134px;
      width: 360px;
    }
    .authntick-right-wrap {
      margin-left: 50px;
    }
  }

  .authntick-sec4 {
    .authntick-sec4-3 {
      position: absolute;
      top: 26px;
      left: -82px;
      width: 360px;
    }
    .authntick-sec4-2-outer {
      .authntick-sec4-2 {
        display: inline-block;
        position: absolute;
        max-width: inherit;
        left: 50px;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 40px;
        width: 530px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
      }
    }
  }
  .authntick-sec6 {
    .authntick-sec6-1 {
      left: 270px;
      width: 400px;
      margin-top: 0px;
    }
    .authntick-sec6-2 {
      max-width: inherit;
      position: relative;
      z-index: 3;
      width: 690px;
    }
  }

  .authntick-sec7 {
    .ui-card-slider {
      transform: scale(2) translateZ(0);
      -webkit-transform: scale(2) translateZ(0);
      -moz-transform: scale(2) translateZ(0);
      -ms-transform: scale(2) translateZ(0);
      -o-transform: scale(2) translateZ(0);
    }
  }
  .authntick-sec8 {
    .authntick-sec8-2-outer {
      .authntick-wizard-carosual {
        width: 680px;
        margin-left: 0;
      }
    }
  }
  .smartlinks-sec1 {
    .smartlinks-logo-after {
      position: absolute;
      bottom: 27px;
      right: -380px;
      z-index: 1;
      width: 700px;
    }
  }
  .smartlinks-sect2 {
    position: relative;
    overflow: hidden;
    .smartlinks-logo-after2 {
      position: absolute;
      bottom: 27px;
      right: -480px;
      z-index: 1;
    }
  }

  .smartlinks-sec5 {
    .portfolio-info-wrap {
      .smartlinks-sec5-1 {
        margin-top: 20px;
      }
    }
  }
  .smartlinks-sec6 {
    .smartlinks-sec6-1 {
      margin-top: 50px;
    }
  }
  .smartlinks-sec7 {
    #comparison {
      #divisor,
      figure {
        background-position-x: 360px;
      }
    }
  }

  .smartlinks-sec8 {
    .smartlinks-sec8-1 {
      margin-top: 20px;
    }
  }
  .alloch-sec1 {
    .alloch-right-wrap {
      transform: scale(0.75);
      transform-origin: center 0;
      -webkit-transform: scale(0.75);
      -moz-transform: scale(0.75);
      -ms-transform: scale(0.75);
      -o-transform: scale(0.75);
    }
    .alloch-left-wrap {
      h2 {
        margin-bottom: 15px;
      }
    }
  }

  .alloch-sec2 {
    .alloch-right-wrap {
      &::after {
        transform-origin: center center;
        transform: scale(0.75);
        -webkit-transform: scale(0.75);
        -moz-transform: scale(0.75);
        -ms-transform: scale(0.75);
        -o-transform: scale(0.75);
      }
    }
    .alloch-left-wrap {
      margin-top: 100px;
    }
    .alloch-sec2-1 {
      top: 150px;
    }
    .alloch-sec2-2 {
      display: inline-block;
      position: relative;
    }
    .alloch-sec2-3 {
      .alloch-sec2-3-inner {
        width: 1400px;
      }
    }
  }
  .alloch-sec3,
  .alloch-sec4,
  .alloch-sec5 {
    .vh100-100 {
      margin-top: -60px;
    }
    .img-fluid {
      transform-origin: center center;
      transform: scale(0.75);
      -webkit-transform: scale(0.75);
      -moz-transform: scale(0.75);
      -ms-transform: scale(0.75);
      -o-transform: scale(0.75);
    }
  }
  .alloch-sec4 {
    .alloch-sec4-3-outer {
      left: 80px;
    }
  }
  .alloch-sec5 {
    .alloch-sec5-1-outer {
      left: 0px;
    }
  }
  .alloch-sec7 {
    .alloch-sec7-right {
      transform-origin: center 0;
      transform: scale(0.75);
      -webkit-transform: scale(0.75);
      -moz-transform: scale(0.75);
      -ms-transform: scale(0.75);
      -o-transform: scale(0.75);
    }
  }

  .alloch-sec8 {
    .portfolio-info-wrap {
      transform-origin: center 0;
      transform: scale(0.75);
      -webkit-transform: scale(0.75);
      -moz-transform: scale(0.75);
      -ms-transform: scale(0.75);
      -o-transform: scale(0.75);
      height: 580px;
    }
  }

  .alloch-sec9 {
    .portfolio-info-wrap.alloch-sec8-center {
      margin-left: -20px;
    }
    .portfolio-info-wrap {
      transform-origin: center 0;
      transform: scale(0.75);
      -webkit-transform: scale(0.75);
      -moz-transform: scale(0.75);
      -ms-transform: scale(0.75);
      -o-transform: scale(0.75);
    }
  }

  .alloch-sec10 {
    .alloch-sec10-1-outer {
      left: 0;
    }
    .img-fluid {
      transform-origin: center 0;
      transform: scale(0.7);
      -webkit-transform: scale(0.7);
      -moz-transform: scale(0.7);
      -ms-transform: scale(0.7);
      -o-transform: scale(0.7);
    }
    .alloch-sec10-right {
      margin-top: -70px;
    }
    .alloch-sec10-3-outer {
      top: 0;
    }
    .alloch-sec10-4-outer {
      bottom: 80px;
      position: absolute;
      right: 0;
      z-index: 3;
    }
    .alloch-sec10-5-outer {
      bottom: 252px;
      position: absolute;
      right: 80px;
      z-index: 3;
    }
    .alloch-sec10-6-outer {
      position: absolute;
      top: -177px;
      left: -670px;
      z-index: 1;
    }
    .alloch-sec7-left.portfolio-info-wrap2 {
      margin-top: -200px;
      margin-left: -40px;
    }
  }
  .alloch-sec11 {
    .img-fluid {
      transform-origin: center 0;
      transform: scale(0.8);
      -webkit-transform: scale(0.8);
      -moz-transform: scale(0.8);
      -ms-transform: scale(0.8);
      -o-transform: scale(0.8);
    }
    .alloch-sec11-right::after {
      content: "";
      position: absolute;
      top: -270px;
      left: 0px;
      width: 1200px;
      height: 1200px;
    }
    .alloch-sec11-1-outer {
      margin-top: -150px;
    }
    .alloch-sec11-3-outer {
      top: 30px;
    }
    .alloch-sec11-4-outer {
      top: -160px;
    }
  }
  .alloch-sec12 {
    .alloch-sec12-1-outer {
      top: 120px;
      .alloch-sec12-1 {
        transform-origin: center 0;
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        -o-transform: scale(0.8);
      }
    }
  }
  .alloch-sec13 {
    .alloch-sec12-1-outer {
      top: -580px;
      .alloch-sec12-1 {
        transform-origin: center 0;
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        -o-transform: scale(0.8);
      }
    }
  }
  .vigan-sec2 {
    .vigan-right-wrap {
      position: relative;
      left: 0;
      top: 0;
      height: 420px;
      img {
        transform-origin: center 0;
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        -o-transform: scale(0.8);
      }
    }
  }
  .vigan-sec3 {
    .vigan-sec3-1-outer1 {
      img {
        width: 320px;
      }
    }
  }
  .vigan-sec5 {
    .vigan-sec5-2-outer {
      margin-left: 280px;
    }
  }
  .vigan-sec6 {
    .vigan-right-wrap {
      width: 470px;
      margin-top: 18px;
      margin-left: 155px;
    }
  }
  .vigan-sec7 {
    .vigan-right-wrap {
      position: absolute;
      top: -220px;
      left: 0;
      .img-fluid {
        max-width: inherit;
        width: 600px;
        float: right;
      }
    }
  }
  .about-sec4 {
    .our-values-wrap {
      .our-values-card h4 {
        margin-bottom: 2px;
      }
    }
  }
  .about-sec5 {
    .comman-card-info {
      h2 {
        margin-top: 23px;
      }
    }
    .our-expressions-wrap {
      .about-sec5-1 {
        position: absolute;
        top: 150px;
        right: -85px;
        z-index: 1;
      }
    }
  }

  .notion-edge-sec5-1-wrap {
    transform: scale(0.9);
    transform-origin: center 0;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
  }
  .notion-edge-sec8 {
    #comparison {
      figure {
        position: absolute;
        background: url("../images/portfolio/portfolio-branding-notion-edge/notion-edge-sec8-2.svg")
          420px center no-repeat;
        background-size: 500px auto;
      }
      #divisor {
        background: $white
          url("../images/portfolio/portfolio-branding-notion-edge/notion-edge-sec8-1.svg")
          420px center no-repeat;
        background-size: 500px auto;
      }
    }
  }
  .insisive-website-sec2 {
    .insisive-website-sec2-1 {
      height: 330px;
      object-fit: cover;
    }
  }
  .mytrux-webapp-sec6 {
    .mytrux-webapp-sec6-right {
      .mytrux-web-sec6-1 {
        position: absolute;
        top: 100px;
        right: 150px;
      }
    }
  }
  .mytrux-webapp-sec7 {
    .bottom-col-set {
      .img-fluid {
        height: 370px;
      }
    }
  }
  .mytrux-webapp-sec8 {
    .bottom-col-set {
      height: 260px;
    }
  }
  .mytrux-webapp-sec1 {
    .mytrux-webapp-sec1-1 {
      display: inline-block;
      max-width: inherit;
      width: 550px;
      margin-left: -60px;
      margin-top: 30px;
    }
  }
  .alloch-sec1 {
    .alloch-right-wrap {
      text-align: center;
      margin-top: 100px;
    }
  }
  .moneyguru-sec-website1 {
    h1 {
    }
  }
  .portfolio-branding-insisive-body {
    .insisive-logo-after-img {
      position: absolute;
      bottom: 30px;
      right: 30px;
      width: 250px;
      z-index: 1;
    }
    .bagshaha-sec4 {
      .bagshaha-sec4-right {
        .bagshaha-sec4-right-b {
          img {
            padding-top: 10px;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .notion-edge-sec1 {
    .notion-edge-logo-bg {
      display: inline-block;
      position: absolute;
      bottom: 30px;
      left: -430px;
      z-index: 0;
      width: 870px;
    }
    .notion-edge-left-wrap {
      .notion-edge-left-inner-wrap {
        position: relative;
        z-index: 5;
      }
    }
  }

  .rsmart-sect1 {
    .rsmart-overview-after-img {
      margin-left: 120px;
    }
    .rsmart-overview-before-img {
      top: -100px;
      margin-left: -130px;
    }
    .img-fluid.rsmart-sec1-1.lazy {
      margin-left: 40px;
    }
  }
  .rsmart-sect2 .rsmart-overview-after-img {
    top: 4px;
    left: -120px;
  }

  .portfolio-thumb-all {
    &:nth-child(odd) {
      padding-top: 50px !important;
    }
    .work-card {
      margin-bottom: 90px !important;
      .work-card-img {
        position: relative;
        transform: scale(0.8);
        transform-origin: center top;
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        -o-transform: scale(0.8);
        height: 480px;
      }
      .work-card-body {
        width: 390px;
      }
    }
  }
  .our-expressions-upd {
    transform: scale(0.8);
    transform-origin: center center;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
  }
  .process-section1 {
    // height: 4230px !important;
  }
  .industries-section1 {
    // height: 5865px !important;
  }
  .expertise-h2300 {
    // height: 2330px !important;
  }
  .expertise-h1730 {
    // height: 1760px !important;
  }
  .expertise-h1730 {
    // height: 1760px !important;
  }
  .services-user-experience-design-wrap {
    transform-origin: center right !important;
  }
  .portfolio-website-insisive2-body {
    // .insisive-website-sect1-1 {
    //   margin-top: -220px;
    //   margin-left: -170px;
    // }
    .img-fluid.insisive-website-sect1-1.lazy {
      margin-left: -50px;
    }
    .insisive-website-sect7 {
      position: relative;
      overflow: hidden;
      .insisive-website-sect7-wrap {
        transform-origin: center 50px;
        transform: scale(0.8) !important;
        -webkit-transform: scale(0.8) !important;
        -moz-transform: scale(0.8) !important;
        -ms-transform: scale(0.8) !important;
        -o-transform: scale(0.8) !important;
      }
    }
    .insisive-website-sect6 {
      .insisive-website-sect2-img {
        width: 400px;
      }
    }
    .img-fluid.insisive-website-sect5-img.lazy {
      margin-left: 87px;
    }
    .insisive-website-sect7-wrap {
      height: 430px;
    }
  }
  .portfolio-website-bynry-body
    .bynry-website-sec7
    .bagshaha-sec9-2-outer
    .bynry-devices {
    max-width: 660px;
  }
  .portfolio-webapp-mytrux-body {
    .mytrux-webapp-sect1 {
      .mytrux-webapp-sect1-1 {
        margin-top: -10px;
      }
      .mytrux-webapp-sect1-2 {
        position: absolute;
        left: -170px;
        top: 126px;
        z-index: 3;
        width: 350px;
      }
    }
    .mytrux-webapp-sect2 {
      .mytrux-website-sect2-1 {
        position: absolute;
        bottom: -85px;
        right: -50px;
        z-index: 3;
        width: 350px;
      }
    }
    .mytrux-webapp-sect3 {
      .mytrux-webapp-sect3-right-list {
        li {
          position: relative;
          padding-left: 40px;
          margin-bottom: 30px;
        }
      }
    }
    .mytrux-webapp-sect4 {
      .mytrux-webapp-sect4-1,
      .mytrux-webapp-sect4-2,
      .mytrux-webapp-sect4-3 {
        max-width: 600px;
      }
    }
    .mytrux-webapp-sect5 {
      .mytrux-webapp-sect5-1 {
        max-width: 920px;
        margin-top: -40px;
        margin-left: -132px;
      }
    }
    .mytrux-webapp-sect6 {
      .mytrux-webapp-sect6-1-wrap {
        .mytrux-webapp-sect6-1 {
          max-width: 670px;
          margin-top: 30px;
          margin-left: -30px;
        }
      }
      .mytrux-webapp-sect6-2-wrap {
        .mytrux-webapp-sect6-2 {
          max-width: 670px;
          margin-top: 152px;
          margin-left: -100px;
        }
      }
    }
  }
  .portfolio-webapp-mytrux-body
    .mytrux-webapp-sect6
    .mytrux-webapp-sect6-1-wrap
    p {
    top: 27px;
    margin-left: 50px;
  }
  .portfolio-webapp-mytrux-body
    .mytrux-webapp-sect6
    .mytrux-webapp-sect6-2-wrap
    p {
    top: 151px;
    margin-left: -20px;
  }
  .comman-section.comman-section-100vh.insisive-website-sect8.scroll-div.xs-pt0 {
    position: relative;
    overflow: hidden;
    h3 {
      position: relative;
      top: 40px;
    }
    .insisive-website-sect8-wrap {
      margin-top: 80px !important;
      transform: scale(0.8) !important;
      -webkit-transform: scale(0.8) !important;
      -moz-transform: scale(0.8) !important;
      -ms-transform: scale(0.8) !important;
      -o-transform: scale(0.8) !important;
    }
  }
  .custom-nav-wrap {
    li {
      &:not(:last-child) {
        margin-right: 45px;
      }
    }
  }
  .failure-section .failure-sec-right {
    top: 40px;
  }
 
  .esop-sec1{
    .esop-triangle1 {
      top: 10px;
      right: 300px;
  }
 
    .esop-triangle2 {
      bottom: -37px;
      left: -70px;
    }
    .esop-header-img {
      max-width: 640px;
margin-top: 40px;
margin-left: 30px;
  }
  }
  .esop-sec4 {
    background: #15269b url("../images/portfolio/portfolio-webapp-esop/esop-findability-bg.png") no-repeat right -400px bottom -185px;
    background-size: 1000px auto;
  }

  .esop-sec6{
    .esop-left-wrap{
      .esop-slider-circle {
        width: 600px;
        height: 600px;
      }
      .comman-scrollbar-wrap{
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        -o-transform: scale(0.8);
        transform-origin: 120px 0;
}
    }
  }
  .esop-sec7{
    .bottom-col-set {
      bottom: -60px;
      max-width: 700px;
      margin: auto;
  }
    .esop-center-wrap {
     // margin-top: 60px !important;
    }
  }
  .esop-sec8 {
    .esop-right-wrap {
      .esop-attention-wrap {
        transform: scale(0.75);
        transform-origin: 80px center;
        -webkit-transform: scale(0.75);
        -moz-transform: scale(0.75);
        -ms-transform: scale(0.75);
        -o-transform: scale(0.75);
}
    }
  }
  .circle-sec {
    width: 650px;
    height: 650px;
  }
  .esop-sec9{
    .esop-left-wrap{
      .img-fluid {
        margin-left: -180px;
        position: relative;
        top: 50px;
        max-width: 800px;
      }
    }
  }

  .esop-sec10 {
    .esop-left-wrap {
      margin-top: 110px;
      margin-left: 60px;
    }
    .esop-right-wrap {
      .img-fluid{
        max-width: 800px;
        margin-left: -40px;
        position: relative;
        top: 50px;
      }
    }
  }
  .esop-sec11 {
    .esop-left-wrap {
      .img-fluid {
        max-width: 740px;
        margin-left: -160px;
        position: relative;
        top: 23px;
      }
    }
  }
  .esop-sec2 {
    background-size: 95vh auto;
    background-position-y: 10px;  
  }
  .esop-sec12 {
    height: 700px;
    background-size: 95vh auto;
    background-position-y: 10px;
    .esop-center-wrap {
      position: relative;
      top: 100px;
    }
    .ui-card-slider {
      position: relative;
      width: 320px;
      height: 260px;
      overflow: hidden;
      transform: scale(3.5);
      transform-origin: 0 top;
      -webkit-transform: scale(3.5);
      -moz-transform: scale(3.5);
      -ms-transform: scale(3.5);
      -o-transform: scale(3.5);
}
  }
  .concerro-pattern-wrap .img-fluid {
    width: 400px;
  }
  .concerro-sec4 {
    .concerro-right-wrap {
      .concerro-brainstorming-1 {
        top: -170px;
      }
      .concerro-brainstorming-2 {
        top: 70px;
      }
    }
  }
  
  .source-trace-sec1 {
    .source-trace-right-wrap{
      img {
        top: 0;
        left: -130px;
        width: 650px;
      }
    }
  }
  .source-trace-sec2 {
    .farmers-img {
      width: 380px;
    }
  }
  .source-trace-sec3 {
    .small-tracker-img {
      bottom: -100px;
    }
  }
  .source-trace-sec4 {
    .corn-img {
      position: absolute;
      bottom: -70px;
      right: -130px;
      z-index: 2;
      width: 300px;
    }
  }
  .source-trace-sec5 {
    .info-architecture1 {
      max-width: 700px;
      display: block;
      margin: 10px auto 0;
    }
  }
  .source-trace-sec6{
  .source-trace-center-wrap{
    margin-bottom: 20px;
  }
  .wireframe-img{
    margin-top: 30px;
  }
}
  .source-trace-sec7{
    .leave2-img {top: 60px;}
    .farmer-flow {
      margin-top: 0;
      max-width: 600px;
    }
  }
  .source-trace-sec8 {
    .leave2-img {
      top: 180px;
    }
    .multi-dashboard {
      max-width: 650px;
      margin-top: 156px;
    }
  }
  .source-trace-sec10{
    .dt-img{
      max-width: 850px;
    }
    .db2 {
      bottom: -10px;
      left: -70px;
      max-width: 280px;
    }
  }  
  .source-trace-sec11.esop-sec6 {
    .esop-left-wrap{
      .comman-scrollbar-wrap {
        .esop-slider-outer-wrap {
          margin-left: 40px;
          animation: none;
          -webkit-animation: none;
          margin-top: -160px;
        }
      }
    }
  }
  .source-trace-sec12{    
    .gui-components {
      max-height: calc(100vh - 180px);
      margin: auto !important;
      display: block;
    }
  }

}
@media (min-width: 1200px) and (max-width: 1299px) {
  .failure-section .failure-sec-right {
    transform: translateY(200px) !important;
    -webkit-transform: translateY(200px) !important;
    -moz-transform: translateY(200px) !important;
    -ms-transform: translateY(200px) !important;
    -o-transform: translateY(200px) !important;
  }
  .portfolio-website-bynry-body
    .bynry-website-sec2
    .moneyguru-sec2-left
    .img-fluid {
    max-width: 720px;
  }
  .portfolio-website-bynry-body
    .bynry-website-sec1
    .bynry-banner-wrap
    .img-fluid {
    height: 80vh;
  }
  .crubba-sec8 .crubba-sec3-right img {
    height: 80vh;
    margin-top: 132px;
  }
  .crubba-sec9 .crubba-sec9-img-wrap .crubba-slider-mobile {
    width: 245px;
    height: 476px;
    margin-left: -107px;
    margin-top: -9px;
  }
  .portfolio-website-insisive2-body .insisive-website-sect2-img {
    width: 400px;
  }
  .portfolio-website-insisive2-body .img-fluid.insisive-website-sect5-img.lazy {
    margin-left: 97px;
  }
  .comman-section.comman-section-100vh.insisive-website-sect8.scroll-div.xs-pt0
    .insisive-website-sect8-wrap {
    margin-top: 80px !important;
  }
  .mytrux-webapp-sec6 .mytrux-webapp-sec6-right .mytrux-web-sec6-1 {
    top: 150px;
  }
  .rsmart-sec5 .rsmart-architecture-wrap .rsmart-architecture-img {
    margin-top: 80px;
  }
  .rsmart-sect6 .rsmart-sect6-2 {
    height: calc(100vh - 330px) !important;
  }
  .rsmart-sect7 .rsmart-strategy-img {
    margin-top: 50px;
  }
  .rsmart-sect8 .rsmart-app-view1-img,
  .rsmart-sect8 .rsmart-app-view2-img {
    margin-top: 90px;
  }
  .about-sec5-1 .about-sec-img-right .img-fluid,
  .about-sec5-2 .about-sec-img-right .img-fluid {
    max-width: 400px;
  }
  .authntick-sec9 .authntick-result-carosual .owl-stage-outer.owl-height {
    height: 315px !important;
  }
 
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min--moz-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3/2),
  only screen and (min-device-pixel-ratio: 1.5),
  only screen and (min-resolution: 192dpi) and (min-width: 1200px) {
  .mt60-ratina-lg {
    margin-top: 60px !important;
  }
  .mt100-ratina-lg {
    margin-top: 100px !important;
  }
}
